import "./message-sign-card.scss";
import * as template from "./message-sign-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MessageSignCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class MessageSignCard extends CityCard<MessageSignCardOptions> {

    constructor(context: InvipoContext, options: MessageSignCardOptions) {
        super(context, template, options);
    }

}
