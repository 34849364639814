import "./traffic-incident-card.scss";
import * as template from "./traffic-incident-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { TrafficIncidentCardOptions } from "./types";

export class TrafficIncidentCard extends CityCard<TrafficIncidentCardOptions> {

    // Properties
    public incident: any;

    constructor(context: InvipoContext, options: TrafficIncidentCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load incident
        this.incident = await this.context.invipo.getManagement(`traffic/incidents/${this.options.incidentId}`);
    }
}
