import "./street-light-controller-card.scss";
import * as template from "./street-light-controller-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { StreetLightControllerCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class StreetLightControllerCard extends CityCard<StreetLightControllerCardOptions> {

    constructor(context: InvipoContext, options: StreetLightControllerCardOptions) {
        super(context, template, options);
    }

}
