import "./monitoring-map.scss";
import * as template from "./monitoring-map.hbs";
import { Component } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapControl } from "../../common/map-control/map-control";
import { Feature, GeoJsonProperties, Point, Polygon } from "geojson";
import { MonitoringMapOptions } from "./types";
import { MonitoringDetail } from "../monitoring-detail/monitoring-detail";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { MonitoringDetailOptions } from "../monitoring-detail/types";
import { MonitoringSearch } from "../monitoring-search/monitoring-search";
import { ItemStatusCircleLayer } from "../../../layers/monitoring/item-status-circle-layer";
import { InvipoHelpers } from "../../../invipo-helpers";
import { MonitoringHud } from "../monitoring-hud/monitoring-hud";
import { TooltipLabelLayer } from "../../../layers/tooltip-label-layer";
import { Log } from "hiyo/log";
import { MonitoringCard } from "../monitoring-card/monitoring-card";

export class MonitoringMap extends Component<InvipoContext, MonitoringMapOptions> {

    // Components
    public map: BasicMap;
    public search: MonitoringSearch;
    public control: MapControl;
    public hud: MonitoringHud;
    public tooltip: TooltipLabelLayer;
    public card: MonitoringCard;
    public detail: MonitoringDetail;
    public status: ItemStatusCircleLayer;

    constructor(context: InvipoContext, options?: MonitoringMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createSearch();
        this.createControl();
        this.createHud();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
        this.registerComponent(this.search, "search");
        this.registerComponent(this.control, "control");
        this.registerComponent(this.hud, "hud");
    }

    public onDetach(): void {
        // Hide detail if visible
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Dark",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 20
        });

        // Show tooltip
        this.map.onFeatureEnter = (layer: string, feature: Feature, e: MouseEvent) => {
            // No tooltip defined?
            if (!feature.properties.tooltip) {
                return;
            }

            // Create tooltip layer
            this.tooltip = new TooltipLabelLayer(this.context, (<Point>feature.geometry).coordinates, feature.properties.tooltip, true);

            // Add tooltip to map
            this.map.addLayer(this.tooltip);
        }

        // Hide tooltip
        this.map.onFeatureLeave = (layer: string) => {
            // No tooltip displayed?
            if (!this.tooltip) {
                return;
            }

            // Remove tooltip from map
            this.map.removeLayer(this.tooltip.options.layer.id);
            this.tooltip = null;
        }

        // Select detail
        // This must be binded to "mousedown" event as it does not break detail hiding
        this.map.onFeatureMouseDown = async (layer: string, feature: Feature, event?: MouseEvent) => {
            await this.openCard(feature.properties, event);
        }

        // Zoom arrangements
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }

        // Create map layer
        this.status = new ItemStatusCircleLayer(this.context);

        // Add map layers
        this.map.addLayer(this.status);
    }

    private createSearch(): void {
        // Create component
        this.search = new MonitoringSearch(this.context);

        // Locate item
        this.search.onItemSelect = async (item: InvipoItem) => {
            // Open detail
            await this.openDetail({
                itemName: item.name,
                itemClass: item.class,
                itemId: item.id
            });

            // Map pulse
            this.map.pulseAt({
                type: "Monitoring",
                position: (<Point>item.geometry.location).coordinates,
            });

            // Locate on map
            this.map.flyTo({
                center: (<Point>item.geometry.location).coordinates,
                zoom: Math.max(this.map.options.zoom, 14),
                duration: 1200
            });
        }

        // Locate area
        this.search.onAreaSelect = (area: InvipoArea) => {
            let bounds = InvipoHelpers.toBounds(area.geometry);
            this.map.fitBounds(bounds);
        }

        // Filter class
        this.search.onClassSelect = async (name: string) => {
            // Set class filter to hud
            await this.hud.setItemClass(name);

            // Set class filter to status layer
            this.status.setItemClass(name);

            // Fit to all visible items
            //this.map.fitBounds(this.status.getBounds());
        }

        // Reset class
        this.search.onSearch = async () => {
            // If class was selected, every search type must reset class filter
            if (this.status.options.itemClass) {
                // Empty hud class filter
                await this.hud.setItemClass(null);

                // Empty status layer class filter
                this.status.setItemClass(null);
            }
        }
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Dark",
            styleItems: [
                {
                    name: "Dark",
                    label: "enums.MapStyle.Dark",
                    disabled: true
                },
                {
                    name: "Satellite",
                    label: "enums.MapStyle.Satellite"
                }
            ]

        });

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            this.map.fitAll();
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.context.options.home.center,
                zoom: this.context.options.home.zoom,
                duration: 3000
            })
        }

        // Change map style
        this.control.onStyleSelect = (name: string) => {
            this.map.setStyle(name);
        }
    }

    private createHud(): void {
        // Create component
        this.hud = new MonitoringHud(this.context, {
            itemClass: null
        });
    }

    public async openCard(properties: GeoJsonProperties, event?: MouseEvent): Promise<void> {
        // Card is already displayed?
        if (this.card?.isAttached() && this.card.options.itemId == properties.itemId) {
            if (!this.card.options.cluster) {
                // If no cluster, second click opens detail
                await this.openDetail(properties);
            }

            // Otherwise no action on second click
            return;
        }

        Log.i(`${this.id} opens monitoring card`);

        // Create detail
        this.card = new MonitoringCard(this.context, {
            style: "Dark",
            ...properties
        });

        // Handle item selection
        this.card.onItemSelect = (item: InvipoItem) => {
            // New set of properties
            properties = {
                type: properties.type,
                data: properties.data,
                card: `${item.class}Card`,
                detail: "CityDetail",
                itemId: item.id,
                itemName: item.name,
                itemClass: item.class
            }

            // Repin new detail to keep all bindings, callbacks etc.
            this.openCard(properties, event);
        }

        // Open data view
        this.card.onDetailView = async (content?: string) => {
            // TODO: content?
            await this.openDetail(properties);
        }

        // Show detail
        this.card.show(event?.x || (this.element.offsetWidth / 2 + 56), event?.y || (this.element.offsetHeight / 2));
    }

    private async openDetail(properties: GeoJsonProperties): Promise<void> {
        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: properties.itemName,
            subtitle: `classes.${properties.itemClass}`,
            itemId: properties.itemId,
            closable: true,
            localizable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MonitoringDetail(this.context, options);

        // OnClose handler
        this.detail.onClose = () => {
            this.map.resize();
        }

        // OnCenter handler
        this.detail.onCenter = (item: InvipoItem) => {
            this.map.pulseAt({
                type: "Monitoring",
                position: (<Point>item.geometry.location).coordinates,
            });

            this.map.flyTo({
                center: (<Point>item.geometry.location).coordinates,
                duration: 600
            });
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
        this.map.resize();
    }
}
