import "./city-navigator.scss";
import * as template from "./city-navigator.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { CityNavigatorOptions } from "./types";
import { CitySubdomain } from "../city-subdomain/city-subdomain";
import { COMPONENT_STORE } from "../../../store";
import { SubdomainMetrics } from "../city-subdomain/types";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { CityList } from "../city-list/city-list";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { HiyoEvent } from "../../../../hiyo/event-broker";

export class CityNavigator extends MuklitComponent<InvipoContext, CityNavigatorOptions> {

    // Components
    public subdomains: CitySubdomain[];

    // Event handling methods
    public onLayersChange(layers: MapLayer[]): void {}
    public onListCreate(list: CityList, metrics?: SubdomainMetrics): void {}

    constructor(context: InvipoContext, options: CityNavigatorOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createSubdomains();
    }

    private createSubdomains(): void {
        // Empty list
        this.subdomains = [];

        // Create all subdomains for all domains
        for (let domain of Object.keys(this.context.options.city)) {
            for (let subdomain of this.context.options.city[domain]) {
                let component: CitySubdomain = new COMPONENT_STORE[subdomain](this.context, {
                    domain: domain
                });

                // Subdomain selection
                component.onSelfSelect = () => {
                    this.selectSubdomain(component);
                }

                // Metrics selection
                component.onMetricsSelect = (metrics: SubdomainMetrics) => {
                    this.selectMetrics(metrics);
                }

                // Create list
                component.onListCreate = (list: CityList, metrics?: SubdomainMetrics) => {
                    this.onListCreate(list, metrics);
                }

                // Add to list
                this.subdomains.push(component);
            }
        }
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean): void {
        // Do component attach
        super.attach(target, before, skipLoad);

        // Set only visible contents for selected group
        this.arrange();
    }

    public detach(): void {
        // Dettach already attached subdomains
        for (let subdomain of this.subdomains) {
            if (subdomain.isAttached()) {
                subdomain.detach();
            }
        }

        // Do component detach
        super.detach();
    }

    public update(): void {
        // Do component update
        super.update();

        // Set only visible contents for selected group
        this.arrange();
    }

    public arrange(): void {
        // Dettach already attached subdomains
        for (let subdomain of this.subdomains) {
            if (subdomain.isAttached()) {
                subdomain.detach();
            }
        }

        // Get domain subdomains
        let subdomains = this.subdomains.filter(x => x.options.domain == this.options.domain);

        // Default select strategy if no subdomain is selected
        if (subdomains.length && !subdomains.find(x => x.options.selected)) {
            // Get subdomain from routing or take first one as default
            let subdomain = subdomains.find(x => x.name == this.context.application.state.subdomain) || subdomains[0];

            // Select
            subdomain.options.selected = true;
        }

        // Attach subdomains that should be visible under domain
        for (let subdomain of subdomains) {
            // Attach subdomain
            subdomain.attach(this.query("div.subdomains"));

            // Synchronize layers if selected
            if (subdomain.options.selected) {
                this.onLayersChange(subdomain.metrics.layers);
            }
        }
    }

    public selectDomain(): void {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "BottomLeft",
            anchor: "TopLeft",
            offset: [0, 4],
            items: []
        });

        // Push domains as items
        for (let domain of Object.keys(this.context.options.city)) {
            menu.options.items.push(
                {
                    name: domain,
                    label: `domains.${domain}`,
                    disabled: this.options.domain == domain
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Change domain
            this.options.domain = item.name;

            // Routing
            this.context.application.route({
                viewId: this.context.application.currentView.id,
                domain: item.name
            }, true);

            // Redraw and rearrange
            this.update();
        }

        // Show menu
        menu.show(this.query("div.header div.title"));
    }

    public selectSubdomain(subdomain: CitySubdomain): void {
        // Deselect all other subdomains
        for (let s of this.subdomains.filter(x => x.name != subdomain.name)) {
            // Deselect
            s.options.selected = false;

            // Redraw if visible
            if (s.isAttached()) {
                s.update();
            }
        }

        // Routing
        this.context.application.route({
            viewId: this.context.application.currentView.id,
            domain: this.options.domain,
            subdomain: subdomain.name
        }, true);

        // OnLayersChanged handler
        this.onLayersChange(subdomain.metrics.layers);
    }

    public selectMetrics(metrics: SubdomainMetrics): void {
        // OnLayersChanged handler
        this.onLayersChange(metrics.layers);
    }

}
