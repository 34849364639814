import "./havirov-dashboard.scss";
import * as template from "./havirov-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { Dashboard } from "../dashboard/dashboard";
import { HavirovDashboardOptions } from "./types";
import { TrafficOccupancyAreaTile } from "../traffic-occupancy-area-tile/traffic-occupancy-area-tile";

export class HavirovDashboard extends Dashboard<HavirovDashboardOptions> {

    constructor(context: InvipoContext, options?: HavirovDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficOccupancyAreaTile(this.context, {
                        title: "components.TrafficOccupancyAreaTile.title",
                        areaId: "663d0074340a1bed470d2b2d"
                    })
                ]
            }

        ];
    }
}
