import { AreaLayer } from "../area-layer";
import { InvipoContext } from "../../context/invipo-context";

const TRANSITION_DURATION = 600;
const MIN_ZOOM = 9;

export class StreetLightAreaBoundaryLayer extends AreaLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "StreetLight",
            dynamic: true,
            before: "road-label",
            layer: {
                id: "street-light-area-boundary",
                type: "line",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#000000",
                    "line-width": 4,
                    "line-opacity": 0,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    MIN_ZOOM, 0,
                    MIN_ZOOM + 0.5, 0.2
                ]
            }
        });
    }
}
