import "./city-map.scss";
import * as template from "./city-map.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapControl } from "../../common/map-control/map-control";
import { Feature, GeoJsonProperties, Point, Position } from "geojson";
import { Helpers } from "hiyo/helpers";
import { COMPONENT_STORE } from "../../../store";
import { Log } from "hiyo/log";
import { Detail } from "muklit/components/detail/detail";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MapWeather } from "../../common/map-weather/map-weather";
import { CityCard } from "../city-card/city-card";
import { HradecKraloveOrthophotoLayer } from "../../../layers/custom/hradec-kralove-orthophoto-layer";
import { CityMapOptions } from "./types";
import { CityNavigator } from "../city-navigator/city-navigator";
import { MapLayer, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { TooltipLabelLayer } from "../../../layers/tooltip-label-layer";
import { CityList } from "../city-list/city-list";
import { LngLatBounds } from "mapbox-gl";
import { SubdomainMetrics } from "../city-subdomain/types";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { InvipoHelpers } from "../../../invipo-helpers";
import { CuzkZtmLayer } from "../../../layers/custom/cuzk-ztm-layer";
import { CuzkOrthophotoLayer } from "../../../layers/custom/cuzk-orthophoto-layer";

export class CityMap extends MuklitComponent<InvipoContext, CityMapOptions> {

    // Components
    public map: BasicMap;
    public navigator: CityNavigator;
    public list: CityList;
    public control: MapControl;
    public weather: MapWeather;
    public card: CityCard;
    public tooltip: TooltipLabelLayer;
    public timer: any;

    constructor(context: InvipoContext, options?: CityMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createNavigator();
        this.createControl();
        this.createWeather();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
        this.registerComponent(this.navigator, "navigator");
        this.registerComponent(this.control, "control");
        this.registerComponent(this.weather, "weather");
    }

    public onDetach() {
        // Detach list when attached
        if (this.list?.isAttached()) {
            this.list.detach();
        }
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 21
        });

        // Check custom layers on style loaded
        this.map.onMapLoad = () => {
            // HradecKraloveOrthophoto style?
            if (this.map.options.style == "HradecKraloveOrthophoto") {
                this.map.mapboxMap.addLayer(new HradecKraloveOrthophotoLayer(this.context).options.layer);
            }
            // GoogleOrthophoto style?
            if (this.map.options.style == "GoogleOrthophoto") {
                this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer);
            }
            // CUZK ZTM map
            if (this.map.options.style == "CuzkZtm") {
                this.map.mapboxMap.addLayer(new CuzkZtmLayer(this.context).options.layer);
            }
            // CUZK Orthophoto map
            if (this.map.options.style == "CuzkOrthophoto") {
                this.map.mapboxMap.addLayer(new CuzkOrthophotoLayer(this.context).options.layer);
            }
        }

        // Show tooltip
        this.map.onFeatureEnter = async (layer: string, feature: Feature, event?: MouseEvent) => {
            // No tooltip defined?
            if (!feature.properties.tooltip) {
                return;
            }

            // Tooltip already visible?
            if (this.map.hasLayer(this.tooltip?.options.layer.id)) {
                return;
            }

            // Create tooltip layer
            // Is point?
            if (feature.geometry.type == "Point") {
                this.tooltip = new TooltipLabelLayer(this.context, (<Point>feature.geometry).coordinates, feature.properties.tooltip);
            }
            // Is polygon?
            if (feature.geometry.type == "Polygon") {
                this.tooltip = new TooltipLabelLayer(this.context, InvipoHelpers.toBounds(feature.geometry).getCenter().toArray(), feature.properties.tooltip);
            }

            // Unknown feature geometry to draw tooltip
            if (!this.tooltip) {
                Log.w("Unsupported feature type for tooltip");
                return;
            }

            // Add tooltip to map
            this.map.addLayer(this.tooltip);
        }

        // Hide tooltip
        this.map.onFeatureLeave = async (layer: string, event?: MouseEvent) => {
            // No tooltip displayed?
            if (!this.tooltip) {
                return;
            }

            // Remove tooltip from map
            this.map.removeLayer(this.tooltip.options.layer.id);
            this.tooltip = null;
        }

        // Perform fit action
        this.map.onFeatureClick = async (layer: string, feature: Feature, event?: MouseEvent) => {
            // Zoom to area center
            if (feature.properties.type == "AreaSpot") {
                this.map.flyTo({
                    center: (<Point>feature.geometry).coordinates,
                    zoom: ZOOM_AREA + 1
                });
            }
            // Zoom to single location
            else if (feature.properties.type == "Location") {
                this.map.flyTo({
                    center: (<Point>feature.geometry).coordinates,
                    zoom: 18
                });
            }
        }

        // Select detail
        // This must be binded to "mousedown" event as it does not break detail hiding
        this.map.onFeatureMouseDown = async (layer: string, feature: Feature, event?: MouseEvent) => {
            // Open card
            if (feature.properties.card) {
                this.openCard(feature.properties, event);
            }
        }

        // Control zoom panel
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }
    }

    private createNavigator(): void {
        // Create component
        this.navigator = new CityNavigator(this.context, {
            domain: this.context.application.state.domain || Object.keys(this.context.options.city)[0] // routing or first (default) domain
        });

        // Synchronize metrics layers on change
        this.navigator.onLayersChange = (layers: MapLayer[]) => {
            // Remove old layers
            for (let id in this.map.layers) {
                // Layer does not exist in new matrics?
                if (!layers.find(x => x.options.layer.id == id)) {
                    this.map.removeLayer(id);
                }
            }

            // Add new layers
            for (let layer of layers) {
                // Layer was not already added? (some metrics can share same layers)
                if (!this.map.layers[layer.options.layer.id]) {
                    this.map.addLayer(layer);
                }
            }
        }

        // Opens list component
        this.navigator.onListCreate = (list: CityList, metrics?: SubdomainMetrics) => {
            this.openList(list, metrics);
        }
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Light",
            styleItems: [
                {
                    name: "Light",
                    label: "enums.MapStyle.Light",
                    disabled: true // Light style is default, see createMap()
                }
            ]
        });

        // Custom styles in config?
        if (this.context.options.home.styles) {
            for (let style of this.context.options.home.styles) {
                this.control.options.styleItems.push({
                    name: style,
                    label: `enums.MapStyle.${style}`
                });
            }
        }

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            this.map.fitAll();
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.context.options.home.center,
                zoom: this.context.options.home.zoom,
                duration: 3000
            })
        }

        // Change map style
        this.control.onStyleSelect = (name: string) => {
            this.map.setStyle(name);
        }
    }

    private createWeather(): void {
        // Create component
        this.weather = new MapWeather(this.context);
    }

    public openCard(properties: GeoJsonProperties, event?: MouseEvent): void {
        // Missing cardId?
        if (!properties.cardId) {
            Log.w(`No cardId for ${properties.card}`);
        }

        // Card is already displayed?
        if (this.card?.isAttached() && properties.detail && this.card.options.cardId == properties.cardId) {
            // Second click opens detail then
            this.openDetail(properties);

            // Otherwise no action on second click
            return;
        }

        Log.i(`${this.id} opens card ${properties.card}`);

        // Create detail
        this.card = new COMPONENT_STORE[properties.card](this.context, {
            style: "Light",
            ...properties,
            //closable: true // Not closable for now
        });

        // Handle item selection
        this.card.onItemSelect = (item: InvipoItem, card?: string) => {
            // New set of properties
            properties = {
                type: properties.type,
                data: properties.data,
                card: card || properties.card,
                detail: "CityDetail",
                cardId: item.id,
                itemId: item.id,
                itemName: item.name,
                itemClass: item.class
            }

            // Repin new detail to keep all bindings, callbacks etc.
            this.openCard(properties, event);
        }

        // Open data view
        this.card.onDetailView = (content?: string) => {
            // TODO: content?
            this.openDetail(properties, content);
        }

        // Show card
        this.card.show(event?.x || (this.element.offsetWidth / 2 + 56), event?.y || (this.element.offsetHeight / 2));
    }

    public openDetail(properties: GeoJsonProperties, content?: string): void {
        // Compatibility issue: detail will be replaced by detail
        Log.i(`${this.id} opens detail ${properties.detail}`);

        // Create detail
        let detail: Detail = new COMPONENT_STORE[properties.detail](this.context, {
            content: content,
            overlay: true,
            closable: true,
            ...properties,
        });

        // Show detail
        detail.attach();
    }

    public openList(list: CityList, metrics?: SubdomainMetrics): void {
        // Assign list to property
        this.list = list;

        // Add map layers on attachment
        list.onAttach = () => {
            // Remove metrics layers
            if (metrics) {
                for (let layer of metrics?.layers) {
                    this.map.removeLayer(layer.options.layer.id);
                }
            }

            // Add list layers
            for (let layer of list.options.layers) {
                this.map.addLayer(layer);
            }
        }

        // Remove map layers on detachment
        list.onDetach = () => {
            // Add metrics layers
            if (metrics) {
                for (let layer of metrics?.layers) {
                    this.map.addLayer(layer);
                }
            }

            // Remove list layers
            for (let layer of list.options.layers) {
                this.map.removeLayer(layer.options.layer.id);
            }
        }

        // Localize position
        list.onPositionNavigate = (position: Position, item?: InvipoItem) => {
            // Clear timer
            clearTimeout(this.timer);

            // Animation and card opener timer
            let timer = 3000;

            // Fly to position
            this.map.flyTo({
                center: position,
                duration: 3000,
                zoom: 18
            });

            // And pulse
            this.map.pulseAt({
                type: "Selection",
                position: position
            });

            // If item is passed, card will be opened
            if (item) {
                this.timer = setTimeout(() => {
                    this.openCard({
                        type: list.options.type,
                        data: list.options.data,
                        card: "MonitoringCard",
                        detail: "CityDetail",
                        cardId: item.id,
                        itemId: item.id,
                        itemClass: item.class,
                        itemName: item.name
                    });
                }, timer - 300);
            }
        }

        // Localize aera
        list.onBoundsNavigate = (bounds: LngLatBounds) => {
            // Fit to bounds
            this.map.fitBounds(bounds);
        }

        // Show
        list.attach(this.query("div.placeholder-list"));
    }

    public async sload(): Promise<void> {
        // Wait for map to be loaded (mapboxMap.onLoad does not work)
        await Helpers.sleep(600);

        // Fade in (map canvas only)
        if (this.isAttached()) {
            this.map.query("canvas").style.opacity = "1";
        }
    }
}
