import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { HiyoEvent } from "../../../hiyo/event-broker";
import { TrafficEventLayer } from "./traffic-event-layer";

export const SYMBOL_MAP: { [type: string]: string } = {
    "Accident": "24-markers-road-accident",
    "Closure": "24-markers-road-restriction_02",
    "Roadworks": "24-markers-road-roadwork",
    "Danger": "24-markers-road-danger",
    "Info": "24-markers-road-road_non_weather"
}

export class TrafficEventSymbolLayer extends TrafficEventLayer {

    // Properties
    public event: any;

    public constructor(context: InvipoContext, event?: any) {
        super(context, {
            dynamic: true,
            pointer: true,
            layer: {
                id: "traffic-event-symbol",
                type: "symbol",
                minzoom: 15,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": ["get", "symbol"],
                    "icon-offset": [0, 0],
                    "icon-allow-overlap": true
                }
            }
        });

        // Set message
        this.event = event;
    }

    public async load(): Promise<any> {
        // Load events
        let events = [];

        // Load detail?
        if (this.event) {
            events.push(this.event);
        }
        // Load all currently visible
        else {
            events = await this.context.invipo.getQuery("traffic-events-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let event of events) {
            json.features.push({
                type: "Feature",
                properties: {
                    symbol: SYMBOL_MAP[event.type]
                },
                geometry: {
                    type: "Point",
                    coordinates: (event.position.type == "LineString") ? event.position.coordinates[0] : event.position.coordinates
                }
            })
        }

        return json;
    }
}
