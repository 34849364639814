import "./cits-vehicle-detail.scss";
import * as template from "./cits-vehicle-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { CitsVehicleDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { CitsVehicleLayer } from "../../../layers/traffic/cits-vehicle-layer";
import { CitsVehicleCircleLayer } from "../../../layers/traffic/cits-vehicle-circle-layer";

export class CitsVehicleDetail extends Detail<InvipoContext, CitsVehicleDetailOptions> {

    // Components
    public map: BasicMap;
    public layer: CitsVehicleLayer;

    constructor(context: InvipoContext, options: CitsVehicleDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new CitsVehicleCircleLayer(this.context, <any>this.options.vehicle));
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom,
            minZoom: 5,
            maxZoom: 20
        });
    }

}
