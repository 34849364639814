import "./picker-menu.scss"
import * as template from "./picker-menu.hbs"
import { InvipoContext } from "../../../context/invipo-context";
import { PickerMenuOptions, PickerGroup, PickerItem } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Log } from "hiyo/log";

export class PickerMenu extends MuklitComponent<InvipoContext, PickerMenuOptions> {

    // Event handling methods
    public onSelect(item: PickerItem, group?: PickerGroup): void {};

    constructor(context: InvipoContext, options: PickerMenuOptions) {
        super(context, template, options);
    }

    public select(groupName: string, itemName: string): void {
        let group = this.options.groups.find(x => x.name == groupName);

        // Group not found?
        if (!group) {
            Log.w(`Unknown group ${groupName} to select in ${this.id}`);
            return;
        }

        let item = group.items.find(x => x.name == itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Redraw
        this.update();

        // OnItemSelect handler
        this.onSelect(item, group);
    }
}
