import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { InvipoHelpers } from "../../invipo-helpers";
import { Helpers } from "../../../hiyo/helpers";

export class EnergyConsumptionAreaLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: true,
            layer: {
                id: "energy-consumption-area-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 4,
                //maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": ["get", "color"],
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = this.context.data.getAreas({
            type: "EnergyConsumption,SmartBuilding"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Current consumption
            let consumption = await this.context.invipo.getQuery<number>("consumption-current", `area.id=${area.id}`);

            json.features.push({
                type: "Feature",
                properties: {
                    type: "AreaSpot",
                    tooltip: area.name,
                    areaId: area.id,
                    areaName: area.name,
                    color: "#161616",
                    label: consumption != null ? `${Helpers.toNumber(consumption, 1, 1)}\nkWh` : "N/A"
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }
}
