import "./user-exports.scss";
import * as template from "./user-exports.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Helpers } from "hiyo/helpers";
import { UserExportsOptions } from "./types";
import { DataTable } from "muklit/components/data-table/data-table";
import { Detail } from "muklit/components/detail/detail";
import { Dom } from "hiyo/dom";
import { QueryTable } from "muklit/components/query-table/query-table";

export class UserExports extends Detail<InvipoContext, UserExportsOptions> {

    // Components
    public table: QueryTable;

    constructor(context: InvipoContext, options?: UserExportsOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

    public createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/invipo/users/${this.context.user.id}/exports`,
            http: this.context.invipo.http,
            bar: {
                title: "components.UserExports.title",
                reloadable: true,
                items: []
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                autosort: true,
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true
                    },
                    {
                        name: "result",
                        type: "String",
                        property: "result",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return null;
                            }
                            return `<div class="cell">${value?.split("/").pop()}</div>`
                        },
                        label: "tables.columns.name",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "duration",
                        type: "Number",
                        property: "duration",
                        formatter: (value: any, data: any): string => {
                            if (data.result == null) {
                                return `<div class="cell cell-center">${this.context.locale.getMessage("components.UserExports.inProgress")}</div>`
                            } else {
                                return `<div class="cell cell-center">${Helpers.toDuration(value / 1000)}</div>`
                            }
                        },
                        label: "tables.columns.duration",
                        width: 120,
                        align: "Center",
                        sortable: true
                    },
                    {
                        name: "download",
                        type: "String",
                        property: "download",
                        formatter: (value: any, data: any): string => {
                            if (data.result == null) {
                                return null;
                            }
                            return `<div class="cell cell-right"><a href="${this.context.options.host}/download${data.result}" target="_blank" class="link">${this.context.locale.getMessage("labels.download")}</a></div>`;
                        },
                        label: null,
                        width: 120,
                        sortable: true
                    }
                ]
            }
        });
    }
}
