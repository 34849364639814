import { Log, LogColor } from "./log";
import { HiyoObject } from "./hiyo-object";
import { Context } from "./context";

export class View<T extends Context = Context> extends HiyoObject<T> {

    // Properties
    public active: boolean = false;

    // Event handling methods
    public onCreate(): void {};
    public onEnter(): void {};
    public onLeave(): void {};

    constructor(context: T, id?: string) {
        super(context);

        this.id = id || this.constructor.name;

        // OnCreate handler
        this.onCreate();
    }

    public isActive(): boolean {
        return this.active;
    }

    public enter(): void {
        Log.i(`Entering view ${this.id}`, LogColor.Magenta);

        // Active flag
        this.active = true;

        // Attach all registered components
        for (let key in this.components) {
            this.components[key].attach();
        }

        // OnEnter handler
        this.onEnter();
    }

    public leave(): void {
        Log.i(`Leaving view ${this.id}`, LogColor.Magenta);

        // Active flag
        this.active = false;

        // Detach all registered components
        for (let key in this.components) {
            this.components[key].detach();
        }

        // OnLeave handler
        this.onLeave();
    }

    public invalidate(): void {
        Log.i(`Invalidating view "${this.id}"`, LogColor.Magenta);

        // Invalidate all registered components
        for (let key in this.components) {
            const component = this.components[key];
            component.invalidate()
        }
    }


}
