import "./user-password-form.scss";
import * as template from "./user-pasword-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { UserPasswordFormOptions } from "./types";

export class UserPasswordForm extends Detail<InvipoContext, UserPasswordFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: UserPasswordFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // User profile form
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "password",
                            label: "forms.fields.password",
                            placeholderText: "forms.placeholders.newPassword",
                            width: 288,
                            password: true,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "retypePassword",
                            label: "forms.fields.retypePassword",
                            placeholderText: "forms.placeholders.retypePassword",
                            width: 288,
                            password: true,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Submit form on enter
        this.form.onSubmit = async () => {
            await this.submit();
        }
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData();

        // Show loader
        this.showLoader();

        // Set new password
        try {
            await this.context.invipo.setUserPassword(this.options.user.id, data);
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnProfileSubmit handler
        this.onSubmit();
    }
}
