import { FeatureCollection, Position } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Log } from "../../../hiyo/log";

export class GoogleRouteLineLayer extends MapLayer<InvipoContext> {

    // Properties
    public positions: Position[];

    public constructor(context: InvipoContext, positions: Position[]) {
        super(context, {
            dynamic: true,
            before: "road-label",
            layer: {
                id: "google-route-line",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-width": 4,
                    "line-color": "#008EFA",
                    "line-opacity": 1,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": 1
            }
        });

        // Assign proeprties
        this.positions = positions;
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // No coordinates?
        if (this.positions?.length < 2) {
            Log.w(`Route computing enabled for more than 2 positions (${this.positions.length} defined)`);
            return json;
        }

        // Origin, destination and intermediates
        let origin: Position = this.positions[0];
        let destination: Position = this.positions[this.positions.length - 1];
        let intermediates: Position[] = null;

        if (this.positions.length > 2) {
            intermediates = this.positions.slice(1, this.positions.length - 1)
        }

        // Google API call
        let response = await this.context.invipo.postExternal("/google/compute-routes", {
            origin: origin,
            destination: destination,
            intermediates: intermediates ? [ ...intermediates ] : null
        });

        if (!response.routes.length || !response.routes[0]?.polyline?.geoJsonLinestring) {
            Log.w(`No route found for ${this.positions.length} positions specified`);
            return json;
        }

        // Push features from locations
        json.features.push({
            type: "Feature",
            properties: {
            },
            geometry: response.routes[0].polyline.geoJsonLinestring
        });

        return json;
    }

}
