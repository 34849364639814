import "./wifi-access-point-overview.scss";
import * as template from "./wifi-access-point-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { WifiAccessPointOverviewOptions } from "./types";
import { DatasetResult } from "muklit/components/query-table/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class WifiAccessPointOverview extends DetailPanel<WifiAccessPointOverviewOptions> {

    // Properties
    public history: DetailHistoryValue[];

    constructor(context: InvipoContext, options?: WifiAccessPointOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-120, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery(`network-connections-by-hour`, `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Reset history data
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 120; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.connections,
                    percent: Helpers.range(0, 100, 0, METRICS.services.hotspots.connections.range[1], d.connections),
                    color: METRICS.services.hotspots.connections.colors[Math.round(Helpers.range(0, METRICS.services.hotspots.connections.colors.length - 1, METRICS.services.hotspots.connections.range[0], METRICS.services.hotspots.connections.range[1], d.connections))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }

}
