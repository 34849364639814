import "./user-detail.scss";
import * as template from "./user-detail.hbs";
import { InvipoItem, User } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { UserDetailOptions } from "./types";
import { UserProfileForm } from "../user-profile-form/user-profile-form";
import { Dialog } from "muklit/components/dialog/dialog";
import { Toggle } from "muklit/components/toggle/toggle";
import { UserPermissionsForm } from "../user-permissions-form/user-permissions-form";
import { UserPasswordForm } from "../user-password-form/user-password-form";

export class UserDetail extends Detail<InvipoContext, UserDetailOptions> {

    // Properties
    public user: User;

    // Components
    public tabs: Tabs;
    public disabled: Toggle;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: UserDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createDisabled();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.disabled, "disabled");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "Profile",
                    label: "components.UserDetail.profile",
                    content: "div.content-profile",
                    selected: true
                },
                {
                    name: "Permissions",
                    label: "components.UserDetail.permissions",
                    content: "div.content-permissions"
                }
            ]
        });
    }

    private createDisabled(): void {
        // Crate component
        this.disabled = new Toggle(this.context, {
            style: "Light",
            size: "Small",
            name: "disabled",
            label: "components.UserDetail.disabled"
        })

        // Disable user
        this.disabled.onToggle = async (disabled: boolean) => {
            await this.disable(disabled);
        }
    }

    public openProfile(): void {
        // Details form
        let form = new UserProfileForm(this.context, {
            style: "Light",
            title: null,
            user: this.user,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public openPassword(): void {
        // Details form
        let form = new UserPasswordForm(this.context, {
            style: "Light",
            title: null,
            user: this.user,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public openPermissions(): void {
        // Details form
        let form = new UserPermissionsForm(this.context, {
            style: "Light",
            title: null,
            user: this.user,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public async disable(disabled: boolean): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        await this.context.invipo.disableUser(this.options.userId, disabled);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.UserDetail.userDelete",
            text: this.context.locale.getMessage("components.UserDetail.reallyDelete", this.user.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteUser(this.user.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.user = await this.context.invipo.getUser(this.options.userId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);

        // Manage child components
        this.disabled.setValue(this.user.disabled);
    }

}
