import { ContentView } from "./content-view";
    
export class Home extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("HomeScreen");
    }

}
