import "./vehicle-appearance-report.scss";
import * as template from "./vehicle-appearance-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleLocation, VehicleAppearanceReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { PanelKpis, PanelTable } from "../../common/panel/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Panel } from "../../common/panel/panel";
import { RangeInput } from "muklit/components/range-input/range-input";
import { TextInput } from "muklit/components/text-input/text-input";
import { DatasetResult } from "muklit/components/query-table/types";
import { Helpers } from "hiyo/helpers";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { GoogleOrthophotoLayer } from "../../../layers/custom/google-orthophoto-layer";
import { ItemSelect } from "../../common/item-select/item-select";
import { Templates } from "hiyo/templates";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { CameraLocationCircleLayer } from "../../../layers/safety/camera-location-circle-layer";
import { CameraLocationLabelLayer } from "../../../layers/safety/camera-location-label-layer";
import { Point } from "geojson";
import { VehicleLocationCard } from "../vehicle-location-card/vehicle-location-card";
import { GoogleRouteLineLayer } from "../../../layers/traffic/google-route-line-layer";
import { VideoDetail } from "../../common/video-detail/video-detail";

export const VEHICLE_LIMIT = 50;

export class VehicleAppearanceReport extends Panel<VehicleAppearanceReportOptions> {

    // Properties
    public vehicles: DatasetResult;
    public kpis: PanelKpis;
    public table: PanelTable;
    public items: InvipoItem[];
    public card: VehicleLocationCard;
    public timeouts: any[];

    public constructor(context: InvipoContext, options?: VehicleAppearanceReportOptions) {
        super(context, template, options);
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            time: true,
                            label: "forms.fields.date",
                            placeholderText: "forms.placeholders.anytime",
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            distinct: "VehiclesData",
                            items: [],
                            multiselect: true,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "category",
                            label: "forms.fields.category",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                            bright: true,
                            multiselect: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "plateNumber",
                            label: "forms.fields.plateNumber",
                            placeholderText: "forms.placeholders.plateNumber",
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "brand",
                            label: "forms.fields.brand",
                            placeholderText: "forms.placeholders.all",
                            bright: true,
                            items: InvipoHelpers.toMenuItems(this.context.config.vehicle?.brands)
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "color",
                            label: "forms.fields.color",
                            placeholderText: "forms.placeholders.all",
                            bright: true,
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.VehicleColor"))
                        }),
                        /*new TextInput(this.context, {
                            style: "Light",
                            name: "color",
                            label: "forms.fields.color",
                            width: 320,
                            bright: true
                        }),*/
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "GoogleOrthophoto",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 22
        });

        // Add Google custom layer
        this.map.onMapLoad = () => {
            this.map.mapboxMap.addLayer(new GoogleOrthophotoLayer(this.context).options.layer);
        }

        // Clear tracking
        this.map.onMapClick = () => {
            // Clear timeouts
            for (let timeout of this.timeouts || []) {
                clearInterval(timeout);
            }

            // Start from beginning
            this.timeouts = [];
        }

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    public selectImage(i: number): void {
        // Stop propagation
        event.stopPropagation();
        event.preventDefault();

        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: `${this.context.options.host}/download${this.vehicles.data[i].images[0].url}`,
            urls: (<any[]>this.vehicles.data[i].images).map(x => `${this.context.options.host}/download${x.url}`),
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async selectRow(row: number): Promise<void> {
        // Get item location
        let item = this.items.find(x => x.name == this.table.rows[row].cells[2])

        // Fly to
        this.map.flyTo({
            center: (<any>item.geometry.location).coordinates,
            zoom: 20
        });
    }

    public openVideo(i: number): void {
        // Stop propagation
        event.stopPropagation();
        event.preventDefault();

        // Get item location
        let item = this.items.find(x => x.id == this.vehicles.data[i].item.id);

        // Construct stream, start 1s before
        let stream = item.meta.stream.iframe.replace("{{timestamp}}", new Date(new Date(this.vehicles.data[i].timestamp).getTime() - 1000).toISOString());

        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            stream: stream,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public async activeMap(): Promise<void> {
        // Request fullscreen
        await this.query("div.map").requestFullscreen();

        // REsize
        this.map.resize();

        // Clear timeouts
        for (let timeout of this.timeouts || []) {
            clearInterval(timeout);
        }

        // Start from beginning
        this.timeouts = [];

        // Put a timer on each point and shift it by 5seconds
        for (let i = 0; i < this.vehicles.data.length; i++) {
            // Current vehicle
            let vehicle = this.vehicles.data[i];

            // Full item
            let item = this.items.find(x => x.id == vehicle.item.id);

            // Fly to timer
            this.timeouts.push(setTimeout(() => {
                // Close previous one
                this.card?.hide();

                // Just fly
                this.map.flyTo({
                    center: (<Point>item.geometry.location).coordinates,
                    duration: 3000,
                    zoom: 20
                });
            }, 6000 * i));

            // Open card timer
            this.timeouts.push(setTimeout(() => {
                // New card
                this.card = new VehicleLocationCard(this.context, {
                    location: {
                        timestamp: vehicle.timestamp,
                        name: item.name,
                        itemClass: item.class,
                        color: vehicle.color,
                        geometry: item.geometry.location,
                        images: vehicle.images
                    }
                })

                // Map element
                let map = this.query("div.map");

                // Show inside map element
                this.card.show((map.getBoundingClientRect().width / 2) + 12, map.getBoundingClientRect().height / 2, 1, map);
            }, (6000 * i) + 3000));

            // Show detail

        }
    }

    public async extraLoad(): Promise<void> {
        // Load all items
        this.items = this.context.data.getItems();

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Get simplified form data
        let form = this.form.getData(true);

        // Item not selected?
        if (!form.from || !form.to) {
            return;
        }

        // Query string
        let query = "";

        if (form.from) {
            query += `from=${form.from}&`;
        }
        if (form.to) {
            query += `to=${form.to}&`;
        }
        if (form.itemId) {
            query += `item.id=${form.itemId}&`;
        }
        if (form.category) {
            query += `classification.category=${form.category}&`;
        }
        if (form.plateNumber) {
            query += `plate.number=${form.plateNumber}&`;
        }
        if (form.brand) {
            query += `brand=${form.brand}&`;
        }
        if (form.color) {
            query += `color=${form.color}&`;
        }

        // Speed data per hour
        this.vehicles = await this.context.invipo.getDataset("vehicles-data", `${query}pageSize=${VEHICLE_LIMIT}&sort=timestamp:asc`);

        // Build up locations
        let locations: VehicleLocation[] = [];
        let vehicles: VehicleLocation[] = [];

        for (let d of this.vehicles.data) {
            // Find corresponding item
            let item = this.items.find(x => x.id == d.item.id);

            // Get item location
            let location = locations.find(x => x.name == item.name);

            // Already exists?
            if (location) {
                location.count += 1;
            }
            // New location
            else {
                locations.push({
                    name: item.name,
                    geometry: item.geometry.location,
                    direction: item.meta.direction || 0,
                    count: 1
                });
            }

            // Push to vehicles, all routes
            vehicles.push({
                name: item.name,
                geometry: item.geometry.location
            });
        }

        // Reinitialize layers
        this.map.removeLayers();
        this.map.addLayer(new GoogleRouteLineLayer(this.context, locations.map(x => (<Point>x.geometry).coordinates)));
        this.map.addLayer(new CameraLocationCircleLayer(this.context, locations));
        this.map.addLayer(new CameraLocationLabelLayer(this.context, locations));

        // Clear KPIs
        this.kpis = null;

        // Calculate KPIs
        let first = this.vehicles.data[0];
        let last = this.vehicles.data[this.vehicles.data.length - 1];

        // Build speed KPIs
        if (this.vehicles.data?.length) {
            this.kpis = {
                size: "Third",
                data: [
                    {
                        label: "components.VehicleAppearanceReport.totalAppearance",
                        value: `${Helpers.toNumber(this.vehicles.data.length)} times`,
                        description: `During time period`
                    },
                    {
                        label: "components.VehicleAppearanceReport.lastAppearance",
                        value: Helpers.toDateTimeString(last.timestamp),
                        description: last.item.name
                    },
                    {
                        label: "components.VehicleAppearanceReport.firstAppearance",
                        value: Helpers.toDateTimeString(first.timestamp),
                        description: first.item.name
                    },
                ]
            }
        }

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.VehicleAppearanceReport.table",
            selectable: true,
            columns: [
                {
                    label: "#",
                    width: "20px"
                },
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.location",
                    align: "Left",
                    width: "160px"
                },
                {
                    label: "tables.columns.plate",
                    align: "Left",
                    width: "100px"
                },
                {
                    label: "tables.columns.video",
                    align: "Left",
                    width: "120px"
                },
                {
                    label: "tables.columns.image",
                    align: "Right",
                    width: "80px"
                }
            ],
            rows: []
        };

        for (let i = 0; i < this.vehicles.data.length; i++) {
            // Data
            let d = this.vehicles.data[i];

            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toNumber(i + 1),
                    Helpers.toDateTimeString(d.timestamp),
                    d.item.name,
                    Templates.renderPartial("plate-number", d.plate),
                    `<div class="partial-action partial-action-single" onclick="component.openVideo(${i})">${this.context.locale.getMessage("labels.play")}</div>`,
                    `<img class="snapshot" width="80" src="${this.context.options.host}/download${d.images[0].url}" onclick="component.selectImage(${i})" />`
                ]
            });
        }
    }

}
