import './traffic-incident-tile.scss';
import * as template from "./traffic-incident-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TrafficIncidentTileOptions } from "./types";
import { TrafficIncidentDetailOptions } from "../../traffic/traffic-incident-detail/types";
import { TrafficIncidentDetail } from "../../traffic/traffic-incident-detail/traffic-incident-detail";

export class TrafficIncidentTile extends Tile<TrafficIncidentTileOptions> {

    // Properties
    public incident: any;
    public detail: TrafficIncidentDetail;

    constructor(context: InvipoContext, options?: TrafficIncidentTileOptions) {
        super(context, template, options);
    }


    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }
    protected openDetail(): void {
        // Detail options
        let options: TrafficIncidentDetailOptions = {
            style: "Light",
            incident: this.incident,
            title: `enums.TrafficIncidentType.${this.incident.type}`,
            subtitle: this.incident.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TrafficIncidentDetail(this.context, options);

        // Show detail
        this.detail.attach();
    }

    public update() {
        // Super call
        super.update();

        this.element.style.backgroundImage = `url("${this.context.options.host}/download${this.incident.images[Math.floor(this.incident.images.length / 2)].url}")`;
        this.element.onclick = () => this.openDetail();
    }

    public async extraLoad(): Promise<void> {
        // Query filter
        let query = "sort=timestamp:desc&pageSize=1";

        // Type filtering?
        if (this.options?.type) {
            query += `&type=${this.options.type}`;
        }

        // Filter by class
        let incidents = await this.context.invipo.getDataset("traffic-incidents", query);

        // Take last incident
        this.incident = incidents?.data?.pop();
    }
}
