import { MapLayer } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";

export class HradecKraloveOrthophotoLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            pointer: true,
            layer: {
                id: "hradec-kralove-orthophoto",
                type: "raster",
                source: {
                    type: "raster",
                    "tiles": [
                        "https://gis.mmhk.cz/arcgis/rest/services/basemaps/ortofoto_mercator/MapServer/tile/{z}/{y}/{x}"
                    ],
                    "tileSize": 256
                },
            }
        });
    }

    public async load(): Promise<any> {
        return null;
    }

}
