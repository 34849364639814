import "./calendar2.scss";
import * as template from "./calendar2.hbs";
import { Calendar2Options, Calendar2Week } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Context } from "hiyo/context";
import { Helpers } from "hiyo/helpers";

export const TIMEOUT_DOCUMENT_LISTENER = 150; // ms

export class Calendar2 extends MuklitComponent<Context, Calendar2Options> {

    // Properties
    public weeks: Calendar2Week[];
    public labels: string[];
    public current: Date;
    public disabled: boolean;

    public from: Date;
    public to: Date;

    // Listeners
    private hideListener = () => this.hide();

    // Event handling methods`
    public onSelect(from: string, to?: string): void {};

    constructor(context: Context, options: Calendar2Options) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Set initial date range
        this.from = this.options.from ? new Date(this.options.from) : null;
        this.to = this.options.to ? new Date(this.options.to) : null;

        // Enable or disable submit
        this.disabled = (this.options.type == "Day" && this.from == null) || (this.options.type == "Range" && this.to == null);

        // Set current
        // Update: Current will be always start of the current month neverthless the seelction start
        this.current = new Date(new Date(new Date().setDate(1)).setHours(0,0,0,0));

        // Build label and week data
        this.createLabels()
        this.createWeeks();
    }

    public render(): string {
        // Recreate weeks
        this.createWeeks();

        // Super call
        return super.render();
    }

    public show(trigger: HTMLElement): void {
        // Already visible?
        if (this.isAttached()) {
            return;
        }

        // Attach to document
        this.attach();

        // Trigger element positions
        let rect = trigger.getBoundingClientRect();

        // Pivot point of the trigger
        let startX = 0;
        let startY = 0;

        // Get the trigger point
        if (this.options.start == "TopLeft") {
            startX = rect.left;
            startY = rect.top;
        }
        else if (this.options.start == "TopRight") {
            startX = rect.left + rect.width;
            startY = rect.top;
        }
        else if (this.options.start == "BottomLeft") {
            startX = rect.left;
            startY = rect.top +  rect.height;
        }
        else {
            startX = rect.left + rect.width;
            startY = rect.top + rect.height;
        }

        // Menu position
        let left = 0;
        let top = 0;

        // Assign menu position
        if (this.options.anchor == "TopLeft") {
            left = startX + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "TopRight") {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY + document.body.scrollTop;
        }
        else if (this.options.anchor == "BottomLeft") {
            left = startX + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }
        else {
            left = startX - this.element.offsetWidth + document.body.scrollLeft;
            top = startY - this.element.offsetHeight + document.body.scrollTop;
        }

        // Move position by offset
        if (this.options.offset?.length == 2) {
            left += this.options.offset[0];
            top += this.options.offset[1];
        }

        // Set position to style
        this.element.style.left = `${left}px`;
        this.element.style.top = `${top}px`;

        // We need to cancel event propagation if click on itself
        this.element.addEventListener("mousedown", (e: MouseEvent) => {
            e.cancelBubble = true;
        });

        // We add document listener after some short delay because of event bubbling
        setTimeout(() => {
            document.addEventListener("mousedown", this.hideListener);
        }, TIMEOUT_DOCUMENT_LISTENER);
    }

    public hide(): void {
        // Detach from document
        this.detach();

        // Remove document listeners
        document.removeEventListener("mousedown", this.hideListener);
    }

    private createWeeks(): void {
        // reset weeks
        this.weeks = [];

        let y = this.current.getFullYear();
        let m = this.current.getMonth();

        // Range of visible month
        let startOfMonth = new Date(y, m);

        let endOfMonth = new Date(y, m + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);

        // Full range of visible calendar
        let startOfCalendar = new Date(startOfMonth);
        startOfCalendar.setDate(startOfCalendar.getDate() - startOfMonth.getUTCDay())

        let endOfCalendar = new Date(endOfMonth);
        if (endOfCalendar.getUTCDay() > 0) {
            endOfCalendar.setDate(endOfCalendar.getDate() + (6 - endOfMonth.getUTCDay()))
        }

        // Generate weeks
        let date = new Date(startOfCalendar);
        while (date < endOfCalendar) {
            let week: Calendar2Week = {
                number: 0,
                days: []
            };

            // Create and prepare selected days
            for (let i = 0; i < 7; i++) {
                week.days.push({
                    date: date.toISOString(),
                    number: date.getDate(),
                    past: date.getTime() < startOfMonth.getTime(),
                    future: date.getTime() > endOfMonth.getTime(),
                    today: new Date(date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0),
                    selected: this.getDaySelectionState(date)
                });
                date.setDate(date.getDate() + 1);
            }

            // Add week row
            this.weeks.push(week);
        }
    }

    private createLabels(): void {
        // Find start of week
        let d = new Date();
        let day = d.getUTCDay();
        let diff = d.getDate() - day + (day == 0 ? -6 : 1);
        let date = new Date(d.setDate(diff));

        // Empty labels
        this.labels = [];

        // Iterate all week days
        for (let i = 0; i < 7; i++) {
            // Push localized name
            this.labels.push(Helpers.toShortWeekDayString(date));

            // Move to next day
            date.setDate(date.getDate() + 1);
        }
    }

    private getDaySelectionState(date: Date): string {
        // Range selection?
        if (this.options.type == "Range") {
            let from = this.from ? new Date(this.from).setHours(0, 0, 0, 0) : null;
            let to = this.to ? new Date(this.to).setHours(0, 0, 0, 0) : null;
            let time = date.getTime();

            // Single day selected?
            if (time == from || time == to) {
                return "Day";
            }

            // Range selected?
            if (time > from && time < to) {
                return "Range";
            }
        }
        // Single day selection
        if (this.options.type == "Day") {
            // Day mode: select a single day

            let start = new Date(this.from).setHours(0, 0, 0, 0);
            let time = date.getTime();

            // Single day selected?
            if (time == start) {
                return "Day";
            }
        }
    }

    public selectDay(date: string) {
        // Range selection?
        if (this.options.type == "Range") {
            // From select?
            if (!this.from || this.to) {
                // Select the first date of a range
                this.from = new Date(date)
                this.to = null;

                // Disable submit
                this.disabled = true;
            }
            // To select?
            else {
                // Select the second date of a range
                this.to = new Date(date);

                // Invalid selection?
                if (this.to < this.from) {
                    this.to = this.from;
                    this.from = new Date(date);
                }

                // Enable submit
                this.disabled = false;

                // Range includes the whole end day
                this.to.setHours(23, 59, 59, 999);
            }

            this.options.from = this.from.toISOString();
            this.options.to = this.to?.toISOString();
        }

        // Day selection?
        if (this.options.type == "Day") {
            // Day mode: just update selection start
            this.from = new Date(date);
            this.to = null;

            // Update component options
            this.options.from = this.from.toISOString();
            this.options.to = new Date(new Date(date).setHours(24, 0, 0, 0) - 1).toISOString();

            // Enable submit
            this.disabled = false;
        }

        // If no time edit, we submit immidiatelly
        if (this.options.from && this.options.to && !this.options.time) {
            this.submit();
        }
        else {
            this.update();
        }
    }

    public changeTime(): void {
        let from = this.query<HTMLInputElement>("input[name=fromTime]")?.value;
        let to = this.query<HTMLInputElement>("input[name=toTime]")?.value;

        // Update from time
        if (this.from && from) {
            // Parse time
            let time = from.split(":");

            // Update proeprty and options
            this.from.setHours(Number(time[0]), Number(time[1]), 0, 0);
            this.options.from = this.from.toISOString();
        }

        // Update to time
        if (this.to && to) {
            // Parse time
            let time = to.split(":");

            // Update proeprty and options
            this.to.setHours(Number(time[0]), Number(time[1]), 0, 0);
            this.options.to = this.to.toISOString();
        }
    }

    public moveMonth(d: number) {
        // Subtract one month
        this.current.setMonth(this.current.getMonth() + d);

        // Redraw
        this.update();
    }

    public keyUp(event: KeyboardEvent): void {
        if (event.key == "Enter") {
            this.submit();
        }
    }

    public clear(): void {
        // Reset dates
        this.from = null;
        this.to = null;

        // Reset options
        this.options.from = null;
        this.options.to = null;

        // Disable submit
        this.disabled = true;

        // Redraw
        this.update();
    }

    public submit(): void {
        // Disabled?
        if (this.disabled) {
            return;
        }

        // Hide self
        this.hide();

        // OnSelect handler
        return this.onSelect(this.options.from, this.options.to);
    }
}
