import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { AreaSelectOptions } from "./types";
import { InvipoArea } from "../../../clients/invipo-client/types";

export class AreaSelect extends Select<InvipoContext, AreaSelectOptions> {

    public areas: InvipoArea[];

    public async load(): Promise<void> {
        // Get items
        this.areas = this.context.data.getAreas({
            type: this.options.areaType,
            sort: "name:asc"
        });

        // Reset menu options
        this.options.items = [];

        for (let area of this.areas) {
            // Add item
            this.options.items.push({
                name: area.id,
                label: area.name
            });
        }

        // Now call the rest
        super.onCreate();
    }

}
