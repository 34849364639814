import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";
import { HiyoEvent } from "../../../hiyo/event-broker";

export abstract class TrafficIncidentLayer extends MapLayer<InvipoContext> {

    public onAdd(): void {
        this.context.broker.subscribe(this, ["TrafficIncidentReceived", "TrafficIncidentRemoved"]);
    }

    public onRemove(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Reload data
        this.data = await this.load();

        // Update
        this.update();
    }

}