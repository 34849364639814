import "./notification-detail.scss";
import * as template from "./notification-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { DataTable } from "muklit/components/data-table/data-table";
import { Detail } from "muklit/components/detail/detail";
import { NotificationDetailOptions } from "./types";
import { Dialog } from "muklit/components/dialog/dialog";
import { Toggle } from "muklit/components/toggle/toggle";
import { Notification } from "../../../clients/invipo-client/types";
import { NotificationForm } from "../notification-form/notification-form";

export class NotificationDetail extends Detail<InvipoContext, NotificationDetailOptions> {

    // Properties
    public notification: Notification;

    // Components
    public tabs: Tabs;
    public toggle: Toggle;
    public logs: DataTable;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: NotificationDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createToggle();
        this.createLogs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.toggle, "toggle");
        this.registerComponent(this.logs, "logs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "General",
                    label: "components.NotificationDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Logs",
                    label: "components.NotificationDetail.logs",
                    content: "div.content-logs"
                }
            ]
        });
    }

    private createToggle(): void {
        // Crate component
        this.toggle = new Toggle(this.context, {
            style: "Light",
            size: "Small",
            name: "toggle"
        })

        // Disable user
        this.toggle.onToggle = async (toggled: boolean) => {
            // Change label
            this.toggle.setLabel(toggled ? "components.NotificationDetail.enabled" : "components.NotificationDetail.disabled");

            // Update notification
            await this.disable(!toggled);
        }
    }

    private createLogs(): void {
        // Create component
        this.logs = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            autosort: true,
            height: "100%",
            rows: {
                id: "id",
                decorator: (data: any) => {
                    return !data.succeeded ? "Error" : "Ok";
                }
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    minWidth: 160,
                    sortable: true,
                    selected: true,
                    descendent: true,
                },
                {
                    name: "succeeded",
                    type: "String",
                    property: "succeeded",
                    label: "tables.columns.succeeded",
                    formatter: (value: any, data: any): string => {
                        return `<div class="cell cell-center" ${data.trace ? `data-tooltip="${data.trace}` : ""}">${this.context.locale.getMessage(value ? "common.yes" : "common.no")}</div>`
                    },
                    width: 120,
                    align: "Center",
                    sortable: true,
                    ellipsis: true
                },
            ]
        });
    }

    public openForm(): void {
        // Details form
        let form = new NotificationForm(this.context, {
            style: "Light",
            title: "components.NotificationForm.updateNotification",
            notification: this.notification,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public async disable(disabled: boolean): Promise<void> {
        // Show loader
        this.showLoader();

        // Update whole object
        await this.context.invipo.updateNotification(this.options.notificationId, {
            ...this.notification,
            disabled: disabled
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.NotificationDetail.notificationDelete",
            text: this.context.locale.getMessage("components.NotificationDetail.reallyDelete", this.notification.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteNotification(this.notification.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.notification = await this.context.invipo.getNotification(this.options.notificationId);
        let logs = await this.context.invipo.getDataset("notifications-log", `notification.id=${this.options.notificationId}&pageSize=50&sort=timestamp:desc`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);

        // Manage child components
        this.toggle.setValue(!this.notification.disabled, this.notification.disabled ? "components.NotificationDetail.disabled" : "components.NotificationDetail.enabled");
        this.logs.setData(logs.data);
    }

}
