import './area-list.scss';
import * as template from "./area-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { AreaListOptions } from "./types";
import { CityList } from "../city-list/city-list";
import { InvipoArea } from "../../../clients/invipo-client/types";
import { InvipoHelpers } from "../../../invipo-helpers";

export class AreaList extends CityList<AreaListOptions> {

    // Properties
    public areas: InvipoArea[];

    constructor(context: InvipoContext, options?: AreaListOptions) {
        super(context, template, options);
    }

    public selectArea(i: number): void {
        // Get item
        let area = this.areas[i];

        // CAll handler
        this.onBoundsNavigate(InvipoHelpers.toBounds(area.geometry), area);
    }

    public async extraLoad(): Promise<void> {
        // Load areas
        if (this.options.areaType) {
            this.areas = await this.context.invipo.getAreas(`type=${this.options.areaType}&sort=name:asc`);
        }
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.areas) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
