import "./traffic-event-overview.scss";
import * as template from "./traffic-event-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { TrafficEventOverviewOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { TrafficEventCircleLayer } from "../../../layers/traffic/traffic-event-circle-layer";
import { LineString, Point } from "geojson";
import { TrafficEventSymbolLayer } from "../../../layers/traffic/traffic-event-symbol-layer";

export class TrafficEventOverview extends DetailPanel<TrafficEventOverviewOptions> {

    // Properties
    public event: any;

    constructor(context: InvipoContext, options?: TrafficEventOverviewOptions) {
        super(context, template, options);
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: 15,
            minZoom: 5,
            maxZoom: 20
        });

        // Set p[osition after load
        this.map.onMapLoad = () => {
            // Center map to first point in line
            if (this.event.position.type == "LineString") {
                this.map.setCenter((<LineString>this.event.position).coordinates[0]);
            }

            // Center map to coordinates
            if (this.event.position.type == "Point") {
                this.map.setCenter((<Point>this.event.position).coordinates);
            }
        }

        // Register map layers
        this.map.addLayer(new TrafficEventCircleLayer(this.context));
        this.map.addLayer(new TrafficEventSymbolLayer(this.context));
    }

    public async extraLoad(): Promise<void> {
        // Load event data
        this.event = await this.context.invipo.getManagement(`/traffic/events/${this.options.eventId}`)
    }

}
