import "./violation-form.scss";
import * as template from "./violation-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { ViolationFormOptions } from "./types";
import { TextArea } from "muklit/components/text-area/text-area";
import { TextInput } from "muklit/components/text-input/text-input";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { DateInput } from "muklit/components/date-input/date-input";
import { Ticket } from "../../../clients/invipo-client/types";
import { Helpers } from "hiyo/helpers";

export class ViolationForm extends Detail<InvipoContext, ViolationFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ViolationFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Create title based on attached data
        let title: any[] = [];

        // Has access data?
        if (this.options.violation.data?.access) {
            for (let access of this.options.violation.data.access) {
                // Plate number, tag id or camera id
                let id = access.vehicle?.plate?.number || access.tag?.id || access.camera?.id;

                // Add to title if not exists
                if (!title.includes(id)) {
                    title.push(id);
                }
            }
        }

        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Workflow",
                    label: "forms.fieldsets.workflow",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "title",
                            label: "forms.fields.title",
                            value: this.options.violation.workflow?.title || title.join(", "),
                            width: 672,
                            bright: true
                        }),
                        new TextArea(this.context, {
                            style: "Light",
                            name: "description",
                            label: "forms.fields.description",
                            value: this.options.violation.workflow?.description,
                            width: 672,
                            rows: 6,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "reference",
                            label: "forms.fields.reference",
                            value: this.options.violation.workflow?.reference,
                            width: 672,
                            bright: true
                        }),
                        new AutocompleteSelect(this.context, {
                            style: "Light",
                            name: "assignee",
                            label: "forms.fields.assignee",
                            value: this.options.violation.workflow?.assignee?.id,
                            collection: "Users",
                            type: "Name",
                            placeholderText: "forms.placeholders.nobody",
                            items: [],
                            width: 320,
                            bright: true
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "due",
                            label: "forms.fields.due",
                            value: this.options.violation.workflow?.due,
                            width: 320,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Copnstruct new ticket
        let violation: any = {
            // Set type and timestamp
            type: this.options.violation?.type || "General",
            timestamp: new Date().toISOString(),
            // Merge with violation passed in options
            ...this.options.violation
        };

        // Enable workflow?
        if (this.context.config.violations.columns) {
            violation.workflow = {
                column: this.context.config.violations.columns[0],
                title: data.title,
                description: data.description,
                reference: data.reference,
                assignee: data.assignee,
                due: data.due
            }
        }

        // Show loader
        this.showLoader();

        // Normalize
        violation = Helpers.removeEmpty(violation);

        // Create new user with two form merged together
        try {
            if (this.options.violation.id) {
                // Update existing
                await this.context.invipo.putManagement(`violations/${this.options.violation.id}`, violation);
                await this.context.invipo.putManagement(`violations/${this.options.violation.id}/workflow`, {
                    workflow: violation.workflow
                });
            }
            else {
                // Create new
                await this.context.invipo.postManagement("violations", violation);
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
