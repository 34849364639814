import "./city-card.scss";
import * as header from "./city-card.header.hbs";
import * as alerts from "./city-card.alerts.hbs";
import * as properties from "./city-card.properties.hbs";
import * as history from "./city-card.history.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { CityCardOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Templates } from "hiyo/templates";
import { ImageDetail } from "../../common/image-detail/image-detail";

export const TIMEOUT_DOCUMENT_LISTENER = 150; // ms
export const MIN_HEIGHT = 460;
export const MAX_HEIGHT = 680;

export abstract class CityCard<T extends CityCardOptions = CityCardOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public timer: any;
    public item: InvipoItem;
    public items: InvipoItem[];
    public area: InvipoArea;
    public scrolled: boolean;

    // Private listeners
    private hideListener = () => this.hide();
    private keyListener = (e: KeyboardEvent) => this.key(e);

    // Event handling methods
    public onItemSelect(item: InvipoItem, card?: string): void {};
    public onDetailView(content?: string): void {};

    protected constructor(context: InvipoContext, template: any, options: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("city-card-header", header);
        Templates.registerPartial("city-card-alerts", alerts);
        Templates.registerPartial("city-card-properties", properties);
        Templates.registerPartial("city-card-history", history);
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean): void {
        super.attach(target, before, skipLoad);

        // Set minimized height
        this.element.style.height = `${MIN_HEIGHT}px`;
    }

    public update(): void {
        super.update();

        let header = this.query("div.header");
        let content = this.query("div.content");

        // Scroll listener after data is loaded and component was updated
        content?.addEventListener("scroll", (e: MouseEvent) => {
            // Add border if content is scrolled
            header.classList.toggle("header-border", content.scrollTop > 0);

            // Already scrolled?
            if (this.scrolled) {
                return;
            }

            // Do this only once
            this.scrolled = true;

            // Sometimes the content could be too short
            let height = Math.min(MAX_HEIGHT, header.scrollHeight + content.scrollHeight);

            // Get rect and adjust new top and height
            let rect = this.element.getBoundingClientRect();

            rect.y = rect.y - (height - MIN_HEIGHT) / 2;
            rect.height = height;

            // Clip to screen
            this.clip(rect);

            // Set position to style
            this.element.style.top = `${rect.y}px`;
            this.element.style.height = `${rect.height}px`;
        });
    }

    public show(x: number, y: number, zIndex?: number, target?: string | HTMLElement): void {
        // Already visible?
        if (this.isAttached()) {
            return;
        }

        // Attach to document
        this.attach(target);

        // Self dimensions
        let rect = this.element.getBoundingClientRect();

        // Optimal position
        rect.x = x + 16;
        rect.y = y - (rect.height / 2);

        // Clip to screen
        this.clip(rect);

        // Set position to style
        this.element.style.left = `${rect.x}px`;
        this.element.style.top = `${rect.y}px`;

        // Custom z-index?
        if (zIndex) {
            this.element.style.zIndex = String(zIndex);
        }

        // Add keyup listener on document to close self on escape key
        document.addEventListener("keyup", this.keyListener);

        // We need to cancel event propagation if click on itself
        this.element.addEventListener("mousedown", (e: MouseEvent) => {
            e.cancelBubble = true;
        });

        // We add document listener after some short delay because of event bubbling
        setTimeout(() => {
            document.addEventListener("mousedown", this.hideListener);
        }, TIMEOUT_DOCUMENT_LISTENER)
    }

    public clip(rect: DOMRect): void {
        // Clip left
        if (rect.x < 8) {
            rect.x = 8;
        }
        // Clip right
        if (rect.x + rect.width + 8 > document.body.offsetWidth) {
            rect.x = document.body.offsetWidth - rect.width - 8;
        }
        // Clip top
        if (rect.y < 8) {
            rect.y = 8;
        }
        // Clip bottom
        if (rect.y + rect.height + 8 > document.body.offsetHeight) {
            rect.y = document.body.offsetHeight - rect.height - 8;
        }
    }

    public key(e: KeyboardEvent): void {
        // ESC pressed so we can hide?
        if (e.key == "Escape") {
            this.hide();
        }
    }

    public hide(): void {
        // Detach from document
        this.detach();

        // Remove all listeners on document level
        document.removeEventListener("keyup", this.keyListener);
        document.removeEventListener("mousedown", this.hideListener);
    }

    public close(): void {
        // Stop propagation of bubbling events
        event.stopPropagation();

        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Hide
        this.hide();

        // OnClose handler;
        this.onClose();
    }

    public selectItem(i: number, card?: string): void {
        // Hide self
        this.hide();

        // OnItemSelect handler
        this.onItemSelect(this.items[i], card);
    }

    public selectParent(): void {
        // Hide self
        this.hide();

        // OnItemSelect handler
        this.onItemSelect(this.item.parent);
    }

    public viewDetail(content?: string): void {
        // Hide self
        this.hide();

        // OnDetailView handler
        this.onDetailView(content);
    }

    public openImage(url: string): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: url,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public openImages(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Clear loaded items
        this.item = null;
        this.items = [];

        // Load item?
        if (this.options.itemId) {
            // Multiple items separated by comma?
            if (this.options.itemId.includes(",")) {
                for (let id of this.options.itemId.split(",")) {
                    this.items.push(this.context.data.getItem(id));
                }
            }
            // Single item
            else {
                this.item = this.context.data.getItem(this.options.itemId);
            }
        }

        // Load area?
        if (this.options.areaId) {
            this.area = this.context.data.getArea(this.options.areaId);
            this.items = await this.context.invipo.getAreaItems(this.options.areaId, this.area.class ? `class=${this.area.class}` : null);
        }

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw
        this.update();
    }

}
