import "./pagination.scss";
import * as template from "./pagination.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { PaginationOptions } from "./types";

export class Pagination extends MuklitComponent<Context, PaginationOptions> {

    // Event handling methods
    public onSelect(page: number): void {};

    constructor(context: Context, options: PaginationOptions) {
        super(context, template, options);
    }

    public focus(): void {
        this.query("div.page-input").classList.add("page-editing");
        this.query("div.page-input").innerText = "";
    }

    public blur(): void {
        this.query("div.page-input").classList.remove("page-editing");
        this.query("div.page-input").innerText = String(this.options.page);
    }

    public key(event: KeyboardEvent): void {
        // Read value
        let value = this.query("div.page-input").innerText;

        // Enter
        if (event.key == "Enter") {
            // Stop bubbles
            event.preventDefault();

            // Convert value to page number
            let page = Number(value);

            // Valid number only
            if (page && page >= 1 && page <= this.options.pageCount) {
                this.select(page)
            }
        }

        // ESC
        if (event.key == "Escape") {
            this.query("div.page-input").blur();
        }
    }

    public select(i: number): void {
        this.options.page = i;

        // Update
        this.update();

        // OnSelect handler
        this.onSelect(i);
    }

}
