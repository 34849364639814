import './toast-manager.scss';
import * as template from "./toast-manager.hbs";
import sound from "muklit/assets/audio/correct-answer-tone-952.mp3"
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { NotificationManagerOptions } from "./types";
import { HiyoEvent } from "hiyo/event-broker";
import { NotificationToast } from "muklit/components/notification-toast/notification-toast";
import { ToastAction } from "muklit/components/notification-toast/types";
import { Dom } from "hiyo/dom";

const INFO_DURATION = 4000; // Basic info toast duration (in ms)

const EVENT_TOAST_REQUESTED = "ToastRequested";
const EVENT_EXPORT_COMPLETED = "ExportCompleted";
const EVENT_EXPORT_FAILED = "ExportFailed";

export class ToastManager extends MuklitComponent<InvipoContext, NotificationManagerOptions> {

    // Properties
    public toasts: NotificationToast[];

    constructor(context: InvipoContext, options?: NotificationManagerOptions) {
        super(context, template, options);

        // Array of created toasts because of reference
        this.toasts = [];
    }

    public onAttach(): void {
        // Subscribe for events
        this.context.broker.subscribe(this, [
            EVENT_TOAST_REQUESTED,
            EVENT_EXPORT_COMPLETED,
            EVENT_EXPORT_FAILED
        ]);
    }

    public onHandle(event: HiyoEvent): void {
        // Reference to alreqady displayed toast?
        if (event.payload.extras?.ref) {
            let ref = this.toasts.find(x => x.options.ref == event.payload.extras?.ref);

            // Detach if found and attached
            if (ref?.isAttached()) {
                ref.detach();
            }
        }

        // When toast should be displayed on background here,
        // you should handle it here
        switch (event.type) {
            // Toast requested
            case EVENT_TOAST_REQUESTED:
                this.showInfoToast(event.payload.extras.title, event.payload.extras.text, null, event.payload.extras.beep);
                break;
            // Export was successfully completed
            case EVENT_EXPORT_COMPLETED:
                this.showLinkToast("components.ToastManager.exportCompleted", "components.ToastManager.exportReady", `${this.context.options.host}/download${event.payload.extras.url}`);
                break;
            // Export was successfully completed
            case EVENT_EXPORT_FAILED:
                this.showErrorToast("components.ToastManager.exportFailed", event.payload.extras.reason);
                break;
        }
    }

    public showInfoToast(title: string, message: string, ref?: string, beep?: boolean) {
        // Create toast
        let toast = new NotificationToast(this.context, {
            kind: "Info",
            title: title,
            message: message,
            timestamp: new Date(),
            duration: INFO_DURATION + (title + message).length * 30,
            ref: ref
        });

        // Play sound?
        if (beep) {
            // Async wrapper for audio tag
            (async () => {
                await new Audio(sound).play();
            })();
        }

        // Show toast
        this.attachToast(toast);
    }

    public showErrorToast(title: string, message: string) {
        // Create toast
        let toast = new NotificationToast(this.context, {
            kind: "Error",
            title: title,
            message: message
        });

        // Show toast
        this.attachToast(toast);
    }

    public showLinkToast(title: string, message: string, url: string, label?: string): NotificationToast {
        // Create toast
        let toast = new NotificationToast(this.context, {
            kind: "Success",
            title: title,
            message: message,
            timestamp: new Date(),
            actions: [
                {
                    name: "Link",
                    label: label || "Download"
                }
            ]
        });

        // Follow url to download
        toast.onAction = (action: ToastAction) => {
            Dom.openLink(url);
        };

        // Show toast
        this.attachToast(toast);

        return toast;
    }

    public attachToast(toast: NotificationToast) {
        // Add to evidence
        this.toasts.push(toast);

        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Empty all previous toasts (remove to queue toasts again)
        this.element.innerHTML = "";

        // Show toast
        toast.attach(this.element);
    }

    public test(): void {
        this.showInfoToast("Info", "This is a info toast", null, true);
        this.showErrorToast("Error", "This is a error toast");
        this.showLinkToast("Link", "This is a link toast", "http://www.seznam.cz");
    }
}
