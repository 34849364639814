import "./vejle-dashboard.scss";
import * as template from "./vejle-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { Dashboard } from "../dashboard/dashboard";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { VejleDashboardOptions } from "./types";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";

export class VejleDashboard extends Dashboard<VejleDashboardOptions> {

    // Components
    public map: BasicMap;
    public dataStorage: DataStorageTile;

    constructor(context: InvipoContext, options?: VejleDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficVolumeTile(this.context, {
                        itemId: "63dcf2423b8a0f64ac35e7df"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "663c97e71fd64b4c7efff60c"
                    })
                ]
            }
        ];
    }
}
