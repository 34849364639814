import "./ticket-table.scss";
import * as template from "./ticket-table.hbs";

import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TicketTableOptions } from "./types";
import { Ticket } from "../../../clients/invipo-client/types";
import { DataTable } from "muklit/components/data-table/data-table";

export class TicketTable extends MuklitComponent<InvipoContext, TicketTableOptions> {

    // Components
    private table: DataTable;

    // Properties
    public tickets: Ticket[];

    // Event handling methods
    public onTicketSelect(ticket: any): void {}

    constructor(context: InvipoContext, options?: TicketTableOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();

        // Register components
        this.registerComponent(this.table, "data-table")
    }

    public createTable(): void {
        this.table = new DataTable(this.context, {
            style: "Light",
            size: "Short",
            type: "HoverOnly",
            autosort: true,
            groups: {
                property: "column",
                sort: (a, b) => this.context.config.tickets.columns.indexOf(a.value) - this.context.config.tickets.columns.indexOf(b.value)
            },
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "created",
                    property: "created",
                    label: "details.properties.created",
                    type: "DateTime",
                    width: 200,
                    sortable: true,
                    selected: true,
                },
                {
                    name: "type",
                    property: "type",
                    label: "details.properties.type",
                    type: "String",
                    width: 140,
                    formatter: (value) => `<div class="cell">${this.context.locale.getMessage(`components.TicketManager.types.${value}`)}</div>`
                },
                {
                    name: "title",
                    property: "title",
                    label: "details.properties.title",
                    type: "String",
                    width: 200,
                },
                {
                    name: "description",
                    property: "description",
                    label: "details.properties.description",
                    type: "String",
                },
                {
                    name: "assignee",
                    property: (data) => data.assignee?.name,
                    label: "details.properties.assignee",
                    type: "String",
                    width: 140,
                },
                {
                    name: "due",
                    property: "due",
                    label: "details.properties.due",
                    type: "DateTime",
                    width: 200,
                    sortable: true,
                },
            ],
            data: this.tickets
        });

        this.table.onRowSelect = (row) => {
            this.onTicketSelect(row.data);
        };
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Keep selected ticket
        let selected = this.tickets?.find(x => x.selected == true);

        // Load data
        this.tickets = await this.context.invipo.getTickets();
        console.log(this.tickets);

        // Reselect ticket because we have new data
        if (selected) {
            this.tickets.find(x => x.id == selected.id).selected = true;
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.table.setData(this.tickets);
    }

}
