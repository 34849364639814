import './wall-cameras.scss';
import * as template from "./wall-cameras.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { WallCamerasOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { MonitoringDetailOptions } from "../../monitoring/monitoring-detail/types";
import { MonitoringDetail } from "../../monitoring/monitoring-detail/monitoring-detail";
import { Helpers } from "hiyo/helpers";
import { VideoDetail } from "../../common/video-detail/video-detail";

const RELOAD_TIMEOUT = 30;
const SCROLL_TIMEOUT = 60;

export class WallCameras extends MuklitComponent<InvipoContext, WallCamerasOptions> {

    // Properties
    public items: InvipoItem[];
    public detail: MonitoringDetail;
    public timer: any;
    public interval: any;

    constructor(context: InvipoContext, options?: WallCamerasOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        // Add scroll interval
        this.interval = setInterval(() => {
            this.scroll(3);
        }, SCROLL_TIMEOUT * 1000);

        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Clear scroll interval
        clearInterval(this.interval);

        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public scroll(d: number): void {
        // Image height?
        let height = this.query("div.camera").offsetHeight;

        // Add margin to height
        height += parseInt(getComputedStyle(this.query("div.camera")).marginBottom);

        // Calculate scroll top
        let top = height * d;

        // Get scroll container
        let element = this.query("div.cameras");

        // Scrolled down already?
        if (d > 0 && (element.scrollTop + element.offsetHeight >= element.scrollHeight)) {
            top = -element.scrollHeight;
        }

        // Scrolled up already?
        if (d < 0 && element.scrollTop <= 0) {
            top = element.scrollHeight;
        }

        // Scroll by one whole width respecting delta
        element.scrollBy({
            top: top,
            left: 0,
            behavior: "smooth"
        });
    }

    public openImage(url: string): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: null,
            url: url,
            closable: true,
            overlay: true
        });

        // Show and center in element viewport
        detail.attach();
    }

    public openVideo(url: string): void {
        // Replace placeholders
        let stream = url.replace("{{timestamp}}", new Date().toISOString());

        // New image detail
        let detail = new VideoDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            stream: stream,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    private async openDetail(itemId: string, itemName: string): Promise<void> {
        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: itemName,
            subtitle: `classes.Camera`,
            itemId: itemId,
            closable: true,
            localizable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MonitoringDetail(this.context, options);

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public async load(): Promise<void> {
        // Clear timer
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // Camera items
        this.items = this.context.data.getItems({
            class: "Camera",
            sort: "name:asc"
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Reset after one minute
        this.timer = setTimeout(() => {
            this.load()
        }, RELOAD_TIMEOUT * 1000);

        // On first load we need to render HTML
        if (!this.query("img")) {
            // Update and exit
            this.update();
            return;
        }

        // Update dynamically
        for (let item of this.items) {
            let image = this.query<HTMLImageElement>(`div.camera-${item.id} img`);

            // Image not found?
            if (!image) {
                continue;
            }

            // Update image URL
            image.src = `${this.context.options.host}/download${item.data.snapshot.url}?${item.data.snapshot.timestamp}`;

            // Sleep before other request
            await Helpers.sleep(250);

            // Component is gone menawhile?
            if (!this.isAttached()) {
                break;
            }
        }

    }

}
