import { InvipoContext } from "../../context/invipo-context";
import { AreaSpotLayer } from "../area-spot-layer";

const MAX_ZOOM = 10.5;

export class ParkingAreaSymbolLayer extends AreaSpotLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "ParkingOccupancy",
            tooltip: "ParkingOccupancyAreaTooltip",
            dynamic: true,
            pointer: true,
            layer: {
                id: "parking-occupancy-area-symbol",
                type: "symbol",
                maxzoom: MAX_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": "24-tools-area-grey",
                    "icon-offset": [0, 20]
                }
            }
        });
    }
}
