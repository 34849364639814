import { Component } from "./component";
import { Log } from "./log";
import { Context } from "./context";
import { Helpers } from "./helpers";
import { HiyoEvent } from "./event-broker";

export abstract class HiyoObject<T extends Context = Context> {

    // Properties
    public id: string;
    public name: string;
    public context: T;
    public components: { [id: string]: Component };

    // Event handling methods
    public onHandle(event: HiyoEvent): void {}

    protected constructor(context: T) {
        this.context = context;
        this.components = {};

        // Assign unique id
        this.id = Helpers.newUid(this);

        // Assign name
        this.name = this.constructor.name;
    }

    protected registerComponent(component: Component, key?: string): void {
        if (!component) {
            Log.w(`Could not register null component on ${this.id}`);
            return;
        }

        // Element name
        key = key || Helpers.toKebabCase(component.constructor.name);

        let found: Component = null;

        for (let i in this.components) {
            if (this.components[i] == component) {
                found = this.components[i];
                break;
            }
        }

        if (found) {
            Log.w(`Component ${found.id} already registered`);
            return;
        }

        // Register to map
        this.components[key] = component;
    }

}
