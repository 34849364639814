import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";
import { FeatureCollection } from "geojson";
import { InvipoArea } from "../../clients/invipo-client/types";

export class AreaBoundaryLayer extends MapLayer<InvipoContext> {

    // Properties
    public areas: InvipoArea[];

    constructor(context: InvipoContext, areas?: InvipoArea[]) {
        super (context, {
            pointer: true,
            dynamic: true,
            before: "road-label",
            tooltip: "AreaTooltip",
            layer: {
                id: "area-boundary-layer",
                type: "line",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#000000",
                    "line-width": 4,
                    "line-opacity": 0.15,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            }
        })

        // Custom areas
        this.areas = areas;
    }

    public async load(): Promise<any> {
        let areas = this.areas || this.context.data.getAreas();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        };

        // Push features from items
        for (let area of areas) {
            json.features.push({
                type: "Feature",
                properties: {
                    type: "Area",
                    areaId: area.id,
                    areaName: area.name,
                    areaType: area.type,
                    tooltip: this.options.tooltip,
                    pointer: this.options.pointer
                },
                geometry: area.geometry
            });
        }

        return json;
    }
}
