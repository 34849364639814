import * as template from "./monitoring-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { MonitoringPanelOptions } from "./types";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis, PanelTable } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class MonitoringPanel extends Panel<MonitoringPanelOptions> {

    // Properties
    public kpis: PanelKpis;
    public debug: PanelKpis;
    public chart: PanelChart;
    public malfunctions: PanelTable;

    constructor(context: InvipoContext, options?: MonitoringPanelOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history data
        let from = new Date(new Date(Date.now() - 48 * 60 * 60 * 1000).setMinutes(0, 0, 0));
        let to = new Date(new Date().setMinutes(0, 0, 0));

        // Data
        let data = await this.context.invipo.getQuery("item-status-by-hour", `&item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        let downtimes = await this.context.invipo.getDataset("monitoring-status-history", `item.id=${this.item.id}&from=${from.toISOString()}&status=Disconnected,Unreachable&pageSize=5&sort=timestamp:desc`);
        let logs = await this.context.invipo.getDataset("items-log", `item.id=${this.item.id}&pageSize=5&sort=timestamp:desc`);

        // Build status KPIs
        this.kpis = {
            label: "components.MonitoringPanel.status",
            size: "Fourth",
            data: [
                {
                    label: "components.MonitoringPanel.status",
                    value: `monitoringStatus.${this.item.monitoringStatus.status}`,
                    type: (this.item.monitoringStatus.status == "Ok") ? "Positive" : "Negative",
                    description: Helpers.toDuration((Date.now() - new Date(this.item.monitoringStatus.changed).getTime()) / 1000)
                },
                {
                    label: "components.MonitoringPanel.availability",
                    value: `${Helpers.toNumber(this.item.availability.value, 2, 2)} %`,
                    type: (this.item.availability.value > 95) ? "Positive" : "Negative",
                    description: Helpers.toDuration(this.item.availability.uptime)
                },
                {
                    label: "components.MonitoringPanel.downtimes",
                    value: `${downtimes.data.length}&times;`,
                    type: (downtimes.data.length == 0) ? "Positive" : "Negative",
                    description: "components.MonitoringPanel.duringLastTwoWeeks"
                },
                {
                    label: "components.MonitoringPanel.malfunctions",
                    value: Helpers.toNumber(this.item.malfunctions?.issues?.length || 0),
                    type: (this.item.malfunctions?.issues?.length) ? "Negative" : "Positive",
                    description: (this.item.malfunctions?.issues?.length) ? Helpers.toDateTimeString(this.item.malfunctions?.timestamp) : "components.MonitoringPanel.noMalfunctions",
                }
            ]
        }

        // Build hour chart
        this.chart = {
            type: "Bar",
            size: "Small",
            length: 48,
            label: "components.MonitoringPanel.history",
            series: []
        }

        // Add hourly data to chart series
        do {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Has data?
            if (d) {
                this.chart.series.push(
                    [
                        {
                            timestamp: d.timestamp,
                            valueX: Helpers.toShortDateTimeString(d.timestamp),
                            percent: 100,
                            label: `monitoringStatus.${d.status}`,
                            color: METRICS.infrastructure.technology.status.colors[METRICS.infrastructure.technology.status.range.indexOf(d.status)]
                        }
                    ]
                );
            }
            // No data
            else {
                this.chart.series.push(
                    [
                        {
                            timestamp: from.toISOString()
                        }
                    ]
                );
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
        while (from.getTime() < to.getTime())

        // Custom range
        this.chart.range = [
            {
                label: Helpers.toShortDateTimeString(from)
            },
            {
                label: Helpers.toShortDateTimeString(new Date(from.getTime() + (to.getTime() - from.getTime()) / 2))
            },
            {
                label: Helpers.toShortDateTimeString(to)
            }
        ];

        // Build debug KPIs
        this.debug = {
            label: "components.MonitoringPanel.debug",
            size: "Half",
            data: [
                {
                    label: "components.MonitoringPanel.log",
                    value: (logs.data?.length == 0) ? "components.MonitoringPanel.noLog" : logs.data[0].message,
                    type: (logs.data?.length == 0) ? "Positive" : "Negative",
                    description: (logs.data?.length == 0) ? "components.MonitoringPanel.duringLastTwoWeeks" : Helpers.toShortDateTimeString(logs.data[0].timestamp),
                },
                {
                    label: "components.MonitoringPanel.reason",
                    value: this.item.monitoringStatus?.reason || "components.MonitoringPanel.noReason",
                    type: (this.item.monitoringStatus?.reason == null) ? "Positive" : "Negative"
                }
            ]
        }

    }
}
