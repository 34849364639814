import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { VehicleLocation } from "../../components/traffic/vehicle-appearance-report/types";

export class CameraLocationLabelLayer extends MapLayer<InvipoContext> {

    // Properties
    public locations: VehicleLocation[];

    public constructor(context: InvipoContext, locations: VehicleLocation[]) {
        super(context, {
            dynamic: true,
            layer: {
                id: "camera-location-label",
                type: "symbol",
                minzoom: 16,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#efefef"
                },
                layout: {
                    "text-field": ["get", "count"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 10,
                    "text-offset": [0, 0.1],
                    "text-anchor": "center",
                    "text-max-width": 10,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true
                }
            }
        });

        // Assign proeprties
        this.locations = locations;
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let location of this.locations) {
            json.features.push({
                type: "Feature",
                properties: {
                    count: `${location.count}×`
                },
                geometry: location.geometry
            })
        }

        return json;
    }

}
