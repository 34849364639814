import { MapLayer } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";

export class GoogleOrthophotoLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            pointer: true,
            layer: {
                id: "google-orthophoto",
                type: "raster",
                source: {
                    type: "raster",
                    "tiles": [
                        "https://khms2.google.com/kh/v=946?x={x}&y={y}&z={z}"
                    ],
                    "tileSize": 256
                },
            }
        });
    }

    public async load(): Promise<any> {
        return null;
    }

}
