import "./violation-comment-form.scss";
import * as template from "./violation-comment-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextArea } from "muklit/components/text-area/text-area";
import { ViolationCommentFormOptions } from "./types";

export class ViolationCommentForm extends Detail<InvipoContext, ViolationCommentFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ViolationCommentFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "text",
                            label: "forms.fields.text",
                            messageText: "components.TicketCommentForm.message",
                            width: 420,
                            rows: 6,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Create comment
            await this.context.invipo.postManagement(`violations/${this.options.violation.id}/comments`, {
                text: data.text
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
