import { Component } from "./component";
import { Log } from "./log";

export class Dom {

    public static getElement(selector: string): Element {
        return document.querySelector(selector);
    }

    public static createElement(parent: string | HTMLElement, html: string, before?: boolean): HTMLElement {
        // New element in memory
        let wrapper = document.createElement("div");
        wrapper.innerHTML = html;

        let element = <HTMLElement>wrapper.childNodes.item(0);
        let target: HTMLElement;

        // Parent element?
        if (typeof parent == "string") {
            target = document.querySelector(parent);
        }
        else {
            target = parent;
        }

        // Consistency check
        if (target == null) {
            Log.w(`Invalid element or selector ${parent}. Does not exist in DOM?`);
        }

        let result: HTMLElement;

        // DOM creation
        result = before ? target.parentElement.insertBefore(element, target) : target.appendChild(element);

        if (result.nodeType != Node.ELEMENT_NODE) {
            Log.w(`Could not create HTML element in DOM. Invalid html code?`);
        }

        return result;
    }

    public static updateElement(e: HTMLElement, html: string): void {
        // New element in memory
        let wrapper = document.createElement("div");
        wrapper.innerHTML = html;

        let target = <HTMLElement>wrapper.childNodes.item(0);

        // Copy root classes
        e.className = target.className;

        // Replace only innerHTML, so element instance can stay unchanged
        e.innerHTML = target.innerHTML;

        // Cleanup
        wrapper.remove();
    }

    public static propagateToContext(element: HTMLElement, c: Component<any, any>): void {
        // Self
        (<any>element).component = c;

        // All children
        element.querySelectorAll("*").forEach((e: HTMLElement) => {
            (<any>e).component = c;
        })
    }

    public static openLink(url: string, name?: string): void {
        // Create anchor
        let element = document.createElement("a");

        element.setAttribute("href", url);
        element.setAttribute("download", name || `export-${Date.now()}`);
        element.style.display = "none";

        // Add to DOM, follow link and remove
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}
