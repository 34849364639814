import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoItem } from "../../clients/invipo-client/types";
import { METRICS } from "../../components/city/city-subdomain/types";

export class WasteCollectionLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "WasteCollectionCard",
            layer: {
                id: "waste-collection-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 3,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": ["get", "color"],
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "GarbageBin"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Sort by collection
        items.sort((a: InvipoItem, b: InvipoItem) => {
            return new Date(b.data?.collection?.timestamp).getTime() - new Date(a.data?.collection?.timestamp).getTime();
        });

        // Push features from items
        for (let item of items) {
            // No data or data too old?
            if (item.data?.collection?.timestamp == null) {
                continue;
            }

            // Number of days and maximum value on legend
            let days = Math.floor((Date.now() - new Date(item.data.collection.timestamp).getTime()) / 86400000);
            let max = METRICS.waste.waste.collection.range[METRICS.waste.waste.collection.range.length - 1];

            json.features.push({
                type: "Feature",
                properties: {
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: "#161616",
                    label: days > max ? `${max}+` : days
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
