import "./network-browser.scss";
import * as template from "./network-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { NetworkBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { RangeInput } from "muklit/components/range-input/range-input";

export class NetworkBrowser extends MuklitComponent<InvipoContext, NetworkBrowserOptions> {

    // Components
    public form: Form;
    public table: FilterTable;

    constructor(context: InvipoContext, options?: NetworkBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/network-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.NetworkBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "NetworkData",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                groups: {
                    property: "interval.from",
                    formatter: (value: any, data: any) => {
                        return `${Helpers.toDateString(value)} ${Helpers.toShortTimeString(value)}`;
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${Helpers.toShortTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}</div>`
                        },
                        label: "tables.columns.interval",
                        width: 220,
                        selected: true,
                        sortable: true,
                        extraSort: "item.name",
                        descendent: true
                    },
                    {
                        name: "connections",
                        type: "String",
                        property: "connections",
                        label: "tables.columns.connections",
                        width: 80,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "count",
                        type: "Number",
                        property: "count",
                        label: "tables.columns.count",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${Helpers.toNumber(data.count)} ${this.context.locale.getMessage("units.vph")}</div>`
                        },
                        width: 220,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },*/
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Register component
        this.registerComponent(this.table, "table");
    }
}
