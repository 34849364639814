import './envi-pollution-subdomain.scss';
import * as template from "./envi-pollution-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS, SubdomainMetrics } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { EnviPollutionCoCircleLayer } from "../../../layers/envi/envi-pollution-co-circle-layer";
import { EnviPollutionCoLabelLayer } from "../../../layers/envi/envi-pollution-co-label-layer";
import { EnviPollutionNo2CircleLayer } from "../../../layers/envi/envi-pollution-no2-circle-layer";
import { EnviPollutionNo2LabelLayer } from "../../../layers/envi/envi-pollution-no2-label-layer";
import { EnviPollutionSo2CircleLayer } from "../../../layers/envi/envi-pollution-so2-circle-layer";
import { EnviPollutionSo2LabelLayer } from "../../../layers/envi/envi-pollution-so2-label-layer";
import { EnviPollutionPm1CircleLayer } from "../../../layers/envi/envi-pollution-pm1-circle-layer";
import { EnviPollutionPm1LabelLayer } from "../../../layers/envi/envi-pollution-pm1-label-layer";
import { EnviPollutionPm25CircleLayer } from "../../../layers/envi/envi-pollution-pm25-circle-layer";
import { EnviPollutionPm25LabelLayer } from "../../../layers/envi/envi-pollution-pm25-label-layer";
import { EnviPollutionPm10CircleLayer } from "../../../layers/envi/envi-pollution-pm10-circle-layer";
import { EnviPollutionPm10LabelLayer } from "../../../layers/envi/envi-pollution-pm10-label-layer";
import { EnviPollutionSubdomainOptions } from "./types";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { EnviPollutionO3CircleLayer } from "../../../layers/envi/envi-pollution-o3-circle-layer";
import { EnviPollutionO3LabelLayer } from "../../../layers/envi/envi-pollution-o3-label-layer";
import { InvipoHelpers } from "../../../invipo-helpers";

export const ITEM_CLASS = "EnvironmentalSensor,RoadWeatherStation";

export class EnviPollutionSubdomain extends CitySubdomain<EnviPollutionSubdomainOptions> {

    constructor(context: InvipoContext, options?: EnviPollutionSubdomainOptions) {
        // Metrics available based on cofiguration
        let metrics: SubdomainMetrics[] = [];

        if (InvipoHelpers.hasData(context.config.data, "pollution.co")) {
            metrics.push(
                {
                    name: "Co",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionCoCircleLayer(context),
                        new EnviPollutionCoLabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.no2")) {
            metrics.push(
                {
                    name: "No2",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionNo2CircleLayer(context),
                        new EnviPollutionNo2LabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.so2")) {
            metrics.push(
                {
                    name: "So2",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionSo2CircleLayer(context),
                        new EnviPollutionSo2LabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.pm1")) {
            metrics.push(
                {
                    name: "Pm1",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionPm1CircleLayer(context),
                        new EnviPollutionPm1LabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.pm25")) {
            metrics.push(
                {
                    name: "Pm25",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionPm25CircleLayer(context),
                        new EnviPollutionPm25LabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.pm10")) {
            metrics.push(
                {
                    name: "Pm10",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionPm10CircleLayer(context),
                        new EnviPollutionPm10LabelLayer(context)
                    ]
                }
            );
        }
        if (InvipoHelpers.hasData(context.config.data, "pollution.o3")) {
            metrics.push(
                {
                    name: "O3",
                    itemClass: "EnvironmentalSensor,RoadWeatherStation",
                    layers: [
                        new EnviPollutionO3CircleLayer(context),
                        new EnviPollutionO3LabelLayer(context)
                    ]
                }
            );
        }

        // Super constructor call
        super(context, template, {
            metrics: metrics,
            ...options
        });
    }

    public selectSensors(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.sensors",
            subtitle: "components.EnviPollutionSubdomain.sensors",
            type: "pollution",
            data: Helpers.toKebabCase(this.metrics.name),
            itemClass: ITEM_CLASS,
            layers: [
                new ItemCircleLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        this.legend = {
            description: `components.EnviPollutionSubdomain.${metrics}Description`,
            range: {
                range: METRICS.envi.pollution[metrics].range,
                colors: METRICS.envi.pollution[metrics].colors,
                unit: METRICS.envi.pollution[metrics].unit,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate average
        for (let item of this.items) {
            // No data?
            if (!item.data?.envi?.pollution || !item.data.envi.pollution[metrics]) {
                continue;
            }

            this.legend.range.value += item.data.envi.pollution[metrics];
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.envi.pollution[metrics].range[0], METRICS.envi.pollution[metrics].range[1], this.legend.range.value);
    }

}
