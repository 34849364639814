// Imports
import { Log, LogColor } from "./log";
import { View } from "./view";
import { Component } from "./component";
import { HiyoObject } from "./hiyo-object";
import { Context } from "./context";
import { Templates } from "./templates";
import { HiyoEvent } from "./event-broker";
import { Helpers } from "./helpers";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export abstract class Application<T extends Context = Context> extends HiyoObject<T> {

    // Properties
    public views: View<T>[];
    public currentView: View<T>;
    public state: any;

    // Event handling methods
    public onCreate(): void {};
    public onViewChange(view: View): void {};

    protected constructor(context: T, views?: View<T>[]) {
        super(context);

        Log.i(`Hiyo application ${this.constructor.name} created. Welcome.`, LogColor.Magenta);

        // Clear state and [arse state from search params
        this.state = {};
        let params = new URLSearchParams(window.location.search);

        // Build state from params
        for (let param of params) {
            // Put only key=value to the application state
            if (param[1]) {
                this.state[param[0]] = param[1];
            }
        }

        // Log only if state is not empty
        if (Object.keys(this.state).length) {
            Log.i(`Application: State restored (${Helpers.toString(this.state)})`);
        }

        // Subscribe to routing event to listen for view cahnge request
        context.broker.subscribe(this, ["RouteChanged"]);

        // Assign or clear views
        this.views = views || [];

        // Set self to context
        this.context.application = this;

        // Register helpers
        this.registerHelpers();

        // History listener
        window.addEventListener("popstate", (e: PopStateEvent) => {
            // Store state localy
            this.state = e.state;

            // State pop must be anounced via broker for all registered handlers
            this.context.broker.fire({
                type: "RouteChanged",
                timestamp: new Date().toISOString(),
                payload: e.state
            });
        });

        // Empty document.body for start
        document.body.innerHTML = '';

        // onCrete event
        this.onCreate();

        // Put itself into Window context (window.app from browser console)
        (<any>window).app = this;
    }

    public onHandle(event: HiyoEvent) {
        // viewId arrived in routing?
        let viewId = event.payload?.viewId;

        // Route with viewId?
        if (viewId && viewId != this.currentView?.id) {
            this.changeView(viewId);
        }
    }

    public registerView(view: View<T>): void {
        this.views.push(view);
    }

    public registerHelpers(): void {
        // All static helpers
        Templates.registerStaticHelpers();

        // Component render
        Handlebars.registerHelper("render", (c: Component) => {
            if (!c) {
                Log.w(`{{render <component>}} has invalid parameter`);
            }
            return c.render();
        });

        // Localization
        Handlebars.registerHelper("message", (key: string | any, ...args: any) => {
            // Empty message?
            if (!key) {
                return;
            }

            // Not string key means that key must be evaluated from tag's body
            if (typeof key != "string") {
                key = key.fn(this);
            }
            else {
                // Remove last arguments, hHandlebars context
                args.pop();
            }

            return this.context.locale.getMessage(key, ...args);
        });

        // Has user role(s)
        Handlebars.registerHelper("permission", (permissions: string, options: any) => {
            let required = permissions.split(",");

            // Check for permission
            if (this.context.checkPermission(required)) {
                return options.fn(this);
            }

            return options.inverse(this);
        });
    }

    public run(viewId?: string): void {
        Log.i(`Running application ${this.constructor.name}, muhehehe🤡`, LogColor.Magenta);

        // Attach all registered components
        for (let key in this.components) {
            this.components[key].attach();
        }

        // Get view from storage
        let storedViewId = localStorage.getItem(`hiyo.${this.name}.viewId`);

        // Change view according priority (parameter, url, storage, default)
        this.changeView(viewId || this.state.viewId || storedViewId || this.views[0]?.id);
    }

    public route(state: any, skipEvent?: boolean): void {
        // Build the url alias with the data
        let url = "?";

        // If conf is present in url, we must add it to the state to keep it everywhere
        let search = new URLSearchParams(document.location.search);

        if (search.has("conf")) {
            state = {
                conf: search.get("conf"),
                ...state
            }
        }

        for (let key of Object.keys(state)) {
            url += `${key}=${state[key]}&`;
        }

        // Remove last amp
        url = url.slice(0, -1)

        Log.i(`Routing to ${url} (${Helpers.toString(state)})`);

        // Store state localy
        this.state = state;

        // Push new state
        history.pushState(state, null, url);

        // State pop must be anounced via broker for all registered handlers
        if (!skipEvent) {
            this.context.broker.fire({
                type: "RouteChanged",
                timestamp: new Date().toISOString(),
                payload: state
            });
        }
    }

    public changeView(id: string): void {
        // No views defined?
        if (!this.views || this.views.length == 0) {
            Log.w(`Application has no views, use registerView(view) to add a view first`);
            return;
        }

        // Find by id
        let view = this.views.find(x => x.id == id);

        // Not found?
        if (!view) {
            Log.w(`View ${id} not found`);
            return;
        }

        // Already on the same view?
        if (this.currentView == view) {
            Log.w(`Could not change to view ${view.id}, already active`);
            return;
        }

        // Leave view
        if (this.currentView) {
            this.currentView.leave();
        }

        // Assign new view
        this.currentView = view;

        // Store to local storage
        localStorage.setItem(`hiyo.${this.name}.viewId`, id);

        // Enter view
        this.currentView.enter();

        // OnViewChange handler
        this.onViewChange(this.currentView);
    }

}
