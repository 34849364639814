import "./krnap-dashboard.scss";
import * as template from "./krnap-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { KrnapDashboardOptions } from "./types";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { Dashboard } from "../dashboard/dashboard";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { ViolationStatusTile } from "../violation-status-tile/violation-status-tile";

export class KrnapDashboard extends Dashboard<KrnapDashboardOptions> {

    // Components
    public map: BasicMap;
    public dataStorage: DataStorageTile;

    constructor(context: InvipoContext, options?: KrnapDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            /*{
                name: "Mobility",
                width: "1",
                tiles: [
                    new CategoryVolumeTile(this.context, {
                        categoryId: 1,
                        capacity: 3000
                    })
                ]
            },*/
            {
                name: "Violations",
                width: "2",
                tiles: [
                    new ViolationStatusTile(this.context)
                ]
            }
        ];
    }
}
