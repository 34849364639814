import "./feature-form.scss";
import * as template from "./feature-form.hbs";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { FeatureFormOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { Input } from "muklit/components/input/input";
import { Point } from "geojson";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Checkbox } from "muklit/components/checkbox/checkbox";

export class FeatureForm extends Detail<InvipoContext, FeatureFormOptions> {

    // Properties
    public item: InvipoItem;

    // Components
    public form: Form;
    public map: BasicMap;

    // Event handling methods
    public onSubmit(data: any): void {}

    constructor(context: InvipoContext, options: FeatureFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Feature shortcut
        let feature = this.options.feature;

        // Custom fields
        let fields: Input[] = []

        // Location feature
        if (feature.properties.key == "location") {
            fields.push(...[
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "latitude",
                    label: "forms.fields.latitude",
                    value: (<Point>feature.geometry).coordinates[0],
                    width: 272,
                    required: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "longitude",
                    label: "forms.fields.longitude",
                    value: (<Point>feature.geometry).coordinates[1],
                    width: 272,
                    required: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "direction",
                    label: "forms.fields.direction",
                    placeholderText: "forms.placeholders.direction",
                    value: feature.properties.direction,
                    width: 272
                })
            ]);
        }

        // Segment feature
        if (feature.properties.key == "segment") {
            fields.push(...[
                new TextInput(this.context, {
                    style: "Light",
                    name: "name",
                    label: "forms.fields.name",
                    value: feature.properties.name,
                    width: 272,
                    required: true
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "capacity",
                    label: "forms.fields.capacity",
                    value: feature.properties.capacity,
                    width: 272
                }),
                new TextInput(this.context, {
                    style: "Light",
                    format: "Number",
                    name: "offset",
                    label: "forms.fields.offset",
                    value: feature.properties.offset,
                    width: 272
                }),
                new Select(this.context, {
                    style: "Light",
                    name: "type",
                    label: "forms.fields.type",
                    value: feature.properties.type,
                    items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.FeatureForm.segment.type")),
                    width: 272
                }),
                new Checkbox(this.context, {
                    style: "Light",
                    name: "bidirectional",
                    label: "forms.fields.bidirectional",
                    value: feature.properties.bidirectional
                })

            ])
        }

        // Feature form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: fields
                }
            ]
        });
    }

    public save(): void {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Featuire shortcut
        let feature = this.options.feature;

        // Get form data
        let data = this.form.getData(true);

        // Location data merging?
        if (feature.properties.key == "location") {
            // Update feature geometry and direction
            (<Point>feature.geometry).coordinates = [data.latitude, data.longitude];
            feature.properties.direction = data.direction;
        }

        // Segment data merging?
        if (feature.properties.key == "segment") {
            // Merge old and new segment properties
            feature.properties = {
                ...feature.properties,
                ...data
            }
        }

        console.info(feature);

        // Self close
        this.close();

        // OnSave Handler
        this.onSubmit(feature);
    }
}
