import "./docker-detail.scss";
import * as template from "./docker-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { DockerDetailOptions } from "./types";
import { DockerLogDetail } from "../docker-log-detail/docker-log-detail";

export class DockerDetail extends Detail<InvipoContext, DockerDetailOptions> {

    // Properties
    public container: any;
    public raw: any;
    public logs: string[];

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: DockerDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Dark",
            tabs: [
                {
                    name: "General",
                    label: "components.DockerDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Raw",
                    label: "components.DockerDetail.raw",
                    content: "div.content-raw"
                },
                {
                    name: "Log",
                    label: "components.DockerDetail.log",
                    content: "div.content-log"
                }
            ]
        });

        this.tabs.onAttach = () => {
            // Scroll to bottom of log content
            let log = this.query("div.content-log");
            log.scrollTop = log.scrollHeight - log.clientHeight;
        }

        this.tabs.onSelect = (item: TabsItem) => {
            // In console?
            let isConsole = (item.name == "Raw" || item.name == "Log");

            // Toggle flag and class
            this.options.wide = isConsole;
            this.element.classList.toggle("invipo-docker-detail-wide", isConsole);
        }
    }

    public async sendCommand(command: any): Promise<any> {
        // Show loader
        this.showLoader();

        // Disable all actions
        this.queryAll("div.content-general div.actions div.action").forEach((action: HTMLElement) => {
            action.classList.add("action-disabled");
        });

        // Send command via Docker API
        await this.context.invipo.postContainerCommand(this.options.containerId, command);

        // Hide loader
        this.hideLoader();

        // Reload detail
        await this.load();
    }

    public openLog(): void {
        // New image detail
        let detail = new DockerLogDetail(this.context, {
            style: "Dark",
            title: this.options.title,
            overlay: true,
            closable: true,
            containerId: this.options.containerId
        });

        // Shoe
        detail.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.container = await this.context.invipo.getContainer(this.options.containerId);
        this.raw = await this.context.invipo.getContainerRaw(this.options.containerId);
        this.logs = await this.context.invipo.getContainerLogs(this.options.containerId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
