import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { Helpers } from "hiyo/helpers";
import { AutocompleteOptions } from "./types";

export class AutocompleteSelect extends Select<InvipoContext, AutocompleteOptions> {

    public async load(): Promise<void> {
        // Load items
        let items = await this.context.invipo.getAutocomplete(Helpers.toKebabCase(this.options.collection), `f=${Helpers.toKebabCase(this.options.type)}`);

        // Reset menu options
        this.options.items = []

        for (let item of items || []    ) {
            // New class
            this.options.items.push({
                name: item.id,
                label: item.text || item.id
            });
        }

        // Disable select if no items
        this.options.disabled = this.options.items.length == 0;

        // Redaraw due to disabled state
        this.update();
    }

}
