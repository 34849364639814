export class Log {

    public static d(message?: any): void {
        console.debug(`%c${message}`, `color: ${LogColor.Navy.toString()}; font-size: 12px`);
    }

    public static i(message?: any, color?: LogColor): void {
        // Colors
        if (color) {
            console.info(`%c${message}`, `color: ${color.toString()}; font-size: 12px`);
            return;
        }

        // Hash # pattern coloring
        if (message && message.toString().indexOf("#") >= 0) {
            let regex = /(.*)(\#.*?)\s(.*$)/;
            let parts = regex.exec(message);

            if (parts && parts.length == 4) {
                console.info(`${parts[1]}%c${parts[2]}`, `color: #AAAAAA; font-size: 12px`, parts[3]);
                return;
            }
        }

        // Regular logging
        console.info(message);
    }

    public static w(message?: any, color?: LogColor): void {
        console.warn(message);
    }

    public static e(message?: any): void {
        console.error(message);
    }
}

export enum LogColor {
    Magenta = "#ff0097",
    Navy = "#2d89ef",
    Olive = "#00b159",
    Yellow = "#b1b159",
}
