import "./v2x-unit-card.scss";
import * as template from "./v2x-unit-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { V2xUnitCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class V2XUnitCard extends CityCard<V2xUnitCardOptions> {

    constructor(context: InvipoContext, options: V2xUnitCardOptions) {
        super(context, template, options);
    }

}
