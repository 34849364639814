import "./traffic-detector-marker.scss";
import * as template from "./traffic-detector-marker.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficDetectorMarkerOptions } from "./types";
import { HiyoEvent } from "hiyo/event-broker";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { OrtoMarker } from "../orto-marker/orto-marker";

export class TrafficDetectorMarker extends OrtoMarker<TrafficDetectorMarkerOptions> {

    // Properties
    public item: InvipoItem;

    // Event handling methods
    public onFinish(): void {}

    constructor(context: InvipoContext, options: TrafficDetectorMarkerOptions) {
        super(context, template, options);
    }

    public onEvent(event: HiyoEvent) {
        // Traffic second frame
        let detector = (<any[]>event.payload.extras.detectors).find(x => x.no == this.options.no);

        // Toggle detector occupied class
        if (detector) {
            let occupied = (<any[]>detector.occupancies).filter(x => x.occupied == true);
            let call = (<any[]>detector.occupancies).filter(x => x.call == true);
            this.query("div.detector").setAttribute("data-tooltip", detector.name);
            this.query("div.detector").classList.toggle("detector-occupied", occupied.length > 0);
            this.query("div.detector").classList.toggle("detector-call", call.length > 0);
        }
    }
}
