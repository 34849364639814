import "./traffic-control-report.scss";
import * as template from "./traffic-control-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { TrafficControlReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "muklit/components/date-input/date-input";
import { Log } from "hiyo/log";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { PanelLegendSymbol } from "../../common/panel/types";
import { METRICS } from "../../city/city-subdomain/types";

export class TrafficControlReport extends Panel<TrafficControlReportOptions> {

    // Properties
    public data: any[];
    public states: PanelLegendSymbol[];
    public modes: PanelLegendSymbol[];

    // Components
    public form: Form;

    public constructor(context: InvipoContext, options?: TrafficControlReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createForm();
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            width: 320,
                            bright: true,
                            type: "Date"
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            width: 320,
                            bright: true,
                            type: "Date"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.selectOne",
                            itemClass: "TrafficLightController",
                            items: [],
                            width: 672,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Reload data on form submit
        this.form.onSubmit = async () => {
            // Get flat data
            let data = this.form.getData(true);

            // We must assign itemId to options because the item is loaded in base class
            this.options.itemId = data.itemId;

            // Reload
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Item not selected?
        if (!form.itemId) {
            return;
        }

        // Query string
        let query = "";

        if (form.itemId) {
            query = `item.id=${form.itemId}`;
        }

        // Get date objects and create from to interval
        let from = new Date(new Date(form.from).setHours(0, 0, 0, 0));
        let to = new Date(new Date(form.to ? form.to : form.from).setHours(24, 0, 0, 0));

        // Speed data per hour
        let data = await this.context.invipo.getQuery("traffic-control-changes", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Data with midnights
        let dataWithMidnights = [];

        // Add midnights to data
        for (let i = 0; i < data.length; i++) {
            let current = new Date(data[i].timestamp);
            let next = (i < data.length - 1) ? new Date(data[i + 1].timestamp) : null;
            let midnight = next ? new Date(new Date(next).setHours(0, 0, 0, 0)) : null;

            // Is midnight bertween two times?
            if (current.getTime() < midnight?.getTime() && next?.getTime() > midnight?.getTime()) {
                // Push the current if it is not out of interval
                if (current.getTime() > from.getTime()) {
                    dataWithMidnights.push({
                        ...data[i],
                        timestamp: current.toISOString()
                    });
                }

                // Push midnight into data
                dataWithMidnights.push({
                    ...data[i],
                    timestamp: midnight.toISOString()
                });
            }
            else {
                dataWithMidnights.push({
                    ...data[i],
                    timestamp: current.toISOString()
                });
            }
        }

        console.info(dataWithMidnights);
        this.data = [];

        // Itterate over days as some data may miss
        from = new Date(form.from);
        do {
            // Midnight to midnight interval
            let start = new Date(from);
            let end = new Date(new Date(start).setHours(24, 0, 0, 0));

            // Get day changes
            let changes = dataWithMidnights.filter(x => new Date(x.timestamp).getTime() >= new Date(start).getTime() && new Date(x.timestamp).getTime() <= new Date(end).getTime());

            console.info(changes);

            let plans: any[] = [];

            // Iterate over changes and build plans
            for (let i = 0; i < changes.length - 1; i++) {
                let start = new Date(changes[i].timestamp);
                let end = new Date(changes[i + 1].timestamp);
                let duration = end.getTime() - start.getTime();

                // Add to bar to plan
                plans.push({
                    plan: changes[i].plan,
                    mode: changes[i].mode,
                    state: changes[i].state,
                    from: start.toISOString(),
                    to: end.toISOString(),
                    duration: duration,
                    percent: duration / 86400000 * 100
                })
            }

            // Push plans to day data
            this.data.push({
                timestamp: start.toISOString(),
                plans: plans
            })

            // Add one day
            from.setHours(24, 0, 0, 0);
        }
        while (from.getTime() < to.getTime())

        // State legend
        this.states = [];

        for (let i in METRICS.traffic.control.state.range) {
            this.states.push({
                label: `enums.TrafficLightControllerState.${METRICS.traffic.control.state.range[i]}`,
                color: METRICS.traffic.control.state.colors[i]
            })
        }

        console.info(this.data);

        // No data?
        if (!this.data?.length) {
            Log.w(`TrafficControlReport: ${this.item.name} has no traffic changes data`)
            return;
        }

    }
}
