import './envi-quality-subdomain.scss';
import * as template from "./envi-quality-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { EnviQualitySubdomainOptions } from "./types";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { EnviQualityIndexCircleLayer } from "../../../layers/envi/envi-quality-index-circle-layer";
import { EnviQualityIndexLabelLayer } from "../../../layers/envi/envi-quality-index-label-layer";

export const ITEM_CLASS = "EnvironmentalSensor,RoadWeatherStation";

export class EnviQualitySubdomain extends CitySubdomain<EnviQualitySubdomainOptions> {

    constructor(context: InvipoContext, options?: EnviQualitySubdomainOptions) {
        // Super constructor call
        super(context, template, {
            metrics: [
                {
                    name: "Pollution",
                    itemClass: ITEM_CLASS,
                    layers: [
                        new EnviQualityIndexCircleLayer(context),
                        new EnviQualityIndexLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectSensors(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.sensors",
            subtitle: "components.EnviQualitySubdomain.sensors",
            type: "pollution",
            data: Helpers.toKebabCase(this.metrics.name),
            itemClass: ITEM_CLASS,
            layers: [
                new ItemCircleLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        this.legend = {
            description: `components.EnviQualitySubdomain.description`,
            range: {
                range: METRICS.envi.quality.index.range,
                colors: METRICS.envi.quality.index.colors,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate average
        for (let item of this.items) {
            // No data?
            if (!item.data?.envi?.quality) {
                continue;
            }

            this.legend.range.value += item.data.envi.quality.index;
            this.legend.range.count += 1;
        }


        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.envi.quality.index.range[0], METRICS.envi.quality.index.range[1], this.legend.range.value);
    }

}
