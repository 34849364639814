import "./map-marker.scss";
import * as header from "./map-marker.header.hbs";
import { MapMarkerOptions } from "./types";
import * as mapbox from "mapbox-gl";
import { Context } from "hiyo/context";
import { Log } from "hiyo/log";
import { Templates } from "hiyo/templates";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { Position } from "geojson";

export class MapMarker<T extends Context = Context, U extends MapMarkerOptions = MapMarkerOptions> extends MuklitComponent<T, U>{

    // Properties
    public mapboxMarker: mapbox.Marker;

    // Event handling methods
    public onSelect(): void {};
    public onUnselect(): void {};
    public onMove(): void {};

    constructor(context: T, template: any, options: U) {
        super(context, template, options);

        // Partials
        Templates.registerPartial("marker-header", header);
    }

    public setPosition(position: Position): void {
        this.mapboxMarker.setLngLat([position[0], position[1]]);
    }

    public click(selected?: boolean): void {
        // Toggle?
        if (selected == null) {
            this.options.selected = !this.options.selected;
        }
        else {
            this.options.selected = selected;
        }

        // Update class
        this.element?.classList.toggle("muklit-map-marker-selected", this.options.selected);

        // Call handlers only on toggle
        if (selected == null) {
            if (this.options.selected) {
                // OnSelect handler
                this.onSelect();
            } else {
                // OnUnselect handler
                this.onUnselect();
            }
        }
    }

    public close(): void {
        // Not attached?
        if (!this.isAttached()) {
            Log.w(`Trying to close not attached component ${this.id}`);
            return;
        }

        // Detach from DOM
        this.detach();

        // OnClose handler;
        this.onClose();
    }
}
