import "./traffic-light-controller-panel.scss";
import * as template from "./traffic-light-controller-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { TrafficLightControllerOverviewOptions } from "../traffic-light-controller-overview/types";
import { TrafficLightControllerPanelOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { Button } from "muklit/components/button/button";
import { InvipoApplication } from "../../../invipo-application";
import { Dialog } from "muklit/components/dialog/dialog";
import { DateInput } from "muklit/components/date-input/date-input";
import { Select } from "muklit/components/select/select";
import { Checkbox } from "muklit/components/checkbox/checkbox";

export class TrafficLightControllerPanel extends DetailPanel<TrafficLightControllerPanelOptions> {

    // Properties
    public switchForm: Form;
    public switchButton: Button;
    public restartButton: Button;

    constructor(context: InvipoContext, options?: TrafficLightControllerOverviewOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createPlan();
        this.createRestart();

        // Register components that will be automatically attached
        this.registerComponent(this.switchForm, "switch-form");
        this.registerComponent(this.switchButton, "switch-button");
        this.registerComponent(this.restartButton, "restart-button");
    }

    private createPlan(): void {
        // Plan select
        let planSelect: Select = new Select(this.context, {
            style: "Light",
            name: "plan",
            label: "components.TrafficLightControllerPanel.stateOrPlan",
            width: 672,
            bright: true,
            required: true,
            items: [
                {
                    name: "Off",
                    label: "enums.TrafficLightControllerState.Off"
                },
                {
                    name: "Dark",
                    label: "enums.TrafficLightControllerState.Dark"
                },
                {
                    name: "FlashingYellow",
                    label: "enums.TrafficLightControllerState.FlashingYellow"
                },
                {
                    name: "FlashingYellowYields",
                    label: "enums.TrafficLightControllerState.FlashingYellowYields",
                    separated: true
                }
            ]
        });

        // Switch state form
        this.switchForm = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Plan",
                    fields: [planSelect]
                },
                {
                    name: "Time",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date().toISOString(),
                            width: 320,
                            bright: true,
                            required: true,
                        }),
                        new DateInput(this.context, {
                            style: "Light",
                            name: "to",
                            label: "forms.fields.to",
                            value: new Date(Date.now() + 3600000).toISOString(),
                            width: 320,
                            bright: true,
                            required: true
                        })
                    ]
                },
                {
                    name: "Modifications",
                    label: "components.TrafficLightControllerPanel.modifications",
                    fields: [
                        new Checkbox(this.context, {
                            style: "Light",
                            name: "modifierK",
                            label: "forms.fields.modifierK",
                            bright: true
                        }),
                        new Checkbox(this.context, {
                            style: "Light",
                            name: "modifierTa",
                            label: "forms.fields.modifierTa",
                            bright: true
                        }),
                        new Checkbox(this.context, {
                            style: "Light",
                            name: "modifierPt",
                            label: "forms.fields.modifierPt",
                            bright: true
                        }),
                        new Checkbox(this.context, {
                            style: "Light",
                            name: "modifierIt",
                            label: "forms.fields.modifierIt",
                            bright: true
                        })
                    ],
                }
            ]
        });

        // Rearrange form
        planSelect.onSubmit = () => {
            let value = Object.keys(planSelect.options.value)[0];
            let switchState = value == "Off" || value == "Dark" || value == "FlashingYellow" || value == "FlashingYellowYields";
            let switchPlan = !switchState;

            // Toggle form class to show/hide fieldsets via style
            this.query("div.form-switch").classList.toggle("form-switch-state", switchState);
            this.query("div.form-switch").classList.toggle("form-switch-plan", switchPlan);
        }

        // Switch state button
        this.switchButton = new Button(this.context, {
            style: "Light",
            kind: "Primary",
            type: "LabelOnly",
            size: "Medium",
            label: "components.TrafficLightControllerPanel.switch",
            width: "160px",
            align: "Center"
        });

        // Submit on button click
        this.switchButton.onClick = async () => {
            await this.submitSwitch();
        }
    }

    public async submitSwitch(): Promise<void> {
        // Invalid form data?
        if (!this.switchForm.validate()) {
            return;
        }

        // Form data
        let data = this.switchForm.getData(true);

        // Show loader
        this.showLoader();

        // Disable button
        this.switchButton.setDisabled(true);

        // Update existing user
        try {
            // State or plan
            let switchState = data.plan == "Off" || data.plan == "Dark" || data.plan == "FlashingYellow" || data.plan == "FlashingYellowYields";
            let switchPlan = !switchState;

            await this.context.invipo.postItemCommand(this.options.itemId, "SwitchTrafficLightController", {
                from: data.from,
                to: data.to,
                state: switchState ? data.plan : "On",
                plan: switchPlan ? Number(data.plan) : 0,
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.switchForm.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.switchButton.setDisabled(false);
            this.hideLoader();
        }

        // Toast
        let toasts = (<InvipoApplication>this.context.application).toasts;
        toasts.showInfoToast("State", `Switch of traffic light controller plan ${data.plan} requested`);
    }

    private createRestart(): void {
        // Switch state button
        this.restartButton = new Button(this.context, {
            style: "Light",
            kind: "Danger",
            type: "LabelOnly",
            size: "Medium",
            label: "components.TrafficLightControllerPanel.restartController",
            width: "160px",
            align: "Center"
        });

        // Submit on button click
        this.restartButton.onClick = async () => {
            // Dialog to confirm
            let dialog = new Dialog(this.context, {
                style: "Light",
                overlay: true,
                closable: true,
                escalated: true,
                title: "Restart controller",
                text: `Are you sure you want to restart traffic light controller ${this.item.name}?`,
                labelCancel: "labels.cancel",
                labelConfirm: "labels.restart"
            })

            // OnUserLogout handler
            dialog.onConfirm = async () => {
                // Close self
                dialog.close();

                // Proceed the restart command
                await this.submitRestart();
            }

            // Show dialog
            dialog.attach();
        }
    }

    public async submitRestart(): Promise<void> {
        // Show loader
        this.showLoader();

        // Disable button
        this.restartButton.setDisabled(true);

        // Update existing user
        try {
            await this.context.invipo.postItemCommand(this.options.itemId, "RestartTrafficLightController");
        }
        catch (e) {
            return;
        }
        finally {
            this.restartButton.setDisabled(false);
            this.hideLoader();
        }

        // Toast
        let toasts = (<InvipoApplication>this.context.application).toasts;
        toasts.showInfoToast("Restart", `Restart of traffic light controller requested`);
    }

    public async extraLoad(): Promise<void> {
        // Plan select
        let plan = this.switchForm.getField<Select>("plan");

        // Add current item plans to select
        for (let p of this.item.meta.plans) {
            plan.options.items.push({
                name: p.no,
                label: p.name
            });
        }
    }

}
