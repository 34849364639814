import "./connector-browser.scss";
import * as template from "./connector-browser.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ConnectorBrowserOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { MonitoringDetailOptions } from "../monitoring-detail/types";
import { ConnectorDetail } from "../connector-detail/connector-detail";

const REFRESH_DELAY = 60; // Seconds

export class ConnectorBrowser extends MuklitComponent<InvipoContext, ConnectorBrowserOptions> {

    // Properties
    public items: InvipoItem[];
    public connectors: any[];

    // Components
    public detail: ConnectorDetail;

    constructor(context: InvipoContext, options?: ConnectorBrowserOptions) {
        super(context, template, options);
    }

    public onAttach() {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public async select(id: string): Promise<void> {
        // Unselect all
        this.connectors.forEach(x => x.selected = false);

        // Select current camera
        let connector = this.connectors.find(x => x.item.id == id);
        connector.selected = true;

        // Redraw component
        this.update();

        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: connector.item.name,
            subtitle: this.context.locale.getMessage(`classes.${connector.item.class}`),
            itemId: connector.item.id,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new ConnectorDetail(this.context, options);

        // Deselect on close
        this.detail.onClose = () => {
            // Unselect all
            this.connectors.forEach(x => x.selected = false);

            // Redraw component
            this.update();

            // Null detail
            this.detail = null;
        }

        // Attach detail
        this.detail.attach();
    }

    public async load(): Promise<void> {
        // Component not attached?
        if (!this.isAttached()) {
            return;
        }

        this.connectors = [];

        // Show loader
        this.showLoader();

        // Load data
        let connectors = this.context.data.getItems({
            class: "Connector"
        });
        let items = this.context.data.getItems({
            sort: "name:asc"
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        for (let connector of connectors) {
            // Push result
            this.connectors.push({
                item: connector,
                items: items.filter(x => x.connector?.item?.id == connector.id),
                itemCount: items.filter(x => x.connector?.item?.id == connector.id).length,
                selected: (this.detail?.isAttached() && this.detail?.options?.itemId == connector.id)
            })
        }

        // Hide loader
        this.hideLoader();

        // Manual update
        this.update();

        // Autorefresh
        setTimeout(async () => this.load(), REFRESH_DELAY * 1000);
    }
}
