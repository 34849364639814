import './violation-status-tile.scss';
import * as template from "./violation-status-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend, TileList } from "../../common/tile/types";
import { Helpers } from "hiyo/helpers";
import { ViolationStatusTileOptions } from "./types";
import { InvipoHelpers } from "../../../invipo-helpers";

export class ViolationStatusTile extends Tile<ViolationStatusTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;
    public list: TileList;

    constructor(context: InvipoContext, options?: ViolationStatusTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        let violations = await this.context.invipo.getDataset("violations-data");

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.ViolationStatusTile.total",
                    value: Helpers.toNumber(violations.data.length)
                },
                {
                    label: "components.ViolationStatusTile.completed",
                    value: Helpers.toNumber(violations.data.filter(x => x.workflow.completed).length)
                }
            ]
        }

        // Builld items legend
        this.legend = {
            symbols: []
        };


        for (let i = 0; i < this.context.config.violations.columns.length; i++) {
            let column = this.context.config.violations.columns[i];
            let count = violations.data.filter(x => x.workflow.column == column).length;

            // Add row to legend
            this.legend.symbols.push({
                symbol: column,
                count: count,
                label: column,
                percent: count / (violations.data.length || 1) * 100,
                color: InvipoHelpers.toChartColor(i + 1)
            });
        }
    }
}
