import './traffic-event-list.scss';
import * as template from "./traffic-event-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityList } from "../../city/city-list/city-list";
import { TrafficEventListOptions } from "./types";
import { TrafficEventForm } from "../../management/traffic-event-form/traffic-event-form";

export class TrafficEventList extends CityList<TrafficEventListOptions> {

    // Properties
    public events: any[];

    constructor(context: InvipoContext, options?: TrafficEventListOptions) {
        super(context, template, options);
    }

    public selectEvent(i: number): void {
        // Get item
        let event = this.events[i];

        // Call handler
        this.onPositionNavigate((event.position.type == "LineString") ? event.position.coordinates[0] : event.position.coordinates);
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.events = await this.context.invipo.getQuery("traffic-events-current");
    }

    public selectAction(): void {
        // Message form
        const form = new TrafficEventForm(this.context, {
            title: "components.TrafficEventForm.title",
            style: "Light",
            overlay: true,
            closable: true
        });

        // Attach form
        form.attach();
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.events) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
