import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";

export class EnviAirPressureCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "EnvironmentalSensorCard",
            detail: "CityDetail",
            layer: {
                id: "envi-air-pressure-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-blur": 1,
                    "circle-radius": [
                        "interpolate",
                        ["exponential", 1.9],
                        ["zoom"],
                        1, 1,
                        20, 50000
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 0.15
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 0.15
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "RoadWeatherStation,EnvironmentalSensor"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No data or data too old?
            if (item.data?.envi?.air?.pressure == null) {
                continue;
            }

            // Color calculation from range
            let range = Helpers.range(0, METRICS.envi.air.pressure.colors.length - 1, METRICS.envi.air.pressure.range[0], METRICS.envi.air.pressure.range[1], item.data?.envi?.air?.pressure);
            let color = METRICS.envi.air.pressure.colors[Math.round(range)];

            json.features.push({
                type: "Feature",
                properties: {
                    type: "air",
                    data: "pressure",
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }

}
