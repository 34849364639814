import "./traffic-detail-element-properties.scss";
import * as template from "./traffic-detail-element-properties.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { TrafficDetailElementPropertiesOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { TextInput } from "muklit/components/text-input/text-input";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { TrafficDetailTypeSelect } from "../traffic-detail-type-select/traffic-detail-type-select";

export class TrafficDetailElementProperties extends MuklitComponent<InvipoContext, TrafficDetailElementPropertiesOptions> {

    // Properties

    // Components
    public type: TrafficDetailTypeSelect;
    public latitude: TextInput;
    public longitude: TextInput;
    public rotation: TextInput;
    public scale: TextInput;

    // Event callbacks
    public onBack () {}
    public onClose () {}
    public onMarkerRemove (marker: MapMarker) {}
    public onMarkerChange (marker: MapMarker) {}

    constructor(context: InvipoContext, options?: TrafficDetailElementPropertiesOptions) {
        super(context, template, options);

        if (!this.options.name) {
            this.options.name = this.options.sources.find(x => x.no === this.options.no)?.name ?? ""
        }
    }

    public onCreate(): void {
        // create components
        if (this.options.type === "group") this.createType();
        this.createLatitude();
        this.createLongitude();
        this.createRotation();
        this.createScale();

        // register components
        this.registerComponent(this.type, "type");
        this.registerComponent(this.latitude, "latitude");
        this.registerComponent(this.longitude, "longitude");
        this.registerComponent(this.rotation, "rotation");
        this.registerComponent(this.scale, "scale");

        this.options.marker.onMove = () => {
            const position = this.options.marker.options.position
            this.latitude.setValue(position[1]);
            this.longitude.setValue(position[0]);

            this.onMarkerChange(this.options.marker);
        }
    }

    private createLatitude () {
        // Create component
        this.latitude = new TextInput(this.context, {
            style: "Light",
            bright: false,
            label: "Latitude",
            name: "latitude",
        });

        // Set current value
        this.latitude.setValue(this.options.marker.options.position[1]);

        // Update data on key press
        this.latitude.onKey  = () => {
            // Get value
            let latitude = parseFloat(this.latitude.options.value);

            // Update markers position
            this.options.marker.options.position[1] = latitude;
            this.options.marker.setPosition(this.options.marker.options.position)

            // Notify data change
            this.onMarkerChange(this.options.marker);
        }
    }
    private createLongitude () {
        // Create component
        this.longitude = new TextInput(this.context, {
            style: "Light",
            bright: false,
            label: "Longitude",
            name: "longitude",
        });

        // Set current value
        this.longitude.setValue(this.options.marker.options.position[0]);

        // Update data on key press
        this.longitude.onKey  = () => {
            // Get value
            let longitude = parseFloat(this.longitude.options.value);

            // Update markers position
            this.options.marker.options.position[0] = longitude;
            this.options.marker.setPosition(this.options.marker.options.position)

            // Notify data change
            this.onMarkerChange(this.options.marker);
        }
    }
    private createRotation () {
        // Create component
        this.rotation = new TextInput(this.context, {
            style: "Light",
            bright: false,
            label: "Rotation",
            name: "rotation",
        });

        // Set current value
        this.rotation.setValue(this.options.marker.options.rotation);

        // Update data on key press
        this.rotation.onKey  = (key: number) => {
            // Get current value
            let angle = parseFloat(this.rotation.options.value);


            if (key === 38) {
                // Arrow Up pressed, increase angle
                angle++;

                this.rotation.setValue(angle)
                this.rotation.focus();
            } else if (key === 40) {
                // Arrow down pressed, decrease ange
                angle--

                this.rotation.setValue(angle)
                this.rotation.focus();
            }

            // Update marker rotation
            this.options.marker.options.rotation = angle;
            this.options.marker.update();

            // Notify data change
            this.onMarkerChange(this.options.marker);
        }
    }

    private createScale () {
        // Create component
        this.scale = new TextInput(this.context, {
            style: "Light",
            bright: false,
            label: "Scale",
            name: "scale",
        });

        // Set current value
        this.scale.setValue(this.options.marker.options.scale);

        // Update data on key press
        this.scale.onKey  = (key: number) => {
            // Get value
            let scale = parseFloat(this.scale.options.value);

            // Update markers scale
            this.options.marker.options.scale = scale;
            this.options.marker.update();

            // Notify data change
            this.onMarkerChange(this.options.marker);
        }
    }

    private createType (): void {
        this.type = new TrafficDetailTypeSelect(this.context, {
            value: this.options.marker?.options?.type
        });

        this.type.onSelect = (type: string) => {
            this.options.marker.options.type = type;
            this.options.marker.update();
            this.options.marker.click(true);

            this.onMarkerChange(this.options.marker);
        }
    }

    public close () {
        this.detach();
        this.onClose();
    }

    public back () {
        this.detach();
        this.onBack();
    }

    public delete () {
        this.onMarkerRemove(this.options.marker);
        this.close();
    }
}
