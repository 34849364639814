import "./area-tooltip.scss";
import * as template from "./area-tooltip.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { AreaTooltipOptions } from "./types";
import { Tooltip } from "muklit/components/tooltip/tooltip";

export class AreaTooltip extends Tooltip<InvipoContext, AreaTooltipOptions> {

    constructor(context: InvipoContext, options: AreaTooltipOptions) {
        super(context, template, options);
    }

}
