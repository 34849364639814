import "./waste-collection-card.scss";
import * as template from "./waste-collection-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { WasteCollectionCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class WasteCollectionCard extends CityCard<WasteCollectionCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: WasteCollectionCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24 * 47, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let data = await this.context.invipo.getQuery(`waste-collection-by-day`, `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data (last 48 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let d = 0; d < 48; d++) {
            // Find hour in data
            // WORKAROUND: Local vs UTC time hack
            let d = data.find(x => x.timestamp.substring(0, 10) == from.toISOString().substring(0, 10));

            // Add history line
            if (d) {
                console.info(d.weight);
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.weight,
                    percent: Helpers.range(0, 100, 0, 500, d.weight),
                    color: "#00b4f5",
                    unit: "units.kg"
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setHours(24, 0, 0, 0);
        }
    }

}
