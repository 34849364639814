import { PickerGroup } from "invipo/components/common/picker-menu/types";
import { DetailOptions } from "muklit/components/detail/types";
import { TrafficEvent } from "../traffic-event-manager/types";

export interface TrafficEventFormOptions extends DetailOptions {
    event?: TrafficEvent;
}

export const eventFormMenuGroups: PickerGroup[] = [
    {
        name: "types",
        title: "components.TrafficEventForm.types",
        items: [
            {
                name: "Accident",
                label: "enums.TrafficEventType.Accident",
                description: "components.TrafficEventForm.TypeDescription.Accident",
                icon: "RoadHazard",
            },
            {
                name: "Closure",
                label: "enums.TrafficEventType.Closure",
                description: "components.TrafficEventForm.TypeDescription.Closure",
                icon: "RoadWork",
            },
            {
                name: "Danger",
                label: "enums.TrafficEventType.Danger",
                description: "components.TrafficEventForm.TypeDescription.Danger",
                icon: "RoadHazard",
            },
            {
                name: "Roadworks",
                label: "enums.TrafficEventType.Roadworks",
                description: "components.TrafficEventForm.TypeDescription.Roadworks",
                icon: "RoadWork",
            },
            {
                name: "Info",
                label: "enums.TrafficEventType.Info",
                description: "components.TrafficEventForm.TypeDescription.Info",
                icon: "RoadHazard",
            },
        ]
    }
]