import "./hotspots-subdomain.scss";
import * as template from "./hotspots-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { HotspotsSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { HotspotConnectionsLabelLayer } from "../../../layers/services/hotspot-connections-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { HotspotConnectionsCircleLayer } from "../../../layers/services/hotspot-connections-circle-layer";

export class HotspotsSubdomain extends CitySubdomain<HotspotsSubdomainOptions> {

    constructor(context: InvipoContext, options?: HotspotsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Connections",
                    itemClass: "WifiAccessPoint",
                    layers: [
                        new HotspotConnectionsCircleLayer(context),
                        new HotspotConnectionsLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.HotspotsSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        this.legend = {
            description: `components.HotspotsSubdomain.${metrics}Description`,
            range: {
                range: METRICS.services.hotspots[metrics].range,
                colors: METRICS.services.hotspots[metrics].colors,
                unit: METRICS.services.hotspots[metrics].unit,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate average
        for (let item of this.items) {
            // No data?
            if (!item.data?.network || !item.data.network[metrics]) {
                continue;
            }

            this.legend.range.value += item.data.network[metrics];
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.services.hotspots[metrics].range[0], METRICS.services.hotspots[metrics].range[1], this.legend.range.value);
    }
}
