import "./event-browser.scss";
import * as template from "./event-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { EventBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { EventDetailOptions } from "../event-detail/types";
import { EventDetail } from "../event-detail/event-detail";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { EventForm } from "../event-form/event-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class EventBrowser extends MuklitComponent<InvipoContext, EventBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: EventDetail;

    constructor(context: InvipoContext, options?: EventBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Dark",
            url: `${this.context.options.host}/api/data/datasets/events`,
            http: this.context.invipo.http,
            filter: {
                title: "components.EventBrowser.title"
            },
            form: {
                style: "Dark",
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Dark",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Dark",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                items: [],
                                multiselect: true
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Dark",
                                collection: "Events",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.type",
                                items: [],
                                type: "Type"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1
            },
            table: {
                style: "Dark",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: "type",
                        label: "tables.columns.type",
                        formatter: (value: any, data: any) => {
                            if (value == "DataRegistered") {
                                return `<div class="cell" style="color: #00a2ff">${value}</div>`
                            }
                            else {
                                return `<div class="cell">${value}</div>`
                            }
                        },
                        width: 240,
                        sortable: true,
                    },
                    {
                        name: "actor.name",
                        type: "String",
                        property: "actor.name",
                        label: "tables.columns.actor",
                        width: 220,
                        ellipsis: true,
                        sortable: true,
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 160,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Add items
        this.table.options.filter.items = [];

        // Boss detected?
        if (this.context.user.boss) {
            // Boss is allowed to register new event
            this.table.options.filter.items = [
                {
                    name: "Add",
                    label: "labels.add"
                }
            ]
        }

        // Relaod functions
        this.table.options.filter.items.push({
            name: "Reload",
            label: "labels.reload"
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Handle menu
        this.table.onItemSelect = (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                this.openNew();
            }
        }

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

    private openNew(): void {
        // Dialog to confirm
        let form = new EventForm(this.context, {
            style: "Light",
            title: "components.EventForm.title",
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Refresh
            await this.table.load();
        }

        // Show dialog
        form.attach();
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: EventDetailOptions = {
            style: "Dark",
            title: data.type,
            subtitle: data.username,
            event: data,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Redraw component
            await this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new EventDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.event.id);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }
}
