import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_LABEL } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoHelpers } from "../../invipo-helpers";

export class CameraSurveillanceLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            card: "CameraCard",
            detail: "CityDetail",
            layer: {
                id: "camera-surveillance-label",
                type: "symbol",
                minzoom: ZOOM_LABEL,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1,
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 1.8],
                    "text-anchor": "top",
                    "text-allow-overlap": true,
                    "text-max-width": 15
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "Camera"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);

        // Push features from items
        for (let items of Object.values(cluster)) {
            json.features.push({
                type: "Feature",
                properties: {
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: items.map(x => { return x.id }).join(","),
                    itemId: items.map(x => { return x.id }).join(","),
                    itemName: items[0].name,
                    itemClass: items[0].class,
                    label: items.length > 1 ? items.map(x => { return x.name }).join("\n") : `${items[0].name}\n${this.context.locale.getMessage(`components.CamerasSubdomain.surveillance.${items[0].data?.snapshot ? "Active" : "None"}`)}`,
                },
                geometry: items[0].geometry["location"]
            })
        }

        return json;
    }
}
