import "./traffic-volume-insight.scss";
import * as template from "./traffic-volume-insight.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { Log } from "hiyo/log";
import { Helpers } from "hiyo/helpers";
import { Chart, ChartDataSets, ChartTooltipModel } from "chart.js";
import { DEFAULT_FONT } from "../../common/stats-chart/stats-chart";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";
import { InvipoHelpers } from "../../../invipo-helpers";
import { ClientExportForm } from "../../common/client-export-form/client-export-form";
import { TrafficVolumeInsightOptions } from "./types";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Select } from "muklit/components/select/select";

export class TrafficVolumeInsight extends MuklitComponent<InvipoContext, TrafficVolumeInsightOptions> {

    // Properties
    public data: any[];
    private chart: Chart;

    // Components
    public forms: Form[];
    public tooltip: ElementTooltip;

    public constructor(context: InvipoContext, options?: TrafficVolumeInsightOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Default options: series
        this.options = {
            ...this.options,
            series: [
                {
                    label: null,
                    values: {
                        from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0)).toISOString()
                    }
                }
            ],
            groups: [
                {
                    name: "Hour",
                    label: "components.TrafficVolumeInsight.groups.Hour",
                    selected: true
                },
                {
                    name: "DayOfWeek",
                    label: "components.TrafficVolumeInsight.groups.DayOfWeek"
                }
            ]
        }

        // Create components
        this.createForms();
    }

    public onAttach(): void {
        // Attach forms
        for (let f in this.forms) {
            if (!this.forms[f].isAttached()) {
                this.forms[f].attach(`div.series div.data div.form-${f}`);
            }
        }

        // Draw chart
        if (this.data?.length > 0) {
            this.drawChart();
        }
    }

    public onDetach(): void {
        // Detach forms
        for (let form of this.forms) {
            if (form.isAttached()) {
                form.detach();
            }
        }
    }

    public createForms(): void {
        // Empty forms
        this.forms = [];

        // Create form components from series definition
        for (let i = 0; i < this.options.series.length; i++) {
            let series = this.options.series[i];

            // Default series label and color
            series.label = this.context.locale.getMessage("components.TrafficVolumeInsight.series", i + 1);
            series.color = InvipoHelpers.toChartColor(i + 1);

            // Item select
            let item = new ItemSelect(this.context, {
                style: "Light",
                name: "item.id",
                label: "forms.fields.item",
                placeholderText: "All",
                distinct: "TrafficData",
                items: [],
                bright: true,
                multiselect: true
            });

            // Segment select
            let segment = new Select(this.context, {
                style: "Light",
                name: "segment",
                label: "forms.fields.segment",
                placeholderText: "forms.placeholders.all",
                items: [],
                bright: true,
                multiselect: true
            });

            // New form component
            let form = new Form(this.context, {
                fieldsets: [
                    {
                        name: "timestamp",
                        fields: [
                            new DateInput(this.context, {
                                style: "Light",
                                name: "from",
                                label: "forms.fields.from",
                                value: series.values.from,
                                bright: true,
                                type: "Date"
                            }),
                            new DateInput(this.context, {
                                style: "Light",
                                name: "to",
                                label: "forms.fields.to",
                                value: series.values.to,
                                bright: true,
                                type: "Date"
                            }),
                            item,
                            segment
                        ]
                    }
                ]
            });

            // Fill segments after item is selected
            item.onSubmit = () => {
                // Reset segments
                segment.options.items = [];

                // Find all selected items
                for (let i of item.options.items.filter(x => x.selected)) {
                    // Find item definition
                    let found = item.items.find(x => x.name == i.label);

                    // Add all segments
                    for (let s of found.meta.segments || []) {
                        segment.options.items.push({
                            name: s.name,
                            label: s.name
                        })
                    }
                }

                // We must invalidate select to display new items
                segment.invalidate();

                // Invoke form submit
                form.onSubmit(form.getData());
            }

            // Reload insight on change
            form.onSubmit = async (data: any) => {
                series.values.from = data["from"] ? Object.keys(data["from"])[0] : null;
                series.values.to = data["to"] ? Object.keys(data["to"])[0] : null;
                series.values.itemName = data["item.id"] ? Object.values(data["item.id"])[0] : null;
                series.values.itemId = data["item.id"];

                // Reload completely
                await this.load();
            }

            // Add to evidence
            this.forms.push(form);
        }
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = this.query<HTMLCanvasElement>("div.chart canvas");

        // Chart datasets
        let datasets: ChartDataSets[] = [];
        let labels: any[] = [];

        // Daily hours as labels
        for (let h = 0; h < 24; h++) {
            labels.push(h);
        }

        // Browse series
        for (let i in this.options.series) {
            let series = this.options.series[i];
            let data: any[] = [];

            for (let h = 0; h < 24; h++) {
                data.push((<any[]>this.data[i]).find(x => x.hour == h)?.count ?? 0);
            }

            // Add data to dataset
            datasets.push({
                data: data,
                backgroundColor: series.color,
                barPercentage: 0.8

            })
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        mode: "x",
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints && model.dataPoints[0].value != "0") {
                                let data = model.dataPoints[0];
                                let from = new Date(2020, 0, 1, Number(data.label), 0, 0);
                                let to = new Date(2020, 0, 1, Number(data.label) + 1, 0, 0);

                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    style: "Dark",
                                    text: `${Helpers.toNumber(data.value)} ${this.context.locale.getMessage("units.vehicles")} (${Helpers.toShortTimeString(from)} &ndash; ${Helpers.toShortTimeString(to)})`
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("units.hour")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    callback: (value: any): string | number => {
                                        return `${value}:00`;
                                    }
                                },
                                gridLines: {
                                    display: false
                                },
                            },
                        ],
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: `${this.context.locale.getMessages("units.vehicles")} / ${this.context.locale.getMessages("units.h")}`
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    stepSize: 100,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return Helpers.toNumber(value);
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.1)",
                                    zeroLineColor: "rgba(141, 141, 141, 0.1)",
                                    drawOnChartArea: true
                                }
                            }
                        ]
                    }
                }
            });
    }

    public async newSeries(): Promise<void> {
        this.options.series.push({
            label: null,
            values: {
                from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                to: new Date(new Date().setHours(24, 0, 0, 0)).toISOString()
            }
        });

        // Recreate forms based on series
        this.createForms();

        // Reload data
        await this.load();
    }

    public async removeSeries(i: number): Promise<void> {
        this.options.series.splice(i, 1);

        // Recreate forms based on series
        this.createForms();

        // Reload data
        await this.load();
    }

    public async selectGroup(i: number): Promise<void> {
        // Already selected?
        if (this.options.groups[i].selected) {
            return;
        }

        // Unselect all
        this.options.groups.forEach(x => x.selected = false);

        // Selected tab
        this.options.groups[i].selected = true;

        // Reload data
        await this.load();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ClientExportForm(this.context, {
            style: "Light",
            title: "components.ClientExportForm.title",
            exportPdf: true,
            overlay: true,
            closable: this.options.closable
        });

        // Handle export
        form.onPdfExport = (orientation: string, title?: string) => {
            this.exportPdf(orientation, title);
        }

        // Show form
        form.attach();
    }

    private exportPdf(orientation: string, title?: string): void {
        title = title ?? this.context.locale.getMessage(`components.${this.name}.title`);
        InvipoHelpers.toPdf(this.chart, orientation, title);
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Selected group
        let group = this.options.groups?.find(x => x.selected);
        if (!group) {
            Log.w(`At last one group in ${this.id} must be selected`);
            return;
        }

        // Reset data
        this.data = [];

        // Load data series per series
        for (let i in this.options.series) {
            // URL query
            let query = `&group=${Helpers.toCamelCase(group.name)}`;

            // Form data
            let data = this.forms[i].getData(true);

            // Get full query
            for (let key of Object.keys(data)) {
                let value = data[key];

                // Null value?
                if (value == null) {
                    continue;
                }

                query += `&${key}=${value}`;
            }

            // Read and push data
            this.data.push(await this.context.invipo.getQuery("traffic-by-group", query));

            // Add form data to options.series to visualize
            this.options.series[i].data = data;
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw whole component
        this.invalidate(true);

    }

}
