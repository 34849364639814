import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { METRICS } from "../../components/city/city-subdomain/types";
import { HiyoEvent } from "../../../hiyo/event-broker";
import { TrafficEventLayer } from "./traffic-event-layer";

export class TrafficEventCircleLayer extends TrafficEventLayer {

    // Properties
    public event: any;

    public constructor(context: InvipoContext, event?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "TrafficEventCard",
            detail: "CityDetail",
            layer: {
                id: "traffic-event-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 6, // 6, 3
                        14.95, 6,
                        15.0, 14,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.75
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });

        // Set message
        this.event = event;
    }

    public async load(): Promise<FeatureCollection> {
        // Load events
        let events = [];

        // Load detail?
        if (this.event) {
            events.push(this.event);
        }
        // Load all currently visible
        else {
            events = await this.context.invipo.getQuery("traffic-events-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let event of events) {
            // Get symbol color from definition
            let color = METRICS.traffic.events.events.colors[METRICS.traffic.events.events.range.indexOf(event.type)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.context.locale.getMessage(`enums.TrafficEventType.${event.type}`),
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: event.id,
                    eventId: event.id,
                    eventType: event.type,
                    color: color
                },
                geometry: {
                    type: "Point",
                    coordinates: (event.position.type == "LineString") ? event.position.coordinates[0] : event.position.coordinates
                }
            })
        }

        return json;
    }
}
