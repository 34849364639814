import "./design-map.scss";
import * as template from "./design-map.hbs";
import "mapbox-gl/dist/mapbox-gl.css";
import * as mapbox from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Context } from "hiyo/context";
import { Component } from "hiyo/component";
import { DesignMapOptions } from "./types";
import { LineString, Position, Point, Feature, GeometryObject, Polygon } from "geojson";
import MapboxDraw = require("@mapbox/mapbox-gl-draw");
import { Log } from "hiyo/log";
import centroid from "@turf/centroid";
import bearing from "@turf/bearing";
import distance from "@turf/distance";
import destination from "@turf/destination";

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiaW5jaW5pdHkiLCJhIjoiY2thbDZ0N3g4MG5xcTJybzZnMHNxcm51NiJ9.AIdB5NT1kyQmlur8EKEm0Q";

const MAP_STYLES: { [style: string]: string } = {
    "Light": "mapbox://styles/incinity/ckov73uh701id17qns6etcljs?fresh=true",
    "Dark": "mapbox://styles/incinity/ckovf52b809bi17mh21tdc0d2?fresh=true",
    "Satellite": "mapbox://styles/incinity/ckwcb0kwp0tsp14l3yzzrq0p9?fresh=true",
    "Czechia": "mapbox://styles/incinity/cjzo45jtw0v9o1doa2soxgdg2?fresh=true"
}

export class DesignMap<T extends Context = Context, U extends DesignMapOptions = DesignMapOptions> extends Component<T, U> {

    // Properties
    public features: Feature[] = [];
    public selectedFeatures: Feature[] = [];
    public mapboxMap: mapbox.Map;
    public mapboxDraw: MapboxDraw;
    public mapboxLoaded: boolean;

    // Event handling methods
    public onMapLoad(): void {};
    public onMapZoom(zoom: number): void {};
    public onMapMove(center: Position): void {};
    public onFeatureCreated(feature: Feature): void {};
    public onFeatureDeleted(feature: Feature): void {};
    public onFeatureUpdated(feature: Feature): void {};
    public onFeaturesChange(features: Feature[]): void {};

    constructor(context: T, options: U) {
        super(context, template.toString());

        // Merge options
        this.options = {...options};
    }

    public onAttach(): void {
        // Need to put valid token here
        (<any>mapbox).accessToken = MAPBOX_ACCESS_TOKEN;

        // New map instance
        this.mapboxMap = new mapbox.Map({
            container: this.element,
            style: MAP_STYLES[this.options.style],
            center: this.options.center ? [this.options.center[0], this.options.center[1]] : [0, 0],
            zoom: this.options.zoom || 10,
            minZoom: this.options.minZoom || 0,
            maxZoom: this.options.maxZoom || 20,
            maxBounds: this.options.maxBounds || null,
            pitch: this.options.pitch || 0
        });

        this.mapboxDraw = new MapboxDraw({
            controls: {
                point: this.options.controls?.point ?? true,
                line_string: this.options.controls?.line ?? true,
                polygon: this.options.controls?.area ?? true,
                trash: true,
                combine_features: false,
                uncombine_features: false,
            }
        });

        this.mapboxMap.addControl(this.mapboxDraw, "top-left");

        // Add existing features;
        for (const feature of this.features) {
            this.mapboxDraw.add(feature);
        }

        // Filter for Draw mode events
        const modes = ["draw_point", "draw_polygon", "draw_line_string"]
        let showDeleteButton = false;

        // Mode changed, show delete button
        this.mapboxMap.on("draw.modechange", (e: MapboxDraw.DrawModeChangeEvent) => {
            showDeleteButton = modes.includes(e.mode);

            this.query("button.mapbox-gl-draw_trash").classList.toggle("visible", showDeleteButton);
        });

        // Features selected, show delete button
        this.mapboxMap.on("draw.selectionchange", (e: MapboxDraw.DrawSelectionChangeEvent) => {
            showDeleteButton = e.features.length > 0 || modes.includes(this.mapboxDraw.getMode());

            this.selectedFeatures = e.features;

            this.query("button.mapbox-gl-draw_trash").classList.toggle("visible", showDeleteButton);
        });

        // Features deleted, no selection, hide delete button
        this.mapboxMap.on("draw.delete", (e: MapboxDraw.DrawDeleteEvent) => {
            showDeleteButton = false;

            this.query("button.mapbox-gl-draw_trash").classList.toggle("visible", showDeleteButton);
        });

        // Notify other components about Feature changes
        this.mapboxMap.on("draw.create", (e: MapboxDraw.DrawCreateEvent) => {
            this.onFeatureCreated(e.features[0])

            const features = this.getFeatures().reduce((s, x) => {
                s[x.geometry.type] = x;
                return s;
            }, {} as { [key: string]: Feature })

            this.setFeatures(Object.values(features));

            this.onFeaturesChange(Object.values(features));
        });

        this.mapboxMap.on("draw.delete", (e) => {
            this.onFeatureDeleted(e.features[0]);

            const features = this.getFeatures().reduce((s, x) => {
                s[x.geometry.type] = x;
                return s;
            }, {} as { [key: string]: Feature })

            this.setFeatures(Object.values(features));

            this.onFeaturesChange(Object.values(features));
        });

        this.mapboxMap.on("draw.update", (e) => {
            this.onFeatureUpdated(e.features[0])

            const features = this.getFeatures().reduce((s, x) => {
                s[x.geometry.type] = x;
                return s;
            }, {} as { [key: string]: Feature })

            this.setFeatures(Object.values(features));

            this.onFeaturesChange(Object.values(features));
        });

        // Handle load, we use style.load to cover loading new styles
        this.mapboxMap.on("style.load", () => {
            // Loaded flag
            this.mapboxLoaded = true;

            // OnMapLoad handler
            this.onMapLoad()
        });

        // Handle zoom
        this.mapboxMap.on("zoom", () => {
            // Remember zoom
            this.options.zoom = this.mapboxMap.getZoom();

            // OnMapZoom handler
            this.onMapZoom(this.options.zoom);
        });

        // Store center position on move
        this.mapboxMap.on("move", () => {
            // Remember center
            let center = this.mapboxMap.getCenter();
            this.options.center = [center.lng, center.lat];
        });

        // Call move callback on when move ends
        this.mapboxMap.on("moveend", () => {
            // New center
            let center = this.mapboxMap.getCenter();

            // OnMapZoomEnd handler
            this.onMapMove([center.lng, center.lat]);
        });

        // Debug
        this.mapboxMap.on("dblclick", (e) => {
            e.preventDefault();
            Log.i(this.mapboxMap.getCenter());
            Log.i(this.mapboxMap.getZoom());
            Log.i(this.mapboxMap.getStyle().layers);
        });

        // Rotate
        this.element.onkeydown = (e: KeyboardEvent) => {
            if (e.ctrlKey) {
                if (e.code === 'ArrowLeft') this.rotate(-5);
                if (e.code === 'ArrowRight') this.rotate(5);
            }
        }
    }

    private rotate (deg: number) {
        for (const feature of this.selectedFeatures) {
            switch (feature.geometry.type) {
                case "LineString":
                    const lineStringGeometry = <LineString>feature.geometry;
                    const lineStringCenter = centroid(lineStringGeometry)

                    for (const coords of lineStringGeometry.coordinates) {
                        var distanceFromCenter = distance(lineStringCenter, coords);
                        var bearingFromCenter = bearing(lineStringCenter, coords);
                        var newPoint = destination(lineStringCenter, distanceFromCenter, bearingFromCenter + deg);
                        coords[0] = newPoint.geometry.coordinates[0];
                        coords[1] = newPoint.geometry.coordinates[1];
                    }
                    break;
                case "Polygon":
                    const polygonGeometry = <Polygon>feature.geometry;
                    const polygonCenter = centroid(polygonGeometry)

                    for (const coords of polygonGeometry.coordinates[0]) {
                        var distanceFromCenter = distance(polygonCenter, coords);
                        var bearingFromCenter = bearing(polygonCenter, coords);
                        var newPoint = destination(polygonCenter, distanceFromCenter, bearingFromCenter + deg);
                        coords[0] = newPoint.geometry.coordinates[0];
                        coords[1] = newPoint.geometry.coordinates[1];
                    }
                    break;
            }

            // update geometry in map - this cancels selection
            this.mapboxDraw.delete(<string>feature.id);
            this.mapboxDraw?.add(feature);
        }

        // select current geometry
        this.mapboxDraw.changeMode(`simple_select`, {
            featureIds: this.selectedFeatures.map(x => <string>x.id)
        });
    }

    public onDetach(): void {
        // Mapbox clean-up
        this.mapboxMap.remove();
        this.mapboxMap = null;
        this.mapboxLoaded = false;
    }

    public setFeatures(features: Feature[]) {
        this.features = features;

        // Remove old feature
        this.mapboxDraw?.deleteAll();

        // Add new features
        for (const feature of this.features) {
            this.mapboxDraw?.add(feature);
        }
    }

    public setGeometry(geometry: { [key: string]: GeometryObject }) {
        this.features = [];
        for (let g of Object.values(geometry)) {
            const feature: Feature = {
                "type": "Feature",
                "properties": {},
                "geometry": g
            };

            this.features.push(feature);
            this.mapboxDraw?.add(feature);
        }
    }

    public getFeatures(): Feature[] {
        const collection = this.mapboxDraw.getAll();
        return collection.features
    }

    public getGeometry(): { [key: string]: GeometryObject } {
        const features = this.getFeatures().reduce((s: {[p: string]: GeometryObject}, x: Feature) => {
            s[x.geometry.type] = x.geometry;
            return s;
        }, {} as { [key: string]: GeometryObject })

        const result: { [key: string]: GeometryObject } = {};
        if (features.Point) result.location = features.Point;
        if (features.Polygon) result.area = features.Polygon;
        if (features.LineString) result.segment = features.LineString;

        return result;
    }

    public resize(): void {
        if (this.mapboxMap) {
            this.mapboxMap.resize();
        }
    }

    public fitBounds(bounds: mapbox.LngLatBounds, duration: number = 3000): void {
        if (this.mapboxMap) {
            this.mapboxMap.fitBounds(bounds, {
                padding: 64,
                duration: duration,
                maxZoom: 22,
                pitch: 0
            });
        }
    }

    public fitAll(): void {
        const bounds = new mapbox.LngLatBounds();

        for (const ft of this.features) {
            if (ft.geometry.type == "Point") {
                const coord = (<Point>ft.geometry).coordinates
                bounds.extend([coord[0], coord[1]]);
            }
            else if (ft.geometry.type == "LineString") {
                const coordinates = (<LineString>ft.geometry).coordinates
                for (const coord of coordinates) {
                    bounds.extend([coord[0], coord[1]]);
                }
            }
            else if (ft.geometry.type == "Polygon") {
                const coordinates = (<Polygon>ft.geometry).coordinates
                for (const coord of coordinates[0]) {
                    bounds.extend([coord[0], coord[1]]);
                }
            }
        }

        if (!bounds.isEmpty()) {
            this.fitBounds(bounds, 0);
        }
    }
}
