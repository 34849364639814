import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoHelpers } from "../../invipo-helpers";

export class CameraSurveillanceCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "CameraCard",
            detail: "CityDetail",
            layer: {
                id: "camera-surveillance-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], "#ffffff",
                        ["get", "color"]
                    ],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": [
                        "case",
                        ["==", ["get", "cluster"], ["boolean", true]], ["get", "color"],
                        "#ffffff"
                    ],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": [
                    "case",
                    ["==", ["get", "cluster"], ["boolean", true]], 0,
                    1
                ],
                "circle-stroke-opacity": [
                    "case",
                    ["==", ["get", "cluster"], ["boolean", true]], 1,
                    0.8
                ]
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "Camera"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Build unique position list
        let cluster = InvipoHelpers.toItemCluster(items);
        console.info(cluster);

        // Push features from items
        for (let items of Object.values(cluster)) {
            // None symbol as default
            let symbol = "None";

            // Iterate and get symbol, last state
            for (let item of items) {
                if (item.data?.snapshot) {
                    symbol = "Active";
                }
            }

            // Symbol color
            let color = METRICS.safety.cameras.surveillance.colors[METRICS.safety.cameras.surveillance.range.indexOf(symbol)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: items.length > 1 ? items.map(x => { return x.name }).join("\n") : `${items[0].name}\n${this.context.locale.getMessage(`components.CamerasSubdomain.surveillance.${symbol}`)}`,
                    cluster: items.length > 1,
                    card: this.options.card,
                    detail: items.length == 1 ? "CityDetail" : this.options.detail,
                    cardId: items.map(x => { return x.id }).join(","),
                    itemId: items.map(x => { return x.id }).join(","),
                    itemName: items[0].name,
                    itemClass: items[0].class,
                    color: color
                },
                geometry: items[0].geometry["location"]
            })
        }

        return json;
    }

}
