import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";
import { HiyoEvent } from "../../../hiyo/event-broker";

export abstract class TrafficMessageLayer extends MapLayer<InvipoContext> {

    public onAdd(): void {
        this.context.broker.subscribe(this, ["CommandRequested", "TrafficMessageReceived", "TrafficMessageRemoved"]);
    }

    public onRemove(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent) {
        // Different command?
        if (event.type == "CommandRequested" && event.payload.extras.type != "TriggerTrafficMessage" && event.payload.extras.type != "UpdateTrafficMessage" && event.payload.extras.type != "CancelTrafficMessage") {
            return;
        }

        // Reload data
        this.data = await this.load();

        // Update
        this.update();
    }

}