import "./traffic-loads-browser.scss";
import * as template from "./traffic-loads-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficLoadBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class TrafficLoadsBrowser extends MuklitComponent<InvipoContext, TrafficLoadBrowserOptions> {

    // Components
    public table: FilterTable;

    constructor(context: InvipoContext, options?: TrafficLoadBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-loads`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TrafficLoadsBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "TrafficLoads",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                groups: {
                    property: "interval.from",
                    formatter: (value: any, data: any) => {
                        return `${Helpers.toDateString(value)} ${Helpers.toShortTimeString(value)}`;
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${Helpers.toShortTimeString(data.interval.from)} &mdash; ${Helpers.toShortTimeString(data.interval.to)}</div>`
                        },
                        label: "tables.columns.interval",
                        width: 220,
                        selected: true,
                        sortable: true,
                        extraSort: "item.name",
                        descendent: true
                    },
                    {
                        name: "count",
                        type: "String",
                        property: "count",
                        label: "tables.columns.count",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${Helpers.toNumber(data.detectors.length)}</div>`
                        },
                        width: 80,
                        sortable: true,
                        ellipsis: true
                    },
                    /*{
                        name: "count",
                        type: "Number",
                        property: "count",
                        label: "tables.columns.count",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${Helpers.toNumber(data.count)} ${this.context.locale.getMessage("units.vph")}</div>`
                        },
                        width: 220,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },*/
                    {
                        name: "occupancy",
                        type: "Number",
                        property: "occupancy",
                        label: "tables.columns.occupancy",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-right">${Helpers.toNumber(data.occupancy || 0)} %</div>`
                        },
                        width: 120,
                        align: "Right",
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "TrafficLoadsCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }
}
