import "./v2x-unit-overview.scss";
import * as template from "./v2x-unit-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { V2XUnitOverviewOptions } from "./types";

export class V2XUnitOverview extends DetailPanel<V2XUnitOverviewOptions> {

    // Properties
    public history: DetailHistoryValue[];

    constructor(context: InvipoContext, options?: V2XUnitOverviewOptions) {
        super(context, template, options);
    }

}
