import './cits-vehicle-list.scss';
import * as template from "./cits-vehicle-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitsVehicleListOptions } from "./types";
import { CityList } from "../../city/city-list/city-list";

export class CitsVehicleList extends CityList<CitsVehicleListOptions> {

    // Properties
    public vehicles: any[];

    constructor(context: InvipoContext, options?: CitsVehicleListOptions) {
        super(context, template, options);
    }

    public selectVehicle(i: number): void {
        // Get item
        let vehicle = this.vehicles[i];

        // Call handler
        this.onPositionNavigate((vehicle.position.type == "LineString") ? vehicle.position.coordinates[0] : vehicle.position.coordinates);
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.vehicles = await this.context.invipo.getQuery("cits-vehicles");
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.vehicles) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
