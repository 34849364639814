import "./cits-preference-count-report.scss";
import * as template from "./cits-preference-count-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitsPreferenceCountReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { DateInput } from "muklit/components/date-input/date-input";
import { PanelChart, PanelKpis, PanelTable } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { CitsPreferenceCircleLayer } from "../../../layers/traffic/cits-preference-circle-layer";
import { Panel } from "../../common/panel/panel";
import { RangeInput } from "muklit/components/range-input/range-input";

export class CitsPreferenceCountReport extends Panel<CitsPreferenceCountReportOptions> {

    // Properties
    public days: PanelChart;
    public kpis: PanelKpis;
    public table: PanelTable;

    public constructor(context: InvipoContext, options?: CitsPreferenceCountReportOptions) {
        super(context, template, options);
    }

    protected createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                                to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                                range: "LastWeek"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            itemClass: "V2XUnit",
                            items: [],
                            width: 320,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "type",
                            label: "forms.fields.type",
                            placeholderText: "forms.placeholders.all",
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.CitsVehicleType")),
                            width: 320,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 20,
            layers: [
                new CitsPreferenceCircleLayer(this.context)
            ]
        });

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    async load(): Promise<void> {
        // Before load and detach/attach ofcomponent we need to set layer search params,
        // otherwise the layer data will be loaded twice (if params set in extraLoad())
        this.map.layers["cits-preference-circle"].options.search = this.form.getData(true);

        // Super call
        return super.load();
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Query string
        let query = "";

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }

        if (form.type) {
            query += `&type=${form.type}`;
        }

        // Interval
        let from = new Date(form.from);
        let to = new Date(form.to);

        // Speed data per hour
        let data = await this.context.invipo.getQuery("cits-preferences-by-day", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate data extrems
        let total = data.map(x => x.count).reduce((r, n) => { return r + n}, 0);
        let highest = Math.max(...data.map(x => x.count));
        let lowest = Math.min(...data.map(x => x.count));

        // Build hours chart
        this.days = {
            type: "Bar",
            size: "Tall",
            name: "Speed",
            label: "components.CitsPreferenceCountReport.days",
            series: []
        }

        // Itterate over days as some data may miss
        from = new Date(form.from);
        do {
            // find data
            let d = data.find(x => new Date(x.timestamp).getTime() == from.getTime());

            // Has data?
            if (d) {
                this.days.series.push(
                    [
                        {
                            timestamp: from.toISOString(),
                            valueY: Helpers.toNumber(d.count),
                            valueX: Helpers.toShortDateString(d.timestamp),
                            percent: Helpers.range(0, 100, 0, highest * 1.2, d.count), // autoscale based on highest value
                            label: `${Helpers.toNumber(d.count)} ${this.context.locale.getMessage("units.requests")}`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.days.series.push(
                    [
                        {
                            timestamp: from.toISOString(),
                            valueX: Helpers.toShortDateString(from)
                        }
                    ]
                );
            }

            // Add one day
            from.setHours(24, 0, 0, 0);
        }
        while (from.getTime() < to.getTime())

        // Build speed KPIs
        this.kpis = {
            size: "Third",
            data: [
                {
                    label: "components.CitsPreferenceCountReport.totalCount",
                    value: Number.isInteger(lowest) ? `${Helpers.toNumber(total)} ${this.context.locale.getMessage("units.requests")}` : this.context.locale.getMessage("common.noData"),
                    description: `${Helpers.toNumber(data.length)} ${this.context.locale.getMessage("units.days")}`
                },
                {
                    label: "components.CitsPreferenceCountReport.highestCount",
                    value: Number.isInteger(highest) ? `${Helpers.toNumber(highest)} ${this.context.locale.getMessage("units.requests")}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(highest) ? Helpers.toDateString(data.find(x => x.count == highest)?.timestamp) : ""
                },
                {
                    label: "components.CitsPreferenceCountReport.lowestCount",
                    value: Number.isInteger(lowest) ? `${Helpers.toNumber(lowest)} ${this.context.locale.getMessage("units.requests")}` : this.context.locale.getMessage("common.noData"),
                    description: Number.isInteger(lowest) ? Helpers.toDateString(data.find(x => x.count == lowest)?.timestamp) : ""
                }
            ]
        }

        // Build categories table
        this.table = {
            name: "Table",
            label: "components.CitsPreferenceCountReport.table",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.count",
                    style: "Bold",
                    align: "Right",
                    width: "80px"
                }
            ],
            rows: []
        };

        for (let d of data) {
            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toDateString(d.timestamp),
                    Helpers.toNumber(d.count)
                ]
            });
        }
    }

}
