import "./traffic-volume-card.scss";
import * as template from "./traffic-volume-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficVolumeCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";
import { DEFAULT_LANE_CAPACITY } from "../traffic-counting-subdomain/traffic-counting-subdomain";

export class TrafficVolumeCard extends CityCard<TrafficVolumeCardOptions> {

    // Properties
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: TrafficVolumeCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {

        // First we need to add segment type to traffic data if they come from segments
        if (this.item.data?.traffic) {
            for (let data of this.item.data.traffic) {
                if (data.segment) {
                    data.type = (<any[]>this.item.meta?.segments)?.find(x => x.name == data.segment)?.type
                }
            }
        }

        // Second we nned to store segment definition if in options
        let segment = (<any[]>this.item.meta?.segments)?.find(x => x.name == this.options.segment);

        // Calculate maximum capacity
        let capacity = DEFAULT_LANE_CAPACITY;

        // Segment meta defined?
        if (this.item.meta?.segments?.length) {
            // Signle segment capacity
            if (this.options.segment) {
                capacity = (<any[]>this.item.meta.segments).find(x => x.name == this.options.segment)?.capacity || DEFAULT_LANE_CAPACITY;
            }
            // Sum of capacity of all segments
            else {
                for (let segment of this.item.meta.segments) {
                    capacity += segment?.capacity || DEFAULT_LANE_CAPACITY;
                }
            }
        }

        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));

        // Data query
        let query = `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`;

        if (this.options.segment) {
            query += `&segment=${encodeURIComponent(this.options.segment)}`;
        }

        let data = await this.context.invipo.getQuery("traffic-by-hour", query);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                // Calcualte percentual volume from capacity
                let volume = d.count / capacity * 100;

                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.count,
                    percent: volume,
                    color: METRICS.traffic.counting.volume.colors[Math.round(Helpers.range(0, METRICS.traffic.counting.volume.colors.length - 1, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], volume))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }
}
