import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoItem } from "../../clients/invipo-client/types";

export class WasteCollectionCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "WasteCollectionCard",
            detail: "CityDetail",
            layer: {
                id: "waste-collection-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    //"circle-blur": 0.75,
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "GarbageBin"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Sort by collection
        items.sort((a: InvipoItem, b: InvipoItem) => {
            return new Date(b.data?.collection?.timestamp).getTime() - new Date(a.data?.collection?.timestamp).getTime();
        });

        // Push features from items
        for (let item of items) {
            // No data?
            if (item.data?.collection?.timestamp == null) {
                continue;
            }

            // Calcualte last collection in days
            let collection = (Date.now() - new Date(item.data.collection.timestamp).getTime()) / 86400000;

            // Color calculation from range
            let range = Helpers.range(0, METRICS.waste.waste.collection.colors.length - 1, METRICS.waste.waste.collection.range[0], METRICS.waste.waste.collection.range[1], collection);
            let color = METRICS.waste.waste.collection.colors[Math.round(range)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: item.name,
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color,
                    scale: 4
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
