import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { CitsVehicleLayer } from "./cits-vehicle-layer";

export class CitsVehicleCircleLayer extends CitsVehicleLayer {

    public vehicle: any;

    public constructor(context: InvipoContext, vehicle?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            //tooltip: true,
            card: "CitsVehicleCard",
            layer: {
                id: `cits-vehicle-circle`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        12, 4,
                        16, 8,
                        19, 10,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });

        // Set vehicle
        this.vehicle = vehicle;
    }

    public toFeature(vehicle: any): Feature<Point> {
        return {
            type: "Feature",
            properties: {
                tooltip: `${this.context.locale.getMessage(`enums.CitsVehicleRole.${vehicle.role}`)}\n${vehicle.lpn || vehicle.stationId}`,
                card: this.options.card,
                cardId: vehicle.stationId,
                vehicleId: vehicle.stationId,
                vehicleType: vehicle.type,
                vehicleRole: vehicle.role,
                vehicleLpn: vehicle.lpn,
                vehicleSpeed: vehicle.speed,
                color: vehicle.intercity ? METRICS.traffic.messages.vehicles.colors[0] : METRICS.traffic.messages.vehicles.colors[1]
            },
            geometry: vehicle.position
        }
    }

    public async load(): Promise<FeatureCollection> {
        // Load messages
        let vehicles = [];

        // Load detail?
        if (this.vehicle) {
            vehicles.push(this.vehicle);
        }
        // Load vehicles
        else {
            vehicles = (await this.context.invipo.getDataset("cits-vehicles")).data;
        }

        //Sort vehicles by deviation
        vehicles.sort((a, b): number => {
            return a.deviation - b.deviation;
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of vehicles) {
            json.features.push(this.toFeature(vehicle))
        }

        return json;
    }
}
