import "./notification-toast.scss";
import * as template from "./notification-toast.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { Log } from "hiyo/log";
import { ToastAction, NotificationToastOptions } from "./types";

export class NotificationToast extends MuklitComponent<Context, NotificationToastOptions> {

    // Properties
    public timer: any;

    // Event handling methods
    public onAction(action: ToastAction): void {};

    constructor(context: Context, options: NotificationToastOptions) {
        options.style = "Light"
        super(context, template, options);
    }

    public onAttach(): void {
        Log.i(`Toast: *${this.options.title}* ${this.options.message}`);

        // Auto close?
        if (this.options.duration > 0) {
            this.timer = setTimeout(() => {
                this.close();
            }, this.options.duration)
        }
    }

    public onDetach(): void {
        // Clear timer if set
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    public close(): void {
        // Not attached?
        if (!this.isAttached()) {
            Log.w(`Trying to close not attached component ${this.id}`);
            return;
        }

        // Detach from DOM
        this.detach();

        // OnClose handler;
        this.onClose();
    }

    public action(i: number): void {
        // OnAction handler;
        this.onAction(this.options.actions[i]);
    }
}
