import { AreaLayer } from "../area-layer";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";

export class TrafficVolumeAreaBoundaryLayer extends AreaLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "TrafficVolume",
            dynamic: true,
            before: "road-label",
            layer: {
                id: "traffic-volume-area-boundary",
                type: "line",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#000000",
                    "line-width": 4,
                    "line-opacity": 0.15,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            }
        });
    }
}
