import "./user-permissions-form.scss";
import * as template from "./user-permissions-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { UserPermissionsFormOptions } from "./types";
import { Toggle } from "muklit/components/toggle/toggle";
import { Input } from "muklit/components/input/input";
import { Checkbox } from "muklit/components/checkbox/checkbox";

export class UserPermissionsForm extends Detail<InvipoContext, UserPermissionsFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: UserPermissionsFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // User profile form
        let fields: Input[] = [];

        // Generate permission fields from project config
        for (let permission of this.context.options.permissions) {
            fields.push(
                new Checkbox(this.context, {
                    style: "Light",
                    name: permission,
                    label: `permissions.${permission}`,
                    messageText: "Message text",
                    value: this.options.user.permissions?.includes(permission)
                })
            );
        }

        // User profile form
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "Left",
                    fields: fields
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Form data
        let data = this.form.getData();

        // Show loader
        this.showLoader();

        // Update permissions with selected only permissions
        try {
            await this.context.invipo.updateUserPermissions(this.options.user.id, {
                permissions: Object.keys(data).filter(x => data[x])
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnProfileSubmit handler
        this.onSubmit();
    }
}
