import { AreaLayer } from "../area-layer";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";

const MIN_ZOOM = 9;
const MAX_ZOOM = 13;

export class WasteCollectionAreaBoundaryLayer extends AreaLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "WasteCollection",
            dynamic: true,
            before: "road-label",
            layer: {
                id: "waste-collection-area-boundary",
                type: "line",
                minzoom: MIN_ZOOM,
                maxzoom: MAX_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#000000",
                    "line-width": 4,
                    "line-opacity": 0,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    MIN_ZOOM, 0,
                    MIN_ZOOM + 0.1, 0.2,
                    MAX_ZOOM - 0.1, 0.2,
                    MAX_ZOOM, 0
                ]
            }
        });
    }
}
