import { InvipoContext } from "../../context/invipo-context";
import { AreaLayer } from "../area-layer";
import { TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";

const MAX_ZOOM = 12.5;

export class ParkingAreaPatternLayer extends AreaLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "Parking",
            card: "ParkingAreaCard",
            dynamic: true,
            pointer: true,
            before: "waterway-label",
            layer: {
                id: "occupancy-area-pattern",
                type: "fill",
                maxzoom: MAX_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "fill-pattern": "pattern-building-grey",
                    //"fill-color": "rgba(0, 0, 0, 0.075)",
                    "fill-antialias": false,
                    "fill-opacity": 0,
                    "fill-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },

                }
            },
            transitions: {
                "fill-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    MAX_ZOOM - 0.05, 1,
                    MAX_ZOOM, 0
                ]
            }
        });
    }
}
