import "./text-input.scss";
import * as template from "./text-input.hbs";
import { Context } from "hiyo/context";
import { TextInputOptions } from "./types";
import { Input } from "../input/input";

const CLASS_ACTIVE = "muklit-text-input-active";

export class TextInput extends Input<Context, TextInputOptions> {

    // Event handling methods
    public onKey(key: number): void {};

    constructor(context: Context, options: TextInputOptions) {
        super(context, template, options);
    }

    public focus(selectAll?: boolean): void {
        this.element.classList.add(CLASS_ACTIVE);

        // Focus also an input element
        this.query("input").focus();

        // Select all text inside input?
        if (selectAll && this.options.value) {
            this.query<HTMLInputElement>("input").setSelectionRange(0, this.options.value.length);
        }
    }

    public blur(): void {
        this.element.classList.remove(CLASS_ACTIVE);
    }

    public change() {
        // Read value
        this.options.value = this.element.querySelector<HTMLInputElement>("input").value;
    }

    public key(key: number) {
        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Read value
        this.options.value = this.element.querySelector<HTMLInputElement>("input").value;

        // If number, we need to avoid string content
        if (this.options.format == "Number") {
            this.options.value = Number(this.options.value);
        }

        // Enter
        if (key == 13) {
            this.onSubmit();
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear value
            this.setValue(null);
            this.onSubmit();
            return;
        }

        // OnKey handler
        this.onKey(key);
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}
