import './energy-metering-subdomain.scss';
import * as template from "./energy-metering-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { AreaList } from "../../city/area-list/area-list";
import { EnergyMeteringSubdomainOptions } from "./types";
import { EnergyConsumptionItemCircleLayer } from "../../../layers/energy/energy-consumption-item-circle-layer";
import { EnergyConsumptionItemLabelLayer } from "../../../layers/energy/energy-consumption-item-label-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class EnergyMeteringSubdomain extends CitySubdomain<EnergyMeteringSubdomainOptions> {

    constructor(context: InvipoContext, options?: EnergyMeteringSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Consumption",
                    itemClass: "EnergyMeter",
                    areaType: "EnergyConsumption",
                    layers: [
                        new EnergyConsumptionItemCircleLayer(context),
                        new EnergyConsumptionItemLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.EnergyMeteringSubdomain.areas",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.EnergyMeteringSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Create volume legend
        this.legend = {
            range: {
                range: METRICS.energy.metering.consumption.range,
                colors: METRICS.energy.metering.consumption.colors,
                count: 0,
                value: 0,
                unit: "kW/h"
            },
            symbols: []
        }
    }
}
