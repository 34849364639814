import { InvipoContext } from "../../context/invipo-context";
import { FeatureCollection } from "geojson";
import { TrafficMessageLayer } from "./traffic-message-layer";

export const SYMBOL_MAP: { [type: string]: string } = {
    "RWW": "24-markers-road-roadwork",
    "HLN": "24-markers-road-danger",
    "IVS": "24-markers-road-road_non_weather"
}

export class TrafficMessageSymbolLayer extends TrafficMessageLayer {

    // Properties
    public message: any;

    public constructor(context: InvipoContext, message?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            pointer: true,
            layer: {
                id: "traffic-message-symbol",
                type: "symbol",
                minzoom: 15,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": ["get", "symbol"],
                    "icon-offset": [0, 0],
                    "icon-allow-overlap": true
                }
            }
        });

        // Set message
        this.message = message;
    }

    public async load(): Promise<any> {
        // Load messages
        let messages = [];

        // Load detail?
        if (this.message) {
            messages.push(this.message);
        }
        // Load all currently visible
        else {
            messages = await this.context.invipo.getQuery("traffic-messages-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let message of messages) {
            json.features.push({
                type: "Feature",
                properties: {
                    symbol: SYMBOL_MAP[message.type]
                },
                geometry: {
                    type: "Point",
                    coordinates: (message.position.type == "LineString") ? message.position.coordinates[0] : message.position.coordinates
                }
            })
        }

        return json;
    }
}
