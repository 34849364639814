import './item-list.scss';
import * as template from "./item-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemListOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { CityList } from "../city-list/city-list";
import { Point } from "geojson";

export class ItemList extends CityList<ItemListOptions> {

    // Properties
    public items: InvipoItem[];

    constructor(context: InvipoContext, options?: ItemListOptions) {
        super(context, template, options);
    }

    public selectItem(i: number): void {
        // Get item
        let item = this.items[i];

        // CAll handler
        this.onPositionNavigate((<Point>item.geometry.location).coordinates, item);
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.items = this.context.data.getItems({
            class: this.options.itemClass,
            sort: "name:asc"
        });
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.items) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
