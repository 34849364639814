import { InvipoContext } from "../../context/invipo-context";
import { FeatureCollection } from "geojson";
import { TrafficIncidentLayer } from "./traffic-incident-layer";

export class TrafficIncidentSymbolLayer extends TrafficIncidentLayer {

    // Properties
    public incident: any;

    public constructor(context: InvipoContext, incident?: any) {
        super(context, {
            dynamic: true,
            pointer: true,
            layer: {
                id: "traffic-incident-symbol",
                type: "symbol",
                minzoom: 15,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": "24-markers-road-danger",
                    "icon-offset": [0, 0],
                    "icon-allow-overlap": true
                }
            }
        });

        // Set message
        this.incident = incident;
    }

    public update(): void {
        // Tell map source has updated
        this.map?.setLayerData(this, this.data);
    }

    public async load(): Promise<any> {
        // Load incidents
        let incidents = [];

        // Load detail?
        if (this.incident) {
            incidents.push(this.incident);
        }
        // Load all currently visible
        else {
            incidents = await this.context.invipo.getQuery("traffic-incidents-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let incident of incidents) {
            json.features.push({
                type: "Feature",
                properties: {
                },
                geometry: {
                    type: "Point",
                    coordinates: incident.position.coordinates
                }
            })
        }

        return json;
    }
}
