import "./waze-route-detail.scss";
import * as template from "./waze-route-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { WazeRouteDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { WazeFeedLineLayer } from "../../../layers/traffic/waze-feed-line-layer";
import { WazeRouteLineLayer } from "../../../layers/traffic/waze-route-line-layer";

export class WazeRouteDetail extends Detail<InvipoContext, WazeRouteDetailOptions> {

    // Components
    public map: BasicMap;
    public layer: WazeFeedLineLayer;

    constructor(context: InvipoContext, options: WazeRouteDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new WazeRouteLineLayer(this.context, <any>this.options.route));
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom - 0.5,
            minZoom: 5,
            maxZoom: 20
        });

        // Register component
        this.registerComponent(this.map, "map");
    }

}
