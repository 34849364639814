import "./vehicle-registry-detail.scss";
import * as template from "./vehicle-registry-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { VehicleRegistry, VehicleRegistryDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Helpers } from "hiyo/helpers";
import { Http, HttpMethod } from "hiyo/http";
import { Log } from "hiyo/log";

export class VehicleRegistryDetail extends Detail<InvipoContext, VehicleRegistryDetailOptions> {

    // Properties
    public registry: VehicleRegistry;
    public error: boolean;

    constructor(context: InvipoContext, options: VehicleRegistryDetailOptions) {
        super(context, template, options);
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        try {
            // Hanoi
            let data = await new Http().request(HttpMethod.GET, `http://192.168.24.20:9180/api/Vehicle/info?plateNumber=${this.options.lpn}`);

            this.registry = {
                registrationId: data.registrationId,
                firstRegistrationDate: data.firstRegistrationDate,
                registrationDate: data.registrationDate,
                owner: {
                    id: data.ownerId,
                    name: data.ownerName,
                    address: data.address,
                    phone: data.phoneNumber
                },
                vehicle: {
                    type: data.vehicleType,
                    brand: data.brand,
                    model: data.model,
                    color: data.color,
                    plate: {
                        color: data.plateColor,
                        number: data.plateNumber
                    },
                    vin: data.chassisNumber,
                    engineNumber: data.engineNumber,
                    engineDisplacement: data.engineDisplacement,
                    seats: data.seat
                }
            }
        }
        catch (e) {
            Log.w(`VehicleRegistry: No data recieved (plate=${this.options.lpn})`)
            this.error = true;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();
    }

}
