import { DEFAULT_FONT, StatsChart } from "../../common/stats-chart/stats-chart";
import { TrafficVolumeChartOptions } from "./types";
import { Chart, ChartDataSets, ChartTooltipModel } from "chart.js";
import { Helpers } from "hiyo/helpers";
import { DataTable } from "muklit/components/data-table/data-table";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";

export class TrafficVolumeChart extends StatsChart<TrafficVolumeChartOptions> {

    public onDataLoad(data: any) {
        if (!this.data?.length) {
            return;
        }

        // Starting date of interval
        let date = new Date(this.data[0].timestamp);

        for (let i = 0; i < 24; i++) {
            // Hour was set
            if (this.data[i]) {
                continue;
            }

            // Add missing hour
            this.data.push({
                timestamp: new Date(date.setHours(i, 0, 0, 0)).toISOString()
            })
        }
    }

    public createLegend() {
        let categories: any[] = [];

        // Start from empty legend
        this.legend = [];

        // Create datasets with data from categories
        for (let c of this.context.config.categories) {
            this.legend.push({
                type: "Line",
                label: c.name,
                color: c.color
            });
        }
    }

    public createHighlights(): void {
        // Reset
        this.highlights = {};

        // Sum up categories
        for (let data of this.data) {
            let count = 0;

            for (let c of data.categories || []) {
                count += c.count;
            }

            data.count = count;
        }

        // Count values
        let total = 0;
        for (let data of this.data) {
            total += data.count;
        }

        // First and last
        let first = this.data[0];
        let last = this.data[this.data.length - 1];

        // Minimum and maximum
        let min = { ...first };
        let max = { ...first };

        for (let data of this.data) {
            if (data.count < min.count) {
                min = data;
            }
            if (data.count > max.count) {
                max = data;
            }
        }

        // Total count
        this.highlights.total = {
            label: "components.StatsChart.highlights.total",
            value: Helpers.toNumber(total),
            units: "units.vehicles",
            description: Helpers.toDateString(first.timestamp)
        }

        // Average
        this.highlights.average = {
            label: "components.StatsChart.highlights.average",
            value: Helpers.toNumber(total / this.data.length),
            units: "units.vehicles",
            description: Helpers.toDateString(first.timestamp)
        }

        // Maximum
        this.highlights.highest = {
            label: "components.StatsChart.highlights.peak",
            value: Helpers.toShortTimeString(max.timestamp),
            description: `${Helpers.toNumber(max.count)} ${this.context.locale.getMessage("units.vehicles")}`
        }

        // Minimum
        this.highlights.lowest = {
            label: "components.StatsChart.highlights.low",
            value: Helpers.toShortTimeString(min.timestamp),
            description: `${Helpers.toNumber(min.count)} ${this.context.locale.getMessage("units.vehicles")}`
        }
    }

    public createTable() {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            autosort: true,
            data: this.data,
            rows: {
                id: "timestamp"
            },
            columns: [
                {
                    name: "date",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.date",
                    width: 160,
                    formatter: (value: any, data: any) => {
                        return `<div class="cell cell-left">${Helpers.toDateString(value)}</div>`;
                    },
                },
                {
                    name: "interval",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.interval",
                    width: 160,
                    formatter: (value: any, data: any) => {
                        return `<div class="cell cell-left">${Helpers.toShortTimeString(value)} &ndash; ${Helpers.toShortTimeString(new Date(new Date(value).setHours(new Date(value).getHours() + 1, 0, 0, 0)))}</div>`;
                    }
                },
            ]
        });

        for (let c of this.context.config.categories) {
            this.table.options.columns.push({
                name: `category-${Helpers.toKebabCase(c.name)}`,
                type: "Number",
                property: (data: any) => {
                    let categories: any[] = data.categories ?? [];
                    let count = categories.find(x => x.id == c.id)?.count;

                    // Display 0 when no data in past, empty string when no data in future
                    if (new Date(data.timestamp).getTime() < new Date().getTime()) {
                        return count || 0;
                    }
                    else {
                        return count;
                    }
                },
                label: c.name,
                width: 110,
                align: "Center"
            });
        }

        // Total volume
        this.table.options.columns.push({
            name: "total",
            type: "Number",
            property: (data: any) => {
                let categories: any[] = data.categories ?? [];
                let count = 0;

                for (let c of categories) {
                    count += c.count;
                }
                return count || null;
            },
            label: "tables.columns.total",
            width: 110,
            align: "Center"
        });

        // last column
        this.table.options.columns.push({
            name: "last",
            type: "String",
            property: null,
            label: null
        });
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = (this.query<HTMLCanvasElement>("div.chart canvas"));

        // Labels and data
        let labels: any[] = [];
        let counts: { [category: string]: number[] } = {};
        let datasets: ChartDataSets[] = [];

        // Selected group
        for (let d of this.data) {
            labels.push(d.timestamp);

            // Empty counts?
            if (!d.categories) {
                continue;
            }

            for (let c of d.categories) {
                !counts[c.id] ? counts[c.id] = [c.count] : counts[c.id].push(c.count);
            }
        }

        // Create datasets with data from counts based on classification order
        for (let c of this.context.config.categories) {
            // Category has no data
            if (!counts[c.id]) {
                continue;
            }

            // Add new dataset
            datasets.push({
                label: c.name,
                backgroundColor: c.color,
                hoverBackgroundColor: c.color,
                data: counts[c.id]
            });
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints) {
                                let data = model.dataPoints[0];
                                let from = new Date(data.label);
                                let to = new Date(new Date(data.label).setHours(new Date(data.label).getHours() + 1));


                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    text: `${datasets[data.datasetIndex].label}<br /> ${Helpers.toNumber(data.value)} ${this.context.locale.getMessage("units.vehicles")}<br />${Helpers.toShortTimeString(from)} &ndash; ${Helpers.toShortTimeString(to)}`
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                type: "category",
                                stacked: true,
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toShortTimeString(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                display: true,
                                stacked: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("units.vehicles")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    stepSize: 100,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.35)",
                                    borderDash: [2, 0, 2],
                                    zeroLineColor: "rgba(141, 141, 141, 0.35)",
                                    drawOnChartArea: true,
                                }
                            }
                        ]
                    }
                }
            });
    }

}
