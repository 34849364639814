import { ContentView } from "./content-view";

export class Items extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("ItemEditor");
    }

}
