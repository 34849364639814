import "./home-screen.scss";
import * as template from "./home-screen.hbs";
import { HomeScreenOptions, TechnologyClass } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Info } from "../../../clients/invipo-client/types";
import { City } from "../../../views/city";
import { CityMap } from "../../city/city-map/city-map";
import { LicenseDetail } from "../license-detail/license-detail";

export class HomeScreen extends MuklitComponent<InvipoContext, HomeScreenOptions> {

    // Properties
    public classes: TechnologyClass[];
    public info: Info;

    constructor(context: InvipoContext, options?: HomeScreenOptions) {
        super(context, template, options);
    }

    public onCreate() {
        this.classes = [];

        // Push classes from evidence
        for (let c of this.context.options.classes) {
            this.classes.push({
                name: c,
                count: 0,
                limit: 50,
            })
        }
    }

    public selectDomain(name: string): void {
        // Find domains view
        let view = <City>this.context.application.views.find(x => x.name == "City" || x.name == "Extravilan");

        // Change application view to Domains
        this.context.application.route({ viewId: view.id });

        // Switch domain
        let city = <CityMap>view.content;
        city.navigator.options.domain = name;

        // Redraw city map
        city.invalidate();
    }

    public openLicense(): void {
        // Detail form
        let detail = new LicenseDetail(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true,
            preventEsc: true
        });

        // Show form
        detail.attach();
    }

    public async load(): Promise<void> {
        // Load items
        let items = this.context.data.getItems();

        // Build technology table
        for (let c of this.classes) {
            c.count = items.filter(x => x.class == c.name).length;
        }

        // Redraw
        this.update();
    }

}
