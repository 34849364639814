import "./traffic-incidents-subdomain.scss";
import * as template from "./traffic-incidents-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficIncidentsSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { TrafficIncidentList } from "../traffic-incident-list/traffic-incident-list";
import { TrafficIncidentCircleLayer } from "../../../layers/traffic/traffic-incident-circle-layer";
import { HiyoEvent } from "hiyo/event-broker";
import { TrafficIncidentSymbolLayer } from "../../../layers/traffic/traffic-incident-symbol-layer";

export class TrafficIncidentsSubdomain extends CitySubdomain<TrafficIncidentsSubdomainOptions> {

    // Properties
    public incidents: any[];

    constructor(context: InvipoContext, options?: TrafficIncidentsSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Incidents",
                    layers: [
                        new TrafficIncidentCircleLayer(context),
                        new TrafficIncidentSymbolLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public onAttach(): void {
        this.context.broker.subscribe(this, ["TrafficIncidentReceived", "TrafficIncidentRemoved"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(incident: HiyoEvent) {
        // Reload
        await this.load();
    }

    public selectIncidents(incidents?: any[], tags?: string[]): void {
        // Crate item list
        let list = new TrafficIncidentList(this.context, {
            style: "Light",
            title: "components.TrafficIncidentList.title",
            subtitle: "components.TrafficIncidentList.subtitle",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.incidents = incidents || this.incidents;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string) {
        this.selectIncidents(this.incidents.filter(x => x.type == symbol), [`enums.TrafficIncidentType.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Empty all data
        this.incidents = null;

        // Incidents metrics?
        if (this.metrics.name == "Incidents") {
            // Load traffic incidents data
            this.incidents = await this.context.invipo.getQuery("traffic-incidents-current");

            // Create messages legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let symbol of this.context.options.enums?.TrafficIncidentType || METRICS.traffic.incidents.incidents.range) {
                let count = this.incidents.filter(x => x.type == symbol).length;

                this.legend.symbols.push({
                    type: "Circle",
                    symbol: symbol,
                    count: count,
                    label: `enums.TrafficIncidentType.${symbol}`,
                    percent: count / (this.incidents.length || 1) * 100,
                    color: METRICS.traffic.incidents.incidents.colors[METRICS.traffic.incidents.incidents.range.indexOf(symbol)],
                    selectable: true
                });
            }
        }
    }

}
