import "./traffic-control-browser.scss";
import * as template from "./traffic-control-browser.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemSelect } from "../../common/item-select/item-select";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { TrafficControlBrowserOptions } from "./types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class TrafficControlBrowser extends MuklitComponent<InvipoContext, TrafficControlBrowserOptions> {

    // Components
    public table: FilterTable;

    constructor(context: InvipoContext, options?: TrafficControlBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-control-changes`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TrafficControlBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                //value: value,
                                placeholderText: "All",
                                distinct: "TrafficControlChanges",
                                items: [],
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "Timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "state",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficLightControllerState.${data.state}`)
                        },
                        label: "tables.columns.state",
                        width: 120,
                        align: "Center",
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "mode",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficLightControllerMode.${data.mode}`)
                        },
                        label: "tables.columns.mode",
                        width: 120,
                        align: "Center",
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "plan",
                        type: "String",
                        property: "plan",
                        label: "tables.columns.plan",
                        width: 180,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        ellipsis: true,
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Close self
        this.table.onClose = () => {
            this.detach();

            // OnClose handler
            this.onClose();
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "ParkingsOccupanciesCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
