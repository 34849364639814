import './traffic-counting-subdomain.scss';
import * as template from "./traffic-counting-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficVolumeAreaBoundaryLayer } from "../../../layers/traffic/traffic-volume-area-boundary-layer";
import { TrafficCountingSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { AreaList } from "../../city/area-list/area-list";
import { TrafficVolumeItemLabelLayer } from "../../../layers/traffic/traffic-volume-item-label-layer";
import { TrafficVolumeSegmentLineLayer } from "../../../layers/traffic/traffic-volume-segment-line-layer";
import { TrafficVolumeSegmentSymbolLayer } from "../../../layers/traffic/traffic-volume-segment-symbol-layer";
import { TrafficVolumeSegmentLabelLayer } from "../../../layers/traffic/traffic-volume-segment-label-layer";
import { TrafficVolumeItemCircleLayer } from "../../../layers/traffic/traffic-volume-item-circle-layer";
import { TrafficVolumeAreaCircleLayer } from "../../../layers/traffic/traffic-volume-area-circle-layer";
import { TrafficVolumeAreaLabelLayer } from "../../../layers/traffic/traffic-volume-area-label-layer";
import { AreaBoundaryLayer } from "../../../layers/infrastructure/area-boundary-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export const VOLUME_CLASS = "TrafficCounter,WimStation,BluetoothDetector";
export const DEFAULT_LANE_CAPACITY = 1800; // Default lane capacity in vehicles per lane

export class TrafficCountingSubdomain extends CitySubdomain<TrafficCountingSubdomainOptions> {

    constructor(context: InvipoContext, options?: TrafficCountingSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Volume",
                    itemClass: VOLUME_CLASS,
                    areaType: "TrafficVolume",
                    layers: [
                        new TrafficVolumeAreaBoundaryLayer(context),
                        new TrafficVolumeSegmentLineLayer(context),
                        new TrafficVolumeSegmentSymbolLayer(context),
                        new TrafficVolumeSegmentLabelLayer(context),
                        new TrafficVolumeItemCircleLayer(context),
                        new TrafficVolumeItemLabelLayer(context),
                        new TrafficVolumeAreaCircleLayer(context),
                        new TrafficVolumeAreaLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.TrafficCountingSubdomain.areas",
            layers: [
                new AreaBoundaryLayer(this.context, this.areas)
            ]
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.TrafficCountingSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Occupncy metrics?
        if (this.metrics.name == "Volume") {
            // Create volume legend
            this.legend = {
                description: "components.TrafficCountingSubdomain.volumeDescription",
                range: {
                    range: METRICS.traffic.counting.volume.range,
                    colors: METRICS.traffic.counting.volume.colors,
                    unit: "%",
                    value: 0,
                    count: 0,
                },
                symbols: []
            }

            // Calculate volume average
            for (let item of this.items) {
                // No data?
                if (!item.data?.traffic) {
                    continue;
                }

                // Sum up all traffic data
                for (let data of item.data?.traffic) {
                    // Current segment definition
                    let segment = (<any[]>item.meta?.segments)?.find(x => x.name == data.segment);

                    this.legend.range.value += data.count * (60 / this.context.config.aggregations.traffic.interval) / (segment?.capacity || DEFAULT_LANE_CAPACITY) * 100;
                    this.legend.range.count += 1;
                }
            }

            // Set average and calculate relative position on range
            this.legend.range.value /= this.legend.range.count;
            this.legend.range.percent = Helpers.range(0, 100, METRICS.traffic.counting.volume.range[0], METRICS.traffic.counting.volume.range[1], this.legend.range.value);
        }
    }
}
