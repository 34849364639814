import "./access-card.scss";
import * as template from "./access-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { AccessCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { Data } from "../../../views/data";
import { AccessBrowser } from "../access-browser/access-browser";
import { Helpers } from "hiyo/helpers";

export class AccessCard extends CityCard<AccessCardOptions> {

    // Properties
    public history: CardHistoryValue[][];
    public stats: any;

    constructor(context: InvipoContext, options: AccessCardOptions) {
        super(context, template, options);
    }

    public async selectData(): Promise<void> {
        // Close self
        this.close();

        // Navigate to access data browser
        this.context.application.route({
            viewId: "Data",
            itemName: "AccessBrowser",
            areaId: this.area.id
        });

        // Get data view and browser component
        let view = <Data>this.context.application.views.find(x => x.name == "Data");
        let browser = <AccessBrowser>view.content;

        // Set form values manualy
        browser.table.form.setValue("from", new Date(new Date().setHours(-24, 0, 0, 0)).toISOString());
        browser.table.form.setValue("to", new Date(new Date().setHours(24, 0, 0, 0)).toISOString());
        browser.table.form.setValue("area.id", this.area.id);

        // Perform search
        browser.table.form.submit();
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let access = await this.context.invipo.getQuery("access-count-by-hour", `area.id=${this.options.areaId}&from=${from.toISOString()}&to=${to.toISOString()}&sort=timestamp`);

        // Calculate stats
        this.stats = {
            granted: access.map(x => x.authorization.granted).reduce((r, n) => { return r + n }, 0),
            denied: access.map(x => x.authorization.denied).reduce((r, n) => { return r + n }, 0),
        }

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = access.find(x => x.timestamp == from.toISOString());

            // Add hour data
            if (d) {
                this.history.push([
                    {
                        timestamp: from.toISOString(),
                        value: d.authorization.granted,
                        percent: d.authorization.granted / (d.count || 1) * 100,
                        color: "#0fd170"
                    },
                    {
                        timestamp: from.toISOString(),
                        value: d.authorization.denied,
                        percent: d.authorization.denied / (d.count || 1) * 100,
                        color: "#ff0038"
                    }
                ])
            }
            // No data means all green
            else if (from.getTime() < Date.now()) {
                this.history.push([
                    {
                        timestamp: from.toISOString(),
                        value: 1,
                        percent: 100,
                        color: "#0fd170"
                    },
                    {
                        timestamp: from.toISOString(),
                        value: 0
                    }
                ])
            }
            // No data for hour, we are in the future
            else {
                this.history.push([
                    {
                        timestamp: from.toISOString(),
                        value: 0
                    },
                    {
                        timestamp: from.toISOString(),
                        value: 0
                    }
                ])
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }
    }
}
