import "./detection-detail.scss";
import * as template from "./detection-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { DetectionDetailOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class DetectionDetail extends Detail<InvipoContext, DetectionDetailOptions> {

    constructor(context: InvipoContext, options: DetectionDetailOptions) {
        super(context, template, options);
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
