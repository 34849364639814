import { Chart, ChartDataSets, ChartTooltipModel } from "chart.js";
import { Helpers } from "hiyo/helpers";
import { DataTable } from "muklit/components/data-table/data-table";
import { InvipoHelpers } from "../../../invipo-helpers";
import { DEFAULT_FONT, StatsChart } from "../../common/stats-chart/stats-chart";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";
import { WasteCollectionChartOptions } from "./types";

export class WasteCollectionChart extends StatsChart<WasteCollectionChartOptions> {

    public createLegend() {
        // Create legend
        this.legend = [
            {
                type: "Line",
                label: "tables.columns.collections",
                color: InvipoHelpers.toChartColor(1)
            },
            {
                type: "Line",
                label: "tables.columns.weight",
                color: InvipoHelpers.toChartColor(2)
            }
        ];
    }

    public createHighlights(): void {
        // Reset
        this.highlights = {};

        // Count values
        let total = 0;
        for (let data of this.data) {
            total += data.count;
        }

        // First and last
        let first = this.data[0];
        let last = this.data[this.data.length - 1];

        // Minimum and maximum
        let min = { ...first };
        let max = { ...first };

        for (let data of this.data) {
            if (data.count < min.count) {
                min = data;
            }
            if (data.count > max.count) {
                max = data;
            }
        }

        // Total count
        this.highlights.total = {
            label: "components.StatsChart.highlights.total",
            value: Helpers.toNumber(total),
            units: "units.collections",
            description: `${Helpers.toDateString(first.timestamp)} &ndash; ${Helpers.toDateString(last.timestamp)}`
        }

        // Average
        this.highlights.average = {
            label: "components.StatsChart.highlights.average",
            value: Helpers.toNumber(total / this.data.length),
            units: "units.collections",
            description: `${Helpers.toDateString(first.timestamp)} &ndash; ${Helpers.toDateString(last.timestamp)}`
        }

        // Minimum
        this.highlights.lowest = {
            label: "components.StatsChart.highlights.lowest",
            value: Helpers.toNumber(min.count),
            units: "units.collections",
            description: `${Helpers.toDateString(min.timestamp)}`
        }

        // Maximum
        this.highlights.highest = {
            label: "components.StatsChart.highlights.highest",
            value: Helpers.toNumber(max.count),
            units: "units.collections",
            description: `${Helpers.toDateString(max.timestamp)}`
        }
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = (this.query<HTMLCanvasElement>("div.chart canvas"));

        // Labels and data
        let labels: any[] = [];
        let countData: number[] = [];
        let weightData: number[] = [];

        // Add labels and data
        for (let d of this.data) {
            labels.push(d.timestamp);
            countData.push(d.count)
            weightData.push(d.weight)
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            yAxisID: "left",
                            label: "tables.columns.count",
                            backgroundColor: InvipoHelpers.toChartColor(1),
                            hoverBackgroundColor: InvipoHelpers.toChartColor(1),
                            data: countData
                        },
                        {
                            yAxisID: "right",
                            label: "tables.columns.weight",
                            backgroundColor: InvipoHelpers.toChartColor(2),
                            hoverBackgroundColor: InvipoHelpers.toChartColor(2),
                            data: weightData
                        }
                    ]
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints) {
                                let data = model.dataPoints[0];

                                // Text based on dataset
                                let text = "";

                                if (data.datasetIndex == 0) {
                                    text = `${Helpers.toNumber(data.value)} ${this.context.locale.getMessage("units.collections")}`
                                }
                                if (data.datasetIndex == 1) {
                                    text = `${Helpers.toNumber(data.value)} ${this.context.locale.getMessage("units.kg")}`
                                }

                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    text: text
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                type: "category",
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    min: this.options.search?.from ? Object.keys(this.options.search.from)[0] : null,
                                    max: this.options.search?.to ? Object.keys(this.options.search.to)[0] : null,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toShortDateString(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                id: "left",
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("units.collections")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    //stepSize: Helpers.calculateStepSize(Math.max(...data), 4),
                                    stepSize: 10,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.1)",
                                    zeroLineColor: "rgba(141, 141, 141, 0.1)",
                                    drawOnChartArea: true
                                }
                            },
                            {
                                id: "right",
                                position: "right",
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("units.kg")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    //stepSize: Helpers.calculateStepSize(Math.max(...data), 4),
                                    stepSize: 10000,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            }

                        ]
                    }
                }
            });
    }

    public drawTable(): void {
        // Data table to attach
        let table: DataTable = null;

        table = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            autosort: true,
            data: this.data,
            rows: {
                id: "timestamp"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "Date",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    width: 160,
                    sortable: true,
                    selected: true
                },
                {
                    name: "count",
                    type: "Number",
                    property: "count",
                    label: "tables.columns.collections",
                    width: 120,
                    sortable: true
                },
                {
                    name: "weight",
                    type: "Number",
                    property: "weight",
                    label: "tables.columns.weight",
                    sortable: true
                }
            ]
        });

        // Attach and set data
        table.attach(this.element.querySelector<HTMLElement>("div.table"));
    }

}
