import "./item-tooltip.scss";
import * as template from "./item-tooltip.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemTooltipOptions } from "./types";
import { Tooltip } from "muklit/components/tooltip/tooltip";

export class ItemTooltip extends Tooltip<InvipoContext, ItemTooltipOptions> {

    constructor(context: InvipoContext, options: ItemTooltipOptions) {
        super(context, template, options);
    }

}
