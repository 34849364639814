import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { ItemSelect } from "../../common/item-select/item-select";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { TrafficVolumeStatsOptions } from "./types";
import { TrafficVolumeChart } from "../traffic-volume-chart/traffic-volume-chart";
import { Helpers } from "hiyo/helpers";
import { Select } from "muklit/components/select/select";

const CLASS_VEHICLE_COUNTER = "WimStation,TrafficCounter";

export class TrafficVolumeStats extends StatsDetail<TrafficVolumeStatsOptions> {

    public createForm(): void {
        // Item select
        let item = new ItemSelect(this.context, {
            style: "Light",
            name: "item.id",
            label: "forms.fields.item",
            placeholderText: "All",
            distinct: "TrafficData",
            items: [],
            bright: true,
            multiselect: true
        });

        // Segment select
        let segment = new Select(this.context, {
            style: "Light",
            name: "segment",
            label: "forms.fields.segment",
            placeholderText: "forms.placeholders.all",
            items: [],
            bright: true,
            multiselect: true
        });

        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "from",
                            label: "forms.fields.from",
                            value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                            bright: true,
                            type: "Date"
                        }),
                        item,
                        segment
                    ]
                }
            ]
        });

        // Fill segments after item is selected
        item.onSubmit = () => {
            // Reset segments
            segment.options.items = [];

            // Find all selected items
            for (let i of item.options.items.filter(x => x.selected)) {
                // Find item definition
                let found = item.items.find(x => x.name == i.label);

                // Add all segments
                for (let s of found.meta.segments || []) {
                    segment.options.items.push({
                        name: s.name,
                        label: s.name
                    })
                }
            }

            // We must invalidate select to display new items
            segment.invalidate();

            // Invoke form submit
            this.form.onSubmit(this.form.getData());
        }

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            // Reload chart
            await this.chart.filter(this.adjustData(data));
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new TrafficVolumeChart(this.context, {
            style: "Light",
            queryName: "TrafficByHour",
            query: `item.class=${CLASS_VEHICLE_COUNTER}`,
            view: "Chart",
            search: this.adjustData(this.form.getData()),
            closable: this.options.closable
        });

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

    public adjustData(data: any): any {
        // We must transfor single date to one day from-to interval
        let from = Object.keys(data.from)[0];
        let to = new Date(new Date(from).setHours(24, 0, 0, 0));
        data.to = {};
        data.to[to.toISOString()] = Helpers.toDateTimeString(to);

        // Return corrected data
        return data;
    }

}
