import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { InvipoItem } from "../../clients/invipo-client/types";
import { METRICS } from "../../components/city/city-subdomain/types";

export class StreetLightControllerCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "StreetLightControllerCard",
            detail: "CityDetail",
            layer: {
                id: `street-light-controller-circle`,
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-color": [
                        "case",
                        ["has", "parentId"], METRICS.energy.lights.topology.colors[1],
                        METRICS.energy.lights.topology.colors[0]
                    ],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 1,
                        22, 3
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.9
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.9
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "StreetLightController"
        });

        // Sort by master controllers first
        items.sort((a: InvipoItem, b: InvipoItem) => {
           if (a.parent && !b.parent) {
               return -1;
           }
           else if (b.parent && !a.parent) {
               return 1;
           }
           else {
               return 0;
           }
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            json.features.push({
                id: item.id,
                type: "Feature",
                properties: {
                    tooltip: `${item.name}\n${this.context.locale.getMessage(item.parent ? "components.StreetLightSubdomain.topology.Slave" : "components.StreetLightSubdomain.topology.Master")}`,
                    card: this.options.card,
                    detail: this.options.detail,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    parentId: item.parent?.id,
                    scale: item.parent?.id ? 0.75 : 1
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }


}
