import './waze-subdomain.scss';
import * as template from "./waze-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { WazeSubdomainOptions } from "./types";
import { WazeFeedLineLayer } from "../../../layers/traffic/waze-feed-line-layer";
import { METRICS } from "../../city/city-subdomain/types";

export class WazeSubdomain extends CitySubdomain<WazeSubdomainOptions> {

    constructor(context: InvipoContext, options?: WazeSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Density",
                    layers: [
                        new WazeFeedLineLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public async extraLoad(): Promise<void> {
        // Incidents metrics?
        if (this.metrics.name == "Density") {
            // Feed data
            let feed = (await this.context.invipo.getDataset("waze-feeds", "sort=timestamp:desc&pageSize=1"))?.data[0];

            // Create messages legend
            this.legend = {
                range: null,
                symbols: []
            };

            // Calculate legend counts and percentage
            for (let symbol of METRICS.traffic.waze.density.range) {
                // Level number
                let level = METRICS.traffic.waze.density.range.indexOf(symbol);

                // count of level routes
                let count = (<any[]>feed.feed.routes).filter(x => x.jamLevel == level).length

                this.legend.symbols.push({
                    type: "Circle",
                    symbol: symbol,
                    count: count,
                    label: `enums.TrafficDensity.${symbol}`,
                    percent: count / (feed.feed.routes.length || 1) * 100,
                    color: METRICS.traffic.waze.density.colors[level]
                });
            }
        }
    }
}
