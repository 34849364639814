import "./connector-detail.scss";
import * as template from "./connector-detail.hbs";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { ConnectorDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { DataTable } from "muklit/components/data-table/data-table";
import { Helpers } from "hiyo/helpers";
import { Detail } from "muklit/components/detail/detail";
import { Templates } from "hiyo/templates";

const HISTORY_COUNT = 50; // Number of status change rows

export class ConnectorDetail extends Detail<InvipoContext, ConnectorDetailOptions> {

    // Properties
    public item: InvipoItem;

    // Components
    public tabs: Tabs;
    public items: DataTable;
    public history: DataTable;

    constructor(context: InvipoContext, options: ConnectorDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createItems();
        this.createHistory();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.items, "items-table");
        this.registerComponent(this.history, "history-table");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Dark",
            tabs: [
                {
                    name: "General",
                    label: "components.ConnectorDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "History",
                    label: "components.ConnectorDetail.items",
                    content: "div.content-items"
                },
                {
                    name: "History",
                    label: "components.ConnectorDetail.history",
                    content: "div.content-history"
                },
                {
                    name: "Data",
                    label: "components.ConnectorDetail.data",
                    content: "div.content-data"
                }
            ]
        });
    }

    private createItems(): void {
        // Create component
        this.items = new DataTable(this.context, {
            style: "Dark",
            type: "Unselectable",
            size: "Short",
            autosort: true,
            height: "100%",
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    formatter: (value: any, data: any) => {
                        return Templates.renderPartial("status-formatter", {
                            style: "Dark",
                            status: data.monitoringStatus?.status,
                            text: data.name
                        })
                    },
                    label: "item.name",
                    width: 320,
                    sortable: true,
                    selected: true
                },
            ]
        });
    }

    private createHistory(): void {
        // Create component
        this.history = new DataTable(this.context, {
            style: "Dark",
            type: "Unselectable",
            size: "Short",
            autosort: true,
            height: "100%",
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    width: 160,
                    sortable: true,
                    selected: true,
                    descendent: true,
                    formatter: (value: any, data: any) => {
                        return `<div class="partial-status-formatter partial-status-formatter-style-dark">
                            <div class="status status-${Helpers.toKebabCase(data.status)}"></div>
                            <div class="text">${Helpers.toDateTimeString(value)}</div>
                        </div>`;
                    }
                },
                {
                    name: "duration",
                    type: "Number",
                    property: "duration",
                    label: "tables.columns.duration",
                    align: "Right",
                    sortable: true,
                    formatter: (value: any, data: any) => {
                        if (data.duration == null) {
                            return `<div class="cell cell-right">${this.context.locale.getMessage("components.ConnectorDetail.untilNow")}</div>`;
                        }
                        else {
                            return `<div class="cell cell-right">${Helpers.toDuration(data.duration)}</div>`;
                        }
                    }
                }
            ]
        });
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.item = this.context.data.getItem(this.options.itemId);
        let items = await this.context.invipo.getDataset("items", `connector.item.id=${this.item.id}&pageSize=0&sort=timestamp:desc`);
        let history = await this.context.invipo.getDataset("monitoring-status-history", `item.id=${this.item.id}&pageSize=${HISTORY_COUNT}&sort=timestamp:desc`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Manage child components
        this.items.setData(items.data);
        this.history.setData(history.data);

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
