import "./cits-manager.scss";
import * as template from "./cits-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { CitsManagerOptions, TrafficMessage } from "./types";
import { Form } from "muklit/components/form/form";
import { Select } from "muklit/components/select/select"
import { Dialog } from "muklit/components/dialog/dialog";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { MessageDetail } from "../message-detail/message-detail";
import { MessageDetailOptions } from "../message-detail/types";
import { MessageForm } from "../message-form/message-form";
import { HttpMethod } from "hiyo/http";
import { Helpers } from "hiyo/helpers";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class CitsManager extends MuklitComponent<InvipoContext, CitsManagerOptions> {

    // Components
    public form: Form;
    public table: FilterTable;
    public detail: MessageDetail;

    constructor(context: InvipoContext, options?: CitsManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-messages`,
            http: this.context.invipo.http,
            filter: {
                title: "components.CitsManager.title",
                items: [
                    {
                        name: "Add",
                        label: "labels.add"
                    },
                    {
                        name: "reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageType"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                }),
                                bright: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "subtype",
                                label: "forms.fields.subtype",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficMessageSubtype"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                }),
                                bright: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "locked",
                                label: "forms.fields.source",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "false",
                                        label: "enums.TrafficMessageSource.Internal"
                                    },
                                    {
                                        name: "true",
                                        label: "enums.TrafficMessageSource.External"
                                    }
                                ],
                                bright: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "duration.from",
                        type: "DateTime",
                        property: "duration.from",
                        formatter: (value: any, data: any) => {
                            let from = `${Helpers.toDateString(data.duration.from)} ${Helpers.toShortTimeString(data.duration.from)}`;
                            
                            // Locked?
                            if (data.locked) {
                                return `<div class="partial-label partial-label-style-light" style="padding-left: -12px">
                                    <div class="icon icon-lock"></div>
                                    <div class="label">${from}</div>
                                </div>`
                            }
                            else {
                                return `<div class="cell" style="padding-left: 28px">${from}</div>`
                            }
                        },
                        label: "tables.columns.from",
                        width: 200,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "duration.to",
                        type: "DateTime",
                        property: "duration.to",
                        formatter: (value: any, data: any) => {
                            if (!data.duration.to) {
                                return null;
                            }
                            else {
                                return `<div class="cell">${Helpers.toDateString(data.duration.to)} ${Helpers.toShortTimeString(data.duration.to)}</div>`
                            }
                        },
                        label: "tables.columns.to",
                        width: 200,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "subtype",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficMessageSubtype.${data.subtype}`);
                        },
                        label: "tables.columns.subtype",
                        width: 280,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficMessageType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        minWidth: 250,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Handle menu
        this.table.onItemSelect = async (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                this.openNew();
            }
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: TrafficMessage): Promise<void> {
        // Detail options
        let options: MessageDetailOptions = {
            style: "Light",
            title: `enums.TrafficMessageSubtype.${data.subtype}`,
            subtitle: `enums.TrafficMessageType.${data.type}`,
            message: data,
            closable: true,
            editable: true,
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Invalidate
            await this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MessageDetail(this.context, options);

        // Open details form
        this.detail.onSelectDetail = async (message: TrafficMessage) => {
            this.openForm(message);
        }

        // Open delete dialog
        this.detail.onSelectDelete = async (message: TrafficMessage) => {
            this.confirmDelete(message);
        }

        // When detail is closed
        this.detail.onClose = () => {
            // Unselect selected row
            this.table.unselectRow(this.detail.options.message?.id);
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public openForm(message: TrafficMessage): void {
        // Detail form
        let form = new MessageForm(this.context, {
            style: "Light",
            title: "components.MessageForm.updateMessage",
            message,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();

            // Reload detail
            await this.detail?.load();
        }

        // Show form
        form.attach();
    }

    public confirmDelete(message: TrafficMessage): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.CitsManager.messageDelete",
            text: this.context.locale.getMessage("components.CitsManager.reallyDelete", this.context.locale.getMessage(`enums.TrafficMessageSubtype.${message.subtype}`)),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            try {
                const http = this.context.invipo.http;
                const host = this.context.invipo.options.host;
                let method = HttpMethod.DELETE;
                let url = `${host}/api/management/traffic/messages/${message.id}`;

                await http.request(method, url, {
                    data: message
                });

                await this.table.load();
            }
            catch (e) {
                if (e.status == 422) {
                    this.form.setValidationErrors(e.response);
                    return;
                }
            }
            finally {
                this.hideLoader();
            }

            // Close dialog
            dialog.close();

            // Close detail
            this.detail?.close();
        }

        // Show dialog
        dialog.attach();
    }

    public openNew(): void {
        // Message form
        const form = new MessageForm(this.context, {
            title: "components.MessageForm.title",
            style: "Light",
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();

            // Reload detail
            // await this.detail?.load();
            // this.openDetail()
        }

        form.attach();
    }
}
