import './filter-table.scss';
import * as template from "./filter-table.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { DatasetResult, FilterTableOptions } from "./types";
import { DataTable } from "../data-table/data-table";
import { TitleBarTag } from "../title-bar/types";
import { Http, HttpMethod } from "hiyo/http";
import { Log } from "../../../hiyo/log";
import { TableColumn, TableRow } from "../data-table/types";
import { Pagination } from "../pagination/pagination";
import { Helpers } from "hiyo/helpers";
import { Filter } from "../filter/filter";
import { FilterAction, FilterItem, FilterTag } from "../filter/types";
import { Form } from "../form/form";

const PAGE_SIZE = 25; // Number of records per page

export class FilterTable extends MuklitComponent<Context, FilterTableOptions> {

    // Properties
    public data: any[];
    public http: Http;
    public timeout: any;

    // Components
    public filter: Filter;
    public form: Form;
    public table: DataTable;
    public pagination: Pagination;

    // Event handling methods
    public onItemSelect(item: FilterItem): void {};
    public onActionSelect(action: FilterAction): void {};
    public onDataSelect(data: any): void {};
    public onDataMultiselect(data: any[]): void {};

    constructor(context: Context, options: FilterTableOptions) {
        super(context, template, options);

        // Http client
        this.http = this.options.http || new Http({ timeout: 10000 });
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createFilter();
        this.createTable();
        this.createPagination();
    }

    private createForm(): void {
        // No options means no form?
        if (!this.options.form) {
            return;
        }

        // Inherit master style
        this.options.form.style = this.options.style;

        // Create component
        this.form = new Form(this.context, this.options.form);

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            // When query change, we need to reset page
            this.pagination.options.page = 1;

            // Load new data
            await this.load();
        }

        // Register component
        this.registerComponent(this.form, "form");
    }

    private createFilter(): void {
        // Inherit master style
        this.options.filter.style = this.options.style;

        // Enable form toggler if form is defined
        this.options.filter.togglable = this.options.form != null;

        // TODO: pridat a obslouzit standardni menu funkce (ala export table) centralne sem
        this.options.filter.items = this.options.filter.items || [];

        // Create component
        this.filter = new Filter(this.context, this.options.filter);

        // Toggle form
        this.filter.onFormToggle = (toggled: boolean) => {
            // Assign toggled class
            this.query("div.form").classList.toggle("form-toggled", toggled);
        }

        // Remove tag and update query
        this.filter.onTagRemove = async (tag: TitleBarTag) => {
            // Clear form field
            this.form.setValue(tag.name, null);

            // Reload data
            await this.load();
        }

        // Clear all form
        this.filter.onTagsClear = async () => {
            // Celar search
            this.options.search = null;

            // Celar form
            this.form.clear();

            // Reload data
            await this.load();
        }

        // Handle menu
        this.filter.onItemSelect = (item: FilterItem) => {
            this.onItemSelect(item);
        }

        // Handle actions
        this.filter.onActionSelect = (action: FilterAction) => {
            this.onActionSelect(action);
        }

        // Reload data
        this.filter.onReload = async () => {
            await this.load();
        }

        // Close panel
        this.filter.onClose = () => {
            // Handler only
            this.onClose();
        }

        // Register component
        this.registerComponent(this.filter, "filter");
    }

    private createTable(): void {
        // Inherit master style
        this.options.table.style = this.options.style;

        // Create component
        this.table = new DataTable(this.context, this.options.table);

        // Reload on sort
        this.table.onColumnSelect = async (column: TableColumn) => {
            // Force load
            await this.load();
        }

        // OnRowSelect handle
        this.table.onRowSelect = (row: TableRow) => {
            this.onDataSelect(row.data);
        }

        // OnRowMultiselect handle
        this.table.onRowMultiselect = (rows: TableRow[]) => {
            // Has some multiselect sensitive actions?
            if (this.filter.options.actions.some(x => x.multiselect)) {
                // Enable/disable filter actions
                this.filter.options.actions.forEach(x => {
                    x.disabled = x.multiselect && rows.length == 0
                });

                // Update filter
                this.filter.update();
            }

            // Call handler
            this.onDataMultiselect(rows.map(x => x.data));
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private createPagination(): void {
        // Inherit master style and default page size
        this.options.pagination.style = this.options.style;
        this.options.pagination.pageSize = this.options.pagination.pageSize || PAGE_SIZE;

        // Create component
        this.pagination = new Pagination(this.context, this.options.pagination);

        // Change page
        this.pagination.onSelect = async (page: number) => {
            await this.load();
        }

        // Register component
        this.registerComponent(this.pagination, "pagination");
    }

    public getUrl(): string {
        // Url with host
        let url = new URL(`${this.options.url}`);

        // Pagination
        url.searchParams.set("pageSize", `${this.pagination.options.pageSize || PAGE_SIZE}`);
        url.searchParams.set("page", `${this.pagination.options.page}`);

        // Add sort by selected column
        let column = this.table.options.columns.find(x => x.selected);
        if (column) {
            // Sort parameter
            let sort = `${column.name}:${column.descendent ? "desc" : "asc"}`;

            // Second sort condition enabled?
            if (column.extraSort) {
                sort += `,${column.extraSort}`;
            }

            // Set final sort parameter
            url.searchParams.set("sort", sort);
        }

        // Query building
        if (this.options.search) {
            for (let key of Object.keys(this.options.search)) {
                let value = this.options.search[key];

                // Skip null value
                if (value == null) {
                    continue;
                }

                // Interval (from - to)
                if (value.from || value.to) {
                    url.searchParams.set("from", value.from);
                    url.searchParams.set("to", value.to);
                }
                // Coma separated values
                else if (typeof value == "object") {
                    url.searchParams.set(key, Helpers.toCommaKeys(value));
                }
                // String value
                else {
                    url.searchParams.set(key, value);
                }
            }
        }

        // Return final url as string
        return url.toString();
    }

    public getQuery(): string {
        // Get whole URL
        let url = new URL(this.getUrl());

        // Return only query string without quesstionmark
        return url.search?.substring(1);
    }

    public unselectRow(id: string): void {
        // Unselect row in nest table
        this.table.unselectRow(id);
    }

    public unselectCheckboxes(): void {
        // Unselected all selected checkboxes
        this.table.unselectCheckboxes();

        // Redraw filter
        this.filter.update();
    }

    public async load(): Promise<void> {
        // Clear timeout to prevent multiple redraw after multiple load() calls
        clearTimeout(this.timeout);

        // We always create search object here based on form data
        this.options.search = this.form?.getData();

        // Data
        let result: DatasetResult = null;

        // Show loader
        this.showLoader();

        try {
            // Load data
            result = await this.http.request(HttpMethod.GET, this.getUrl());
        }
        catch (e) {
            Log.e(`Error when loading ${this.id} (${(e.messgae || e.response)})`);
        }

        // Component detached while loading?
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Empty tags
        this.options.filter.tags = [];

        // Update tags
        if (this.options.search != null) {
            for (let key of Object.keys(this.options.search)) {
                let value = this.options.search[key];

                // Empty value?
                if (value == null) {
                    continue;
                }

                // Tag label
                let label = value;

                // Build label based on type
                if (value.from || value.to) {
                    // Range text expression
                    if (value.range) {
                        label = `enums.DateRange.${value.range}`;
                    }
                    else if (value.from && !value.to) {
                        label = Helpers.toShortDateTimeString(value.from);
                    }
                    else if (value.from && value.to) {
                        label = `${Helpers.toShortDateTimeString(value.from)} &ndash; ${Helpers.toShortDateTimeString(value.to)}`;
                    }
                }
                else if (typeof value == "object") {
                    label = Object.values<string>(value);
                }
                else if (typeof value == "string" && value.endsWith("Z")) {
                    label = Helpers.toShortDateTimeString(value);
                }

                this.options.filter.tags.push({
                    name: key,
                    label: label,
                    value: value
                });
            }
        }

        // Update bar with no tags
        this.filter.update();

        // Paged result?
        if (result.data) {
            // Update table with loaded data
            this.table.setData(result.data);

            // Do we have total count?
            if (result.total >= 0) {
                // Update pagination data
                this.pagination.options.pageCount = result.total > 0 ? Math.floor(result.total / result.pageSize) + 1 : 0;
                this.pagination.options.total = result.total;
                this.pagination.options.start = ((this.pagination.options.page - 1) * this.pagination.options.pageSize) + 1;
                this.pagination.options.end = Math.min(this.pagination.options.page * this.pagination.options.pageSize, this.pagination.options.total);
            }
            // No total count
            else {
                // Update pagination data
                this.pagination.options.count = result.data.length;
                this.pagination.options.start = ((this.pagination.options.page - 1) * this.pagination.options.pageSize) + 1;
                this.pagination.options.end = Math.max(this.pagination.options.page * this.pagination.options.pageSize, result.data.length);
            }
        }
        // Non-paged result
        else {
            // Update table with loaded data
            this.table.setData(<any>result);

            // Update pagination with only total counts (no paging)
            this.pagination.options.total = (<any>result).length;
        }

        // Update pagination
        this.pagination.update();

        // Autorefresh enabled?
        if (this.options.refresh != null) {
            this.timeout = setTimeout(async () => {
                await this.load();
            }, this.options.refresh * 1000)
        }
    }
}
