import * as columns from "./dashboard.columns.hbs";
import "./dashboard.scss";
import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { DashboardColumn, DashboardOptions } from "./types";
import { Templates } from "hiyo/templates";
import { Helpers } from "hiyo/helpers";

export abstract class Dashboard<T extends DashboardOptions = DashboardOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public columns: DashboardColumn[];

    protected constructor(context: InvipoContext, template: any, options?: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("dashboard-columns", columns);
    }

    public onCreate() {
        this.createColumns();
    }

    protected abstract createColumns(): void;

    public onAttach() {
        // Attach all tiles
        for (let column of this.columns) {
            for (let tile of column.tiles) {
                tile.attach(this.query(`div.column-${Helpers.toKebabCase(column.name)} div.tiles`));
            }
        }
    }

    public onDetach() {
        // Detach all tiles
        for (let column of this.columns) {
            for (let tile of column.tiles) {
                tile.detach();
            }
        }
    }
}
