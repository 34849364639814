import { MapLayerOptions } from "./types";
import { LngLatBounds } from "mapbox-gl";
import { Log } from "../../../hiyo/log";
import { Context } from "../../../hiyo/context";
import { HiyoObject } from "../../../hiyo/hiyo-object";
import { FeatureCollection } from "geojson";
import { BasicMap } from "./basic-map";

export const ZOOM_AREA_DETAIL = 15; // Where area view breaks to item view
export const ZOOM_LABEL = 16; // Where item labels with current values become visible
export const ZOOM_SEGMENT_LABEL = 19; // Where semgent labels with current values become visible
export const ZOOM_AREA = 17; // Where area boundary starts to be visible
export const TRANSITION_DURATION = 600; // Typical transition dratuin in ms
export const OCCUPANCY_CIRCLE_AREA = 0.6; // Area circle occupancy
export const OCCUPANCY_STROKE_AREA = 0.2; // Area stroke occupancy
export const OCCUPANCY_FILL_AREA = 0.4; // Area fill occupancy

export abstract class MapLayer<T extends Context = Context, U extends MapLayerOptions = MapLayerOptions> extends HiyoObject<T> {

    // Properties
    public options: U;
    public data: FeatureCollection;
    public timer: any;
    public map: BasicMap;

    // Event handling methods
    public onAdd(): void {};
    public onRemove(): void {};

    protected constructor(context: T, options: U) {
        super(context);

        this.context = context;
        this.options = options;
    }

    public getBounds(): LngLatBounds {
        if (this.data?.type != "FeatureCollection") {
            Log.w(`Could not get bounds of ${this.id}`);
            return;
        }

        // New empty bounds
        let bounds = new LngLatBounds();

        for (let feature of this.data.features) {
            // Point?
            if (feature.geometry.type == "Point") {
                bounds.extend([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
            }

            // LineString?
            if (feature.geometry.type == "LineString") {
                for (let coordinates of feature.geometry.coordinates) {
                    bounds.extend([coordinates[0], coordinates[1]]);
                }
            }

            // Polygon?
            if (feature.geometry.type == "Polygon") {
                for (let coordinates of feature.geometry.coordinates) {
                    for (let position of coordinates) {
                        bounds.extend([position[0], position[1]]);
                    }
                }
            }
        }

        return bounds;
    }

    public update(): void {
        // Tell map source has updated
        this.map?.setLayerData(this, this.data);
    }

    public async search(search: any): Promise<any> {
        // Assign new search parameters to options
        this.options.search = search;

        // Load new data
        this.data = await this.load();

        // Update
        this.update();
    }

    public abstract load(): Promise<FeatureCollection>;
}
