import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";
import { Feature, FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoHelpers } from "../../invipo-helpers";

export class EnergyConsumptionAreaCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "energy-consumption-area-circle",
                type: "circle",
                maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    //"circle-blur": 0.4,
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = this.context.data.getAreas({
            type: "EnergyConsumption,SmartBuilding"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Current consumption
            let consumption = await this.context.invipo.getQuery<number>("consumption-current", `area.id=${area.id}`);

            // Unknown last collection
            let color = "#aaaaaa";

            // Consumption is known?
            if (consumption != null) {
                // Consumption color from palette
                let range = Helpers.range(0, METRICS.energy.metering.consumption.colors.length - 1, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], consumption);
                color = METRICS.energy.metering.consumption.colors[Math.round(range)];
            }

            // Create feature
            let feature: Feature = {
                type: "Feature",
                geometry: area.geometry,
                properties: null
            };

            json.features.push({
                type: "Feature",
                properties: {
                    type: "AreaSpot",
                    tooltip: area.name,
                    areaId: area.id,
                    areaName: area.name,
                    color: color,
                    scale: Helpers.range(5, 10, 1000, 10000, InvipoHelpers.toArea(feature))
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }

}
