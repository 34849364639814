import "./ang-dashboard.scss";
import * as template from "./ang-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { Dashboard } from "../dashboard/dashboard";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";
import { TrafficIncidentTile } from "../traffic-incident-tile/traffic-incident-tile";
import { AngDashboardOptions } from "./types";

export class AngDashboard extends Dashboard<AngDashboardOptions> {

    constructor(context: InvipoContext, options?: AngDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "1",
                tiles: [
                    new TrafficVolumeTile(this.context, {
                        itemId: "663c72b76562b93093579dc9",
                        capactiy: 5 * 1800
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "663c72256562b93093579dc7",
                        capactiy: 5 * 1800
                    })
                ]
            },
            {
                name: "Violations",
                width: "2",
                tiles: [
                    new TrafficIncidentTile(this.context, {
                    })
                ]
            }
        ];
    }
}
