import "./garbage-subdomain.scss";
import * as template from "./garbage-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { GarbageSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { GarbageLevelLabelLayer } from "../../../layers/waste/garbage-level-label-layer";
import { GarbageTemperatureLabelLayer } from "../../../layers/waste/garbage-temperature-label-layer";
import { GarbageLevelCircleLayer } from "../../../layers/waste/garbage-level-circle-layer";
import { GarbageTemperatureCircleLayer } from "../../../layers/waste/garbage-temperature-circle-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";

export class GarbageSubdomain extends CitySubdomain<GarbageSubdomainOptions> {

    constructor(context: InvipoContext, options?: GarbageSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Level",
                    itemClass: "GarbageBin",
                    layers: [
                        new GarbageLevelCircleLayer(context),
                        new GarbageLevelLabelLayer(context)
                    ]
                },
                {
                    name: "Temperature",
                    itemClass: "GarbageBin",
                    layers: [
                        new GarbageTemperatureCircleLayer(context),
                        new GarbageTemperatureLabelLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.bins",
            subtitle: "components.GarbageSubdomain.bins",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Metrics name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        // Create legend
        this.legend = {
            description: `components.GarbageSubdomain.${metrics}Description`,
            range: {
                range: METRICS.waste.garbage[metrics].range,
                colors: METRICS.waste.garbage[metrics].colors,
                unit: METRICS.waste.garbage[metrics].unit,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate average
        for (let item of this.items) {
            // No data?
            if (!item.data?.garbage || !item.data.garbage[metrics]) {
                continue;
            }

            this.legend.range.value += item.data.garbage[metrics];
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.waste.garbage[metrics].range[0], METRICS.waste.garbage[metrics].range[1], this.legend.range.value);
    }
}
