import { FeatureCollection } from "geojson";
import { AreaLayer } from "./area-layer";
import { InvipoHelpers } from "../invipo-helpers";

export abstract class AreaSpotLayer extends AreaLayer {

    // Load is override because we use point instead of polygon
    public async load(): Promise<any> {
        let areas = this.context.data.getAreas({
            type: this.options.areaType
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            let bounds = InvipoHelpers.toBounds(area.geometry);

            json.features.push({
                type: "Feature",
                properties: {
                    type: "AreaSpot",
                    areaId: area.id,
                    areaName: area.name,
                    tooltip: this.options.tooltip,
                    card: this.options.card,
                    detail: this.options.detail,
                    pointer: this.options.pointer
                },
                geometry: {
                    type: "Point",
                    coordinates: bounds.getCenter().toArray()
                }
            })
        }

        return json;
    }

}
