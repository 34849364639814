import { ContentView } from "./content-view";

export class Extravilan extends ContentView {

    public onCreate(): void {
        // Set default content
        this.setContent("CityMap");
    }

}
