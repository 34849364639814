
import * as signalGroupAdditionalGreenArrowLight from "./signal-group-additional-green-arrow-light.hbs"
import * as signalGroupAdditionalGreenArrowStraightRight from "./signal-group-additional-green-arrow-straight-right.hbs"
import * as signalGroupAlternatelyIntermittentRedLights from "./signal-group-alternately-intermittent-red-lights.hbs"
import * as signalGroupCarTrafficLight from "./signal-group-car-traffic-light.hbs"
import * as signalGroupCarTrafficLightsLeft from "./signal-group-car-traffic-lights-left.hbs"
import * as signalGroupCarTrafficLightsRight from "./signal-group-car-traffic-lights-right.hbs"
import * as signalGroupCarTrafficLightsStraight from "./signal-group-car-traffic-lights-straight.hbs"
import * as signalGroupCarTrafficLightsStraightLeft from "./signal-group-car-traffic-lights-straight-left.hbs"
import * as signalGroupCarTrafficLightsStraightRight from "./signal-group-car-traffic-lights-straight-right.hbs"
import * as signalGroupCrossExitSignalLight from "./signal-group-cross-exit-signal-light.hbs"
import * as signalGroupCycleLights from "./signal-group-cycle-lights.hbs"
import * as signalGroupCyclePedestrianLights from "./signal-group-cycle-pedestrian-lights.hbs"
import * as signalGroupFlashingYellowLight from "./signal-group-flashing-yellow-light.hbs"
import * as signalGroupPedestrian from "./signal-group-pedestrian.hbs"
import * as signalGroupRailCrossingLights from "./signal-group-rail-crossing-lights.hbs"
import * as signalGroupTramLights from "./signal-group-tram-lights.hbs"
import * as signalGroupYellowPedestrian from "./signal-group-yellow-pedestrian.hbs"
import * as signalGroupYellowPedestrianLights from "./signal-group-yellow-pedestrian-lights.hbs"
import * as trafficDetector from "./traffic-detector.hbs"

import { Templates } from "hiyo/templates";

export namespace TrafficControlElements {

    export function registerAll(): void {
        Templates.registerPartial("signal-group-additional-green-arrow-light", signalGroupAdditionalGreenArrowLight)
        Templates.registerPartial("signal-group-additional-green-arrow-straight-right", signalGroupAdditionalGreenArrowStraightRight)
        Templates.registerPartial("signal-group-alternately-intermittent-red-lights", signalGroupAlternatelyIntermittentRedLights)
        Templates.registerPartial("signal-group-car-traffic-light", signalGroupCarTrafficLight)
        Templates.registerPartial("signal-group-car-traffic-lights-left", signalGroupCarTrafficLightsLeft)
        Templates.registerPartial("signal-group-car-traffic-lights-right", signalGroupCarTrafficLightsRight)
        Templates.registerPartial("signal-group-car-traffic-lights-straight", signalGroupCarTrafficLightsStraight)
        Templates.registerPartial("signal-group-car-traffic-lights-straight-left", signalGroupCarTrafficLightsStraightLeft)
        Templates.registerPartial("signal-group-car-traffic-lights-straight-right", signalGroupCarTrafficLightsStraightRight)
        Templates.registerPartial("signal-group-cross-exit-signal-light", signalGroupCrossExitSignalLight)
        Templates.registerPartial("signal-group-cycle-lights", signalGroupCycleLights)
        Templates.registerPartial("signal-group-cycle-pedestrian-lights", signalGroupCyclePedestrianLights)
        Templates.registerPartial("signal-group-flashing-yellowLight", signalGroupFlashingYellowLight)
        Templates.registerPartial("signal-group-pedestrian", signalGroupPedestrian)
        Templates.registerPartial("signal-group-rail-crossing-lights", signalGroupRailCrossingLights)
        Templates.registerPartial("signal-group-tram-lights", signalGroupTramLights)
        Templates.registerPartial("signal-group-yellow-pedestrian", signalGroupYellowPedestrian)
        Templates.registerPartial("signal-group-yellow-pedestrian-lights", signalGroupYellowPedestrianLights)
        Templates.registerPartial("traffic-detector", trafficDetector)

    }

}
