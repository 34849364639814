import "./warning-light-card.scss";
import * as template from "./warning-light-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { WarningLightCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class WarningLightCard extends CityCard<WarningLightCardOptions> {

    constructor(context: InvipoContext, options: WarningLightCardOptions) {
        super(context, template, options);
    }

}
