import { MenuItem } from "muklit/components/overflow-menu/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { Select } from "muklit/components/select/select";
import { ViolationBrowser } from "../violation-browser/violation-browser";
import { BasicViolationBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Helpers } from "hiyo/helpers";
import { RangeInput } from "muklit/components/range-input/range-input";

export const CLASS_NAMES = "SvdSystem,RlvdSystem,WimStation";

export class BasicViolationBrowser extends ViolationBrowser<BasicViolationBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data?item.class=${CLASS_NAMES}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.BasicViolationBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "ViolationsData",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.ViolationType"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                })
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 200,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        align: "Right",
                        ellipsis: true,
                        formatter: (value: any, data: any) => {
                            // Speed?
                            if (data.extras?.speed) {
                                return `<div class="cell cell-right">${Helpers.toNumber(data.extras.speed)} ${this.context.locale.getMessage("units.kmph")}</div>`
                            }
                        },
                        label: "tables.columns.value",
                        name: "value",
                        property: "extras",
                        sortable: true,
                        type: "String",
                        width: 120
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }
}
