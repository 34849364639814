import "./event-form.scss";
import * as template from "./event-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { EventFormOptions } from "./types";
import { TextArea } from "muklit/components/text-area/text-area";

export class EventForm extends Detail<InvipoContext, EventFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: EventFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // Default notification form
        this.form = new Form(this.context, {
            style: "Dark",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextArea(this.context, {
                            style: "Dark",
                            name: "text",
                            label: "forms.fields.text",
                            messageText: "components.EventForm.message",
                            width: 600,
                            rows: 24,
                            required: true,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            // Register event
            await this.context.invipo.createEvent(JSON.parse(data.text));
        }
        catch (e) {
            // JSON parsing error?
            if (e.message) {
                this.form.setValidationErrors([
                    {
                        field: "text",
                        message: e.message
                    }
                ]);
                return;
            }

            // Server error?
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

}
