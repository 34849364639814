import "./violation-browser.scss";
import * as template from "./violation-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ViolationBrowserOptions } from "./types";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { ViolationDetail } from "../violation-detail/violation-detail";
import { ViolationDetailOptions } from "../violation-detail/types";
import { MenuItem } from "muklit/components/overflow-menu/types";

export abstract class ViolationBrowser<T extends ViolationBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public table: FilterTable;
    public detail: any;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    protected async openDetail(data: any, title?: string): Promise<void> {
        // Detail options
        let options: ViolationDetailOptions = {
            style: "Light",
            violationId: data.id,
            title: title || "components.ViolationDetail.title",
            closable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Relaod completely
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new ViolationDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = async (item: MenuItem)=> {
            // Archive action?
            if (item.name == "Archive") {
                await this.detail.archive();
            }

            // Delete action?
            if (item.name == "Delete") {
                this.detail.delete();
            }
        }

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.violationId);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "ViolationsCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
