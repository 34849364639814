import "./city-subdomain.scss";
import { CitySubdomainOptions, SubdomainLegend, SubdomainMetrics } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { Templates } from "hiyo/templates";
import * as header from "./city-subdomain.header.hbs";
import * as metrics from "./city-subdomain.metrics.hbs";
import * as legend from "./city-subdomain.legend.hbs";
import { CityList } from "../city-list/city-list";

export abstract class CitySubdomain<T extends CitySubdomainOptions = CitySubdomainOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public items: InvipoItem[];
    public areas: InvipoArea[];
    public metrics: SubdomainMetrics; // Selected metrics
    public legend: SubdomainLegend;
    public timer: any;

    // Event handling methods
    public onSelfSelect(): void {}
    public onMetricsSelect(metrics: SubdomainMetrics): void {}
    public onListCreate(list: CityList, metrics?: SubdomainMetrics): void {}

    protected constructor(context: InvipoContext, template: any, options: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("city-subdomain-header", header);
        Templates.registerPartial("city-subdomain-metrics", metrics);
        Templates.registerPartial("city-subdomain-legend", legend);

        // Autoselect first metrics if not selected in options
        if (!this.options.metrics.find(x => x.selected)) {
            this.metrics = this.options.metrics[0];
            this.metrics.selected = true;
        }
    }

    public selectSelf(): void {
        // Already selected?
        if (this.options.selected) {
            return
        }

        // Select or deselectg self based on multiselect
        this.options.selected = true;

        // Redraw
        this.update();

        // OnSelect handler
        this.onSelfSelect();
    }

    public async selectMetrics(i: number): Promise<void> {
        // Already selected?
        if (this.options.metrics[i].selected) {
            return;
        }

        // Unselect all
        this.options.metrics.forEach(x => x.selected = false);

        // Selected new  metrics
        this.metrics = this.options.metrics[i];
        this.metrics.selected = true;

        // Reload
        await this.load();

        // OnMetricsSelect handler
        this.onMetricsSelect(this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Clear timeout to prevent multiple calls
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // Load items
        if (this.metrics.itemClass) {
            this.items = this.context.data.getItems({
                class: this.metrics.itemClass,
                sort: "name:asc"
            });
        }

        // Load areas
        if (this.metrics.areaType) {
            this.areas = this.context.data.getAreas({
                type: this.metrics.areaType,
                sort: "name:asc"
            });
        }

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();

        // Autorefresh
        this.timer = setTimeout(async () => {
            if (this.isAttached()) {
                await this.load();
            }
        }, 60 * 1000);
    }
}
