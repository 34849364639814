import "./ulm-dashboard.scss";
import * as template from "./ulm-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { UlmDashboardOptions } from "./types";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { Dashboard } from "../dashboard/dashboard";

export class UlmDashboard extends Dashboard<UlmDashboardOptions> {

    constructor(context: InvipoContext, options?: UlmDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficVolumeTile(this.context, {
                        areaId: "611fade007856f7e5faa33ab",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "632da0e42e5ade0fb05e6d28",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "641475e3dfb8f52cf7d5d791",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "64147711dfb8f52cf7d5d792",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "642e9f4fcbde5f4814d9de37",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "644a3f3e9c61c37ff597056b",
                        capactiy: 3600
                    }),
                    new TrafficVolumeTile(this.context, {
                        areaId: "64d289604b43f2673e37591b",
                        capactiy: 3600
                    })
                ]
            }
        ];
    }
}
