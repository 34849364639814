import "./ticket-detail.scss";
import * as template from "./ticket-detail.hbs";
import { Actor, Ticket, User } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { Dialog } from "muklit/components/dialog/dialog";
import { Toggle } from "muklit/components/toggle/toggle";
import { TicketDetailOptions } from "./types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { TicketCommentForm } from "../ticket-comment-form/ticket-comment-form";
import { AccessDetailOptions } from "../../safety/access-detail/types";
import { AccessDetail } from "../../safety/access-detail/access-detail";
import { promises } from "dns";

export class TicketDetail extends Detail<InvipoContext, TicketDetailOptions> {

    // Properties
    public ticket: Ticket;

    // Components
    public tabs: Tabs;
    public completed: Toggle;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: TicketDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createDisabled();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.completed, "completed");
    }

    private createTabs(): void {
        // Tabs
        let tabs: TabsItem[] = [
            {
                name: "Ticket",
                label: "components.TicketDetail.ticket",
                content: "div.content-ticket",
                selected: true
            },
            /*{
                name: "Attachments",
                label: "components.TicketDetail.attachments",
                content: "div.content-attachments"
            },*/
        ];

        // Add data specific content
        if (this.options.ticketType == "Offense") {
            tabs.push({
                name: "Offense",
                label: "components.TicketDetail.offense",
                content: "div.content-offense"
            })
        }

        if (this.options.ticketType == "Task") {
            tabs.push({
                name: "Data",
                label: "components.TicketDetail.data",
                content: "div.content-data"
            })
        }

        // Add comments as last tab
        tabs.push(
            {
                name: "Stream",
                label: "components.TicketDetail.stream",
                content: "div.content-stream"
            }
        )

        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: tabs
        });
    }

    private createDisabled(): void {
        // Crate component
        this.completed = new Toggle(this.context, {
            style: "Light",
            size: "Small",
            name: "disabled",
            label: "components.TicketDetail.completed"
        })

        // Disable user
        this.completed.onToggle = async (disabled: boolean) => {
            await this.complete();
        }
    }

    public selectColumn(e: MouseEvent): void {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        // Push columns as items
        for (let column of this.context.config.tickets.columns) {
            menu.options.items.push(
                {
                    name: column,
                    label: column,
                    disabled: this.ticket.column == column
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.updateTicketColumn(this.ticket.id, {
                column: item.label
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public async selectAssignee(e: MouseEvent): Promise<void> {
        // Create component
        let menu = new OverflowMenu(this.context, {
            style: "Light",
            start: "TopRight",
            anchor: "TopRight",
            items: []
        });

        //
        let users = await this.context.invipo.getDataset("users");

        // Push languages as items
        for (let user of users.data) {
            menu.options.items.push(
                {
                    name: user.id,
                    label: user.name,
                    disabled: this.ticket.assignee?.id == user.id
                }
            )
        }

        // Change language
        menu.onSelect = async (item: MenuItem): Promise<void> => {
            // Show loader
            this.showLoader();

            // Update column
            await this.context.invipo.updateTicketAssignee(this.ticket.id, {
                assignee: item.name
            });

            // Hide loader and redraw
            this.hideLoader();
            await this.load();

            // OnUpdate handler
            this.onDetailUpdate();
        }

        // Show menu
        menu.show(<HTMLElement>e.target);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public async complete(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        await this.context.invipo.updateTicketCompleted(this.options.ticketId, {
            result: "Uzavřeno"
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public async archive(): Promise<void> {
        // Delete call
        await this.context.invipo.putManagement(`tickets/${this.ticket.id}/workflow`, {
            workflow: {
                archived: true
            }
        });

        // Close detail
        this.close();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.TicketDetail.userDelete",
            text: this.context.locale.getMessage("components.TicketDetail.reallyDelete", this.ticket.title),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteTicket(this.ticket.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    protected access(i: number): void {
        // Hide self
        this.detach();

        // Access data
        let data = this.ticket.data.access[i];

        // New detail
        let detail = new AccessDetail(this.context, {
            style: "Light",
            access: data,
            title: "components.AccessDetail.title",
            subtitle: data.item?.name || data.area?.name,
            printable: false,
            closable: true
        });

        // Show self on close
        detail.onClose = () => {
            this.attach();
        }

        // Show detail
        detail.attach();
    }

    public comment(): void {
        // Dialog to confirm
        let form = new TicketCommentForm(this.context, {
            style: "Light",
            title: "components.TicketCommentForm.title",
            ticket: this.ticket,
            overlay: true,
            closable: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.ticket = await this.context.invipo.getTicket(this.options.ticketId);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);

        // Manage child components
        this.completed.setValue(this.ticket.completed);
    }

}
