import { Templates } from "hiyo/templates";
import { ItemSelect } from "../../common/item-select/item-select";
import { VehicleSelect } from "../../common/vehicle-select/vehicle-select";
import { WimVehicleBrowserOptions } from "./types";
import { VehicleBrowser } from "../vehicle-browser/vehicle-browser";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterItem } from "muklit/components/filter/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Helpers } from "hiyo/helpers";
import { TextInput } from "muklit/components/text-input/text-input";
import { HiyoEvent } from "../../../../hiyo/event-broker";

export class WimVehicleBrowser extends VehicleBrowser<WimVehicleBrowserOptions> {

    public onCreate(): void {
        // Call self
        super.onCreate();

        // Route handling
        this.context.broker.subscribe(this, ["RouteChanged"]);
    }

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/vehicles-data`,
            search: {},
            http: this.context.invipo.http,
            filter: {
                title: "components.WimVehicleBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.from ? { from: this.context.application.state.from, to: this.context.application.state.to } : null,
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                value: this.context.application.state.componentName == this.name && this.context.application.state.itemId || null,
                                placeholderText: "forms.placeholders.all",
                                distinct: "VehiclesData",
                                items: [],
                                multiselect: true
                            }),
                            new VehicleSelect(this.context, {
                                style: "Light",
                                type: "Lane",
                                name: "lane",
                                label: "forms.fields.lane",
                                placeholderText: "forms.placeholders.all",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "classification.category",
                                label: "forms.fields.category",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toCategoryItems(this.context.config.categories),
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            /*new TextInput(this.context, {
                                style: "Light",
                                name: "referenceId",
                                label: "forms.fields.referenceId",
                                placeholderText: "forms.placeholders.referenceId"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "measurement.speed.min",
                                label: "forms.fields.speedMin",
                                placeholderText: "forms.placeholders.speedMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "measurement.speed.max",
                                label: "forms.fields.speedMax",
                                placeholderText: "forms.placeholders.speedMax"
                            })*/
                        ]
                    }
                ]
            },
            pagination: {
              page: 1
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "plate.number",
                        type: "String",
                        property: "plate.number",
                        label: "tables.columns.plate",
                        formatter: (value: any, data: any): string => {
                            if (!value) {
                                return;
                            }
                            return `<div class="cell">${Templates.renderPartial("plate-number", data.plate)}</div>`;
                        },
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "measurement.speed",
                        type: "Number",
                        property: "measurement.speed",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} km/h</div>`
                        },
                        label: "tables.columns.speed",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "measurement.weight",
                        type: "Number",
                        property: "measurement.weight",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} kg</div>`
                        },
                        label: "tables.columns.weight",
                        width: 100,
                        sortable: true
                    },
                    {
                        name: "lane",
                        type: "String",
                        property: "lane",
                        label: "tables.columns.lane",
                        width: 140,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "classification.category",
                        type: "Number",
                        property: "classification.category",
                        formatter: (value: any, data: any): string => {
                            if (value == null) {
                                return null;
                            }
                            return `<div class="cell cell-ellipsis">${this.context.config.categories.find(x => x.id == value)?.name}</div>`;
                        },
                        label: "tables.columns.category",
                        width: 140,
                        sortable: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public async onHandle(event: HiyoEvent) {
        // Routing is for me?
        if (event.payload?.componentName == this.name) {
            // Set interval
            this.table.form.setValue("interval", {
                from: event.payload.from,
                to: event.payload.to
            });

            // Set item id
            this.table.form.setValue("item.id", event.payload.itemId);

            // Reload table data
            await this.table.load();
        }
    }
}
