import "./sign-info-input.scss";
import * as template from "./sign-info-input.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { Input } from "muklit/components/input/input";
import { SignInfoInputOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { SignInfoLineInput } from "../sign-info-line-input/sign-info-line-input";
import { IvsSignInfo, IvsSignInfoSign } from "../../cits-manager/types";
import { InvipoHelpers } from "invipo/invipo-helpers";

export class SignInfoInput extends Input<InvipoContext, SignInfoInputOptions> {
    public type: Select;
    public lines: SignInfoLineInput[] = []

    public onSubmit(): void {};

    constructor(context: InvipoContext, options: SignInfoInputOptions) {
        super(context, template, options);

        if (!this.options.value) {
            this.options.value = {
                type: undefined,
                signs: undefined
            };
        }

        this.createType();

        this.registerComponent(this.type, "type")
    }

    public onAttach() {
        this.createLines();
    }

    public update () { }

    public setValue(value: IvsSignInfo): void {
        this.options.value = value ?? { type: undefined, signs: undefined };

        this.type.setValue(value.type);

        this.lines.forEach(x => x.detach())
        this.lines = [];
        this.createLines();
    }

    public createLines () {
        if (!this.options.value?.signs?.length) {
            this.addLine();
        }

        for (let i = 0; i < this.options.value?.signs?.length; i++) {
            const sign = this.options.value.signs[i]

            this.addLine(sign);
        }
    }

    public createType () {
        this.type = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "positionType",
            label: "components.MessageForm.type",
            multiselect: false,
            width: 186 + 186 + 24 + 12,
            value: this.options.value?.type,
            required: true,
            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.MessageForm.types"))
        });

        this.type.onSubmit = () => {
            this.options.value.type = <any>Object.keys(this.type.options.value)[0];
        };
    }

    public addLine (sign?: IvsSignInfoSign) {
        if (this.lines.length === 1) {
            this.lines[0].options.removable = true;
            this.lines[0].invalidate(true);
        }

        const line = new SignInfoLineInput(this.context, {
            name: "",
            value: sign,
            removable: this.lines.length > 0
        });
        this.lines.push(line);

        line.onRemove = () => {
            this.lines = this.lines.filter(x => x.id !== line.id);

            if (this.lines.length === 1) {
                this.lines[0].options.removable = false;
                this.lines[0].invalidate(true);
            }

            this.options.value.signs = this.lines.map(x => x.options.value);
        }

        line.onSubmit = () => {
            this.options.value.signs = this.lines.map(x => x.options.value);
        }

        line.onKey = () => {
            this.options.value.signs = this.lines.map(x => x.options.value);
        }

        line.attach(this.query("div.lines"));
    }

    public setDisabled(disabled: boolean) {
        // No change?
        if (this.options.disabled == disabled) {
            return;
        }

        this.options.disabled = disabled;

        // Update
        this.type.setDisabled(disabled);
        this.lines.forEach(x => x.setDisabled(disabled));
    }

    public validate(): string {
        let invalid = undefined

        // Validate IVS sign type
        var typeInvalid = this.type.validate();
        if (typeInvalid) {
            invalid = typeInvalid;
            this.type.options.invalid = true;
            this.type.options.messageText = typeInvalid;
        } else {
            this.type.options.invalid = false;
            this.type.options.messageText = undefined;
        }
        this.type.update();

        // validate IVS Sign lines
        for (const line of this.lines) {
            const lineInvalid = line.validate();
            if (!invalid) invalid = lineInvalid;
        }

        return invalid;
    }
}
