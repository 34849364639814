import "./traffic-detail-type-select.scss";
import * as template from "./traffic-detail-type-select.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { TrafficDetailTypeSelectOptions } from "./types";
import { TrafficControlElements } from "../traffic-detail-element/elements";

export class TrafficDetailTypeSelect extends MuklitComponent<InvipoContext, TrafficDetailTypeSelectOptions> {

    // Event handling methods
    public onSelect(type: string): void {}

    constructor(context: InvipoContext, options?: TrafficDetailTypeSelectOptions) {
        TrafficControlElements.registerAll();

        super(context, template, options);
    }

    public select (value: string) {
        // Update selected value
        this.options.values = undefined;
        this.options.value = value;

        // Redraw component
        this.update();

        // Notify other components
        this.onSelect(value);
    }
}
