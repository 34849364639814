import { MapLayer } from "muklit/components/basic-map/map-layer";
import { InvipoContext } from "../../context/invipo-context";

export class CuzkZtmLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            pointer: true,
            layer: {
                id: "cuzk-ztm",
                type: "raster",
                source: {
                    type: "raster",
                    "tiles": [
                        "https://ags.cuzk.cz/arcgis1/rest/services/ZTM_WM/MapServer/tile/{z}/{y}/{x}?blankTile=false"
                    ],
                    "tileSize": 256
                },
            }
        });
    }

    public async load(): Promise<any> {
        return null;
    }

}
