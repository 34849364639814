import "./registered-data-browser.scss";
import * as template from "./registered-data-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { RegisteredDataBrowserOptions } from "./types";
import { ItemSelect } from "../../common/item-select/item-select";
import { DataDetailOptions } from "../data-detail/types";
import { DataDetail } from "../data-detail/data-detail";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { TextInput } from "muklit/components/text-input/text-input";
import { RangeInput } from "muklit/components/range-input/range-input";

export class RegisteredDataBrowser extends MuklitComponent<InvipoContext, RegisteredDataBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: DataDetail;

    constructor(context: InvipoContext, options?: RegisteredDataBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Dark",
            url: `${this.context.options.host}/api/data/datasets/items-data-log`,
            http: this.context.invipo.http,
            filter: {
                title: "components.RegisteredDataBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "Reload"
                    }
                ]
            },
            form: {
                style: "Dark",
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Dark",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new TextInput(this.context, {
                                style: "Dark",
                                name: "type",
                                label: "forms.fields.type"
                            }),
                            new ItemSelect(this.context, {
                                style: "Dark",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Dark",
                                name: "item.class",
                                label: "item.class",
                                placeholderText: "forms.placeholders.all",
                                multiselect: false,
                                items: InvipoHelpers.toClassItems(this.context.options.classes, (a, b) => {
                                    return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
                                })
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Dark",
                                collection: "Items",
                                name: "item.model",
                                label: "item.model",
                                placeholderText: "forms.placeholders.model",
                                items: [],
                                type: "Model"
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Dark",
                                collection: "Items",
                                name: "item.producer",
                                label: "item.producer",
                                placeholderText: "forms.placeholders.producer",
                                items: [],
                                type: "Producer"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Dark",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: "type",
                        label: "tables.columns.type",
                        width: 140,
                        ellipsis: true,
                        sortable: true,
                    },
                    {
                        name: "name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 320,
                        ellipsis: true,
                        sortable: true
                    },
                    {
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.item.class}`);
                        },
                        label: "item.class",
                        ellipsis: true,
                        sortable: true,
                        width: 200
                    },
                    {
                        name: "model",
                        type: "String",
                        property: "item.model",
                        label: "item.model",
                        ellipsis: true,
                        sortable: true,
                        minWidth: 200,
                    },
                ],
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: MenuItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Dark",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "ItemsRawJson",
                    label: "components.ServerExportForm.types.Json",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: DataDetailOptions = {
            style: "Dark",
            title: data.type,
            subtitle: data.username,
            data: data,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Redraw component
            await this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new DataDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.data.id);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }
}
