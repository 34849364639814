import './traffic-message-list.scss';
import * as template from "./traffic-message-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficMessageListOptions } from "./types";
import { CityList } from "../../city/city-list/city-list";
import { MessageForm } from "../../management/message-form/message-form";

export class TrafficMessageList extends CityList<TrafficMessageListOptions> {

    // Properties
    public messages: any[];

    constructor(context: InvipoContext, options?: TrafficMessageListOptions) {
        super(context, template, options);
    }

    public selectMessage(i: number): void {
        // Get item
        let message = this.messages[i];

        // Call handler
        this.onPositionNavigate((message.position.type == "LineString") ? message.position.coordinates[0] : message.position.coordinates);
    }

    public selectAction(): void {
        // Message form
        const form = new MessageForm(this.context, {
            title: "components.MessageForm.title",
            style: "Light",
            overlay: true,
            closable: true
        });

        // Attach form
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.messages = await this.context.invipo.getQuery("traffic-messages-current");
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.messages) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
