import "./monitoring-card.scss";
import * as template from "./monitoring-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MonitoringCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";
import { CardHistoryValue } from "../../city/city-card/types";
import { METRICS } from "../../city/city-subdomain/types";

export class MonitoringCard extends CityCard<MonitoringCardOptions> {

    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: MonitoringCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load item history for last 48 minutes
        let to = new Date(new Date().setSeconds(0, 0));
        let from = new Date(to.getTime() - 48 * 60 * 1000);
        let data  = await this.context.invipo.getDataset("items-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate history data
        this.history = [];

        for (let d of data.data) {
            this.history.push({
                timestamp: new Date(d.timestamp).toISOString(),
                value: `monitoringStatus.${d.item.monitoringStatus?.status}`,
                percent: 100,
                color: METRICS.infrastructure.technology.status.colors[METRICS.infrastructure.technology.status.range.indexOf(d.item.monitoringStatus?.status)]
            });
        }
    }
}
