import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA, ZOOM_LABEL } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { InvipoHelpers } from "../../invipo-helpers";
import { Helpers } from "../../../hiyo/helpers";

export class AccessAuthorizationAreaLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "AccessCard",
            layer: {
                id: "access-authorization-area-label",
                type: "symbol",
                minzoom: ZOOM_LABEL - 5,
                //maxzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": ["get", "color"],
                    "text-opacity": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = this.context.data.getAreas({
            type: "Access"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Last 24 hours
        let from = new Date(Date.now() - 86400000);

        // Push features from items
        for (let area of areas) {
            // Access counts
            let access = await this.context.invipo.getQuery<any>("access-count", `from=${from.toISOString()}&area.id=${area.id}`);

            json.features.push({
                type: "Feature",
                properties: {
                    color: "#161616",
                    label: `${Helpers.toNumber(access.authorization.denied / (access.count || 1) * 100)} %`
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }
}
