import "./stats-detail.scss";
import * as template from "./stats-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { StatsChart } from "../stats-chart/stats-chart";
import { Detail } from "muklit/components/detail/detail";
import { StatsDetailOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";

export abstract class StatsDetail<T extends StatsDetailOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public form: Form;
    public chart: StatsChart;

    public constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createChart();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.chart, "chart");
    }

    public abstract createForm(): void;

    public abstract createChart(): void;

    public toggleForm(): void {
        let icon = this.query("div.partial-icon-filter");
        let form = this.query("div.form");
        let toggled = form.classList.contains("form-toggled");

        // Toggle form and icon
        form.classList.toggle("form-toggled", !toggled);
    }
}
