import { PickerGroup } from "invipo/components/common/picker-menu/types";
import { DetailOptions } from "muklit/components/detail/types";
import { TrafficMessage } from "../cits-manager/types";

export interface MessageFormOptions extends DetailOptions {
    message?: TrafficMessage;
}

export const messageFormMenuGroups: PickerGroup[] = [
    {
        name: "RWW",
        title: "enums.TrafficMessageType.RWW",
        items: [
            {
                name: "RWW-LC",
                label: "enums.TrafficMessageSubtype.RWW-LC",
                description: "components.MessageForm.SubtypeDescription.RWW-LC",
                icon: "RoadWork"
            },
            {
                name: "RWW-RC",
                label: "enums.TrafficMessageSubtype.RWW-RC",
                description: "components.MessageForm.SubtypeDescription.RWW-RC",
                icon: "RoadWork"
            },
            {
                name: "RWW-WM",
                label: "enums.TrafficMessageSubtype.RWW-WM",
                description: "components.MessageForm.SubtypeDescription.RWW-WM",
                icon: "RoadWork"
            },
            {
                name: "RWW-ROVI",
                label: "enums.TrafficMessageSubtype.RWW-ROVI",
                description: "components.MessageForm.SubtypeDescription.RWW-ROVI",
                icon: "RoadWork"
            },
            {
                name: "RWW-RM",
                label: "enums.TrafficMessageSubtype.RWW-RM",
                description: "components.MessageForm.SubtypeDescription.RWW-RM",
                icon: "RoadWork"
            }
        ]
    },
    {
        name: "HLN",
        title: "enums.TrafficMessageType.HLN",
        items: [
            {
                name: "HLN-EVA",
                label: "enums.TrafficMessageSubtype.HLN-EVA",
                description: "components.MessageForm.SubtypeDescription.HLN-EVA",
                icon: "RoadHazard"
            },
            {
                name: "HLN-AZ",
                label: "enums.TrafficMessageSubtype.HLN-AZ",
                description: "components.MessageForm.SubtypeDescription.HLN-AZ",
                icon: "RoadHazard"
            },
            {
                name: "HLN-TJA",
                label: "enums.TrafficMessageSubtype.HLN-TJA",
                description: "components.MessageForm.SubtypeDescription.HLN-TJA",
                icon: "RoadHazard"
            },
            {
                name: "HLN-SV",
                label: "enums.TrafficMessageSubtype.HLN-SV",
                description: "components.MessageForm.SubtypeDescription.HLN-SV",
                icon: "RoadHazard"
            },
            {
                name: "HLN-WCW",
                label: "enums.TrafficMessageSubtype.HLN-WCW",
                description: "components.MessageForm.SubtypeDescription.HLN-WCW",
                icon: "RoadHazard"
            },
            {
                name: "HLN-TSR",
                label: "enums.TrafficMessageSubtype.HLN-TSR",
                description: "components.MessageForm.SubtypeDescription.HLN-TSR",
                icon: "RoadHazard"
            },
            {
                name: "HLN-APR",
                label: "enums.TrafficMessageSubtype.HLN-APR",
                description: "components.MessageForm.SubtypeDescription.HLN-APR",
                icon: "RoadHazard"
            },
            {
                name: "HLN-OR",
                label: "enums.TrafficMessageSubtype.HLN-OR",
                description: "components.MessageForm.SubtypeDescription.HLN-OR",
                icon: "RoadHazard"
            },
            {
                name: "HLN-EVI",
                label: "enums.TrafficMessageSubtype.HLN-EVI",
                description: "components.MessageForm.SubtypeDescription.HLN-EVI",
                icon: "RoadHazard"
            },
            {
                name: "HLN-RLX",
                label: "enums.TrafficMessageSubtype.HLN-RLX",
                description: "components.MessageForm.SubtypeDescription.HLN-RLX",
                icon: "RoadHazard"
            },
            {
                name: "HLN-UBR",
                label: "enums.TrafficMessageSubtype.HLN-UBR",
                description: "components.MessageForm.SubtypeDescription.HLN-UBR",
                icon: "RoadHazard"
            },
            {
                name: "HLN-AWWD",
                label: "enums.TrafficMessageSubtype.HLN-AWWD",
                description: "components.MessageForm.SubtypeDescription.HLN-AWWD",
                icon: "RoadHazard"
            },
            {
                name: "HLN-PTVC",
                label: "enums.TrafficMessageSubtype.HLN-PTVC",
                description: "components.MessageForm.SubtypeDescription.HLN-PTVC",
                icon: "RoadHazard"
            },
            {
                name: "HLN-PTVS",
                label: "enums.TrafficMessageSubtype.HLN-PTVS",
                description: "components.MessageForm.SubtypeDescription.HLN-PTVS",
                icon: "RoadHazard"
            }
        ]
    },
    {
        name: "IVS",
        title: "enums.TrafficMessageType.IVS",
        items: [
            {
                name: "IVS-FT",
                label: "enums.TrafficMessageSubtype.IVS-FT",
                description: "components.MessageForm.SubtypeDescription.IVS-FT",
                icon: "Access",
            },
            {
                name: "IVS-TS",
                label: "enums.TrafficMessageSubtype.IVS-TS",
                description: "components.MessageForm.SubtypeDescription.IVS-TS",
                icon: "Access",
            }
        ]
    }
]