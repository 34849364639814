import "./area-map.scss";
import * as template from "./area-map.hbs";
import { Component } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapControl } from "../../common/map-control/map-control";
import { AreaMapOptions } from "./types";
import { InvipoArea } from "../../../clients/invipo-client/types";
import { GeoJsonProperties } from "geojson";
import { AreaTooltip } from "../area-tooltip/area-tooltip";
import { InvipoHelpers } from "../../../invipo-helpers";
import { AreaBoundaryLayer } from "../../../layers/infrastructure/area-boundary-layer";

export class AreaMap extends Component<InvipoContext, AreaMapOptions> {

    // Components
    public map: BasicMap;
    public control: MapControl;
    public tooltip: AreaTooltip;

    constructor(context: InvipoContext, options?: AreaMapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
        this.createControl();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
        this.registerComponent(this.control, "control");
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: this.context.options.home.zoom,
            minZoom: 5,
            maxZoom: 20
        });

        // Show tooltip
        this.map.onFeatureEnter = (layer: string, properties: GeoJsonProperties, e: MouseEvent) => {
            this.showTooltip(properties, e);
        }

        // Reposition tooltip
        this.map.onFeatureMove = (layer: string, properties: GeoJsonProperties, e: MouseEvent) => {
            this.tooltip?.setPosition(e.pageX, e.pageY);
        }

        // Hide tooltip
        this.map.onFeatureLeave = (layer: string) => {
            this.tooltip?.hide()
        }

        // Zoom arrangements
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }

        // Zoom arrangements
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }

        // Add map layers
        this.map.addLayer(new AreaBoundaryLayer(this.context));
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Light",
            styleItems: [
                {
                    name: "Light",
                    label: "enums.MapStyle.Light"
                },
                {
                    name: "Satellite",
                    label: "enums.MapStyle.Satellite"
                }
            ]
        });

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            this.map.fitAll();
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.context.options.home.center,
                zoom: this.context.options.home.zoom,
                duration: 3000
            })
        }

        // Change map style
        this.control.onStyleSelect = (name: string) => {
            this.map.setStyle(name);
        }
    }

    public navigateTo(area: InvipoArea): void {
        // No place to navigate?
        if (!area.geometry) {
            return;
        }

        // Just center area
        this.map.fitBounds(InvipoHelpers.toBounds(area.geometry), 1200)
    }

    public showTooltip(properties: GeoJsonProperties, e: MouseEvent): void {
        // Hide previous
        this.tooltip?.hide();

        // Create tooltip component based on properties
        this.tooltip = new AreaTooltip(this.context, {
            areaId: properties.areaId,
            areaName: properties.areaName,
            areaType: properties.areaType
        });

        // Show tooltip
        this.tooltip.show(e.pageX, e.pageY, 100);
    }
}
