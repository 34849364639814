import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { METRICS } from "../../components/city/city-subdomain/types";

export class ParkingLotBoundaryLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            layer: {
                id: "parking-lot-boundary",
                type: "line",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": ["get", "color"],
                    "line-width": 6,
                    "line-opacity": 0,
                    "line-offset": -3,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": OCCUPANCY_STROKE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "ParkingLot"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Area not defined?
            if (!item.geometry.area) {
                continue;
            }

            // Gemoetry and occupancy color
            let occupancy = item.data?.occupancy?.overall?.occupancy;
            let symbol = "Unknown";
            let color = "#aaaaaa";

            // Has data?
            if (occupancy != undefined) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }

                // Get color from scale
                color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];
            }

            json.features.push({
                type: "Feature",
                properties: {
                    color: color
                },
                geometry: item.geometry["area"],
            })
        }

        return json;
    }

}
