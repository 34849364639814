import "./item-passport-form.scss";
import * as template from "./item-passport-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Tabs } from "muklit/components/tabs/tabs";
import { ItemPassportFormOptions } from "./types";
import { InvipoItem, InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../clients/invipo-client/types";
import { DataTable } from "muklit/components/data-table/data-table";
import { DocumentForm } from "./document-form/document-form";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { StreamForm } from "./stream-form/stream-form";
import { PropertyForm } from "./property-form/property-form";
import { ScheduleForm } from "./schedule-form/schedule-form";

export class ItemPassportForm extends Detail<InvipoContext, ItemPassportFormOptions> {

    // Properties
    public item: InvipoItem;
    public documents: InvipoItemDocument[];
    public stream: InvipoItemStream[];
    public calendar: InvipoItemSchedule[];
    public properties: InvipoItemPassport[];

    // Components
    public tabs: Tabs;
    public documentsTable: DataTable;
    public streamTable: DataTable;
    public calendarTable: DataTable;
    public propertiesTable: DataTable;

    public constructor(context: InvipoContext, options: ItemPassportFormOptions) {
        super(context, template, options);
    }

    // Event handling methods
    public onSubmit(): void {
    }

    public onCreate(): void {
        // Create components
        this.createTabs()
        // this.createForm();
        this.createDocuments();
        this.createStream();
        this.createCalendar();
        this.createProperties();

        // Register components that will be always attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.documentsTable, "documents");
        this.registerComponent(this.streamTable, "stream");
        this.registerComponent(this.calendarTable, "calendar");
        this.registerComponent(this.propertiesTable, "properties");
    }

    public createDocuments() {
        // Create component
        this.documentsTable = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            menu: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "components.ItemPassportForm.name",
                    width: 200,
                    sortable: true,
                    ellipsis: true,
                },
                {
                    name: "url",
                    type: "String",
                    property: "url",
                    formatter: (value: any, data: any): any => {
                        return `<a href="${this.context.invipo.options.host}/download${data.url}" download="${data.filename}" target="_blank">${data.filename}</a>`
                    },
                    label: "components.ItemPassportForm.file",
                    ellipsis: true,
                    sortable: true,
                }
            ]
        });

        this.documentsTable.onMenuSelect = (row, trigger) => {
            const menu = new OverflowMenu(this.context, {
                items: [
                    {
                        name: "remove",
                        label: "components.ItemPassportForm.remove"
                    }
                ]
            });

            menu.show(trigger);

            menu.onSelect = async (item) => {
                this.showLoader();

                // Remove item
                await this.context.invipo.deleteItemDocument(this.item.id, row.id);

                // Reload data
                this.documents = await this.context.invipo.getItemDocuments(this.item.id);
                this.documentsTable?.setData(this.documents);

                this.hideLoader();
            }
        }
    }

    public createStream() {
        // Create component
        this.streamTable = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            autosort: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "components.ItemPassportForm.time",
                    width: 160,
                    sortable: true,
                    selected: true,
                    descendent: true
                },
                {
                    name: "actor",
                    type: "String",
                    property: "actor.name",
                    label: "components.ItemPassportForm.user",
                    width: 200,
                },
                {
                    name: "comment",
                    type: "String",
                    property: "comment",
                    label: "components.ItemPassportForm.comment",
                },
            ]
        });
    }

    public createCalendar() {
        // Create component
        this.calendarTable = new DataTable(this.context, {
            style: "Light",
            type: "SingleSelect",
            size: "Short",
            height: "100%",
            menu: true,
            autosort: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "components.ItemPassportForm.time",
                    width: 160,
                    sortable: true,
                    selected: true,
                    descendent: false
                },
                {
                    name: "actor",
                    type: "String",
                    property: "actor.name",
                    label: "components.ItemPassportForm.user",
                    width: 200,
                },
                {
                    name: "task",
                    type: "String",
                    property: "task",
                    label: "components.ItemPassportForm.task",
                },
            ]
        });

        this.calendarTable.onMenuSelect = (row, trigger) => {
            const menu = new OverflowMenu(this.context, {
                items: [
                    {
                        name: "remove",
                        label: "components.ItemPassportForm.remove"
                    }
                ]
            });

            menu.show(trigger);

            menu.onSelect = async (item) => {
                this.showLoader();

                // Remove item
                await this.context.invipo.deleteItemSchedule(this.item.id, row.id);

                // Reload data
                this.calendar = await this.context.invipo.getItemScheduler(this.item.id);
                this.calendarTable?.setData(this.calendar);

                this.hideLoader();
            }
        }
    }

    public createProperties() {
        // Create component
        this.propertiesTable = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            menu: true,
            rows: {
                id: "id"
            },
            columns: [
                {
                    name: "property",
                    type: "String",
                    property: "property",
                    label: "components.ItemPassportForm.key",
                    width: 200,
                    sortable: true,
                    ellipsis: true,
                    // selected: true
                },
                {
                    name: "value",
                    type: "String",
                    property: "value",
                    label: "components.ItemPassportForm.value",
                    ellipsis: true,
                    sortable: true,
                    // minWidth: 200
                }
            ]
        });

        this.propertiesTable.onMenuSelect = (row, trigger) => {
            const menu = new OverflowMenu(this.context, {
                items: [
                    {
                        name: "edit",
                        label: "components.ItemPassportForm.edit"
                    },
                    {
                        name: "remove",
                        label: "components.ItemPassportForm.remove"
                    }
                ]
            });

            menu.show(trigger);

            menu.onSelect = async (item) => {
                if (item.name == "remove") {
                    this.showLoader();

                    // Remove item
                    await this.context.invipo.deleteItemPassport(this.item.id, row.id);

                    // Reload data
                    this.properties = await this.context.invipo.getItemPassports(this.item.id);
                    this.propertiesTable?.setData(this.properties);

                    this.hideLoader();
                } else {
                    this.addProperty(row.data.property, row.data.value);
                }
            }
        }
    }

    public add() {
        switch (this.options.tab || "Properties") {
            case "Documents":
                this.addDocument();
                break;
            case "Stream":
                this.addStream();
                break;
            case "Calendar":
                this.addSchedule();
                break;
            case "Properties":
                this.addProperty();
                break;
        }
    }

    public addDocument() {
        // Detail form
        let form = new DocumentForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.document",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            this.showLoader();
            this.documents = await this.context.invipo.getItemDocuments(this.item.id);
            this.documentsTable?.setData(this.documents);
            this.hideLoader();
        }

        // Show form
        form.attach();
    }

    public addStream() {
        // Detail form
        let form = new StreamForm(this.context, {
            title: "components.ItemPassportForm.stream",
            // title: null,
            style: "Light",
            itemId: this.options.itemId,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            this.showLoader();
            this.stream = await this.context.invipo.getItemStream(this.item.id);
            this.streamTable?.setData(this.stream);
            this.hideLoader();
        }

        // Show form
        form.attach();
    }

    public addSchedule() {
        // Detail form
        let form = new ScheduleForm(this.context, {
            title: "components.ItemPassportForm.event",
            // title: null,
            style: "Light",
            itemId: this.options.itemId,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            this.showLoader();
            this.calendar = await this.context.invipo.getItemScheduler(this.item.id);
            this.calendarTable?.setData(this.calendar);
            this.hideLoader();
        }

        // Show form
        form.attach();
    }

    public addProperty(key?: string, value?: string) {
        // Detail form
        let form = new PropertyForm(this.context, {
            title: "components.ItemPassportForm.property",
            // title: null,
            style: "Light",
            overlay: true,
            itemId: this.options.itemId,
            key, value
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            this.showLoader();
            this.properties = await this.context.invipo.getItemPassports(this.item.id);
            this.propertiesTable?.setData(this.properties);
            this.hideLoader();
        }

        // Show form
        form.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.item = await this.context.invipo.getItem(this.options.itemId);
        this.documents = await this.context.invipo.getItemDocuments(this.item.id);
        this.properties = await this.context.invipo.getItemPassports(this.item.id);
        this.stream = await this.context.invipo.getItemStream(this.item.id);
        this.calendar = await this.context.invipo.getItemScheduler(this.item.id);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Set data from options to form
        this.documentsTable?.setData(this.documents);
        this.propertiesTable?.setData(this.properties);
        this.streamTable?.setData(this.stream);
        this.calendarTable?.setData(this.calendar);
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: this.options.style,
            tabs: [
                {
                    name: "Properties",
                    label: "components.ItemPassportForm.properties",
                    content: "div.content-properties",
                    selected: !this.options.tab || (this.options.tab == "Properties")
                },
                {
                    name: "Stream",
                    label: "components.ItemPassportForm.stream",
                    content: "div.content-stream",
                    selected: this.options.tab == "Stream"
                },
                {
                    name: "Calendar",
                    label: "components.ItemPassportForm.calendar",
                    content: "div.content-calendar",
                    selected: this.options.tab == "Calendar"
                },
                {
                    name: "Documents",
                    label: "components.ItemPassportForm.documents",
                    content: "div.content-documents",
                    selected: this.options.tab == "Documents"
                }
            ]
        });

        this.tabs.onSelect = (tab) => {
            this.options.tab = tab.name
        }
    }

}
