import "./garbage-browser.scss";
import * as template from "./garbage-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { TrafficDetail } from "../../traffic/traffic-detail/traffic-detail";
import { Helpers } from "hiyo/helpers";
import { ItemSelect } from "../../common/item-select/item-select";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { GarbageBrowserOptions } from "./types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class GarbageBrowser extends MuklitComponent<InvipoContext, GarbageBrowserOptions> {

    // Components
    public form: Form;
    public table: QueryTable;
    public detail: TrafficDetail;

    constructor(context: InvipoContext, options?: GarbageBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public createForm(): void {
        // Create item.id value object (id=name)
        let value: any = null;

        if (this.options.itemId) {
            value = {};
            value[this.options.itemId] = this.options.itemName;
        }

        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            time: true,
                            label: "forms.fields.date",
                            placeholderText: "forms.placeholders.anytime"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            value: value,
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            distinct: "GarbageData",
                            items: []
                        })
                    ]
                }
            ]
        });

        // Log data
        this.form.onSubmit = async (data: any) => {
            // Send new query to table
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/garbage-data`,
            http: this.context.invipo.http,
            search: this.form.getData(),
            bar: {
                title: "components.GarbageBrowser.title",
                reloadable: true,
                closable: this.options.closable,
                items: [
                    {
                        name: "Export",
                        label: "components.TitleBar.export"
                    }
                ]
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "Timestamp",
                        width: 160,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 320,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "temperature",
                        type: "Number",
                        property: "temperature",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 0, 0)} &deg; C</div>`
                        },
                        label: "tables.columns.temperature",
                        width: 120,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "level",
                        type: "Number",
                        property: "level",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-left">${Helpers.toNumber(value, 0, 0)} &percnt;</div>`
                        },
                        label: "tables.columns.level",
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onMenuSelect = (item: MenuItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: this.options.closable,
            items: [
                {
                    name: "GarbageCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
