import "./sign-info-line-input.scss";
import * as template from "./sign-info-line-input.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { Input } from "muklit/components/input/input";
import { SignInfoLineInputOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { TextInput } from "muklit/components/text-input/text-input";
import { IvsSignInfoSign } from "../../cits-manager/types";
import { InvipoHelpers } from "invipo/invipo-helpers";

export class SignInfoLineInput extends Input<InvipoContext, SignInfoLineInputOptions> {
    public type: Select;
    public value: TextInput
    public unit: Select
    public text: TextInput;
    public lang: Select;

    public onSubmit(): void {};
    public onKey(): void {};
    public onRemove(): void {};

    constructor(context: InvipoContext, options: SignInfoLineInputOptions) {
        super(context, template, options);

        if (!this.options.value) {
            this.options.value = {
                type: undefined,
                value: undefined
            }
        }

        this.createTypeInput();
        this.createValueInput();
        this.createUnitInput();
        this.createTextInput();
        this.createLangInput();

        this.registerComponent(this.type, "type")
        this.registerComponent(this.value, "value")
        this.registerComponent(this.unit, "unit")
        this.registerComponent(this.text, "text")
        this.registerComponent(this.lang, "lang")
    }

    public setValue (value?: IvsSignInfoSign): void {
        this.options.value = {
            type: undefined,
            value: undefined,
            ...value,
        }
        this.type.setValue(this.options.value.type);
        this.value.setValue(this.options.value.value);
        this.unit.setValue(this.options.value.unit);
        this.text.setValue(this.options.value.text);
        this.lang.setValue(this.options.value.lang);
    }

    public createTypeInput () {
        this.type = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "type",
            label: "components.MessageForm.type",
            multiselect: false,
            width: 186,
            value: this.options.value?.type,
            required: true,
            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("components.MessageForm.signTypes")),
        });

        this.type.onSubmit = () => {
            this.options.value.type = <any>Object.keys(this.type.options.value)[0];
            this.onSubmit();
        }

        // Hack - select menu does not fit to page
        this.type.menu.onAttach = () => {
            // this.type.menu.element.style.maxHeight = '300px';
        }
    }

    public createValueInput () {
        this.value = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 186,
            name: "value",
            label: "components.MessageForm.value",
            value: this.options.value?.value,
            required: true
        });

        this.value.onKey = () => {
            this.options.value.value = parseFloat(this.value.options.value);
            this.onKey();
        };

        this.value.onSubmit = () => this.onSubmit();
    }

    public createUnitInput () {
        this.unit = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "unit",
            label: "components.MessageForm.units",
            multiselect: false,
            width: 186,
            value: this.options.value?.unit,
            items: [
                { name: "ton", label: "units.t" },
                { name: "meter", label: "units.m" },
                { name: "kmh", label: "units.kmph" },
                { name: "mph", label: "units.mph" }
            ],
            required: false,
        });

        this.unit.onSubmit = () => {
            this.options.value.unit = <any>Object.keys(this.unit.options.value)[0];
            this.onSubmit();
        }
    }

    public createTextInput () {
        this.text = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 2 * 186 + 24,
            name: "text",
            label: "components.MessageForm.text",
            value: this.options.value?.text,
            required: false,
        });

        this.text.onKey = () => {
            this.options.value.text = this.text.options.value;
            this.onKey();
        };

        this.text.onSubmit = () => this.onSubmit();
    }

    public createLangInput () {
        this.lang = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "lang",
            label: "components.MessageForm.language",
            multiselect: false,
            width: 186,
            value: this.options.value?.unit,
            items: [
                { name: "en", label: "English" },
                { name: "de", label: "German" },
                { name: "el", label: "Greek" },
                { name: "cs", label: "Czech" }
            ],
            required: false,
        });

        this.lang.onSubmit = () => {
            this.options.value.lang = <any>Object.keys(this.lang.options.value)[0];
            this.onSubmit();
        }
    }

    public remove () {
        if (!this.options.disabled) {
            this.detach();
            this.onRemove();
        }
    }

    public setDisabled(disabled: boolean) {
        // No change?
        if (this.options.disabled == disabled) {
            return;
        }

        this.options.disabled = disabled;

        // Update
        this.type.setDisabled(disabled);
        this.value.setDisabled(disabled);
        this.unit.setDisabled(disabled);
        this.text.setDisabled(disabled);
        this.lang.setDisabled(disabled);
    }

    public validate(): string {
        let invalid = false;

        const typeInvalid = this.type.validate();
        if (typeInvalid) {
            this.type.options.invalid = true;
            this.type.options.messageText = typeInvalid;
            invalid ||= true;
        } else {
            this.type.options.invalid = false;
            this.type.options.messageText = undefined;
        }

        const valueInvalid = this.value.validate();
        if (valueInvalid) {
            this.value.options.invalid = true;
            this.value.options.messageText = valueInvalid;
            invalid ||= true;
        } else {
            this.value.options.invalid = false;
            this.value.options.messageText = undefined;
        }

        const unitInvalid = this.unit.validate();
        if (unitInvalid) {
            this.unit.options.invalid = true;
            this.unit.options.messageText = unitInvalid;
            invalid ||= true;
        } else {
            this.unit.options.invalid = false;
            this.unit.options.messageText = undefined;
        }

        const textInvalid = this.text.validate();
        if (textInvalid) {
            this.text.options.invalid = true;
            this.text.options.messageText = textInvalid;
            invalid ||= true;
        } else {
            this.text.options.invalid = false;
            this.text.options.messageText = undefined;
        }

        const langInvalid = this.lang.validate();
        if (langInvalid) {
            this.lang.options.invalid = true;
            this.lang.options.messageText = langInvalid;
            invalid ||= true;
        } else {
            this.lang.options.invalid = false;
            this.lang.options.messageText = undefined;
        }

        this.invalidate();

        // Validated
        return invalid ? 'invalid' : null;
    }
}