import "./range-input.scss";
import * as template from "./range-input.hbs";
import { Context } from "hiyo/context";
import { OverflowMenu } from "../overflow-menu/overflow-menu";
import { MenuItem } from "../overflow-menu/types";
import { Input } from "../input/input";
import { InputValue } from "../input/types";
import { RangeInputOptions } from "./types";
import { Log } from "../../../hiyo/log";
import { Calendar2 } from "../calendar2/calendar2";

const CLASS_ACTIVE = "muklit-select-active";

export class RangeInput extends Input<Context, RangeInputOptions> {

    constructor(context: Context, options: RangeInputOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
    }

    private createMenu(): void {
    }

    public selectMenu(): void {
        // Menu items
        let items: MenuItem[] = [];

        // Day pick selection
        if (this.options.type == "Day") {
            items.push(
                {
                    name: "Today",
                    label: "enums.DateRange.Today"
                },
                {
                    name: "Yesterday",
                    label: "enums.DateRange.Yesterday",
                    separated: true
                }
            )
        }

        // Range pick selection
        if (this.options.type == "Range") {
            items.push(
                {
                    name: "ThisWeek",
                    label: "enums.DateRange.ThisWeek"
                },
                {
                    name: "LastWeek",
                    label: "enums.DateRange.LastWeek",
                    separated: true
                },
                {
                    name: "ThisMonth",
                    label: "enums.DateRange.ThisMonth"
                },
                {
                    name: "LastMonth",
                    label: "enums.DateRange.LastMonth",
                    separated: true
                },
                {
                    name: "Last7Days",
                    label: "enums.DateRange.Last7Days",
                },
                {
                    name: "Last14Days",
                    label: "enums.DateRange.Last14Days",
                },
                {
                    name: "Last30Days",
                    label: "enums.DateRange.Last30Days",
                    separated: true
                }
            );
        }

        // Push custom
        items.push({
            name: "Custom",
            label: "enums.DateRange.Custom"
        });

        // Create component
        let menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: items,
            start: "BottomLeft",
            anchor: "TopLeft",
            offset: [0, 4],
            width: this.element.offsetWidth,
            multiselect: this.options.multiselect
        })

        // Reset value when selected from menu
        menu.onSelect = (item: MenuItem) => {
            // Action based on item
            switch (item.name) {
                case "Today":
                    this.setValue({
                        from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "Yesterday":
                    this.setValue({
                        from: new Date(new Date().setHours(-24, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(0, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "ThisWeek":
                    this.setValue({
                        from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "LastWeek":
                    this.setValue({
                        from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                        to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "ThisMonth":
                    this.setValue({
                        from: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "LastMonth":
                    this.setValue({
                        from: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).setHours(0, 0, 0, 0)).toISOString(),
                        to: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "Last7Days":
                    this.setValue({
                        from: new Date(new Date().setHours(-24 * 6, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "Last14Days":
                    this.setValue({
                        from: new Date(new Date().setHours(-24 * 13, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "Last30Days":
                    this.setValue({
                        from: new Date(new Date().setHours(-24 * 29, 0, 0, 0)).toISOString(),
                        to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                        range: item.name
                    });
                    break;
                case "Custom":
                    this.selectCalendar();
                    break;
                default:
                    Log.w(`RangeInput: Can't interpret value ${item.name}`);
                    break;
            }

            // OnSubmit handler (only on change)
            if (item.name != "Custom") {
                this.onSubmit();
            }
        }

        // Add active state
        menu.onAttach = () => {
            this.element?.classList.add(CLASS_ACTIVE);
        }

        // Remove active state
        menu.onDetach = () => {
            this.element?.classList.remove(CLASS_ACTIVE);
        }

        // Show menu
        menu.show(this.query("input"));
    }

    public selectCalendar(): void {
        // Create component
        let calendar = new Calendar2(this.context, {
            style: this.options.style,
            type: this.options.type,
            time: this.options.time,
            start: "BottomLeft",
            anchor: "TopLeft",
            from: this.options.value?.from,
            to: this.options.value?.to,
            width: this.element.offsetWidth,
            offset: [0, 4],
        });

        // Date selected
        calendar.onSelect = (from: string, to?: string) => {
            // Set options value
            this.options.value = {
                from: from,
                to: to
            }

            // Redraw
            this.update();

            // OnSubmit handler
            this.onSubmit();
        }

        // Add active state
        calendar.onAttach = () => {
            this.element?.classList.add(CLASS_ACTIVE);
        }

        // Remove active state
        calendar.onDetach = () => {
            this.element?.classList.remove(CLASS_ACTIVE);
        }

        // Show calendar
        calendar.show(this.query("input"));
    }

    public setValue(value: string | InputValue) {
        // Sets value to options
        this.options.value = value;

        // Reset invalid state and messageText once we select new value
        this.options.invalid = null;
        this.options.messageText = null;

        // Reattach select to render it properly
        this.update();

        // OnClear handler
        if (value == null) {
            this.onClear();
        }
    }
}
