import "./tile.scss";
import * as header from "./tile.header.hbs";
import * as kpis from "./tile.kpis.hbs";
import * as legend from "./tile.legend.hbs";
import * as list from "./tile.list.hbs";
import * as chart from "./tile.chart.hbs";
import { TileOptions, TileStatus } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Templates } from "hiyo/templates";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";

export abstract class Tile<T extends TileOptions = TileOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public item: InvipoItem;
    public items: InvipoItem[];
    public area: InvipoArea;
    public status: TileStatus;
    public timer: any;

    protected constructor(context: InvipoContext, template: any, options: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("tile-header", header);
        Templates.registerPartial("tile-kpis", kpis);
        Templates.registerPartial("tile-legend", legend);
        Templates.registerPartial("tile-chart", chart);
        Templates.registerPartial("tile-list", list);
    }

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Clear timeout to prevent multiple calls
        clearTimeout(this.timer);

        // Show loader
        //this.showLoader();

        // Load item?
        if (this.options?.itemId) {
            this.item = this.context.data.getItem(this.options.itemId);
        }

        // Load area?
        if (this.options?.areaId) {
            this.area = this.context.data.getArea(this.options.areaId);
            this.items = await this.context.invipo.getAreaItems(this.options.areaId, this.area.class ? `class=${this.area.class}` : null);
        }

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        //this.hideLoader();

        // Update
        this.update();

        // Autorefresh
        this.timer = setTimeout(async () => {
            if (this.isAttached()) {
                await this.load();
            }
        }, 60 * 1000);
    }
}
