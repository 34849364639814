import './traffic-incident-list.scss';
import * as template from "./traffic-incident-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityList } from "../../city/city-list/city-list";
import { TrafficIncidentListOptions } from "./types";

export class TrafficIncidentList extends CityList<TrafficIncidentListOptions> {

    // Properties
    public incidents: any[];

    constructor(context: InvipoContext, options?: TrafficIncidentListOptions) {
        super(context, template, options);
    }

    public selectIncident(i: number): void {
        // Get item
        let incident = this.incidents[i];

        // Call handler
        this.onPositionNavigate((incident.position.type == "LineString") ? incident.position.coordinates[0] : incident.position.coordinates);
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.incidents = await this.context.invipo.getQuery("traffic-incidents-current");
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.incidents) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
