import { Log } from "./log";
import { Helpers } from "./helpers";

export const STORAGE_LANG_ID = "hiyo.langId";

export class LocaleManager {

    public options: LocaleManagerOptions;
    public readonly messages: Messages;

    public constructor(options: LocaleManagerOptions) {
        this.options = options;

        // Empty langs?
        if (this.options.langs.length == 0) {
            Log.w(`LocaleManager: Empty langs[], you need to specify at least one supported language`);
            return;
        }

        // Init message store
        this.messages = options.messages || {};
        Log.i(`LocalManager: Messages assigned (${Object.keys(this.messages).join(",")})`);

        // No langId in options?
        if (!this.options.langId) {

            const storedLangId = <Language>localStorage.getItem(STORAGE_LANG_ID);
            const browserLanguage = navigator.language;

            // Take langId from localStorage
            if (storedLangId) {
                this.options.langId = storedLangId;
                Log.i(`LocaleManager: langId set to "${this.options.langId}" (from localStorage)`);
            }
            // Take langId from browser navigator settings
            else if (browserLanguage && browserLanguage.length >= 2){
                this.options.langId = <Language>browserLanguage.substr(0, 2);
                Log.i(`LocaleManager: langId set to "${this.options.langId}" (from navigator)`);
            }
            // No strategy: use default
            else {
                Log.w(`LocaleManager: Could not get langId neither from localStorage nor navigator. Will use "en" as default`);
            }
        }

        // Check if selected language is supported
        if (!this.options.langs.find(x => x == this.options.langId)) {
            Log.i(`LocaleManager: langId "${this.options.langId}" not supported, taking default`);
            this.options.langId = this.options.langs[0];
        }

        // Store to localStorage
        localStorage.setItem(STORAGE_LANG_ID, this.options.langId);
    }

    public setLangId(langId: Language): void {
        this.options.langId = langId;

        // Store to local storage
        localStorage.setItem(STORAGE_LANG_ID, langId);

        Log.i(`LocaleManager: langId set to "${langId}"`);
    }

    public getMessages(key: string, ...args: any): any {
        // Language store
        let store = this.messages[this.options.langId];

        if (!store) {
            return null;
        }

        // Get messages object from store
        return Helpers.getProperty(store, key);
    }

    public getMessage(key: string, ...args: any): string {
        // Language store
        let store = this.messages[this.options.langId];

        if (!store) {
            return null;
        }

        // Get message from store
        let message = Helpers.getProperty(store, key);

        // Message not exist?
        if (!message) {
            // Fallback to english
            message = Helpers.getProperty(this.messages["en"], key)
        }

        // Placeholder support
        return Helpers.format(message ?? key, ...args);
    }
}

export type Language = "en" | "cs" | "da" | "de";

export type Messages = { [langId: string]: any };

export interface LocaleManagerOptions {
    langs: Language[];
    langId?: Language;
    messages?: Messages;
}
