import "./traffic-event-card.scss";
import * as template from "./traffic-event-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { Helpers } from "hiyo/helpers";
import { TrafficEventCardOptions } from "./types";

export class TrafficEventCard extends CityCard<TrafficEventCardOptions> {

    // Properties
    public event: any;

    constructor(context: InvipoContext, options: TrafficEventCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load event
        this.event = await this.context.invipo.getManagement(`traffic/events/${this.options.eventId}`);

        // Add duration info
        if (this.event.duration.from && this.event.duration.to) {
            let from = new Date(this.event.duration.from).getTime();
            let to = new Date(this.event.duration.to).getTime();

            // Add suration in seconds and current status in percent
            this.event.duration.time = (to - from) / 1000;
            this.event.duration.percent = Helpers.range(0, 100, from, to, Date.now());
        }
    }
}
