import "./traffic-detail.scss";
import * as template from "./traffic-detail.hbs";
import { Detail } from "muklit/components/detail/detail";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficDetailOptions } from "./types";
import { TrafficDetailSchema } from "./traffic-detail-schema";

export class TrafficDetail extends Detail<InvipoContext, TrafficDetailOptions> {
    public schema: TrafficDetailSchema;

    constructor(context: InvipoContext, options: TrafficDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createSchema();

        // Register components that will be automatically attached
        this.registerComponent(this.schema, "schema")
    }

    private createSchema () {
        this.schema = new TrafficDetailSchema(this.context, {
            itemId: this.options.itemId
        });

        this.schema.onSubmit = () => {
            this.close();
        }
    }
}