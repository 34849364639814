import "./city-detail.scss";
import * as template from "./city-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { DrawerItem } from "../../common/drawer-menu/types";
import { CityDetailOptions } from "./types";
import { DetailMenu } from "../../common/detail-menu/detail-menu";
import { MenuItem } from "../../common/detail-menu/types";
import { ContentDetail } from "../../common/content-detail/content-detail";
import { COMPONENT_STORE } from "../../../store";
import { Log } from "hiyo/log";

export class CityDetail<T extends CityDetailOptions> extends ContentDetail<T> {

    // Components
    public menu: DetailMenu;

    public constructor(context: InvipoContext, options: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();

        // Register components that will be automatically attached
        this.registerComponent(this.menu, "menu");

        // Set component if required
        if (this.options.content) {
            this.setContent(this.options.content);
        }
    }

    private createMenu(): void {
        // Create items based on item class
        let items: MenuItem[] = [];

        // Item related detail?
        if (this.options.itemId != null) {
            // Passport tab only when management is enabled
            if (this.context.options.features?.includes("ItemPassports")) {
                items.push({
                    name: "PassportPanel",
                    label: "components.CityDetail.passport",
                    selected: this.options.content == "PassportPanel"
                });
            }

            // Default monitoring panel
            items.push({
                name: "MonitoringPanel",
                label: "components.CityDetail.monitoring",
                selected: this.options.content == "MonitoringPanel"
            });

            // Default properties panel
            items.push({
                name: "ItemPropertiesPanel",
                label: "components.CityDetail.properties",
                selected: this.options.content == "ItempPropertiesPanel"
            });
        }

        // Traffic light controller specific items
        if (this.options.itemClass == "TrafficLightController") {
            items.unshift({
                name: "TrafficLightControllerPanel",
                label: "components.CityDetail.panel",
                selected: this.options.content == "TrafficLightControllerPanel"
            });
            // Has schema defined?
            if (this.context.data.getItem(this.options.itemId)?.schema?.orto) {
                items.unshift({
                    name: "TrafficLightControllerDiagram",
                    label: "components.CityDetail.diagram",
                    selected: this.options.content == "TrafficLightControllerDiagram"
                });
            }
        }

        // Traffic counter specific items
        if (this.options.itemClass == "TrafficCounter") {
            if (this.context.data.getItem(this.options.itemId)?.schema?.pentlogram) {
                items.unshift({
                    name: "TrafficPentlogramReport",
                    label: "components.CityDetail.pentlogram",
                    selected: this.options.content == "TrafficPentlogramReport"
                });
            }
            items.unshift({
                name: "TrafficHourlyVolumeReport",
                label: "components.CityDetail.insight",
                selected: this.options.content == "TrafficHourlyVolumeReport"
            });
        }

        // Parking lot specific items
        if (this.options.itemClass == "ParkingLot") {
            items.unshift({
                name: "ParkingOccupancyReport",
                label: "components.CityDetail.insight",
                selected: this.options.content == "ParkingOccupancyReport"
            });
        }

        // TODO: Make separate panel
        if (this.options.itemClass == "RoadWeatherStation") {
            items.unshift({
                name: "EnvironmentalSensorOverview",
                label: "components.CityDetail.overview",
                selected: this.options.content == "EnvironmentalSensorOverview"
            });
        }

        // TODO: Make both overview panel versions
        if (this.options.itemClass == "FullMatrixSign" || this.options.itemClass == "LineMatrixSign") {
            items.unshift({
                name: "MessageSignOverview",
                label: "components.CityDetail.overview",
                selected: this.options.content == "MessageSignOverview"
            });
        }

        // Class overview panel
        if (this.options.itemClass) {
            // Component exists?
            if (!COMPONENT_STORE[`${this.options.itemClass}Overview`]) {
                Log.w(`CityDetail: Component ${this.options.itemClass}Overview not found. Looks this class has no overview panel?`)
            }
            // Add overview panel to first position
            else {
                items.unshift({
                    name: `${this.options.itemClass}Overview`,
                    label: "components.CityDetail.overview",
                    selected: this.options.content == `${this.options.itemClass}Overview`
                });
            }
        }

        // Area detail
        if (this.options.areaType == "PreferenceDetectionPoint") {
            items.push({
                name: "PublicTransportPreferenceOverview",
                label: "components.CityDetail.overview",
                selected: this.options.content == "PublicTransportPreferenceOverview"
            });
            items.push({
                name: "PublicTransportPreferenceBrowser",
                label: "components.CityDetail.requests",
                selected: this.options.content == "PublicTransportPreferenceBrowser"
            });
        }

        // Traffic event detail
        if (this.options.eventId != null) {
            items.push({
                name: "TrafficEventOverview",
                label: "components.CityDetail.overview",
                selected: this.options.content == "TrafficEventOverview"
            });
        }

        // Traffic message detail
        if (this.options.messageId != null) {
            items.push({
                name: "TrafficMessageOverview",
                label: "components.CityDetail.overview",
                selected: this.options.content == "TrafficMessageOverview"
            });
        }

        // Title and subtitle
        let title = "";
        let subtitle = "";

        if (this.options.itemId != null) {
            title = this.options.itemName;
            subtitle = `classes.${this.options.itemClass}`;
        }

        if (this.options.areaId != null) {
            title = this.options.areaName;
            subtitle = `enums.AreaType.${this.options.areaType}`;
        }

        if (this.options.eventId != null) {
            title = `enums.TrafficEventType.${this.options.eventType}`;
            subtitle = this.options.eventTitle;
        }

        if (this.options.messageId != null) {
            title = `enums.TrafficMessageType.${this.options.messageType}`;
            subtitle = `enums.TrafficMessageSubtype.${this.options.messageSubtype}`;
        }

        // Create component
        this.menu = new DetailMenu(this.context, {
            style: "Light",
            title: title,
            subtitle: subtitle,
            items: items
        });

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name, ["TrafficLightControllerDiagram"].includes(item.name));
        }

        // Default selection
        if (!items.some(x => x.selected) && items.length > 0) {
            items[0].selected = true;
            this.setContent(items[0].name);
        }
    }
}
