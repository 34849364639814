import { InvipoContext } from "../../context/invipo-context";
import { AreaSpotLayer } from "../area-spot-layer";

const MAX_ZOOM = 10.5;

export class ParkingAreaLabelLayer extends AreaSpotLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "ParkingOccupancy",
            dynamic: true,
            before: "road-label",
            layer: {
                id: "parking-area-label",
                type: "symbol",
                maxzoom: MAX_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "areaName"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 14,
                    "text-anchor": "center",
                    "text-max-width": 10,
                    "text-allow-overlap": true
                }
            }
        });
    }
}
