import './traffic-control-subdomain.scss';
import * as template from "./traffic-control-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficControlSubdomainOptions } from "./types";
import { TrafficControlModeCircleLayer } from "../../../layers/traffic/traffic-control-mode-circle-layer";
import { TrafficControlStateLabelLayer } from "../../../layers/traffic/traffic-control-state-label-layer";
import { TrafficControlStateCircleLayer } from "../../../layers/traffic/traffic-control-state-circle-layer";
import { TrafficControlModeLabelLayer } from "../../../layers/traffic/traffic-control-mode-label-layer";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { AreaList } from "../../city/area-list/area-list";
import { ItemList } from "../../city/item-list/item-list";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Helpers } from "hiyo/helpers";
import { TrafficControlOccupancyCircleLayer } from "../../../layers/traffic/traffic-control-occupancy-circle-layer";
import { TrafficControlOccupancyLabelLayer } from "../../../layers/traffic/traffic-control-occupancy-label-layer";
import { AreaBoundaryLayer } from "../../../layers/infrastructure/area-boundary-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export class TrafficControlSubdomain extends CitySubdomain<TrafficControlSubdomainOptions> {

    constructor(context: InvipoContext, options?: TrafficControlSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Occupancy",
                    itemClass: "TrafficLightController",
                    areaType: "TrafficControl",
                    layers: [
                        new TrafficControlOccupancyCircleLayer(context),
                        new TrafficControlOccupancyLabelLayer(context)
                    ]
                },
                {
                    name: "State",
                    itemClass: "TrafficLightController",
                    areaType: "TrafficControl",
                    layers: [
                        new TrafficControlStateCircleLayer(context),
                        new TrafficControlStateLabelLayer(context)
                    ]
                },
                {
                    name: "Mode",
                    itemClass: "TrafficLightController",
                    areaType: "TrafficControl",
                    layers: [
                        new TrafficControlModeCircleLayer(context),
                        new TrafficControlModeLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: [
                new AreaBoundaryLayer(this.context, this.areas)
            ]
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(items?: InvipoItem[], tags?: string[]): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.ItemList.subtitle",
            tags: tags,
            layers: [
                new ItemCircleLayer(this.context, items || this.items),
                new ItemLabelLayer(this.context, items || this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = items || this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public selectSymbol(symbol: string) {
        // Get selected metrics
        let data = Helpers.toKebabCase(this.metrics.name);

        // Select devices
        this.selectDevices(this.items.filter(x => symbol == "Unknown" ? !x.data?.control[data] : x.data?.control[data] == symbol), [`enums.TrafficLightController${this.metrics.name}.${symbol}`]);
    }

    public async extraLoad(): Promise<void> {
        // Get selected metrics and data property name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        // State or Mode metrics?
        if (this.metrics.name == "State" || this.metrics.name == "Mode") {
            // Create state legend
            this.legend = {
                range: null,
                symbols: []
            };

            for (let i in METRICS.traffic.control[metrics].range) {
                let symbol = METRICS.traffic.control[metrics].range[i]
                let count = this.items.filter(
                    symbol == "Unknown" ?
                        x => !x.data?.control || x.data?.control[metrics] == null :
                        x => x.data?.control && x.data?.control[metrics] != null && x.data.control[metrics] == symbol).length;

                // Add row to legend
                this.legend.symbols.push({
                    type: "Circle",
                    metrics: "State",
                    symbol: symbol,
                    count: count,
                    label: `enums.TrafficLightController${this.metrics.name}.${symbol}`,
                    percent: count / (this.items.length || 1) * 100,
                    color: METRICS.traffic.control[metrics].colors[i],
                    selectable: true
                });
            }
        }

        // Occupancy metrics?
        if (this.metrics.name == "Occupancy") {
            // Create occupancy legend
            this.legend = {
                description: "components.TrafficControlSubdomain.occupancyDescription",
                range: {
                    range: METRICS.traffic.control.occupancy.range,
                    colors: METRICS.traffic.control.occupancy.colors,
                    value: 0,
                    count: 0,
                    unit: "%"
                },
                symbols: []
            }

            // Calculate average
            for (let item of this.items) {
                // No data?
                if (!item.data?.loads?.occupancy) {
                    continue;
                }

                this.legend.range.value += item.data.loads.occupancy;
                this.legend.range.count += 1;
            }

            // Set average and calculate relative position on range
            this.legend.range.value /= this.legend.range.count;
            this.legend.range.percent = Helpers.range(0, 100, METRICS.traffic.control.occupancy.range[0], METRICS.traffic.control.occupancy.range[1], this.legend.range.value);
        }
    }
}
