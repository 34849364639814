import './monitoring-hud.scss';
import * as template from "./monitoring-hud.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { MonitoringHudOptions } from "./types";
import { AvailabilityItem, InvipoItem } from "../../../clients/invipo-client/types";
import { HiyoEvent } from "hiyo/event-broker";

export class MonitoringHud extends MuklitComponent<InvipoContext, MonitoringHudOptions> {

    // Properties
    public items: InvipoItem[];
    public legend: any[];
    public availability: number;
    public history: AvailabilityItem[];
    public timer: any;

    constructor(context: InvipoContext, options?: MonitoringHudOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        this.context.broker.subscribe(this, ["MonitoringStatusChanged"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent): Promise<void> {
        // Monitoring status has changed?
        // FIXME: still not working properly, calling load multiple times
        /*if (event.type == "MonitoringStatusChanged") {
            // Clear existing timer
            clearTimeout(this.timer);

            // Set new timer with maximum 100 ms delay
            this.timer = setTimeout(async () => {
                await this.load();
            }, 100);
        }*/
    }

    public async setItemClass(name: string): Promise<void> {
        // Set new class to options
        this.options.itemClass = name;

        // Reload
        await this.load();
    }

    public async load(): Promise<void> {
        // Clear to prevent multiple timers
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // Api query
        let query = `class=${this.options.itemClass || this.context.options.classes.join(",")}`;

        // Area in query?
        if (this.options.areaId) {
            query += `&area.id=${this.options.areaId}`;
        }

        // Model in query?
        if (this.options.itemModel) {
            query += `&model=${this.options.itemModel}`;
        }

        // Area selected?
        if (this.options.areaId) {
            this.items = await this.context.invipo.getAreaItems(this.options.areaId, query);
        }
        // No area
        else {
            this.items = this.context.data.getItems({
                class: this.options.itemClass || this.context.options.classes.join(","),
                model: this.options.itemModel
            });

        }

        // Empty legend
        this.legend = [];

        // Calculate legend counts and percentage
        for (let status of ["Ok", "Warn", "Error", "Unreachable", "Disconnected"]) {
            let count = this.items.filter(x => x.monitoringStatus.status == status).length;

            this.legend.push({
                status: status,
                count: count,
                percent: count / (this.items.length || 1) * 100
            });
        }

        // Calculate availability
        this.availability = 0;

        for (let item of this.items) {
            this.availability += item.availability?.value ?? 0;
        }

        this.availability /= this.items.length || 1;

        // Load item history for last 2 hours
        let to = new Date(new Date().setSeconds(0, 0));
        let from = new Date(to.getTime() - (61 * 60 * 1000));

        this.history = await this.context.invipo.getItemsAvailability(`from=${from.toISOString()}&to=${to.toISOString()}&${query}`);

        // Slice history to max 60 records
        this.history = this.history.slice(0, 60);

        // Component detached while loading?
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();

        // Autorefresh
        this.timer = setTimeout(async () => {
            if (this.isAttached()) {
                await this.load();
            }
        }, 60 * 1000);
    }
}
