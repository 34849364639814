import "./traffic-light-controller-overview.scss";
import * as template from "./traffic-light-controller-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { TrafficLightControllerOverviewOptions } from "./types";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class TrafficLightControllerOverview extends Panel<TrafficLightControllerOverviewOptions> {

    // Properties
    public kpis: PanelKpis;
    public state: PanelChart;
    public mode: PanelChart;

    constructor(context: InvipoContext, options?: TrafficLightControllerOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Build speed KPIs
        this.kpis = {
            label: "components.TrafficLightControllerOverview.control",
            size: "Fourth",
            data: [
                {
                    label: "components.TrafficLightControllerOverview.detectorOccupancy",
                    value: (this.item.data?.loads?.occupancy != null) ? `${Helpers.toNumber(this.item.data.loads.occupancy)} %` : this.context.locale.getMessage("common.unknown")
                },
                {
                    label: "components.TrafficLightControllerOverview.controlState",
                    value: (this.item.data?.control?.state != null) ? `enums.TrafficLightControllerState.${this.item.data.control?.state}` : this.context.locale.getMessage("common.unknown")
                },
                {
                    label: "components.TrafficLightControllerOverview.controlMode",
                    value: (this.item.data?.control?.mode != null) ? `enums.TrafficLightControllerMode.${this.item.data.control.mode}` : this.context.locale.getMessage("common.unknown")
                },
                {
                    label: "components.TrafficLightControllerOverview.trafficPlan",
                    value: (this.item.data?.control?.plan != null) ? this.item.data.control.plan : this.context.locale.getMessage("common.unknown")
                }
            ]
        }

        // Load item history data
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let history = await this.context.invipo.getDataset("items-data-history", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);
        let start: Date = null;
        
        // Build hour chart
        this.state = {
            type: "Bar",
            size: "Small",
            length: 144,
            label: "components.TrafficLightControllerOverview.lastState",
            series: [],
            range: [],
            legend: []
        }

        // Starting date for iteration
        start = new Date(from);

        // Get history from yesterday's midnight to today's midnight
        for (let q = 0; q < 144; q++) {
            // Find hour in data
            let d = history.data.find(x => x.timestamp == start.toISOString());

            // Add history line
            if (d) {
                this.state.series.push(
                    [
                        {
                            timestamp: start.toISOString(),
                            valueX: Helpers.toShortDateTimeString(d.timestamp),
                            label: `enums.TrafficLightControllerState.${d.item.data?.control?.state}`,
                            percent: 100,
                            color: METRICS.traffic.control.state.colors[METRICS.traffic?.control?.state.range.indexOf(d.item.data?.control?.state)]
                        }
                    ]
                );
            }
            // No data for hour, we are in the future
            else {
                this.state.series.push(
                    [
                        {
                            timestamp: start.toISOString()
                        }
                    ]
                );
            }

            // Move to next hour
            start.setTime(start.getTime() + 1200000);
        }

        // Custom range
        this.state.range = [
            {
                label: Helpers.toShortDateString(this.state.series[0][0].timestamp)
            },
            {
                label: Helpers.toShortTimeString(this.state.series[36][0].timestamp)
            },
            {
                label: Helpers.toShortDateString(this.state.series[72][0].timestamp)
            },
            {
                label: Helpers.toShortTimeString(this.state.series[108][0].timestamp)
            },
            {
                label: ""
            }
        ]

        // Legend with all symbols
        for (let symbol of METRICS.traffic.control.state.range) {
            this.state.legend.push({
                label: `enums.TrafficLightControllerState.${symbol}`,
                color: METRICS.traffic.control.state.colors[METRICS.traffic.control.state.range.indexOf(symbol)]
            });
        }

        // Build hour chart
        this.mode = {
            type: "Bar",
            size: "Small",
            length: 144,
            label: "components.TrafficLightControllerOverview.lastMode",
            series: [],
            range: [],
            legend: []
        }

        // Starting date for iteration
        start = new Date(from);

        // Get history from yesterday's midnight to today's midnight
        for (let q = 0; q < 144; q++) {
            // Find hour in data
            let d = history.data.find(x => x.timestamp == start.toISOString());

            // Add history line
            if (d) {
                this.mode.series.push(
                    [
                        {
                            timestamp: start.toISOString(),
                            valueX: Helpers.toShortDateTimeString(d.timestamp),
                            label: `enums.TrafficLightControllerMode.${d.item.data?.control?.mode}`,
                            percent: 100,
                            color: METRICS.traffic.control.mode.colors[METRICS.traffic.control.mode.range.indexOf(d.item.data?.control?.mode)]
                        }
                    ]
                );
            }
            // No data for hour, we are in the future
            else {
                this.mode.series.push(
                    [
                        {
                            timestamp: start.toISOString()
                        }
                    ]
                );
            }

            // Move to next hour
            start.setTime(start.getTime() + 1200000);
        }

        // Custom range
        this.mode.range = [
            {
                label: Helpers.toShortDateString(this.mode.series[0][0].timestamp)
            },
            {
                label: Helpers.toShortTimeString(this.state.series[36][0].timestamp)
            },
            {
                label: Helpers.toShortDateString(this.mode.series[72][0].timestamp)
            },
            {
                label: Helpers.toShortTimeString(this.state.series[108][0].timestamp)
            },
            {
                label: ""
            }
        ]

        // Legend with all symbols
        for (let symbol of METRICS.traffic.control.mode.range) {
            this.mode.legend.push({
                label: `enums.TrafficLightControllerMode.${symbol}`,
                color: METRICS.traffic.control.mode.colors[METRICS.traffic.control.mode.range.indexOf(symbol)]
            });
        }

    }

}
