import "./detail-menu.scss";
import * as template from "./detail-menu.hbs";
import { Component } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { Log } from "hiyo/log";
import { MenuItem, DetailMenuOptions } from "./types";

export class DetailMenu extends Component<InvipoContext, DetailMenuOptions> {

    // Event handling methods
    public onSelect(item: MenuItem): void {};

    constructor(context: InvipoContext, options: DetailMenuOptions) {
        super(context, template, options);
    }

    public select(name: string): void {
        let item = this.options.items.find(x => x.name == name);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${name} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        this.options.items.forEach(x => x.selected = false);

        // Select current
        item.selected = true;

        // Redraw
        this.update();

        // OnItemSelect handler
        this.onSelect(item);
    }

    public close(back?: boolean): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Detach from DOM
        this.detach();

        // OnClose handler;
        this.onClose(back);
    }
}
