import { ContentView } from "./content-view";
import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerGroup, DrawerItem } from "../components/common/drawer-menu/types";
import { MenuView } from "./menu-view";

export class Insights extends MenuView {

    public createMenu(): void {
        // Menu groups
        let groups: DrawerGroup[] = [];

        // Build groups and items
        for (let g of Object.keys(this.context.options.insights)) {
            // New group based on domain name
            let group: DrawerGroup = {
                name: g,
                title: `groups.${g}`,
                items: []
            }

            // Add data
            for (let insight of this.context.options.insights[g]) {
                group.items.push({
                    name: insight,
                    label: `components.${insight}.title`
                })
            }

            // Add only non-empty group
            if (group.items.length > 0) {
                groups.push(group);
            }
        }

        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: groups
        });
    }

}
