import { Select } from "muklit/components/select/select";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemSelectOptions } from "./types";
import { InvipoItem } from "../../../clients/invipo-client/types";

export class ItemSelect extends Select<InvipoContext, ItemSelectOptions> {

    public items: InvipoItem[];

    public onCreate() {
        // Distinct activated?
        if (this.options.distinct) {
            this.items = this.context.data.getDistinct(this.options.distinct, "items") || [];
        }
        // Get all stored items
        else {
            this.items = this.context.data.getItems({
                class: this.options.itemClass,
                sort: "name:asc"
            });
        }

        // Sort items
        this.items.sort((a: InvipoItem, b: InvipoItem) => {
            return a.name.localeCompare(b.name);
        })

        // Filter active?
        if (this.options.itemFilter) {
            this.items = this.items.filter(x => this.options.itemFilter(x));
        }

        // Reset menu options
        this.options.items = [];

        for (let item of this.items) {
            // Add item
            this.options.items.push({
                name: item.id,
                label: item.name
            });
        }

        // Now call the rest
        super.onCreate();
    }

}
