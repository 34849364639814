import "./ticket-overview.scss";
import * as template from "./ticket-overview.hbs";

import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TicketOverviewOptions } from "./types";
import { Ticket } from "../../../clients/invipo-client/types";

export class TicketOverview extends MuklitComponent<InvipoContext, TicketOverviewOptions> {

    // Properties
    public tickets: Ticket[];

    constructor(context: InvipoContext, options?: TicketOverviewOptions) {
        super(context, template, options);
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Keep selected ticket
        let selected = this.tickets?.find(x => x.selected == true);

        // Load data
        this.tickets = await this.context.invipo.getTickets();

        // Reselect ticket because we have new data
        if (selected) {
            this.tickets.find(x => x.id == selected.id).selected = true;
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.update();
    }

}
