import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { VehicleLocation } from "../../components/traffic/vehicle-appearance-report/types";

export class CameraLocationCircleLayer extends MapLayer<InvipoContext> {

    // Properties
    public locations: VehicleLocation[];

    public constructor(context: InvipoContext, locations: VehicleLocation[]) {
        super(context, {
            dynamic: true,
            layer: {
                id: "camera-location-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 4,
                        12, 5,
                        16, 8,
                        19, 10,
                        22, 12
                    ],
                    "circle-color": "#008EFA",
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        10, 2,
                        22, 7
                    ],
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.8
            }
        });

        // Assign proeprties
        this.locations = locations;
    }

    public async load(): Promise<any> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let location of this.locations) {
            json.features.push({
                type: "Feature",
                properties: {
                },
                geometry: location.geometry
            })
        }

        // Not empty?
        if (json.features.length) {
            // Assign to map
            this.data = json;

            // Fit to zoom
            this.map.fitAll();
        }

        return json;
    }

}
