import "./tabs.scss";
import * as template from "./tabs.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { TabsItem, TabsOptions } from "./types";
import { Log } from "../../../hiyo/log";

export class Tabs extends MuklitComponent<Context, TabsOptions> {

    // Event handling methods
    public onSelect(item: TabsItem): void {};

    constructor(context: Context, options: TabsOptions) {
        super(context, template, options);
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean) {
        // Selected tab check
        let tab = this.options.tabs.find(x => x.selected);

        if (tab?.disabled) {
            // Unselect disable folder
            tab.selected = false;

            // Select first tab
            this.options.tabs[0].selected = true;
        }

        // Do component attach
        super.attach(target, before, skipLoad);

        // Toggle contents
        this.toggle();
    }

    public select(i: number): void {
        // Already selected?
        if (this.options.tabs[i].selected) {
            return;
        }

        // Unselect all
        this.options.tabs.forEach(x => x.selected = false);

        // Selected tab
        this.options.tabs[i].selected = true;

        // Update
        this.update();
        this.toggle();

        // OnSelect handler
        this.onSelect(this.options.tabs[i]);
    }

    private toggle() {
        if (!this.parent) {
            return;
        }

        for (let tab of this.options.tabs) {
            if (!tab.content) {
                continue;
            }

            let content = this.parent.query(<string>tab.content);

            // Store
            if (!content) {
                Log.w(`Could not get content element ${tab.content} for ${this.id}`);
                continue;
            }

            if (!content.dataset.display) {
                content.dataset.display = getComputedStyle(content).display;
            }

            content.style.display = tab.selected ? content.dataset.display : "none";
        }
    }
}
