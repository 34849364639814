import "./public-transport-subdomain.scss";
import * as template from "./public-transport-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { PublicTransportSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { PublicTransportVehicleDelayCircleLayer } from "../../../layers/transit/public-transport-vehicle-delay-circle-layer";
import { PublicVehicleList } from "../public-vehicle-list/public-vehicle-list";
import { AreaList } from "../../city/area-list/area-list";
import { PublicTransportStopCircleLayer } from "../../../layers/transit/public-transport-stop-circle-layer";
import { PublicTransportStopList } from "../public-transport-stop-list/public-transport-stop-list";
import { DatasetResult } from "muklit/components/query-table/types";
import { PublicTransportAreaBoundaryLayer } from "../../../layers/transit/public-transport-area-boundary-layer";
import { Helpers } from "hiyo/helpers";
import { PublicTransportTripLineLayer } from "../../../layers/transit/public-transport-trip-line-layer";
import { PublicTransportVehicleServiceCircleLayer } from "../../../layers/transit/public-transport-vehicle-service-circle-layer";

export class PublicTransportSubdomain extends CitySubdomain<PublicTransportSubdomainOptions> {

    // Properties
    public vehicles: DatasetResult;
    public stops: any[];

    constructor(context: InvipoContext, options?: PublicTransportSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Delay",
                    areaType: "PublicTransport",
                    layers: [
                        new PublicTransportAreaBoundaryLayer(context),
                        //new PublicTransportTripLineLayer(context),
                        new PublicTransportStopCircleLayer(context),
                        new PublicTransportVehicleDelayCircleLayer(context),
                        //new PublicTransportVehicleLabelLayer(context),
                        //new PublicTransportVehicleHeadingLayer(context),
                    ]
                },
                {
                    name: "Service",
                    areaType: "PublicTransport",
                    layers: [
                        new PublicTransportAreaBoundaryLayer(context),
                        new PublicTransportStopCircleLayer(context),
                        new PublicTransportVehicleServiceCircleLayer(context),
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.AreaList.subtitle",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectStops(stops?: any[], tags?: string[]): void {
        // Crate item list
        let list = new PublicTransportStopList(this.context, {
            style: "Light",
            title: "components.PublicTransportStopList.title",
            subtitle: "components.PublicTransportStopList.subtitle",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.stops = stops || this.stops;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectVehicles(vehicles?: any[], tags?: string[]): void {
        // Crate item list
        let list = new PublicVehicleList(this.context, {
            style: "Light",
            title: "components.PublicVehicleList.title",
            subtitle: "components.PublicVehicleList.subtitle",
            tags: tags || [],
            layers: []
        });

        // Messages already loaded, will use them
        list.vehicles = vehicles || this.vehicles.data;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectSymbol(symbol: string) {
        // Get selected metrics
        let data = Helpers.toKebabCase(this.metrics.name);

        // Select devices
        this.selectVehicles(this.vehicles.data.filter(x => x.service == symbol));
    }

    public async extraLoad(): Promise<void> {
        // Load all vehicles and stops
        this.vehicles = await this.context.invipo.getDataset("public-vehicles");
        this.stops = await this.context.invipo.getManagement("transit/public/stops");

        // Delay metrics?
        if (this.metrics.name == "Delay") {

            // Calculate average
            let deviation = 0;

            for (let vehicle of this.vehicles.data) {
                deviation += vehicle.deviation;
            }

            this.legend = {
                description: "components.PublicTransportSubdomain.delayDescription",
                range: {
                    range: METRICS.transit.public.delay.range,
                    colors: METRICS.transit.public.delay.colors,
                    count: this.vehicles.data.length,
                    value: deviation / this.vehicles.data.length,
                    percent: Helpers.range(0, 100, METRICS.transit.public.delay.range[0], METRICS.transit.public.delay.range[1], deviation / this.vehicles.data.length),
                }
            };
        }

        // Service metrics?
        if (this.metrics.name == "Service") {
            this.legend = {
                description: "components.PublicTransportSubdomain.typeDescription",
                symbols: [
                    {
                        type: "Circle",
                        symbol: "City",
                        count: this.vehicles.data.filter(x => x.service == "City").length,
                        label: "components.PublicTransportSubdomain.type.City",
                        percent: this.vehicles.data.filter(x => x.service == "City").length / this.vehicles.data.length * 100,
                        color: METRICS.transit.public.service.colors[0],
                        selectable: true
                    },
                    {
                        type: "Circle",
                        symbol: "Intercity",
                        count: this.vehicles.data.filter(x => x.service == "Intercity").length,
                        label: "components.PublicTransportSubdomain.type.Intercity",
                        percent: this.vehicles.data.filter(x => x.service == "Intercity").length / this.vehicles.data.length * 100,
                        color: METRICS.transit.public.service.colors[1],
                        selectable: true
                    }
                ]
            }
        }
    }

}
