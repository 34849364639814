import "./item-geometry-detail.scss";
import * as template from "./item-geometry-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { ItemGeometryDetailOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { Feature } from "geojson";
import { InvipoHelpers } from "../../../invipo-helpers";

export class ItemGeometryDetail extends Detail<InvipoContext, ItemGeometryDetailOptions> {

    constructor(context: InvipoContext, options: ItemGeometryDetailOptions) {
        super(context, template, options);
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public setFeatures(features: Feature[]): void {
        // Assigm features
        this.options.features = features;

        // Item shortcut
        let item = this.options.item;

        // Update traffic data by current segments
        if (item.data?.traffic?.length > 0) {
            // Replace segment by its definition from meta
            for (let data of item.data.traffic) {
                // Set new feature propery
                data.feature = features.find(x => x.properties.name == data.segment);

                // Put extra legth property
                if (data.feature?.geometry.type == "LineString") {
                    data.feature.properties.length = InvipoHelpers.toLength(data.feature);
                }
            }
        }

        // Will only update on certail item classes
        if (item.class == "TrafficCounter") {
            this.update();
        }
    }
}
