import { Language, LocaleManager, Messages } from "./locale-manager";
import { Log } from "./log";
import { Application } from "./application";
import { EventBroker } from "./event-broker";

const STORAGE_USER = "hiyo.user";

export class Context<T extends ContextOptions = ContextOptions> {

    public options: T;
    public application: Application;
    public broker: EventBroker;
    public locale: LocaleManager;
    public user: UserPrincipal;

    public constructor(options: T) {
        this.options = options;

        // Initialize event broker
        this.broker = new EventBroker();

        // Initialize localization
        this.locale = new LocaleManager({
            langs: this.options.langs,
            messages: this.options.messages
        });

        const storedUser = localStorage.getItem(STORAGE_USER);

        // Found in localStorage
        if (storedUser) {
            // Deserialize and set new principal
            this.user = JSON.parse(storedUser);

            Log.i(`Context: User "${this.user.name}" restored (from localStorage)`);
        }

        // Assign to DOM for debugging reasons
        (<any>document).context = this;
    }

    public setUser(user: UserPrincipal) {
        this.user = user;

        // Store new user
        if (user) {
            // Store to local storage
            localStorage.setItem(STORAGE_USER, JSON.stringify(user));

            Log.i(`Context: User "${user.name}" set to context (and localStorage)`);
        }
        // Remove existing user (user = null)
        else {
            // Store to local storage
            localStorage.removeItem(STORAGE_USER);

            Log.i(`Context: User removed from context (and localStorage)`);
        }
    }

    public checkPermission(permissions: string[]): boolean {
        // User not set
        if (!this.user) {
            return false;
        }

        // Is boss or has requested permission?
        return this.user.boss || this.user.permissions.some(x => permissions.includes(x))
    }
}

export interface UserPrincipal {
    id: string;
    name: string;
    token: string;
    boss: boolean;
    permissions: string[];
    uuid: string;
    avatar: string;
}

export interface ContextOptions {
    langs: Language[];
    messages?: Messages;
}
