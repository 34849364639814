import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoItem } from "../../clients/invipo-client/types";
import { InvipoHelpers } from "../../invipo-helpers";

export class AccessAuthorizationAreaCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "AccessCard",
            layer: {
                id: "access-authorization-area-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = this.context.data.getAreas({
            type: "Access"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Last 24 hours
        let from = new Date(Date.now() - 86400000);

        // Push features from items
        for (let area of areas) {
            // Access counts
            let access = await this.context.invipo.getQuery<any>("access-count", `from=${from.toISOString()}&area.id=${area.id}`);

            // Color calculation from range
            let range = Helpers.range(0, METRICS.safety.access.authorization.colors.length - 1, METRICS.safety.access.authorization.range[0], METRICS.safety.access.authorization.range[1], access.authorization.denied / (access.count || 1) * 100);
            let color = METRICS.safety.access.authorization.colors[Math.round(range)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: area.name,
                    card: this.options.card,
                    cardId: area.id,
                    areaId: area.id,
                    areaName: area.name,
                    color: color,
                    scale: Helpers.range(6, 10, 0, 24, access.count)
                },
                geometry: {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(area.geometry).getCenter().toArray()
                }
            })
        }

        return json;
    }
}
