import "./data-detail.scss";
import * as template from "./data-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { DataDetailOptions } from "./types";

export class DataDetail extends Detail<InvipoContext, DataDetailOptions> {

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: DataDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Dark",
            tabs: [
                {
                    name: "Profile",
                    label: "components.DataDetail.details",
                    content: "div.content-details",
                    selected: true
                },
                {
                    name: "Data",
                    label: "components.DataDetail.data",
                    content: "div.content-data"
                }
            ]
        });
    }
}
