import "./wim-violation-detail.scss";
import * as template from "./wim-violation-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { TabsItem } from "muklit/components/tabs/types";
import { WimViolationDetailOptions } from "./types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { ViolationCommentForm } from "../violation-comment-form/violation-comment-form";

export class WimViolationDetail extends Detail<InvipoContext, WimViolationDetailOptions> {

    // Properties
    public violation: any;

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: WimViolationDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
    }

    private createTabs(): void {
        // Tabs
        let tabs: TabsItem[] = [
            {
                name: "General",
                label: "components.WimViolationDetail.general",
                content: "div.content-general",
                selected: true
            },
            {
                name: "Detail",
                label: "components.WimViolationDetail.detail",
                content: "div.content-detail"
            },
            {
                name: "Stream",
                label: "components.WimViolationDetail.stream",
                content: "div.content-stream"
            }
        ];

        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: tabs
        });

        // Register component
        this.registerComponent(this.tabs, "tabs");
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public openImage(urls: string[]): void {
        // Stop propagation to prevent opening data detail
        event.stopPropagation();

        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

    public openComment(): void {
        // Dialog to confirm
        let form = new ViolationCommentForm(this.context, {
            style: "Light",
            title: "components.TicketCommentForm.title",
            violation: this.violation,
            overlay: true,
            closable: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Delete call
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        form.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.violation = await this.context.invipo.getManagement(`violations/${this.options.violationId}`);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
