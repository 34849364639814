import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { HiyoEvent } from "../../../hiyo/event-broker";
import { PublicTransportVehicleLayer } from "./public-transport-vehicle-layer";

const MIN_ZOOM = 17;

export class PublicTransportVehicleLabelLayer extends PublicTransportVehicleLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "public-transport-vehicle-label",
                type: "symbol",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#efefef"
                },
                layout: {
                    "text-field": ["get", "vehicleLine"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 10,
                    "text-offset": [0, 0.1],
                    "text-anchor": "center",
                    "text-max-width": 10,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true
                }
            }
        });
    }

    public toFeature(vehicle: any): Feature<Point> {
        return {
            type: "Feature",
            properties: {
                type: "Location",
                vehicleId: vehicle.vehicleId,
                vehicleType: vehicle.type,
                vehicleLine: vehicle.line?.slice(-2), // Prevent long strings in label
                vehicleDeviation: vehicle.deviation,
                vehicleSpeed: vehicle.speed,
            },
            geometry: vehicle.position
        }
    }

    public async load(): Promise<any> {
        // Load vehicles
        let vehicles = await this.context.invipo.getDataset("public-vehicles");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of vehicles.data) {
            json.features.push(this.toFeature(vehicle))
        }

        return json;
    }
}
