import './parking-area-tile.scss';
import * as template from "./parking-area-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend, TileList } from "../../common/tile/types";
import { Helpers } from "hiyo/helpers";
import { ParkingAreaTileOptions } from "./types";
import { METRICS } from "../../city/city-subdomain/types";

export class ParkingAreaTile extends Tile<ParkingAreaTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;
    public list: TileList;

    constructor(context: InvipoContext, options?: ParkingAreaTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Calculate area occupancy
        let total = this.items.map(x => x.data?.occupancy?.overall?.total || 0).reduce((a, b: number) => { return a + b }, 0);
        let free = this.items.map(x => x.data?.occupancy?.overall?.free || 0).reduce((a, b: number) => { return a + b }, 0);
        let occupancy = (total - free) / (total || 1) * 100;
        let color = "#aaaaaa";

        // Get color for total occupancy
        for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
            if (Math.max(occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                let symbol = METRICS.parking.parking.occupancy.range[i];
                color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];
                break;
            }
        }

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.ParkingAreaTile.total",
                    value: Helpers.toNumber(total)
                },
                {
                    label: "components.ParkingAreaTile.free",
                    value: Helpers.toNumber(free)
                }
            ]
        }

        // Builld occupancy legend
        this.legend = {
            flat: true,
            symbols: [
                {
                    metrics: "Occuapncy",
                    symbol: "Occupied",
                    count: total - free,
                    label: "components.ParkingAreaTile.occupied",
                    percent: (total - free) / (total || 1) * 100,
                    color: color,
                    selectable: true
                }
            ]
        };

        // Builld items list
        this.list = {
            items: []
        };

        // Sort items by occupancy
        this.items.sort((a, b) => {
            return b.data?.occupancy?.overall?.occupancy - a.data?.occupancy?.overall?.occupancy;
        });

        for (let item of this.items) {
            // Occupancy
            let occupancy = item.data?.occupancy?.overall?.occupancy;
            let symbol = "Unknown";
            let color = "#aaaaaa";

            // Has data?
            if (occupancy != undefined) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }

                // Get color from scale
                color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];
            }

            this.list.items.push({
                name: item.name,
                label: occupancy != undefined ? `${Helpers.toNumber(occupancy)} %` : "",
                color: color
            })
        }

        // Sort items
        /*this.list.items.sort((a, b) => {
            return b.label.localeCompare(a.label);
        })*/

        // Create tile status
        if (this.items.find(x => x.data?.occupancy == null)) {
            this.status = {
                icon: "Error",
                label: "components.ParkingAreaTile.statusError"
            }
        }

    }
}
