import "./orto-marker.scss";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { InvipoContext } from "../../../context/invipo-context";
import { OrtoMarkerOptions } from "./types";
import { HiyoEvent } from "hiyo/event-broker";
import { InvipoItem } from "../../../clients/invipo-client/types";

export class OrtoMarker<T extends OrtoMarkerOptions = OrtoMarkerOptions> extends MapMarker<InvipoContext, T> {

    // Properties
    public item: InvipoItem;

    // Event handling methods
    public onEvent(event: HiyoEvent): void {}

    constructor(context: InvipoContext, template: any, options: T) {
        super(context, template, options);
    }

    public onAttach() {
        // Subscribe on target event types
        if (this.options.events) {
            this.context.broker.subscribe(this, this.options.events);
        }
    }

    public onDetach(): void {
        // Unsubscribe if subscribed
        if (this.options.events) {
            this.context.broker.unsubscribe(this);
        }
    }

    public onHandle(event: HiyoEvent) {
        // Am I ready and is the event for me?
        if (event.payload.item.id == this.options.itemId && this.item) {
            // Handle event
            this.onEvent(event);
        }
    }

    public async load(): Promise<void> {
        // Load all data silently (no loader)
        this.item = await this.context.invipo.getItem(this.options.itemId);
    }
}
