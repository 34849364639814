import "./button.scss";
import * as template from "./button.hbs";
import { Context } from "hiyo/context";
import { ButtonOptions } from "./types";
import { MuklitComponent } from "../muklit-component/muklit-component";

export class Button extends MuklitComponent<Context, ButtonOptions> {

    // Event handling methods
    public onClick(): void {};

    constructor(context: Context, options: ButtonOptions) {
        super(context, template, options);
    }

    public click(): void {
        // Disabled?
        if (this.options.disabled) {
            return;
        }

        // OnClick handler
        this.onClick();
    }

    public setDisabled(disabled: boolean) {
        // No change
        if (this.options.disabled == disabled) {
            return;
        }

        this.options.disabled = disabled;

        // Update
        this.update();
    }

}
