import { View } from "../../hiyo/view";
import { InvipoContext } from "../context/invipo-context";
import { Component } from "hiyo/component";
import { Log } from "../../hiyo/log";
import { COMPONENT_STORE } from "../store";

export class ContentView extends View<InvipoContext> {

    // Properties
    public content: Component;
    public cache: { [id: string]: Component }; // Cache of already created components

    public onEnter(): void {
        // Attach content if not visible
        if (this.content && !this.content.isAttached()) {
            this.content.attach();
        }
    }

    public onLeave(): void {
        // Detach content if visible
        if (this.content && this.content.isAttached()) {
            this.content.detach();
        }
    }

    public setContent(component: string, noCache?: boolean, skipLoad?: boolean, options?: any): void {
        // Same component?
        if (this.content?.name == component) {
            return;
        }

        Log.i(`Setting content of ${this.name} view to ${component}`);

        // Detach already existing content
        if (this.content?.isAttached()) {
            this.content.detach();
        }

        // Ensure cache was initialized
        this.cache = this.cache || {};

        // If restored from cache, we need to skip loading
        let cached = false;

        // Already created and cached?
        if (!noCache && this.cache[component]) {
            this.content = this.cache[component];
            cached = true;
        }
        // Not cached, but registered in store?
        else if (COMPONENT_STORE[component]) {
            this.content = new COMPONENT_STORE[component](this.context, {
                ...options
            });
            this.cache[component] = this.content;
        }
        // Not cached and not found in store
        else {
            Log.w(`Trying to set content of ${this.id} view to non-stored component ${component}`)
            this.content = null;
            return;
        }

        // Attach content if not empty and view is active
        if (this.content && this.isActive()) {
            this.content?.attach(null, false, cached && skipLoad);
        }
    }

}
