import "./access-browser.scss";
import * as template from "./access-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { AccessBrowserOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { AccessDetail } from "../access-detail/access-detail";
import { AccessDetailOptions } from "../access-detail/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { AreaSelect } from "../../common/area-select/area-select";
import { TextInput } from "muklit/components/text-input/text-input";
import { Select } from "muklit/components/select/select";
import { Templates } from "hiyo/templates";
import { Helpers } from "hiyo/helpers";
import { FilterAction, FilterItem } from "muklit/components/filter/types";
import { ViolationForm } from "../../enforcement/violation-form/violation-form";
import { InvipoApplication } from "../../../invipo-application";
import { Search } from "muklit/components/search/search";
import { HiyoEvent } from "hiyo/event-broker";
import { Log } from "hiyo/log";
import { RangeInput } from "muklit/components/range-input/range-input";

const CLASS_NAMES = "TrafficCamera,BluetoothDetector,SecurityCamera";

export class AccessBrowser extends MuklitComponent<InvipoContext, AccessBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: AccessDetail;

    constructor(context: InvipoContext, options?: AccessBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();

        // Subscribe for routing
        this.context.broker.subscribe(this, ["RouteChanged"]);
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public onHandle(event: HiyoEvent): void {
        Log.w("Rountong not implemented");
    }

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/access-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.AccessBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ],
                actions: [
                    {
                        name: "CreateViolation",
                        label: "components.AccessBrowser.createViolation",
                        multiselect: true,
                        disabled: true
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new Search(this.context, {
                                style: "Light",
                                name: "search",
                                label: "forms.fields.search",
                                placeholderText: "forms.placeholders.idSearch"
                            }),
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            /*new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                itemClass: CLASS_NAMES,
                                items: [],
                                multiselect: true
                            }),*/
                            new AreaSelect(this.context, {
                                style: "Light",
                                name: "area.id",
                                label: "forms.fields.area",
                                placeholderText: "forms.placeholders.all",
                                areaType: "Access",
                                items: [],
                                multiselect: true
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "vehicle.plate.number",
                                label: "forms.fields.plateNumber",
                                placeholderText: "forms.placeholders.plateNumber"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "tag.id",
                                label: "forms.fields.tagId",
                                placeholderText: "forms.placeholders.tagId"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "authorization",
                                label: "forms.fields.authorization",
                                placeholderText: "components.NotificationManager.types.AccessEvaluation.condition.authorization.placeholder",
                                items: [
                                    {
                                        name: "Granted",
                                        label: "enums.AccessAuthorization.Granted",
                                    },
                                    {
                                        name: "Denied",
                                        label: "enums.AccessAuthorization.Denied"
                                    }
                                ]
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "MultiSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    disabler: (data: any) => {
                        return data.violation != null;
                    }
                },
                columns: [
                    {
                        name: "interval.from",
                        type: "DateTime",
                        property: "interval.from",
                        formatter: (value: any, data: any) => {
                            let status = "";

                            // Granted color?
                            if (data.authorization == "Granted") {
                                status = "Ok"
                            }

                            // Denied color?
                            if (data.authorization == "Denied") {
                                status = "Error"
                            }

                            // Already in violation?
                            if (data.violation) {
                                status = "Disabled"
                            }

                            return Templates.renderPartial("status-formatter", {
                                style: "Light",
                                status: status,
                                label: Helpers.toDateTimeString(data.interval.from)
                            })
                        },
                        label: "tables.columns.timestamp",
                        width: 280,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "area.name",
                        type: "String",
                        property: "area.name",
                        label: "tables.columns.area",
                        width: 200,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "duration",
                        type: "Number",
                        property: (data: any) => {
                            return (new Date(data.interval.to).getTime() - new Date(data.interval.from).getTime()) / 1000;
                        },
                        formatter: (value: any, data: any) => {
                            // Less than 1 sec?
                            if (value <= 1) {
                                return `<div class="cell cell-center">1s</div>`;
                            }
                            else {
                                return `<div class="cell cell-center">${Helpers.toDuration((new Date(data.interval.to).getTime() - new Date(data.interval.from).getTime()) / 1000)}</div>`
                            }
                        },
                        label: "tables.columns.duration",
                        width: 120,
                        align: "Center",
                    },
                    {
                        name: "vehicle.plate.number",
                        type: "String",
                        property: "id",
                        label: "tables.columns.id",
                        formatter: (value: any, data: any): string => {
                            if (data.vehicle?.plate) {
                                return `<div class="cell">${Templates.renderPartial("label", {
                                    style: "Id",
                                    icon: "Car",
                                    label: data.vehicle.plate.number
                                })}</div>`;

                            }
                            else if (data.tag?.id) {
                                return `<div class="cell">${Templates.renderPartial("label", {
                                    style: "Id",
                                    icon: "Card",
                                    label: data.tag.id
                                })}</div>`;
                            }
                            else if (data.camera?.id) {
                                return `<div class="cell">${Templates.renderPartial("label", {
                                    style: "Id",
                                    icon: "Camera",
                                    label: data.camera.id
                                })}</div>`;
                            }
                            else {
                                return null;
                            }
                        },
                        minWidth: 200,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Handle action  selection
        this.table.onActionSelect = (item: FilterAction) => {
            if (item.name == "CreateViolation") {
                // Open export dialog
                this.openViolation();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any, title?: string): void {
        // Detail options
        let options: AccessDetailOptions = {
            style: "Light",
            access: data,
            title: title || "components.AccessDetail.title",
            subtitle: data.item?.name || data.area?.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new AccessDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.access.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }

    public openImage(image: any): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: null,
            url: `${this.context.options.host}/download${image.url}`,
            closable: true,
            overlay: true
        });

        // Shoe
        detail.attach();
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "AccessCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

    public openViolation(): void {
        // Create ticket with sleected data
        let violation: any = {
            type: "Access"
        };

        // Any rows checked?
        if (Object.keys(this.table.table.selectedRows).length) {
            // We create access data from checked rows
            violation.data = {
                access: Object.values(this.table.table.selectedRows).map(x => x.data)
            }
        }

        // Create notification form
        let form = new ViolationForm(this.context, {
            style: "Light",
            violation: violation,
            title: "components.ViolationForm.title",
            overlay: true,
            closable: true
        });

        // Show notification
        form.onSubmit = async () => {
            // Unselect all checkboxes
            this.table.unselectCheckboxes();

            // Relaod table
            await this.table.load();

            // Show toast
            (<InvipoApplication>this.context.application).toasts.showInfoToast(null, "components.AccessBrowser.violationCreated");
        }

        // Attach
        form.attach();
    }

    public async route(): Promise<any> {
        this.table.form.setValue("vehicle.plate.number", "1");
        this.table.form.setValue("area.id", "652d61afdf09eea08b407750");

        this.table.form.submit();

        /*await this.table.search({
            "vehicle.plate.number": "aa",
            "area.id": "652d61afdf09eea08b407750"
        })*/
    }

}
