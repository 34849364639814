import "./area-manager.scss";
import * as template from "./area-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { AreaManagerOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { QueryTable } from "muklit/components/query-table/query-table";
import { TitleBarTag } from "muklit/components/title-bar/types";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { Search } from "muklit/components/search/search";
import { Select } from "muklit/components/select/select";
import { AreaMap } from "../area-map/area-map";
import { AreaDetailOptions } from "../area-detail/types";
import { AreaDetail } from "../area-detail/area-detail";
import { InvipoArea } from "invipo/clients/invipo-client/types";
import { AreaForm } from "../area-form/area-form";

export class AreaManager extends MuklitComponent<InvipoContext, AreaManagerOptions> {

    // Components
    public form: Form;
    public table: QueryTable;
    public map: AreaMap;
    public detail: AreaDetail;

    constructor(context: InvipoContext, options?: AreaManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
        this.createTable();
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.form, "form");
        this.registerComponent(this.table, "table");
        this.registerComponent(this.map, "map");
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "general",
                    fields: [
                        new Search(this.context, {
                            style: "Light",
                            name: "name",
                            label: "area.name",
                            placeholderText: "forms.placeholders.areaSearch"
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "type",
                            label: "area.type",
                            placeholderText: "forms.placeholders.all",
                            multiselect: true,
                            items: [
                                { name: "TrafficControl", label: "enums.AreaType.TrafficControl" },
                                { name: "TrafficVolume", label: "enums.AreaType.TrafficVolume" },
                                { name: "Parking", label: "enums.AreaType.Parking" },
                                { name: "PreferenceDetectionArea", label: "enums.AreaType.PreferenceDetectionArea" },
                                { name: "PreferenceDetectionPoint", label: "enums.AreaType.PreferenceDetectionPoint" },
                                { name: "EnergyConsumption", label: "enums.AreaType.EnergyConsumption" },
                                { name: "WasteCollection", label: "enums.AreaType.WasteCollection" }
                            ]
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.table.search(data);
        }
    }

    private createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/areas`,
            http: this.context.invipo.http,
            bar: {
                title: "components.AreaManager.title",
                items: [
                    {
                        name: "Add",
                        label: "labels.add"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 15
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        return data.disabled ? "disabled" : null;
                    }
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        label: "area.name",
                        width: 480,
                        sortable: true,
                        ellipsis: true,
                        selected: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.AreaType.${data.type}`);
                        },
                        label: "area.type",
                        ellipsis: true,
                        sortable: true,
                    }
                ]
            }
        });

        // Clear input
        this.table.onTagRemove = (tag: TitleBarTag) => {
           this.form.setValue(tag.name, null);
        }

        // Handle menu
        this.table.onMenuSelect = async (item: MenuItem) => {
            // Add new user?
            if (item.name == "Add") {
                await this.openNew();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }
    }

    private createMap(): void {
        // Create component
        this.map = new AreaMap(this.context);

        /* // Open detail onm feature click
        this.map.map.onFeatureClick = async (layer: string, feature: Feature) =>  {
            // Select row in table first
            this.table.table.selectRow(feature.properties.areaId);
        }*/
    }

    private async openDetail(data: InvipoArea): Promise<void> {
        // Detail options
        let options: AreaDetailOptions = {
            style: "Light",
            title: data.name,
            subtitle: `enums.AreaType.${data.type}`,
            areaId: data.id,
            closable: true
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Navigate in map
            this.map.navigateTo(data);

            // Not continue
            return;
        }

        // New detail
        this.detail = new AreaDetail(this.context, options);

        // Assign self as parent
        this.detail.parent = this;

        // Refresh table on detail update
        this.detail.onDetailUpdate = async () => {
            await this.table.load();
        }

        // When detail is closed
        this.detail.onClose = () => {
            // Unselect selected row
            this.table.unselectRow(this.detail.options.areaId);

            // Force redraw map
            this.map.map.resize();
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();

        // Force redraw map
        this.map.map.resize();

        // Navigate in map
        this.map.navigateTo(data);
    }

    public openNew(): void {
        // Details form
        let form = new AreaForm(this.context, {
            style: "Light",
            title: null,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();
        }

        // Show form
        form.attach();
    }
}
