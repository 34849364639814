import "./parking-occupancy-report.scss";
import * as template from "./parking-occupancy-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ParkingOccupancyReportOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis, PanelTable } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { RangeInput } from "muklit/components/range-input/range-input";
import { METRICS } from "../../city/city-subdomain/types";
import { InvipoHelpers } from "../../../invipo-helpers";

const ITEM_CLASS = "ParkingLot";

export class ParkingOccupancyReport extends Panel<ParkingOccupancyReportOptions> {

    // Properties
    public hours: PanelChart;
    public days: PanelChart;
    public dates: PanelChart;
    public kpis: PanelKpis;
    public table: PanelTable;

    public constructor(context: InvipoContext, options?: ParkingOccupancyReportOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    protected createForm(): void {
        // Filter form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * 13, 0, 0, 0)).toISOString(),
                                to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                                range: "Last14Days"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "itemId",
                            label: "forms.fields.item",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            itemClass: ITEM_CLASS,
                            items: [],
                            width: 320,
                            multiselect: true,
                            bright: true
                        })
                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Query string
        let query = `item.class=${ITEM_CLASS}`;

        if (form.itemId) {
            query += `&item.id=${form.itemId}`;
        }

        // Interval
        let from = new Date(new Date(form.from));
        let to = new Date(new Date(form.to));

        // Occupancy data
        let hours = await this.context.invipo.getQuery("parking-occupancy-by-group", `${query}&group=hour&from=${from.toISOString()}&to=${to.toISOString()}`);
        let days = await this.context.invipo.getQuery("parking-occupancy-by-group", `${query}&group=day&from=${from.toISOString()}&to=${to.toISOString()}`);
        let dates = await this.context.invipo.getQuery("parking-occupancy-by-group", `${query}&group=date&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build volume chart
        this.hours = {
            type: "Bar",
            size: "Tall",
            length: 24,
            name: "Volume",
            label: "components.ParkingOccupancyReport.hours",
            series: []
        }

        // Add hours chart data
        for (let h = 0; h < 24; h++) {
            // Find hour in data
            let d = hours.find(x => x.hour == h);

            // Has data?
            if (d) {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: `${Helpers.toNumber(d.occupancy)}%`,
                            valueX: h.toString().padStart(2, "0"),
                            percent: d.occupancy,
                            label: `${Helpers.toNumber(d.occupancy)}%`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.hours.series.push(
                    [
                        {
                            timestamp: new Date(new Date(from).setHours(h)).toISOString(),
                            valueY: (h < new Date().getHours()) ? "0 %" : null,
                            valueX: h.toString().padStart(2, "0")
                        }
                    ]
                );
            }
        }

        // Calculate highlights
        let averageDateOccupancy = dates.map(x => x.occupancy).reduce((a, b) => { return a + b }, 0) / 24;
        let maxHourOccupancy = Math.max(...hours.map(x => x.occupancy));
        let maxDayOccupancy = Math.max(...days.map(x => x.occupancy));

        // Occupancy KPIs
        this.kpis = {
            label: "components.ParkingOccupancyReport.overview",
            size: "Third",
            data: [
                {
                    label: "components.ParkingOccupancyReport.dateAverage",
                    value: `${Helpers.toNumber(averageDateOccupancy)} %`,
                    description: `${Helpers.toDateString(from)} &ndash; ${Helpers.toDateString(to)}`
                },
                {
                    label: "components.ParkingOccupancyReport.dayHighest",
                    description: `${Helpers.toNumber(maxDayOccupancy)} %`,
                    value: Helpers.toWeekDayString(new Date(new Date().setHours(-24 * ((new Date().getDay() + (7 - days.find(x => x.occupancy == maxDayOccupancy)?.day)) % 7), 0, 0, 0)))
                },
                {
                    label: "components.ParkingOccupancyReport.hourHighest",
                    description: `${Helpers.toNumber(maxHourOccupancy)} %`,
                    value: `${hours.find(x => x.occupancy == maxHourOccupancy)?.hour}:00`
                }
            ]
        }

        // Build day chart
        this.days = {
            type: "Bar",
            size: "Medium",
            label: "components.ParkingOccupancyReport.days",
            series: []
        }

        // Itterate from all days in week
        for (let n = 1; n < 8; n++) {
            // Find data
            let d = days.find(x => x.day == n);

            // Date that represents day in week
            let date = new Date(new Date().setHours(-24 * ((new Date().getDay() + (7 - n)) % 7), 0, 0, 0));

            // Has data?
            if (d) {
                this.days.series.push(
                    [
                        {
                            timestamp: date.toISOString(),
                            valueY: `${Helpers.toNumber(d.occupancy)}%`,
                            valueX: Helpers.toWeekDayString(date),
                            percent: d.occupancy,
                            label: `${Helpers.toNumber(d.occupancy)}%`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.days.series.push(
                    [
                        {
                            timestamp: date.toISOString(),
                            valueX: Helpers.toWeekDayString(date)
                        }
                    ]
                );
            }
        }

        // Build dates chart
        this.dates = {
            type: "Bar",
            size: "Medium",
            label: "components.ParkingOccupancyReport.dates",
            series: []
        }

        // Itterate over dates as some data may miss
        let timestamp = new Date(from);
        do {
            // Find data
            let d = dates.find(x => x.date.split("-")[1] == (timestamp.getMonth() + 1) && x.date.split("-")[2] == (timestamp.getDate()));

            // Has data?
            if (d) {
                this.dates.series.push(
                    [
                        {
                            timestamp: timestamp.toISOString(),
                            valueY: `${Helpers.toNumber(d.occupancy)}%`,
                            valueX: (dates.length <= 14) ? Helpers.toShortDateString(timestamp) : "",
                            percent: d.occupancy,
                            label: `${Helpers.toNumber(d.occupancy)}%`,
                            color: InvipoHelpers.toChartColor(1)
                        }
                    ]
                );
            }
            // No data
            else {
                this.dates.series.push(
                    [
                        {
                            timestamp: timestamp.toISOString(),
                            valueX: Helpers.toShortDateString(timestamp)
                        }
                    ]
                );
            }

            // Add one day
            timestamp.setHours(24, 0, 0, 0);
        }
        while (timestamp.getTime() < to.getTime())

        // Build count table
        this.table = {
            name: "Table",
            label: "components.ParkingOccupancyReport.table",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
                {
                    label: "tables.columns.occupancy",
                    style: "Bold",
                    align: "Right",
                    width: "120px"
                }
            ],
            rows: []
        };

        for (let d of dates) {
            // Add table row
            this.table.rows.push({
                cells: [
                    Helpers.toDateString(d.date),
                    `${Helpers.toNumber(d.occupancy)} %`
                ]
            });
        }
    }
}
