import "./parking-sensor-overview.scss";
import * as template from "./parking-sensor-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis } from "../../common/panel/types";
import { ParkingSensorOverviewOptions } from "./types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class ParkingSensorOverview extends Panel<ParkingSensorOverviewOptions> {

    // Properties
    public kpis: PanelKpis;
    public chart: PanelChart;

    constructor(context: InvipoContext, options?: ParkingSensorOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load parent
        let parent = this.context.data.getItem(this.item.parent.id);

        // Build speed KPIs
        this.kpis = {
            label: "components.ParkingSensorOverview.parkingStatus",
            size: "Half",
            data: [
                {
                    label: "components.ParkingSensorOverview.sensorOccupancy",
                    value: (this.item.data?.occupancy?.overall?.free == 0) ? "components.ParkingSensorOverview.occupied" : "components.ParkingSensorOverview.free",
                    type: (!this.item.data?.occupancy?.overall || this.item.data.occupancy.overall.free == 0) ? "Negative" : "Positive",
                    description: Helpers.toShortDateTimeString(this.item.data?.occupancy?.ts)
                },
                {
                    label: "components.ParkingSensorOverview.lotOccupancy",
                    value: (parent.data?.occupancy?.overall?.occupancy != null) ? `${Helpers.toNumber(parent.data.occupancy.overall.occupancy)} %` : "common.unknown",
                    type: (!parent.data?.occupancy?.overall || parent.data.occupancy.overall.occupancy > 90) ? "Negative" : "Positive",
                    description: parent.name,
                    hidden: (parent == null)
                }
            ]
        }

        // Interval
        let from = new Date(new Date(Date.now() - 48 * 60 * 60 * 1000).setMinutes(0, 0, 0));
        let to = new Date(new Date().setMinutes(0, 0, 0));

        // Parking occupancy per hour
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `&item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build hour chart
        this.chart = {
            type: "Bar",
            size: "Medium",
            length: 48,
            label: "components.ParkingLotOverview.todayOccupancy",
            series: []
        }

        // Add hourly data to chart series
        let timestamp = new Date(from);
        do {
            // Find hour in data
            let d = data.find(x => x.timestamp == timestamp.toISOString());
            let symbol = "Unknown";

            // Has data?
            if (d?.occupancy) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(d.occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(d.occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }
            }

            let color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // Has data?
            if (d) {
                this.chart.series.push(
                    [
                        {
                            timestamp: d.timestamp,
                            valueX: Helpers.toShortDateTimeString(d.timestamp),
                            //valueY: `${d.occupancy} %`,
                            percent: d.occupancy,
                            label: `${d.occupancy} %`,
                            color: color,
                        }
                    ]
                );
            }
            // No data
            else {
                this.chart.series.push(
                    [
                        {
                            timestamp: timestamp.toISOString(),
                        }
                    ]
                );
            }

            // Move to next hour
            timestamp.setTime(timestamp.getTime() + 3600000);
        }
        while (timestamp.getTime() < to.getTime())

        // Custom range
        this.chart.range = [
            {
                label: Helpers.toShortDateTimeString(from)
            },
            {
                label: Helpers.toShortDateTimeString(new Date(from.getTime() + (to.getTime() - from.getTime()) / 2))
            },
            {
                label: Helpers.toShortDateTimeString(to)
            }
        ];
    }

}
