import "./docker-browser.scss";
import * as template from "./docker-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { DockerBrowserOptions } from "./types";
import { Templates } from "hiyo/templates";
import { DockerDetailOptions } from "../docker-detail/types";
import { DockerDetail } from "../docker-detail/docker-detail";
import { HiyoEvent } from "hiyo/event-broker";
import { InvipoApplication } from "../../../invipo-application";
import { Helpers } from "hiyo/helpers";
import { TableGroup } from "muklit/components/data-table/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";

const STATE_STATUS_MAP: { [value: string]: string } = {
    "created": "Warn",
    "restarting": "Warn",
    "running": "Ok",
    "removing": "Error",
    "paused": "Error",
    "exited": "Error",
    "dead": "Error"
}

const GROUP_NAME_ORDER: { [value: string]: number } = {
    "connectors": 1,
    "api": 2,
    "workers": 3,
    "services": 4
}

export class DockerBrowser extends MuklitComponent<InvipoContext, DockerBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: DockerDetail;

    constructor(context: InvipoContext, options?: DockerBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

    public onAttach() {
        // Subscribe for events
        this.context.broker.subscribe(this, ["DockerContainerStopped", "DockerContainerStarted", "DockerContainerRestarted"]);

        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }

        // Unsubscribe for events
        this.context.broker.unsubscribe(this);
    }

    public async onHandle(event: HiyoEvent): Promise<void> {
        // Toast notifications based on docker events via NotificationManager
        let app = (<InvipoApplication>this.context.application);

        switch (event.type) {
            case "DockerContainerStarted":
                app.toasts.showInfoToast("components.DockerBrowser.docker", this.context.locale.getMessage(`components.DockerBrowser.containerStarted`, event.payload.extras.name));
                break;
            case "DockerContainerStopped":
                app.toasts.showInfoToast("components.DockerBrowser.docker", this.context.locale.getMessage(`components.DockerBrowser.containerStopped`, event.payload.extras.name));
                break;
            case "DockerContainerRestarted":
                app.toasts.showInfoToast("components.DockerBrowser.docker", this.context.locale.getMessage(`components.DockerBrowser.containerRestarted`, event.payload.extras.name));
                break;
        }

        // Reload table as container status has changed
        await this.table.load();
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Dark",
            url: `${this.context.options.host}/api/docker/containers`,
            http: this.context.invipo.http,
            refresh: 15,
            filter: {
                title: "components.DockerBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "Reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 1000
            },
            table: {
                style: "Dark",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                autosort: true,
                groups: {
                    property: (data: any) => {
                        return data.labels["com.invipo.group"];
                    },
                    sort: (a: TableGroup, b: TableGroup): number => {
                        return GROUP_NAME_ORDER[a.value] - GROUP_NAME_ORDER[b.value];
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: (data: any) => {
                            return data.labels["com.invipo.name"];
                        },
                        formatter: (value: any, data: any) => {
                            return Templates.renderPartial("status-formatter", {
                                style: "Dark",
                                status: STATE_STATUS_MAP[data.status],
                                text: data.labels["com.invipo.name"]
                            })
                        },
                        label: "tables.columns.name",
                        width: 450,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "service",
                        type: "String",
                        property: "service",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${data.labels["com.docker.compose.service"]}</div>`;
                        },
                        label: "tables.columns.service",
                        width: 350
                    },
                    {
                        name: "duration",
                        type: "String",
                        property: (data: any) => {
                            if (data.status == "running") {
                                return (new Date().getTime() - new Date(data.started).getTime()) / 1000;
                            }
                            else {
                                return (new Date().getTime() - new Date(data.finished).getTime()) / 1000;
                            }
                        },
                        formatter: (value: any, data: any) => {
                            if (value <= 300) {
                                return `<div class="cell cell-left">${Templates.renderPartial("label", {
                                    style: "Dark",
                                    icon: "Error",
                                    label: Helpers.toDuration(value)
                                })}</div>`;
                            }
                            else {
                                return `<div class="cell cell-left">${Helpers.toDuration(value)}</div>`;
                            }
                        },
                        label: "tables.columns.duration",
                        ellipsis: true,
                        minWidth: 160
                    }
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: DockerDetailOptions = {
            style: "Dark",
            title: data.labels["com.invipo.name"],
            subtitle: "components.DockerDetail.dockerContainer",
            containerId: data.id,
            reloadable: true,
            closable: true,
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Merge options with existing
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new DockerDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.containerId);
            this.detail = null;
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

}
