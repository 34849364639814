import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection, GeoJSON } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";

export class EnergyConsumptionAreaBoundaryLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "road-label",
            layer: {
                id: "energy-consumption-area-boundary",
                type: "line",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": ["get", "color"],
                    "line-width": 6,
                    "line-opacity": 0,
                    "line-offset": -3,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "line-opacity": OCCUPANCY_STROKE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load areas
        let areas = this.context.data.getAreas({
            type: "EnergyConsumption,SmartBuilding"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let area of areas) {
            // Current consumption
            let consumption = await this.context.invipo.getQuery<number>("consumption-current", `area.id=${area.id}`);

            // Unknown last collection
            let color = "#aaaaaa";

            // Consumption is known?
            if (consumption != null) {
                // Consumption color from palette
                let range = Helpers.range(0, METRICS.energy.metering.consumption.colors.length - 1, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], consumption);
                color = METRICS.energy.metering.consumption.colors[Math.round(range)];
            }

            json.features.push({
                type: "Feature",
                properties: {
                    color: color
                },
                geometry: area.geometry
            })
        }

        return json;
    }
}
