import "./message-sign-overview.scss";
import * as template from "./message-sign-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { MessageSignOverviewOptions } from "./types";

export class MessageSignOverview extends DetailPanel<MessageSignOverviewOptions> {

    // Properties
    public history: DetailHistoryValue[];

    constructor(context: InvipoContext, options?: MessageSignOverviewOptions) {
        super(context, template, options);
    }

}
