import './query-table.scss';
import * as template from "./query-table.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { DatasetResult, QueryTableOptions } from "./types";
import { DataTable } from "../data-table/data-table";
import { TitleBar } from "../title-bar/title-bar";
import { TitleBarTag } from "../title-bar/types";
import { Http, HttpMethod } from "hiyo/http";
import { Log } from "../../../hiyo/log";
import { TableColumn, TableRow } from "../data-table/types";
import { MenuItem } from "../overflow-menu/types";
import { Pagination } from "../pagination/pagination";
import { Helpers } from "hiyo/helpers";

const PAGE_SIZE = 30; // Number of records per page

export class QueryTable extends MuklitComponent<Context, QueryTableOptions> {

    // Properties
    public data: any[];
    public http: Http;
    public timeout: any;

    // Components
    public bar: TitleBar;
    public table: DataTable;
    public pagination: Pagination;

    // Event handling methods
    public onTagRemove(tag: TitleBarTag): void {};
    public onMenuSelect(item: MenuItem): void {};
    public onDataSelect(data: any): void {};

    constructor(context: Context, options: QueryTableOptions) {
        super(context, template, options);

        // Http client
        this.http = this.options.http || new Http({ timeout: 10000 });
    }

    public onCreate(): void {
        // Create components
        this.createBar();
        this.createTable();
        this.createPagination();

        // Register component to be automatically attached
        this.registerComponent(this.bar, "bar");
        this.registerComponent(this.table, "table");
        this.registerComponent(this.pagination, "pagination");
    }

    private createBar(): void {
        // Inherit master style
        this.options.bar.style = this.options.style;

        // TODO: pridat a obslouzit standardni menu funkce (ala export table) centralne sem
        this.options.bar.items = this.options.bar.items || [];

        // Create component
        this.bar = new TitleBar(this.context, this.options.bar);

        // Remove tag and update query
        this.bar.onTagRemove = async (tag: TitleBarTag) => {
            // Reload if query
            if (this.options.search) {
                // Remove from query
                delete this.options.search[tag.name];

                // Reload data
                await this.load();
            }

            // OnTagRemove handler
            this.onTagRemove(tag);
        }

        // Handle menu
        this.bar.onItemSelect = (item: MenuItem) => {
            this.onMenuSelect(item);
        }

        // Reload data
        this.bar.onReload = async () => {
            await this.load();
        }

        // Close panel
        this.bar.onClose = () => {
            // Handler only
            this.onClose();
        }
    }

    private createTable(): void {
        // Inherit master style
        this.options.table.style = this.options.style;

        // Create component
        this.table = new DataTable(this.context, this.options.table);

        // Reload on sort
        this.table.onColumnSelect = async (column: TableColumn) => {
            // Force load
            await this.load();
        }

        // OnDataSelect handle
        this.table.onRowSelect = (row: TableRow) => {
            this.onDataSelect(row.data);
        }
    }

    private createPagination(): void {
        // Inherit master style and default page size
        this.options.pagination.style = this.options.style;
        this.options.pagination.pageSize = this.options.pagination.pageSize || PAGE_SIZE;

        // Create component
        this.pagination = new Pagination(this.context, this.options.pagination);

        // Change page
        this.pagination.onSelect = async (page: number) => {
            await this.load();
        }
    }

    public async search(search: any): Promise<any> {
        // Assign new query to options
        this.options.search = search;

        // When query change, we need to reset page
        this.pagination.options.page = 1;

        // Load new data
        await this.load();
    }

    public getUrl(): string {
        // Url with host
        let url = new URL(`${this.options.url}`);

        // Pagination
        url.searchParams.set("pageSize", `${this.pagination.options.pageSize || PAGE_SIZE}`);
        url.searchParams.set("page", `${this.pagination.options.page}`);

        // Add sort by selected column
        let column = this.table.options.columns.find(x => x.selected);
        if (column) {
            // Sort parameter
            let sort = `${column.name}:${column.descendent ? "desc" : "asc"}`;

            // Second sort condition enabled?
            if (column.extraSort) {
                sort += `,${column.extraSort}`;
            }

            // Set final sort parameter
            url.searchParams.set("sort", sort);
        }

        // Query building
        if (this.options.search) {
            for (let key of Object.keys(this.options.search)) {
                let value = this.options.search[key];

                // Skip null value
                if (value == null) {
                    continue;
                }

                // Coma separated values
                if (typeof value == "object") {
                    url.searchParams.set(key, Helpers.toCommaKeys(value));
                }
                // String value
                else {
                    url.searchParams.set(key, value);
                }
            }
        }

        // Return final url as string
        return url.toString();
    }

    public getQuery(): string {
        // Get whole URL
        let url = new URL(this.getUrl());

        // Return only query string without quesstionmark
        return url.search?.substring(1);
    }

    public unselectRow(id: string): void {
        // Unselect row in nest table
        this.table.unselectRow(id);
    }

    public async load(): Promise<void> {
        // Clear timeout to prevent multiple redraw after multiple load() calls
        clearTimeout(this.timeout);

        // Data
        let result: DatasetResult;

        // Show loader
        this.showLoader();

        try {
            // Load data
            result = await this.http.request(HttpMethod.GET, this.getUrl());
        }
        catch (e) {
            Log.e(`Error when loading ${this.id} (${(e.messgae || e.response)})`);
        }

        // Component detached while loading?
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Empty tags
        this.options.bar.tags = [];

        // Update tags
        if (this.options.search != null) {
            for (let key of Object.keys(this.options.search)) {
                let value = this.options.search[key];

                // Empty value?
                if (value == null) {
                    continue;
                }

                this.options.bar.tags.push({
                    name: key,
                    label: (typeof value == "object") ? Object.values<string>(value) : value
                });
            }
        }

        // Update bar with no tags
        this.bar.update();

        // Paged result?
        if (result.data) {
            // Update table with loaded data
            this.table.setData(result.data);

            // Update pagination data
            this.pagination.options.pageCount = result.total > 0 ? Math.floor(result.total / result.pageSize) + 1 : 0;
            this.pagination.options.total = result.total;
            this.pagination.options.start = ((this.pagination.options.page - 1) * this.pagination.options.pageSize) + 1;
            this.pagination.options.end = Math.min(this.pagination.options.page * this.pagination.options.pageSize, this.pagination.options.total);
        }
        // Non-paged result
        else {
            // Update table with loaded data
            this.table.setData(<any>result);

            // Update pagination with only total counts (no paging)
            this.pagination.options.total = (<any>result).length;
        }

        // Update pagination
        this.pagination.update();

        // Autorefresh enabled?
        if (this.options.refresh != null) {
            this.timeout = setTimeout(async () => {
                await this.load();
            }, this.options.refresh * 1000)
        }
    }
}
