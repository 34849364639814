import * as template from "./passport-panel.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { ItemPassportOptions } from "./types";
import { InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../clients/invipo-client/types";
import { ItemPassportForm } from "../../devices/item-passport-form/item-passport-form";
import { DocumentForm } from "../../devices/item-passport-form/document-form/document-form";
import { PropertyForm } from "../../devices/item-passport-form/property-form/property-form";
import { StreamForm } from "../../devices/item-passport-form/stream-form/stream-form";
import { ScheduleForm } from "../../devices/item-passport-form/schedule-form/schedule-form";
import { Panel } from "../../common/panel/panel";
import { PanelKpis, PanelProperties } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { Point } from "geojson";

export class PassportPanel extends Panel<ItemPassportOptions> {

    // Properties
    public general: PanelProperties;
    public passports: PanelKpis;
    public documents: InvipoItemDocument[];
    public stream: InvipoItemStream[];
    public calendar: InvipoItemSchedule[];
    public properties: InvipoItemPassport[];

    constructor(context: InvipoContext, options?: ItemPassportOptions) {
        super(context, template, options);
    }

    public openEdit(tab?: string) {
        // Detail form
        let form = new ItemPassportForm(this.context, {
            style: "Light",
            title: null,
            itemId: this.item.id,
            tab: tab,
            overlay: true,
            closable: true,
            preventEsc: true
        });

        // Refresh on submit
        form.onClose = async () => {
            // Reload table
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openProperty() {
        // Detail form
        let form = new PropertyForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.property",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openStream() {
        // Detail form
        let form = new StreamForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.stream",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openCalendar() {
        // Detail form
        let form = new ScheduleForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.event",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public openDocument() {
        // Detail form
        let form = new DocumentForm(this.context, {
            // title: null,
            title: "components.ItemPassportForm.document",
            style: "Light",
            itemId: this.options.itemId,
            overlay: true,
            closable: true
        });

        // Reload on submit
        form.onClose = async () => {
            await this.load();
        }

        // Show form
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Load passports
        this.documents = await this.context.invipo.getItemDocuments(this.item.id);
        this.properties = await this.context.invipo.getItemPassports(this.item.id);
        this.stream = await this.context.invipo.getItemStream(this.item.id);
        this.calendar = await this.context.invipo.getItemScheduler(this.item.id);

        // Build general properties
        this.general = {
            label: "components.PassportPanel.general",
            size: "Third",
            data: [
                {
                    label: "item.model",
                    value: this.item.model || "common.unknown",
                },
                {
                    label: "item.producer",
                    value: this.item.producer || "common.unknown",
                },
                {
                    label: "item.id",
                    value: this.item.id,
                },
                {
                    label: "item.parent",
                    value: this.item.parent?.name,
                    hidden: (this.item.parent == null)
                },
                {
                    label: "item.managementUrl",
                    value: this.item.managementUrl,
                    link: this.item.managementUrl,
                    hidden: (this.item.managementUrl == null)
                },
                {
                    label: "item.gps",
                    value: `${(<Point>this.item.geometry.location)?.coordinates[1]}, ${(<Point>this.item.geometry.location)?.coordinates[0]}`,
                    hidden: (this.item.geometry.location == null)
                },
                {
                    label: "item.location",
                    value: this.item.location,
                    hidden: (this.item.location == null)
                }
            ]
        }

        // Build passport KPIs
        this.passports = {
            label: "components.PassportPanel.passports",
            size: "Fourth",
            action: "Edit",
            data: [
                {
                    label: "components.PassportPanel.properties",
                    value: Helpers.toNumber(this.properties.length),
                    type: (this.properties.length == 0) ? "Negative" : "Positive",
                    description: (this.properties.length > 0) ? Helpers.toShortDateTimeString(this.properties[0].timestamp) : "components.PassportPanel.noRecord"
                },
                {
                    label: "components.PassportPanel.stream",
                    value: Helpers.toNumber(this.stream.length),
                    type: (this.stream.length == 0) ? "Negative" : "Positive",
                    description: (this.stream.length > 0) ? Helpers.toShortDateTimeString(this.stream[0].timestamp) : "components.PassportPanel.noRecord"
                },
                {
                    label: "components.PassportPanel.calendar",
                    value: Helpers.toNumber(this.calendar.length),
                    type: (this.calendar.length == 0) ? "Negative" : "Positive",
                    description: (this.calendar.length > 0) ? Helpers.toShortDateTimeString(this.calendar[0].timestamp) : "components.PassportPanel.noRecord"
                },
                {
                    label: "components.PassportPanel.documents",
                    value: Helpers.toNumber(this.documents.length),
                    type: (this.documents.length == 0) ? "Negative" : "Positive",
                    description: (this.documents.length > 0) ? Helpers.toShortDateTimeString(this.documents[0].timestamp) : "components.PassportPanel.noRecord"
                }
            ]
        }
    }
}
