import "./user-profile-form.scss";
import * as template from "./user-profile-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { UserProfileFormOptions } from "./types";
import { Toggle } from "muklit/components/toggle/toggle";

export class UserProfileForm extends Detail<InvipoContext, UserProfileFormOptions> {

    // Components
    public form: Form;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: UserProfileFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    private createForm(): void {
        // User profile form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.name",
                            placeholderText: "forms.placeholders.name",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "email",
                            label: "forms.fields.email",
                            placeholderText: "forms.placeholders.email",
                            width: 288,
                            required: true,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Right",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "phone",
                            label: "forms.fields.phone",
                            placeholderText: "forms.placeholders.phone",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new Toggle(this.context, {
                            style: "Light",
                            size: "Tall",
                            name: "otp",
                            label: "forms.fields.otp",
                            note: "components.UserCreateForm.otpNote",
                        }),
                    ]
                }
            ]
        });

        // Submit form on enter
        this.form.onSubmit = async () => {
            await this.submit();
        }
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let profile = this.form.getData();

        // Show loader
        this.showLoader();

        // Update existing user
        try {
            await this.context.invipo.updateUserProfile(this.options.user.id, profile);
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnProfileSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // Set data from options to form
        this.form.setData(this.options.user);
    }

}
