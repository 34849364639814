import "./area-form.scss";
import * as template from "./area-form.hbs";

import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { Tabs } from "muklit/components/tabs/tabs";
import { TextInput } from "muklit/components/text-input/text-input";
import { Select } from "muklit/components/select/select";
import { AreaFormOptions } from "./types";
import { DesignMap } from "muklit/components/design-map/design-map";
import { TabsItem } from "muklit/components/tabs/types";
import { InvipoArea } from "invipo/clients/invipo-client/types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { Polygon } from "geojson";

export class AreaForm extends Detail<InvipoContext, AreaFormOptions> {

    // Components
    public tabs: Tabs;
    public form: Form;
    public map: DesignMap;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: AreaFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs()
        this.createForm();
        this.createMap();

        // Register components that will be always attached
        this.registerComponent(this.tabs, "tabs");
        this.registerComponent(this.form, "form");
        this.registerComponent(this.map, "map");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: this.options.style,
            tabs: [
                {
                    name: "General",
                    label: "components.ItemDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Map",
                    label: "components.ItemDetail.map",
                    content: "div.content-map"
                },
            ]
        });

        this.tabs.onSelect = (item: TabsItem) => {
            // Resize map to fit div.content
            if (item.name == "Map") {
                this.map.resize();
                this.map.fitAll();
            }
        }
    }

    private createForm(): void {
        // item detail form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.name",
                            placeholderText: "forms.placeholders.name",
                            width: 288,
                            bright: true
                        }),
                        new Select(this.context, {
                            style: "Light",
                            name: "type",
                            label: "area.type",
                            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.AreaType")),
                            placeholderText: "forms.placeholders.all",
                            multiselect: false,
                            width: 288,
                            bright: true
                        })
                    ]
                }
            ]
        });
    }

    public createMap () {
        // Create component
        this.map = new DesignMap(this.context, {
            style: this.options.mapStyle || "Light",
            center: this.options.area ? InvipoHelpers.toBounds(this.options.area.geometry).getCenter().toArray() : this.context.options.home.center,
            zoom: 18,
            controls: {
                point: false,
                line: false,
                area: true
            },

        });

        // Edit mode?
        if (this.options.area?.geometry) {
            // Set area features to edit
            this.map.setFeatures([{
                "type": "Feature",
                "properties": {},
                "geometry": this.options.area.geometry
            }]);
        }
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData(true);

        // Merge area with options and form data to not lose anything while updating
        const area: InvipoArea = {
            ...this.options.area,
            ...data,
            geometry: this.map.getGeometry().area
        };

        // Show loader
        this.showLoader();

        // Update existing user
        try {
            // Create new area?
            if (!this.options.area) {
                await this.context.invipo.createArea(area);
            }
            // Or update existing?
            else {
                await this.context.invipo.updateArea(this.options.area.id, area);
            }
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnProfileSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // Set data from options to form
        this.form.setData(this.options.area);
    }

}
