import "./parking-sign-card.scss";
import * as template from "./parking-sign-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { ParkingSignCardOptions } from "./types";
import { CityCard } from "../../city/city-card/city-card";

export class ParkingSignCard extends CityCard<ParkingSignCardOptions> {

    constructor(context: InvipoContext, options: ParkingSignCardOptions) {
        super(context, template, options);
    }

}
