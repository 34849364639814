import "./waste-subdomain.scss";
import * as template from "./waste-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { WasteCollectionAreaBoundaryLayer } from "../../../layers/waste/waste-collection-area-boundary-layer";
import { WasteSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { WasteCollectionCircleLayer } from "../../../layers/waste/waste-collection-circle-layer";
import { WasteCollectionLabelLayer } from "../../../layers/waste/waste-collection-label-layer";

export class WasteSubdomain extends CitySubdomain<WasteSubdomainOptions> {

    constructor(context: InvipoContext, options?: WasteSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Collection",
                    itemClass: "GarbageBin",
                    layers: [
                        new WasteCollectionAreaBoundaryLayer(context),
                        new WasteCollectionCircleLayer(context),
                        new WasteCollectionLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.bins",
            subtitle: "components.WasteSubdomain.bins",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Create volume legend
        this.legend = {
            range: {
                range: METRICS.waste.waste.collection.range,
                colors: METRICS.waste.waste.collection.colors,
                count: 0,
                value: 0,
                unit: "units.days"
            },
            symbols: []
        }
    }
}
