import { Form } from "muklit/components/form/form";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { WasteCollectionStatsOptions } from "./types";
import { WasteCollectionChart } from "../waste-collection-chart/waste-collection-chart";
import { RangeInput } from "../../../../muklit/components/range-input/range-input";

export class WasteCollectionStats extends StatsDetail<WasteCollectionStatsOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                                to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                                range: "LastWeek"
                            },
                            placeholderText: "forms.placeholders.anytime"
                        }),
                        new AutocompleteSelect(this.context, {
                            style: "Light",
                            collection: "Waste",
                            name: "vehicle",
                            label: "forms.fields.vehicle",
                            placeholderText: "forms.placeholders.all",
                            items: [],
                            type: "Vehicle",
                            multiselect: true
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new WasteCollectionChart(this.context, {
            style: "Light",
            queryName: "WasteCollectionByDay",
            view: "Chart",
            title: "components.WasteCollectionStats.header",
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Form togle
        this.chart.onFormToggle = () => {
            this.toggleForm();
        }

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
