import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";

const MIN_ZOOM = 17;

export class ParkingSensorCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            before: "waterway-label",
            card: "ParkingSensorCard",
            detail: "CityDetail",
            layer: {
                id: "parking-sensor-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                minzoom: MIN_ZOOM,
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        17, 4,
                        22, 8
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 1,
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0.9,
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "ParkingSensor"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Occupancy color
            let color = "";

            // No data?
            if (!item.data?.occupancy?.overall) {
                color = "#888888";
            }
            // Occupied?
            else if (item.data.occupancy.overall?.free > 0) {
                color = "#00e66a";
            }
            // Free?
            else {
                color = "#ff4d4d";
            }

            json.features.push({
                type: "Feature",
                properties: {
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
