import "./dialog.scss";
import * as template from "./dialog.hbs";
import { Detail } from "muklit/components/detail/detail";
import { Context } from "hiyo/context";
import { DialogOptions } from "./types";

export class Dialog extends Detail<Context, DialogOptions> {

    // Event handling methods
    public onConfirm(): void {};
    public onCancel(): void {};

    constructor(context: Context, options: DialogOptions) {
        super(context, template, options);
    }

    public close(): void {
        // Detach and handle
        super.close();

        // OnCancel handler
        this.onCancel();
    }

    public confirm(): void {
        // OnConfirm handler
        this.onConfirm();
    }

}
