import { FeatureCollection, Polygon } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, ZOOM_AREA, ZOOM_SEGMENT_LABEL } from "muklit/components/basic-map/map-layer";

export class TrafficVolumeSegmentSymbolLayer extends MapLayer<InvipoContext> {

    // Properties
    public itemId: string;

    public constructor(context: InvipoContext, itemId?: string) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "traffic-volume-segment-symbol",
                type: "symbol",
                minzoom: ZOOM_AREA,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "symbol-placement": "line",
                    "symbol-avoid-edges": true,
                    "icon-image": [
                        "case",
                        ["==", ["get", "bidirectional"], ["boolean", true]], "24-tools-arrow-2-two",
                        "24-tools-arrow_up"
                    ],
                    "icon-rotate": 90,
                    "icon-size": 0.8,
                    "icon-offset": ["get", "offset"],
                    "icon-anchor": "bottom",
                    "icon-allow-overlap": false
                }
            }
        });

        // Assign properties
        this.itemId = itemId;
    }

    public async load(): Promise<FeatureCollection> {
        // Load items
        let items = this.context.data.getItems({
            id: this.itemId,
            class: "TrafficCounter,WimStation",
            features: "TrafficCounting"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No segments defined
            if (!item.meta?.segments?.length) {
                continue;
            }

            // Iterate all segments
            for (let segment of item.meta.segments) {
                json.features.push({
                    type: "Feature",
                    properties: {
                        bidirectional: segment.bidirectional,
                        offset: [segment.offset || 0, 0]
                    },
                    geometry: segment.segment
                });
            }
        }

        return json;
    }
}
