import { EnviBrowser } from "../envi-browser/envi-browser";
import { ItemSelect } from "../../common/item-select/item-select";
import { TextInput } from "muklit/components/text-input/text-input";
import { Helpers } from "hiyo/helpers";
import { Templates } from "hiyo/templates";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { RoadEnviBrowserOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export const CLASS_ROAD_WEATHER_STATIONS = "RoadWeatherStation";

export class RoadEnviBrowser extends EnviBrowser<RoadEnviBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/envi-data?item.class=${CLASS_ROAD_WEATHER_STATIONS}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.RoadEnviBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "common.export"
                    },
                    {
                        name: "Reload",
                        label: "common.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "EnviData",
                                items: []
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "road.surface.scid",
                                label: "forms.fields.roadSurface",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "Dry",
                                        label: "surfaceStatus.Dry"
                                    },
                                    {
                                        name: "Moist",
                                        label: "surfaceStatus.Moist"
                                    },
                                    {
                                        name: "Wet",
                                        label: "surfaceStatus.Wet"
                                    },
                                    {
                                        name: "Frost",
                                        label: "surfaceStatus.Frost"
                                    },
                                    {
                                        name: "Ice",
                                        label: "surfaceStatus.Ice"
                                    },
                                    {
                                        name: "Snow",
                                        label: "surfaceStatus.Snow"
                                    },
                                    {
                                        name: "Slush",
                                        label: "surfaceStatus.Slush"
                                    }
                                ],
                                multiselect: true
                            })
                        ]
                    },
                    {
                        name: "Temperature",
                        fields: [
                            new TextInput(this.context, {
                                style: "Light",
                                name: "road.temperature.min",
                                label: "forms.fields.surfaceTemperatureMin",
                                placeholderText: "forms.placeholders.surfaceTemperatureMin"
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "road.temperature.max",
                                label: "forms.fields.surfaceTemperatureMax",
                                placeholderText: "forms.placeholders.surfaceTemperatureMax"
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 320,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "road.surface.sid",
                        type: "Number",
                        property: "road.surface.scid",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return "";
                            }
                            return `<div class="cell cell-left">${Templates.renderPartial("surface-status", {
                                style: "Light",
                                status: value
                            })}</div>`;
                        },
                        label: "tables.columns.roadSurface",
                        width: 140,
                        sortable: true
                    },
                    {
                        name: "road.temperature",
                        type: "Number",
                        property: "road.temperature",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg; C</div>`
                        },
                        label: "tables.columns.roadTemperature",
                        width: 140,
                        sortable: true
                    },
                    {
                        name: "friction",
                        type: "Number",
                        property: "road.friction",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &percnt;</div>`
                        },
                        label: "tables.columns.friction",
                        sortable: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "EnviCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: new URL(this.table.getUrl()).search.substring(1), // Query part of URL without ? char
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
