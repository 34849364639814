import "./traffic-detail-properties.scss";
import * as template from "./traffic-detail-properties.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { TrafficDetailPropertiesOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { TrafficLightMarker } from "invipo/components/schemas/traffic-light-marker/traffic-light-marker";
import { TrafficDetailElementProperties } from "../traffic-detail-element-properties/traffic-detail-element-properties";
import { TrafficDetectorMarker } from "invipo/components/schemas/traffic-detector-marker/traffic-detector-marker";
import { TrafficDetailTypeSelect } from "../traffic-detail-type-select/traffic-detail-type-select";

export class TrafficDetailProperties extends MuklitComponent<InvipoContext, TrafficDetailPropertiesOptions> {

    // Properties
    public source: Select;
    // public type: Select;
    public type: TrafficDetailTypeSelect;

    // Components

    // Event callbacks
    public onMarkerRemove (marker: MapMarker) {}
    public onMarkerEdit (marker: TrafficLightMarker | TrafficDetectorMarker, instance: number) {}
    public onSourceChange (no: number, markers: MapMarker[]) {}
    public onMarkerTypeChange (markers: MapMarker[]) {}
    public onMarkerChange (marker: MapMarker) {}
    public onNewMarker (type: string, instance: number) {}

    constructor(context: InvipoContext, options?: TrafficDetailPropertiesOptions) {
        super(context, template, options);

        if (!this.options.name) {
            this.options.name = this.options.sources.find(x => x.no === this.options.no)?.name ?? ""
        }
    }

    public onCreate(): void {
        // create components
        this.createSource();
        if (this.options.type === "group") this.createType();

        // register components
        this.registerComponent(this.source, "source");
        this.registerComponent(this.type, "type");

        this.options.markers.forEach(marker => {
            marker.onMove = () => {
                this.onMarkerChange(marker);
            }
        })
    }

    private createSource (): void {
        // Create component
        this.source = new Select(this.context, {
            style: "Light",
            bright: false,
            label: "Source",
            name: "source",
            items: this.options.sources?.map(x => ({ name: x.no.toString(), label: x.name }))
        });

        // Set current value
        this.source.setValue(this.options.no.toString())

        // Update value and notify change
        this.source.onSubmit = () => {
            // Parse current value
            const no = parseInt(Object.keys(this.source.options.value)[0])

            // Update markers source
            for (const marker of this.options.markers) {
                marker.options.no = no;
                marker.update();
            }

            // Notify change
            this.onSourceChange(no, this.options.markers);
        }
    }

    private createType (): void {
        // Select all used types
        const types = this.options.markers.reduce((s, x) => {
            if (!s.includes(x.options.type)) s.push(x.options.type);
            return s;
        }, []);

        // Create component
        this.type = new TrafficDetailTypeSelect(this.context, {
            value: types.length === 1 ? types[0] : undefined,
            values: types.length > 1 ? types : undefined
        });

        // Update value and notify change
        this.type.onSelect = (type: string) => {
            for (const marker of this.options.markers) {
                marker.options.type = type;
                marker.update()
            }
            this.onMarkerTypeChange(this.options.markers);
        }
    }

    public removeInstance (markerId: string) {
        // Find marker and request its removal
        const marker = this.options.markers.find(x => x.id == markerId)
        this.onMarkerRemove(marker);
    }

    public createInstance () {
        // Create new instance
        let type: string = undefined;
        // Select already used type
        if (this.options.type === "group") {
            type = this.type.options.value ?? "AnyThree";
        }

        // Notify update
        this.onNewMarker(type, (this.options.markers?.length ?? 0) + 1);
    }

    public editInstance (index: number) {
        // Find selected marker
        const instance = this.options.markers[index];

        // Request to open Marker edit dialog
        this.onMarkerEdit(instance, index + 1);
    }
}
