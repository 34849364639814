import "./traffic-message-card.scss";
import * as template from "./traffic-message-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { Helpers } from "hiyo/helpers";
import { TrafficMessageCardOptions } from "./types";

export class TrafficMessageCard extends CityCard<TrafficMessageCardOptions> {

    // Properties
    public message: any;

    constructor(context: InvipoContext, options: TrafficMessageCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load event
        this.message = await this.context.invipo.getManagement(`traffic/messages/${this.options.messageId}`);

        // Add duration info
        if (this.message.duration.from && this.message.duration.to) {
            let from = new Date(this.message.duration.from).getTime();
            let to = new Date(this.message.duration.to).getTime();

            // Add suration in seconds and current status in percent
            this.message.duration.time = (to - from) / 1000;
            this.message.duration.percent = Helpers.range(0, 100, from, to, Date.now());
        }
    }
}
