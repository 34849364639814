import "./waze-route-card.scss";
import * as template from "./waze-route-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { WazeRouteCardOptions } from "./types";

export class WazeRouteCard extends CityCard<WazeRouteCardOptions> {

    constructor(context: InvipoContext, options: WazeRouteCardOptions) {
        super(context, template, options);
    }

}
