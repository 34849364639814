import "./traffic-browser.scss";
import * as template from "./traffic-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficBrowserOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { TrafficDetailOptions } from "../traffic-detail/types";
import { TrafficDetail } from "../traffic-detail/traffic-detail";
import { FilterTable } from "muklit/components/filter-table/filter-table";

export abstract class TrafficBrowser<T extends TrafficBrowserOptions> extends MuklitComponent<InvipoContext, T> {

    // Components
    public table: FilterTable;
    public detail: TrafficDetail;

    constructor(context: InvipoContext, options?: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    public abstract createTable(): void;

    protected openDetail(data: any): void {
        data.categories?.sort((a: any, b: any) => {
            return b.count - a.count;
        });

        // Detail options
        let options: TrafficDetailOptions = {
            style: "Light",
            traffic: data,
            title: "components.TrafficDetail.title",
            subtitle: data.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TrafficDetail(this.context, options);

        // Unselect table raw on detail close
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.traffic.id);
        }

        // Open image detail
        this.detail.onImageSelect = (image: any) => {
            this.openImage(image);
        }

        // Show detail
        this.detail.attach();
    }

    public openImage(url: string): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Light",
            title: "components.ImageDetail.title",
            url: `${this.context.options.host}/download${url}`,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
