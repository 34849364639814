import "./login-page.scss";
import * as template from "./login-page.hbs";
import { LoginPageOptions } from "./types";
import { Log } from "hiyo/log";
import { UserPrincipal } from "hiyo/context";
import { TextInput } from "muklit/components/text-input/text-input";
import { InvipoContext } from "../../../context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { Button } from "muklit/components/button/button";
import { Language } from "hiyo/locale-manager";
import { Form } from "muklit/components/form/form";
import { User } from "../../../clients/invipo-client/types";
import { NotificationToast } from "muklit/components/notification-toast/notification-toast";

export class LoginPage extends MuklitComponent<InvipoContext, LoginPageOptions> {

    // Properties
    public verification: boolean;
    public firstAttach: boolean;

    // Components
    public login: Form;
    public code: Form;
    public submit: Button;

    // Event handling methods
    public onUserLogin(user: UserPrincipal): void {};
    public onLanguageChange(lang: Language): void {};

    constructor(context: InvipoContext, options?: LoginPageOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createLogin();
        this.createCode();
        this.createSubmit();

        // Component registration
        this.registerComponent(this.login, "login");
        this.registerComponent(this.code, "code");
        this.registerComponent(this.submit, "submit");
    }

    public onAttach(): void {
        // Auto-focus in verification
        if (this.verification) {
            this.code.getField<TextInput>("code").focus();
        }
        // Autofocus in login
        else {
            this.login.getField<TextInput>("username").focus();
        }

        // First attach
        if (!this.firstAttach) {
            // Animation on first attach
            this.animate({
                "div.motive": "keyframes-login-page-motive-fade 800ms ease-in-out",
                "div.content": "keyframes-login-page-content-fade 800ms ease-in-out"
            });

            this.firstAttach = true;
        }
    }

    private createLogin(): void {
        // Username input
        let username = new TextInput(this.context, {
            style: "Light",
            label: "forms.fields.username",
            name: "username",
            placeholderText: "forms.placeholders.username",
            width: 280
        });

        // Password input
        let password = new TextInput(this.context, {
            style: "Light",
            name: "password",
            label: "forms.fields.password",
            placeholderText: "forms.placeholders.password",
            password: true,
            width: 280
        });

        // Login form
        this.login = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "general",
                    fields: [username, password]
                }
            ]
        })

        // Try to log in
        this.login.onSubmit = () => {
            this.submit.click();
        }
    }

    private createCode(): void {
        // Code input
        let code = new TextInput(this.context, {
            style: "Light",
            label: "forms.fields.code",
            name: "code",
            autocomplete: "one-time-code",
            placeholderText: "forms.placeholders.code",
            messageText: "Enter the 6 digit code sent to your phone",
            width: 280
        });

        // Code form
        this.code = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "general",
                    fields: [code]
                }
            ]
        })

        // Try to log in
        this.code.onSubmit = () => {
            this.submit.click();
        }
    }

    private createSubmit(): void {
        // Create component
        this.submit = new Button(this.context, {
            style: "Light",
            type: "LabelOnly",
            kind: "Primary",
            size: "Medium",
            label: "components.LoginPage.submit",
            width: "180px"
        })

        // Submit click handler
        this.submit.onClick = async () => {
            // Code verification mode
            if (this.verification) {
                await this.tryVerify();
            }
            // Username and password mode
            else {
                await this.tryLogin();
            }
        }
    }

    public changeLanguage(lang: Language): void {
        // Set new langId into context
        this.context.locale.setLangId(lang);

        // Redraw
        this.invalidate();

        // Event callback
        this.onLanguageChange(lang);
    }

    private loginUser(user: User): void {
        // Save user to context
        this.context.setUser({ ...user });

        // Enable authorization
        this.context.invipo.enableBasicAuthorization(user.token);
        this.context.invipo.enableUserUuid(user.uuid);

        Log.i(`User "${user.name}" logged in`);

        // OnUserLogin handler
        this.onUserLogin(this.context.user);
    }

    public async resendCode(): Promise<void> {
        // Resubmit login and password
        await this.tryLogin();

        // Show toast
        new NotificationToast(this.context, {
            kind: "Info",
            title: "components.LoginPage.verificationCode",
            message: "components.LoginPage.codeWasResent",
            timestamp: new Date(),
            duration: 3600
        }).attach();
    }

    private async tryLogin(): Promise<void> {
        // Empty login or password?
        let login = this.login.getData();

        // Clear password
        this.login.getField("password").setInvalid(false);

        // Show loader
        this.showLoader();

        try {
            let user = await this.context.invipo.loginUser(login.username, login.password);

            // Set user principal and call login handler
            this.loginUser(user);
        }
        catch (e) {
            // 2FA enabled?
            if (e.status == 423) {
                // Set state to verification and invalidate
                this.verification = true;
                this.invalidate();
            }
            // Unauthorized?
            else if (e.status == 401) {
                // Show error in form
                let password = this.login.getField<TextInput>("password");
                password.setValue(null);
                password.setInvalid(true, "components.LoginPage.invalidCredentials");
                password.focus();
            }
            // Unknown error?
            else {
                // Show error in form
                let password = this.login.getField<TextInput>("password");
                password.setInvalid(true, "components.LoginPage.invalidCredentials");
            }
        }
        finally {
            // Hide loader
            this.hideLoader();
        }
    }

    private async trySsoLogin(): Promise<void> {
        // Show loader
        this.showLoader();

        try {
            // Oauth code
            let code = new URL(document.location.href).searchParams.get("code");

            // Login via oauth provider
            let user = await this.context.invipo.loginSsoUser(this.context.options.sso.provider, code);

            // Set user principal and call login handler
            this.loginUser(user);
        }
        catch (e) {
            // Unauthorized?
            if (e.status == 401) {
                // Show error in form
                let password = this.login.getField<TextInput>("password");
                password.setValue(null);
                password.setInvalid(true, "components.LoginPage.invalidSso");
            }
        }
        finally {
            // Hide loader
            this.hideLoader();
        }
    }

    private async tryVerify(): Promise<void> {
        let login = this.login.getData(true);
        let code = this.code.getData(true);

        // Empty or malformed code?
        if (!code.code || code.code.length != 6) {
            this.code.getField("code").setInvalid(true, "components.LoginPage.invalidCode");
            return;
        }

        // Empty code
        this.code.getField("code").setInvalid(false);

        // Show loader
        this.showLoader();

        try {
            // Login
            const user = await this.context.invipo.loginUser(login.username, login.password, code.code);

            // Set user principal and call login handler
            this.loginUser(user);
        }
        catch (e) {
            // 2FA enabled?
            if (e.status == 410) {
                // Code has expired
                this.verification = false;
                this.invalidate()
            }
            // Unauthorized?
            else if (e.status == 401) {
                // Show error in form
                let code = this.code.getField<TextInput>("code");
                code.setValue(null);
                code.setInvalid(true, "components.LoginPage.invalidCode");
                code.setDisabled(false);
                code.focus();
            }
            // Unknown error?
            else {
                // Show error in form
                let code = this.code.getField<TextInput>("code");
                code.setValue(null);
                code.setInvalid(true, "components.LoginPage.serverError");
                code.setDisabled(false);
                code.focus();
            }
        }
        finally {
            // Hide loader
            this.hideLoader();
        }
    }

    async load(): Promise<void> {
        // Oauth code
        let code = new URL(document.location.href).searchParams.get("code");

        // SSO enabled and code recieved?
        if (this.context.options.sso && code) {
            Log.i(`LoginPage: SSO enabled and code detected, will try to login via provider`);

            // Perform autologin with oauth code
            await this.trySsoLogin();
        }
    }
}
