import "./energy-meter-overview.scss";
import * as template from "./energy-meter-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { EnergyMeterOverviewOptions } from "./types";

export class EnergyMeterOverview extends DetailPanel<EnergyMeterOverviewOptions> {

    // Properties
    public history: { [data: string]: DetailHistoryValue[] };

    constructor(context: InvipoContext, options?: EnergyMeterOverviewOptions) {
        super(context, template, options);
    }

}
