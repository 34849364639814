import { Chart, ChartTooltipModel } from "chart.js";
import { Helpers } from "hiyo/helpers";
import { DataTable } from "muklit/components/data-table/data-table";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";
import { InvipoHelpers } from "../../../invipo-helpers";
import { DEFAULT_FONT, StatsChart } from "../../common/stats-chart/stats-chart";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { RoadTemperatureChartOptions } from "./types";

export class RoadTemperatureChart extends StatsChart<RoadTemperatureChartOptions> {

    // Properties
    public item: InvipoItem[];

    public createLegend() {
        this.legend = [
            {
                type: "Line",
                label: "tables.columns.roadTemperature",
                color: InvipoHelpers.toChartColor(5)
            },
            {
                type: "Line",
                label: "tables.columns.airTemperature",
                color: InvipoHelpers.toChartColor(1)
            }
        ];
    }

    public createHighlights(): void {
        // Reset
        this.highlights = {};

        // Sum speed
        let temperature = 0;

        // First and last
        let first = this.data[0];
        let last = this.data[this.data.length - 1];

        // Minimum and maximum
        let min = { ...first };
        let max = { ...first };

        for (let data of this.data) {
            if (data.road?.temperature < min.road.temperature) {
                min = data;
            }
            if (data.road?.temperature > max.road.temperature) {
                max = data;
            }
        }

        // Maximum
        this.highlights.highest = {
            label: "components.StatsChart.highlights.highest",
            value: Helpers.toNumber(max.road.temperature, 1),
            units: "units.celsius",
            description: Helpers.toDateTimeString(max.timestamp)
        }

        // Minimum
        this.highlights.lowest = {
            label: "components.StatsChart.highlights.lowest",
            value: Helpers.toNumber(min.road.temperature, 1),
            units: "units.celsius",
            description: Helpers.toDateTimeString(min.timestamp)
        }
    }

    public createTable() {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            autosort: true,
            data: this.data,
            rows: {
                id: "timestamp"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    width: 160,
                    sortable: true,
                    selected: true
                },
                {
                    name: "road.temperature",
                    type: "Number",
                    property: "road.temperature",
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return null;
                        }
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg; C</div>`
                    },
                    label: "tables.columns.roadTemperature",
                    width: 120,
                    align: "Right",
                    sortable: true
                },
                {
                    name: "air.temperature",
                    type: "Number",
                    property: "air.temperature",
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return null;
                        }
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg; C</div>`
                    },
                    label: "tables.columns.airTemperature",
                    width: 120,
                    align: "Right",
                    sortable: true
                },
                {
                    name: "last",
                    type: "String",
                    property: "last",
                    label: null,
                    align: "Center"
                }
            ]
        });
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = (this.query<HTMLCanvasElement>("div.chart canvas"));

        // Labels and data
        let labels: any[] = [];
        let airTemperatureData: any[] = [];
        let roadTemperatureData: any[] = [];

        for (let d of this.data) {
            labels.push(d.timestamp);
            airTemperatureData.push(d.air?.temperature);
            roadTemperatureData.push(d.road?.temperature);
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "tables.columns.roadTemperature",
                            data: roadTemperatureData,
                            yAxisID: "left",
                            borderWidth: 2,
                            pointRadius: 0,
                            hoverRadius: 0,
                            borderColor: InvipoHelpers.toChartColor(5),
                            lineTension: 0.2,
                            fill: false
                        },
                        {
                            label: "tables.columns.airTemperature",
                            data: airTemperatureData,
                            yAxisID: "left",
                            borderWidth: 2,
                            pointRadius: 0,
                            hoverRadius: 0,
                            borderColor: InvipoHelpers.toChartColor(1),
                            lineTension: 0.2,
                            fill: false
                        },
                    ]
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints) {
                                let data = model.dataPoints[0];
                                let text = "";

                                // Metrics text
                                if (data.datasetIndex == 0) {
                                    text = `Road temperature<br />${Helpers.toNumber(data.value, 1)} °C<br />${Helpers.toDateTimeString(data.label)}`;
                                }
                                else if (data.datasetIndex == 1) {
                                    text = `Air temperature<br />${Helpers.toNumber(data.value, 1)} °C<br />${Helpers.toDateTimeString(data.label)}`;
                                }

                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    style: "Dark",
                                    text: text
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                type: "time",
                                time: {
                                    unit: "day",
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    min: this.options.search?.from ? Object.keys(this.options.search.from)[0] : null,
                                    max: this.options.search?.to ? Object.keys(this.options.search.to)[0] : null,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toShortDateString(value)}`;
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                id: "left",
                                display: true,
                                ticks: {
                                    ...DEFAULT_FONT,
                                    //stepSize: Helpers.calculateStepSize(Math.max(...data), 4),
                                    //stepSize: 5,
                                    min: -20,
                                    max: +60,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)} °`;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.35)",
                                    borderDash: [2, 0, 2],
                                    zeroLineColor: "rgba(141, 141, 141, 0.35)",
                                    drawOnChartArea: true,
                                }
                            }
                        ]
                    }
                }
            });
    }

    public async load(): Promise<void> {
        // Item not selected?
        if (!this.options.search["item.id"]) {
            // Reset data
            this.data = null;

            // Update and return
            return this.update();
        }

        // Call load to complete data loading
        await super.load();
    }

}
