import "./item-browser.scss";
import * as template from "./item-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemBrowserOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { Search } from "muklit/components/search/search";
import { Templates } from "hiyo/templates";
import { MonitoringDetail } from "../monitoring-detail/monitoring-detail";
import { MonitoringDetailOptions } from "../monitoring-detail/types";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { FilterTable } from "muklit/components/filter-table/filter-table";

export class ItemBrowser extends MuklitComponent<InvipoContext, ItemBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: MonitoringDetail;

    constructor(context: InvipoContext, options?: ItemBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onAttach(): void {
        // Reattach detail if exists
        if (this.detail && !this.detail?.isAttached()) {
            this.detail.attach();
        }
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create form
        // Search select
        let search = new Search(this.context, {
            style: "Dark",
            name: "search",
            label: "forms.fields.search",
            placeholderText: "forms.placeholders.itemSearch"
        });

        // Status select
        let status = new Select(this.context, {
            style: "Dark",
            name: "monitoringStatus.status",
            label: "item.monitoringStatus",
            placeholderText: "forms.placeholders.all",
            multiselect: true,
            items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("monitoringStatus"))
        });

        // Class select
        let clazz = new Select(this.context, {
            style: "Dark",
            name: "class",
            label: "item.class",
            placeholderText: "forms.placeholders.all",
            multiselect: false,
            items: InvipoHelpers.toClassItems(this.context.options.classes, (a, b) => {
                return this.context.locale.getMessage(a.label).localeCompare(this.context.locale.getMessage(b.label));
            })
        })

        // Model select
        let model = new AutocompleteSelect(this.context, {
            style: "Dark",
            collection: "Items",
            name: "model",
            label: "item.model",
            placeholderText: "forms.placeholders.model",
            items: [],
            type: "Model"
        });

        // Producer select
        let producer = new AutocompleteSelect(this.context, {
            style: "Dark",
            collection: "Items",
            name: "producer",
            label: "item.producer",
            placeholderText: "forms.placeholders.producer",
            items: [],
            type: "Producer"
        });

        // Filter models and poducers on class select
        clazz.onSubmit = () => {
            // Form data
            let data = this.table.form.getData();

            // Get class items to filter out models and producers
            let items = this.context.data.getItems({
                class: Object.keys(data.class).join(",")
            })

            // Empty sleects
            model.options.items = [];
            producer.options.items = [];

            for (let item of items) {
                // Model items
                if (!model.options.items.find(x => x.name == item.model)) {
                    model.options.items.push({
                        name: item.model,
                        label: item.model
                    });
                }

                // Producer items
                if (!producer.options.items.find(x => x.name == item.producer)) {
                    producer.options.items.push({
                        name: item.producer,
                        label: item.producer
                    });
                }
            }

            // Submit form
            this.table.form.submit();
        }

        // Reset models and producers on class deselect
        clazz.onClear = async () => {
            // Reload full item lists
            await model.load();
            await producer.load();
        }

        // Create component
        this.table = new FilterTable(this.context, {
            style: "Dark",
            url: `${this.context.options.host}/api/data/datasets/items?class=${this.context.options.classes.join(",")}`,
            http: this.context.invipo.http,
            filter: {
                title: "components.ItemBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            search,
                            status,
                            clazz,
                            model,
                            producer
                        ]
                    }
                ]
            },
            pagination: {
                page: 1
            },
            table: {
                style: "Dark",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "name",
                        type: "String",
                        property: "name",
                        formatter: (value: any, data: any) => {
                            return Templates.renderPartial("status-formatter", {
                                style: "Dark",
                                status: data.monitoringStatus?.status,
                                text: data.name
                            })
                        },
                        label: "item.name",
                        width: 320,
                        sortable: true,
                        selected: true
                    },
                    {
                        name: "class",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`classes.${data.class}`);
                        },
                        label: "item.class",
                        ellipsis: true,
                        sortable: true,
                        width: 200
                    },
                    {
                        name: "model",
                        type: "String",
                        property: "model",
                        label: "item.model",
                        ellipsis: true,
                        sortable: true,
                        width: 200,
                    },
                    {
                        name: "availability.value",
                        type: "Number",
                        property: "availability.value",
                        formatter: (value: any, data: any) => {
                            if (value != null) {
                                return `<div class="cell">${Templates.renderPartial("percentage-formatter", {
                                    style: "Dark",
                                    value: value
                                })}</div>`
                            }
                            else {
                                return `<div class="cell">Unknown</div>`;
                            }
                        },
                        label: "item.availability",
                        ellipsis: true,
                        sortable: true,
                        width: 280
                    },
                    {
                        name: "uptime",
                        type: "Duration",
                        property: "availability.uptime",
                        label: "Uptime",
                        ellipsis: true,
                        minWidth: 150
                    },
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: any): Promise<void> {
        // Detail options
        let options: MonitoringDetailOptions = {
            style: "Dark",
            title: data.name,
            subtitle: `classes.${data.class}`,
            itemId: data.id,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Force reload
            await this.detail.load();

            // Not continue
            return;
        }

        // New detail
        this.detail = new MonitoringDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.itemId);
            this.detail = null;
        }

        this.detail.onMoreHistory = () => {
            //(<MonitoringView>this.context.application.currentView).menu.select("Technology", "LogBrowser");
        }
        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

}
