import "./title-bar.scss";
import * as template from "./title-bar.hbs";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { TitleBarOptions, TitleBarTag } from "./types";
import { MenuItem } from "../overflow-menu/types";
import { OverflowMenu } from "../overflow-menu/overflow-menu";

export class TitleBar extends MuklitComponent<Context, TitleBarOptions> {

    // Components
    public menu: OverflowMenu;

    // Event handling methods
    public onItemSelect(item: MenuItem): void {};
    public onTagRemove(tag: TitleBarTag): void {};
    public onReload(): void {};

    constructor(context: Context, options: TitleBarOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
    }

    private createMenu(): void {
        // Items are empty, menu will be never activated
        if (!this.options.items) {
            return;
        }

        // Create component
        this.menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: this.options.items,
            start: "BottomRight",
            anchor: "TopRight"
        })

        // Reset value when selected from menu
        this.menu.onSelect = (item: MenuItem) => {
            // OnItemSelect handler
            this.onItemSelect(item);
        }
    }

    public selectMenu(): void {
        // Show menu
        this.menu.show(this.query("div.icon-menu"));
    }

    public removeTag(i: number): void {
        // Remember tag
        let tag = this.options.tags[i];

        // Remove tag
        this.options.tags.splice(i, 1);

        // Update
        this.update();

        // OnItemSelect handler
        this.onTagRemove(tag);
    }

    public reload(): void {
        // OnReload handler
        this.onReload();
    }

    public close(): void {
        // OnClose handler
        this.onClose();
    }
}
