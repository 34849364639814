import "./traffic-detail-schema.scss";
import * as template from "./traffic-detail-schema.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { TrafficDetailSchemaOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Log } from "hiyo/log";
import { OrtoMarker } from "invipo/components/schemas/orto-marker/orto-marker";
import { COMPONENT_STORE } from "../../../store";
import { TrafficLightMarker } from "../../schemas/traffic-light-marker/traffic-light-marker";
import { TrafficDetectorMarker } from "../../schemas/traffic-detector-marker/traffic-detector-marker";
import { MapControl } from "../../common/map-control/map-control";
import { LngLatBounds } from "mapbox-gl";
import { TrafficDetailRepository } from "./traffic-detail-repository/traffic-detail-repository";
import { TrafficDetailProperties } from "./traffic-detail-properties/traffic-detail-properties";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { TrafficDetailElementProperties } from "./traffic-detail-element-properties/traffic-detail-element-properties";
import { HiyoEvent } from "hiyo/event-broker";
import { Button } from "muklit/components/button/button";

export class TrafficDetailSchema extends MuklitComponent<InvipoContext, TrafficDetailSchemaOptions> {

    // Properties
    public data: any;
    public groups: any[];
    public detectors: any[];

    // Components
    public map: BasicMap;
    public control: MapControl;
    public button: Button;
    public repository: TrafficDetailRepository;
    public properties: TrafficDetailProperties;
    public elementProperties: TrafficDetailElementProperties;

    constructor(context: InvipoContext, options?: TrafficDetailSchemaOptions) {
        super(context, template, options);
    }

    public onSubmit(): void {}

    public onAttach() {
        this.context.broker.subscribe(this, ["TrafficControlDataReceived"]);
    }

    public onDetach(): void {
        this.context.broker.unsubscribe(this);
    }

    public onHandle(event: HiyoEvent) {
        if (event.type == "TrafficControlDataReceived" && event.payload.item.id == this.options.itemId) {
            Object.values(this.map.markers).forEach(x => x.onHandle(event))
        }
    }

    public onCreate(): void {
        // create components
        this.createMap();
        this.createControl();
        this.createRepository();
        this.createButton();

        // register components
        this.registerComponent(this.map, "map");
        this.registerComponent(this.control, "control");
        this.registerComponent(this.repository, "repository");
    }

    public onLoad(): void {
        // Resize map
        this.map.resize();

        // Update repository
        this.repository.options.item = this.data.item;
        this.repository.invalidate();

        // Orto map definition json in item
        let orto = this.data.item?.schema?.orto;

        if (!orto) {
            // Use default center
            this.map.setCenter(this.data.item.geometry.location.coordinates);
            this.map.setZoom(12);

            Log.w(`${this.id} could not find item.schema.orto to draw a detail map`);
            return;
        }

        // Center and zoom map
        if (orto.map) {
            this.map.setCenter(orto.map.center);
            this.map.setZoom(orto.map.zoom);
            this.map.setMinZoom(orto.map.minZoom);
            this.map.setMaxZoom(orto.map.maxZoom);
        } else {
            this.map.setCenter(this.data.item.geometry.location.coordinates);
            this.map.setZoom(12);
        }

        // Add markers
        for (let m of orto.markers) {
            // Create new marker
            const marker = this.createMarker(m);

            // Add to map
            this.map.addMarker(marker);
        }
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        // this.data = await this.context.invipo.getModel("traffic-light-controller-detail", `item.id=${this.options.itemId}`);
        this.data = { item: await this.context.invipo.getItem(this.options.itemId) }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Update groups and detectors
        let markers = this.data.item?.schema?.orto?.markers ?? [];

        this.groups = this.data.item?.meta?.groups?.map((x: any) => ({
            no: x.no,
            name: x.name,
            count: markers.filter((m: any) => m.name === "TrafficLightMarker" && m.options.no === x.no).length
        })) ?? [];

        this.detectors = this.data.item?.meta?.detectors?.map((x: any) => ({
            no: x.no,
            name: x.name,
            count: markers.filter((m: any) => m.name === "TrafficDetectorMarker" && m.options.no === x.no).length
        })) ?? [];

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

    private createMap (): void {``
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Satellite",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 18,
            maxZoom: 21
        });

        // Zoom arrangements
        this.map.onMapZoom = (zoom: number) => {
            this.control.setZoomInDisabled(zoom >= this.map.options.maxZoom);
            this.control.setZoomOutDisabled(zoom <= this.map.options.minZoom);
        }
    }

    private createControl(): void {
        // Create component
        this.control = new MapControl(this.context, {
            style: "Light",
            styleItems: [
                {
                    name: "Light",
                    label: "enums.MapStyle.Light"
                },
                {
                    name: "Satellite",
                    label: "enums.MapStyle.Satellite"
                }
            ]
        });

        // Zoom in map
        this.control.onZoomInSelect = () => {
            this.map.zoomIn();
        }

        // Zoom out map
        this.control.onZoomOutSelect = () => {
            this.map.zoomOut();
        }

        // Zoom to all
        this.control.onZoomAllSelect = () => {
            // this.map.fitAll();
            let bounds = new LngLatBounds();

            // Get bounds of all layers
            for (const marker of Object.values(this.map.markers)) {
                bounds.extend(marker.mapboxMarker.getLngLat());
            }

            if (!bounds.isEmpty()) {
                this.map.fitBounds(bounds);
            }
        }

        // Zoom to default position
        this.control.onZoomHomeSelect = () => {
            this.map.flyTo( {
                center: this.data.item.geometry.location.coordinates,
                zoom: this.context.options.home.zoom,
                duration: 3000
            })
        }

        // Change map style
        this.control.onStyleSelect = (name: string) => {
            this.map.setStyle(name);
        }
    }

    private createRepository () {
        this.repository = new TrafficDetailRepository(this.context, {
            itemId: this.options.itemId,
            item: this.data?.item
        });

        this.repository.onGroupSelected = (no: number) => {
            const group = this.groups.find(x => x.no == no);

            this.editMarkers(group.no, "group");
        }

        this.repository.onDetectorSelected = (no: number) => {
            const detector = this.detectors.find(x => x.no == no);

            this.editMarkers(detector.no, "detector");
        }
    }

    private createButton(): void {
        // Create component
        this.button = new Button(this.context, {
            style: "Light",
            kind: "Primary",
            size: "Medium",
            type: "LabelOnly",
            label: "labels.save",
            align: "Center",
            width: "80px"
        })

        // Save form
        this.button.onClick = async () => {
            await this.submit();
        }

        // Register component
        this.registerComponent(this.button, "button");
    }

    private createMarker (markerData: any): MapMarker {
        // Create topo marker from schema
        let marker: OrtoMarker = new COMPONENT_STORE[markerData.name](this.context, {
            ...markerData.options,
            draggable: true,
            itemId: this.data.item.id,
        });

        // Group highlight on
        marker.onSelect = () => {
            // Highlight selected item in repository
            if (marker instanceof TrafficLightMarker) {
                this.repository.selectGroup(marker.options.no)
                this.editMarkers(marker.options.no, "group");
            }
            if (marker instanceof TrafficDetectorMarker) {
                this.repository.selectDetector(marker.options.no)
                this.editMarkers(marker.options.no, "detector");
            }

        }

        // Group highlight off
        marker.onUnselect = () => {
            // Remove highlight selected item in repository
            if (marker instanceof TrafficLightMarker) {
                this.repository.selectGroup(marker.options.no, false)
            }
            if (marker instanceof TrafficDetectorMarker) {
                this.repository.selectDetector(marker.options.no, false)
            }

            Object.values(this.map.markers).forEach(x => x.click(false));

            // close all edit panels
            if (this.properties?.isAttached()) this.properties.detach();
            if (this.elementProperties?.isAttached()) this.elementProperties.detach();
        }

        marker.onMove = () => {
            // update item data
            this.updateItemMarkers();
        }

        return marker
    }

    public editMarkers(no: number, type: "group" | "detector"): void {
        // Close all edit panels
        if (this.properties?.isAttached()) this.properties.detach();
        if (this.elementProperties?.isAttached()) this.elementProperties.detach();

        // Remove highlight from all markers
        let markers = <Array<TrafficLightMarker | TrafficDetectorMarker>>Object.values(this.map.markers);
        markers.forEach(x => x.click(false))

        // Select all relevant markers
        if (type === "group") {
            markers = markers.filter((x: TrafficLightMarker) => x instanceof TrafficLightMarker && x.options.no === no);
            this.repository.selectGroup(no)
        }
        if (type === "detector") {
            markers = markers.filter((x: TrafficDetectorMarker) => x instanceof TrafficDetectorMarker && x.options.no === no);
            this.repository.selectDetector(no);
        }

        // Highlight selected markers
        markers.forEach(x => x.click(true));

        // Create new properties panel
        this.properties = new TrafficDetailProperties(this.context, {
            type,
            sources: type === "group" ? this.groups : this.detectors,
            markers,
            no
        });
        this.properties.attach(this.element)

        this.properties.onMarkerRemove = (marker) => {
            this.map.removeMarker(marker.id);

            // redraw TrafficDetailProperties panel
            this.editMarkers(no, type);

            // update item data
            this.updateItemMarkers()
        }

        this.properties.onSourceChange = (no) => {
            // redraw TrafficDetailProperties panel
            this.editMarkers(no, type);

            // update item data
            this.updateItemMarkers()
        }

        this.properties.onMarkerTypeChange = (markers) => {
            // redraw TrafficDetailProperties panel
            this.editMarkers(no, type);

            // update item data
            this.updateItemMarkers();
        }

        this.properties.onNewMarker = (markerType, instance) => {
            // create new orto.markers configuration for new marker
            const markerData = {
                name: type === "group" ? "TrafficLightMarker" : "TrafficDetectorMarker",
                options: {
                    no,
                    type: markerType,
                    scale: 1,
                    rotation: 12,
                    position: this.data.item.geometry.location.coordinates
                }
            }

            // create and select new marker
            const marker = <TrafficLightMarker | TrafficDetectorMarker>this.createMarker(markerData);
            this.map.addMarker(marker);

            // update item data
            this.updateItemMarkers();

            // Open edit panel for selected instance
            this.editMarker(no, type, marker, instance);

        }

        this.properties.onMarkerEdit = (marker, instance) => {
            // Remove highlight from all markers
            Object.values(this.map.markers).forEach((x: MapMarker) => x.click(false));

            // Open edit panel for selected instance
            this.editMarker(no, type, marker, instance);
        }

        this.properties.onMarkerChange = (marker) => {
            // update item data
            this.updateItemMarkers();
        }
    }

    public editMarker (no: number, type: "group" | "detector", marker: TrafficLightMarker | TrafficDetectorMarker, instance: number) {
        // Close all edit panels
        if (this.properties?.isAttached()) this.properties.detach();
        if (this.elementProperties?.isAttached()) this.elementProperties.detach();

        // Remove highlight from all markers
        Object.values(this.map.markers).forEach(x => x.click(false))

        // Highlight current panel
        marker.click(true);

        // Create new element properties panel
        this.elementProperties = new TrafficDetailElementProperties(this.context, {
            type,
            sources: type === "group" ? this.groups : this.detectors,
            marker,
            no,
            instance,
        });
        this.elementProperties.attach(this.element);

        this.elementProperties.onDetach = () => {
            marker.click(false);
        }

        this.elementProperties.onBack = () => {
            this.editMarkers(no, type);
        }

        this.elementProperties.onMarkerRemove = (marker) => {
            this.map.removeMarker(marker.id);

            // update item data
            this.updateItemMarkers()
        }

        this.elementProperties.onMarkerChange = () => {
            // update item data
            this.updateItemMarkers();
        }
    }

    public updateItemMarkers () {
        const markers = <Array<TrafficLightMarker | TrafficDetectorMarker>>Object.values(this.map.markers);

        this.data.item.schema ||= {};
        this.data.item.schema.orto ||= {};
        this.data.item.schema.orto.map ||= {};
        this.data.item.schema.orto.markers = [];

        const center = this.map.mapboxMap.getCenter()
        this.data.item.schema.orto.map.center = [center.lng, center.lat]
        this.data.item.schema.orto.map.zoom = this.map.mapboxMap.getZoom()
        // this.data.item.schema.orto.map.minZoom
        // this.data.item.schema.orto.map.maxZoom

        for (const marker of markers) {
            this.data.item.schema.orto.markers.push({
                name: marker instanceof TrafficLightMarker ? "TrafficLightMarker" : "TrafficDetectorMarker",
                options: {
                    no: marker.options.no,
                    position: marker.options.position,
                    rotation: marker.options.rotation,
                    scale: marker.options.scale,
                    type: marker.options.type,
                }
            });
        }

        // redraw repository panel
        this.repository.options.item = this.data.item;
        this.repository.invalidate();

        // save data
        // this.context.invipo.updateItem(this.data.item.id, this.data.item);
    }

    private async submit(): Promise<void> {
        // Show loader
        this.showLoader();

        // save data
        await this.context.invipo.updateItemSchema(this.data.item.id, { schema: this.data.item.schema});

        // Hide
        this.onSubmit();
    }
}
