import "./street-light-controller-overview.scss";
import * as template from "./street-light-controller-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { StreetLightControllerOverviewOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { Point } from "geojson";
import { StreetLightStateCircleLayer } from "../../../layers/energy/street-light-state-circle-layer";

export class StreetLightControllerOverview extends DetailPanel<StreetLightControllerOverviewOptions> {

    constructor(context: InvipoContext, options?: StreetLightControllerOverviewOptions) {
        super(context, template, options);
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 5,
            maxZoom: 20
        });

        // Set p[osition after load
        this.map.onMapLoad = () => {
            if (this.item) {
                this.map.setCenter((<Point>this.item.geometry.location).coordinates);
            }
        }

        // Register map layers
        this.map.addLayer(new StreetLightStateCircleLayer(this.context));
    }

}
