import "./waze-feed-detail.scss";
import * as template from "./waze-feed-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { WazeFeedDetailOptions, WazeFeedLevel } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { WazeFeedLineLayer } from "../../../layers/traffic/waze-feed-line-layer";
import { METRICS } from "../../city/city-subdomain/types";

export class WazeFeedDetail extends Detail<InvipoContext, WazeFeedDetailOptions> {

    // properties
    public levels: WazeFeedLevel[];

    // Components
    public map: BasicMap;
    public layer: WazeFeedLineLayer;

    constructor(context: InvipoContext, options: WazeFeedDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Recreate new layers to fetch new data
        this.map.addLayer(new WazeFeedLineLayer(this.context, <any>this.options.feed));
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview.center,
            zoom: this.context.options.overview.zoom - 0.5,
            minZoom: 5,
            maxZoom: 20
        });

        // Register component
        this.registerComponent(this.map, "map");
    }

    private createLevels(): void {
        this.levels = [];

        for (let level of METRICS.traffic.waze.density.range) {
            this.levels.push({
                level: level,
                color: METRICS.traffic.waze.density.colors[METRICS.traffic.waze.density.range.indexOf(level)],
                length: (<any[]>this.options.feed.feed.routes).filter(x => x.jamLevel == METRICS.traffic.waze.density.range.indexOf(level)).map(x => x.length).reduce((x, y) => x + y, 0)
            })
        }
    }

    public render(): string {
        // Rebuiolt data
        this.createLevels();

        // Call self
        return super.render();
    }
}
