import './battery-status-subdomain.scss';
import * as template from "./battery-status-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { BatteryStatusVoltageCircleLayer } from "../../../layers/energy/battery-status-voltage-circle-layer";
import { BatteryStatusVoltageLabelLayer } from "../../../layers/energy/battery-status-voltage-label-layer";
import { BatteryStatusTemperatureCircleLayer } from "../../../layers/energy/battery-status-temperature-circle-layer";
import { BatteryStatusTemperatureLabelLayer } from "../../../layers/energy/battery-status-temperature-label-layer";
import { METRICS, LegendRange } from "../../city/city-subdomain/types";
import { BatteryStatusSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { Helpers } from "hiyo/helpers";
import { ItemList } from "../../city/item-list/item-list";

export class BatteryStatusSubdomain extends CitySubdomain<BatteryStatusSubdomainOptions> {

    // Properties
    public ranges: { [data: string]: LegendRange };

    constructor(context: InvipoContext, options?: BatteryStatusSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Voltage",
                    itemClass: "Battery",
                    layers: [
                        new BatteryStatusVoltageCircleLayer(context),
                        new BatteryStatusVoltageLabelLayer(context)
                    ]
                },
                {
                    name: "Temperature",
                    itemClass: "Battery",
                    layers: [
                        new BatteryStatusTemperatureCircleLayer(context),
                        new BatteryStatusTemperatureLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.BatteryStatusSubdomain.devices",
            layers: [
                //new ItemCircleLayer(this.context, "Battery")
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        // Current metrics name
        let metrics = Helpers.toKebabCase(this.metrics.name);

        // Create volume legend
        this.legend = {
            description: `components.BatteryStatusSubdomain.${metrics}Description`,
            range: {
                range: METRICS.energy.battery[metrics].range,
                colors: METRICS.energy.battery[metrics].colors,
                count: 0,
                value: 0
            },
            symbols: []
        }

        // Calculate volume average
        for (let item of this.items) {
            // No data?
            if (!item.data?.battery[metrics]) {
                continue;
            }

            this.legend.range.value += item.data.battery[metrics];
            this.legend.range.count += 1;
        }

        // Set average and calculate relative position on range
        this.legend.range.value /= this.legend.range.count;
        this.legend.range.percent = Helpers.range(0, 100, METRICS.energy.battery[metrics].range[0], METRICS.energy.battery[metrics].range[1], this.legend.range.value);
    }

}
