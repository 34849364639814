import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";

export class CitsPreferenceCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            layer: {
                id: "cits-preference-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        ZOOM_AREA + 1, 4,
                        22, 12
                    ],
                    "circle-color": InvipoHelpers.toChartColor(1),
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 0.1
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Empty data if search is not set
        if (!this.options.search) {
            return json;
        }

        // Data query
        let query = "";

        if (this.options.search.from) {
            query += `from=${this.options.search.from}&`;
        }

        if (this.options.search.to) {
            query += `to=${this.options.search.to}&`;
        }

        if (this.options.search.itemId) {
            query += `item.id=${this.options.search.itemId}&`;
        }

        if (this.options.search.type) {
            query += `type=${this.options.search.type}&`;
        }

        // Remove last amp
        query = query.slice(0, -1);

        // Load history
        let prefrerences = await this.context.invipo.getSamples("cits-preferences", 2000, query);

        // Push features from items
        for (let preference of prefrerences) {
            json.features.push({
                type: "Feature",
                properties: {
                },
                geometry: preference.position
            })
        }

        return json;
    }
}
