import "./traffic-event-manager.scss";
import * as template from "./traffic-event-manager.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficEventManagerOptions, TrafficEvent } from "./types";
import { Select } from "muklit/components/select/select"
import { Dialog } from "muklit/components/dialog/dialog";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { HttpMethod } from "hiyo/http";
import { Helpers } from "hiyo/helpers";
import { InvipoHelpers } from "../../../invipo-helpers";
import { TrafficEventDetail } from "../traffic-event-detail/traffic-event-detail";
import { TrafficEventDetailOptions } from "../traffic-event-detail/types";
import { TrafficEventForm } from "../traffic-event-form/traffic-event-form";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class TrafficEventManager extends MuklitComponent<InvipoContext, TrafficEventManagerOptions> {

    // Components
    public table: FilterTable;
    public detail: TrafficEventDetail;

    constructor(context: InvipoContext, options?: TrafficEventManagerOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/traffic-events`,
            http: this.context.invipo.http,
            filter: {
                title: "components.TrafficEventManager.title",
                items: [
                    {
                        name: "Add",
                        label: "labels.add"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "type",
                                label: "forms.fields.type",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.TrafficEventType"), null, (a: MenuItem, b: MenuItem) => {
                                    return this.context.locale.getMessage(a.name).localeCompare(this.context.locale.getMessage(b.name));
                                })
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "source",
                                label: "forms.fields.source",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "false",
                                        label: "enums.TrafficMessageSource.Internal"
                                    },
                                    {
                                        name: "true",
                                        label: "enums.TrafficMessageSource.External"
                                    }
                                ]
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                style: "Light",
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "duration.from",
                        type: "DateTime",
                        property: "duration.from",
                        formatter: (value: any, data: any) => {
                            let from = `${Helpers.toDateString(data.duration.from)} ${Helpers.toShortTimeString(data.duration.from)}`;

                            // Locked?
                            if (data.locked) {
                                return `<div class="partial-label partial-label-style-light" style="padding-left: -12px">
                                    <div class="icon icon-lock"></div>
                                    <div class="label">${from}</div>
                                </div>`
                            }
                            else {
                                return `<div class="cell" style="padding-left: 28px">${from}</div>`
                            }
                        },
                        label: "tables.columns.from",
                        width: 200,
                        selected: true,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "duration.to",
                        type: "DateTime",
                        property: "duration.to",
                        formatter: (value: any, data: any) => {
                            if (!data.duration.to) {
                                return null;
                            }
                            else {
                                return `<div class="cell">${Helpers.toDateString(data.duration.to)} ${Helpers.toShortTimeString(data.duration.to)}</div>`
                            }
                        },
                        label: "tables.columns.to",
                        width: 200,
                        sortable: true,
                        descendent: true
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any) => {
                            return this.context.locale.getMessage(`enums.TrafficEventType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        minWidth: 250,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Handle menu
        this.table.onItemSelect = async (item: FilterItem) => {
            // Add new user?
            if (item.name == "Add") {
                this.openNew();
            }
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    private async openDetail(data: TrafficEvent): Promise<void> {
        // Detail options
        let options: TrafficEventDetailOptions = {
            style: "Light",
            title: `enums.TrafficEventType.${data.type}`,
            event: data,
            closable: true,
            editable: true,
            menu: [
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        }

        // Detail already visible?
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = {
                ...this.detail.options,
                ...options
            };

            // Invalidate
            await this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new TrafficEventDetail(this.context, options);

        // Menu select
        this.detail.onMenuSelect = (item: MenuItem)=> {
            // Delete action?
            if (item.name == "Delete") {
                this.confirmDelete(data);
            }
        }

        // Open details form
        this.detail.onSelectDetail = async (event: TrafficEvent) => {
            this.openForm(event);
        }

        // When detail is closed
        this.detail.onClose = () => {
            // Unselect selected row
            this.table.unselectRow(this.detail.options.event?.id);
        }

        // Attach detail and redraw map because of viewport changed
        this.detail.attach();
    }

    public openForm(event: TrafficEvent): void {
        // Detail form
        let form = new TrafficEventForm(this.context, {
            style: "Light",
            title: "components.TrafficEventForm.updateEvent",
            event,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();

            // Reload detail
            await this.detail?.load();
        }

        // Show form
        form.attach();
    }

    public confirmDelete(event: TrafficEvent): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.TrafficEventManager.eventDelete",
            text: this.context.locale.getMessage("components.TrafficEventManager.reallyDelete", this.context.locale.getMessage(`enums.TrafficEventType.${event.type}`)),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnConfirm handler
        dialog.onConfirm = async () => {
            // Delete traffic event
            const http = this.context.invipo.http;
            const host = this.context.invipo.options.host;
            let method = HttpMethod.DELETE;
            let url = `${host}/api/management/traffic/events/${event.id}`;

            // Delete request
            await http.request(HttpMethod.DELETE, url);

            // Close dialog
            dialog.close();

            // Close detail
            this.detail?.close();
        }

        // Show dialog
        dialog.attach();
    }

    public openNew(): void {
        // Event form
        const form = new TrafficEventForm(this.context, {
            title: "components.TrafficEventForm.title",
            style: "Light",
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload table
            await this.table.load();
        }

        form.attach();
    }
}
