import { InvipoContext } from "../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { Position } from "geojson";

export class TooltipLabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext, coordinates: Position, label: string, dark?: boolean) {
        super(context, {
            layer: {
                id: "tooltip-label",
                type: "symbol",
                source: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {
                                    label: label
                                },
                                geometry: {
                                    type: "Point",
                                    coordinates: coordinates
                                }
                            }
                        ]
                    }
                },
                paint: {
                    "text-color": dark ? "#ffffff" : "#161616",
                    "text-opacity": 1,
                    "text-halo-color": dark ? "#000000" : "#ffffff",
                    "text-halo-width": 1,
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-offset": [0, 1.8],
                    "text-anchor": "top",
                    "text-allow-overlap": true,
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Empty load, geojson data are assigned via constructor
    }
}
