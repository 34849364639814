import { InvipoContext } from "../../context/invipo-context";
import { AreaSpotLayer } from "../area-spot-layer";
import { ZOOM_AREA } from "../../../muklit/components/basic-map/map-layer";

export class PublicTransportPreferenceAreaSymbolLayer extends AreaSpotLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "PreferenceDetectionArea",
            dynamic: true,
            pointer: true,
            layer: {
                id: "public-transport-preference-area-symbol",
                type: "symbol",
                maxzoom: ZOOM_AREA - 2,
                source: {
                    type: "geojson",
                    data: null
                },
                layout: {
                    "icon-image": "24-tools-area-grey",
                    "icon-offset": [0, 0]
                }
            }
        });
    }
}
