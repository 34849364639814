import "./drawer-menu.scss";
import * as template from "./drawer-menu.hbs";
import { Component } from "hiyo/component";
import { InvipoContext } from "../../../context/invipo-context";
import { DrawerMenuOptions, DrawerGroup, DrawerItem } from "./types";
import { Log } from "hiyo/log";

export class DrawerMenu extends Component<InvipoContext, DrawerMenuOptions> {

    // Event handling methods
    public onSelect(item: DrawerItem, group?: DrawerGroup): void {};

    constructor(context: InvipoContext, options: DrawerMenuOptions) {
        super(context, template, options);
    }

    public find(itemName: string): DrawerItem {
        // In all groups and all group items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                // Match?
                if (itemName == item.name) {
                    return item;
                }
            }
        }

        // Not found?
        return null;
    }

    public select(itemName: string, skipCallback?: boolean): void {
        let item = this.find(itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        this.options.groups.forEach(x => {
            x.items.forEach(y => {
                y.selected = false;
            })
        });

        // Select current
        item.selected = true;

        // Redraw
        this.update();

        // OnItemSelect handler
        if (!skipCallback) {
            this.onSelect(item);
        }
    }

}
