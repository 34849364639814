import "./violation-report.scss";
import * as template from "./violation-report.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { ViolationReportOptions } from "./types";

export class ViolationReport extends Detail<InvipoContext, ViolationReportOptions> {

    public constructor(context: InvipoContext, options: ViolationReportOptions) {
        super(context, template, options);
    }

}
