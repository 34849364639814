import "./cits-vehicle-card.scss";
import * as template from "./cits-vehicle-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { CitsVehicleCardOptions } from "./types";

export class CitsVehicleCard extends CityCard<CitsVehicleCardOptions> {

    constructor(context: InvipoContext, options: CitsVehicleCardOptions) {
        super(context, template, options);
    }

}
