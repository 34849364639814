import "./public-transport-preference-overview.scss";
import * as template from "./public-transport-preference-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { PublicTransportPreferenceOverviewOptions } from "./types";
import { DatasetResult } from "muklit/components/query-table/types";
import { InvipoHelpers } from "../../../invipo-helpers";
import { PublicTransportPreferencePointFillLayer } from "../../../layers/transit/public-transport-preference-point-fill-layer";
import { PublicTransportPreferencePointSymbolLayer } from "../../../layers/transit/public-transport-preference-point-symbol-layer";

export class PublicTransportPreferenceOverview extends DetailPanel<PublicTransportPreferenceOverviewOptions> {

    // Properties
    public preferences: DatasetResult;

    constructor(context: InvipoContext, options?: PublicTransportPreferenceOverviewOptions) {
        super(context, template, options);
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Satellite",
            center: this.context.options.home.center,
            zoom: 18,
            minZoom: 5,
            maxZoom: 20
        });

        // Set p[osition after load
        this.map.onMapLoad = () => {
            if (this.area) {
                // Get bounds to fitn and zoom
                let bounds = InvipoHelpers.toBounds(this.area.geometry);
                this.map.setCenter(bounds.getCenter().toArray());
                this.map.setZoom(18);
            }
        }

        // Register map layers
        this.map.addLayer(new PublicTransportPreferencePointFillLayer(this.context));
        this.map.addLayer(new PublicTransportPreferencePointSymbolLayer(this.context));
    }


    public async extraLoad(): Promise<void> {
        // Load preferences
        this.preferences = await this.context.invipo.getDataset("public-vehicles-preferences", `point=${this.area.meta.number}&page=1&pageSize=10&sort=timestamp:desc`);
    }
}
