import "./parking-lot-overview.scss";
import * as template from "./parking-lot-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { ParkingLotOverviewOptions } from "./types";
import { ParkingOccupancyForm } from "../parking-occupancy-form/parking-occupancy-form";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis } from "../../common/panel/types";
import { METRICS } from "../../city/city-subdomain/types";
import { Helpers } from "../../../../hiyo/helpers";

export class ParkingLotOverview extends Panel<ParkingLotOverviewOptions> {

    // Properties
    public kpis: PanelKpis;
    public chart: PanelChart;

    constructor(context: InvipoContext, options?: ParkingLotOverviewOptions) {
        super(context, template, options);
    }

    public openEdit(): void {
        // Edit form
        let form = new ParkingOccupancyForm(this.context, {
            style: "Light",
            title: "components.ParkingOccupancyForm.title",
            item: this.item,
            overlay: true
        });

        // OnSubmit handler
        form.onSubmit = async () => {
            // Refresh item
            await this.load();
        }

        // Show dialog
        form.attach();
    }

    public async extraLoad(): Promise<void> {
        // Build speed KPIs
        this.kpis = {
            label: "components.ParkingLotOverview.parkingStatus",
            size: "Third",
            action: "Edit",
            data: [
                {
                    label: "components.ParkingLotOverview.currentOccupancy",
                    value: (this.item.data?.occupancy?.overall?.occupancy != null) ? `${Helpers.toNumber(this.item.data.occupancy.overall.occupancy)} %` : this.context.locale.getMessage("common.unknown"),
                    type: (!this.item.data?.occupancy?.overall || this.item.data.occupancy.overall.occupancy > 90) ? "Negative" : "Positive",
                    //description: this.item.data?.occupancy?.ts ? this.context.locale.getMessage("components.ParkingLotOverview.lastData", Helpers.toShortDateTimeString(this.item.data.occupancy.ts)) : ""
                    description: this.item.data?.occupancy?.ts ? Helpers.toShortDateTimeString(this.item.data.occupancy.ts) : ""
                },
                {
                    label: "components.ParkingLotOverview.freeSpots",
                    value: (this.item.data?.occupancy?.overall?.free != null) ? Helpers.toNumber(this.item.data.occupancy.overall.free) : this.context.locale.getMessage("common.unknown"),
                    type: (!this.item.data?.occupancy?.overall || this.item.data.occupancy.overall?.free == 0) ? "Negative" : "Positive",
                    description: this.item.data?.occupancy?.overall?.total ? this.context.locale.getMessage("components.ParkingLotOverview.ofTotalCapacity", Helpers.toNumber(this.item.data.occupancy.overall.total)) : ""
                },
                {
                    label: "components.ParkingLotOverview.occupiedSpots",
                    value: (this.item.data?.occupancy?.overall?.free != null) ? Helpers.toNumber(this.item.data.occupancy.overall.total - this.item.data.occupancy.overall.free) : this.context.locale.getMessage("common.unknown"),
                    type: (!this.item.data?.occupancy?.overall || this.item.data.occupancy.overall.free == 0) ? "Negative" : "Positive",
                    description: this.item.data?.occupancy?.overall?.total ? this.context.locale.getMessage("components.ParkingLotOverview.ofTotalCapacity", Helpers.toNumber(this.item.data.occupancy.overall.total)) : ""
                }
            ]
        }

        // Interval
        let from = new Date(new Date(Date.now() - 48 * 60 * 60 * 1000).setMinutes(0, 0, 0));
        let to = new Date(new Date().setMinutes(0, 0, 0));

        // Parking occupancy per hour
        let data = await this.context.invipo.getQuery("parking-occupancy-by-hour", `&item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Build hour chart
        this.chart = {
            type: "Bar",
            size: "Medium",
            length: 48,
            label: "components.ParkingLotOverview.todayOccupancy",
            series: []
        }

        // Add hourly data to chart series
        let timestamp = new Date(from);
        do {
            // Find hour in data
            let d = data.find(x => x.timestamp == timestamp.toISOString());
            let symbol = "Unknown";

            // Has data?
            if (d?.occupancy) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(d.occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(d.occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }
            }

            let color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];

            // Has data?
            if (d) {
                this.chart.series.push(
                    [
                        {
                            timestamp: timestamp.toISOString(),
                            valueX: Helpers.toShortDateTimeString(timestamp),
                            //valueY: `${d.occupancy} %`,
                            percent: d.occupancy,
                            label: `${d.occupancy} %`,
                            color: color,
                        }
                    ]
                );
            }
            // No data
            else {
                this.chart.series.push(
                    [
                        {
                            timestamp: timestamp.toISOString(),
                        }
                    ]
                );
            }

            // Move to next hour
            timestamp.setTime(timestamp.getTime() + 3600000);
        }
        while (timestamp.getTime() < to.getTime())

        // Custom range
        this.chart.range = [
            {
                label: Helpers.toShortDateTimeString(from)
            },
            {
                label: Helpers.toShortDateTimeString(new Date(from.getTime() + (to.getTime() - from.getTime()) / 2))
            },
            {
                label: Helpers.toShortDateTimeString(to)
            }
        ];
    }

}
