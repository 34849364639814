import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "../../../muklit/components/basic-map/map-layer";
import { FeatureCollection } from "geojson";
import { Helpers } from "../../../hiyo/helpers";
import { InvipoHelpers } from "../../invipo-helpers";

export class EnviPollutionNo2LabelLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "envi-pollution-no2-label",
                type: "symbol",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "text-color": "#161616",
                    "text-opacity": 1,
                    "text-halo-color": "#ffffff",
                    "text-halo-width": 1
                },
                layout: {
                    "text-field": ["get", "label"],
                    "text-font": [
                        "Proxima Nova Semibold",
                        "Open Sans Semibold"
                    ],
                    "text-size": 13,
                    "text-line-height": 1,
                    "text-offset": [0, 0],
                    "text-anchor": "center",
                    "text-max-width": 60
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "RoadWeatherStation,EnvironmentalSensor"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No data or data too old?
            if (item.data?.envi?.pollution?.no2 == null) {
                continue;
            }

            json.features.push({
                type: "Feature",
                properties: {
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    label: `${item.name}\n${Helpers.toNumber(item.data.envi.pollution.no2)} µg/m³`
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
