import "./property-form.scss";
import * as template from "./property-form.hbs";
import { InvipoContext } from "../../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { PropertyFormOptions } from "./types";
import { InvipoItem, InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../../clients/invipo-client/types";
import { TextInput } from "muklit/components/text-input/text-input";
import { Form } from "muklit/components/form/form";

export class PropertyForm extends Detail<InvipoContext, PropertyFormOptions> {

    // Properties
    public stream: InvipoItemStream;

    // Components
    public form: Form;


    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: PropertyFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    public createForm () {
        // Create component
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "key",
                            label: "components.ItemPassportForm.key",
                            required: true,
                            bright: true,
                            value: this.options.key
                        })
                    ]
                },
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "value",
                            label: "components.ItemPassportForm.value",
                            required: true,
                            bright: true,
                            value: this.options.value
                        })
                    ]
                },
            ]
        });
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // add comment to stream
        try {
            const result = await this.context.invipo.createItemPassport(this.options.itemId, data.key, data.value);
            console.log(result);
        }
        finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }
}
