import "./camera-detail.scss";
import * as template from "./camera-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CameraDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";
import { Tabs } from "muklit/components/tabs/tabs";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class CameraDetail extends Detail<InvipoContext, CameraDetailOptions> {

    constructor(context: InvipoContext, options: CameraDetailOptions) {
        super(context, template, options);
    }

    public openImage(i: number): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: `${this.context.options.host}/download${this.options.camera.snapshots[i].url}`,
            urls: (<any[]>this.options.camera.snapshots).map(x => `${this.context.options.host}/download${x.url}`),
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }
}
