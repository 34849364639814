import "./user-activity.scss";
import * as template from "./user-activity.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { QueryTable } from "muklit/components/query-table/query-table";
import { Helpers } from "hiyo/helpers";
import { UserActivityOptions } from "./types";

export class UserActivity extends MuklitComponent<InvipoContext, UserActivityOptions> {

    // Components
    public table: QueryTable;

    constructor(context: InvipoContext, options?: UserActivityOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();

        // Register components that will be automatically attached
        this.registerComponent(this.table, "table");
    }

    public createTable(): void {
        // Create component
        this.table = new QueryTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/journal?type=UserLoggedIn&actor.id=${this.context.user.id}&from=${new Date(new Date().setHours(-7 * 24, 0, 0, 0)).toISOString()}`,
            http: this.context.invipo.http,
            bar: {
                title: "components.UserActivity.description",
                reloadable: true,
                items: []
            },
            pagination: {
                page: 1
            },
            table: {
                type: "Unselectable",
                size: "Short",
                height: "100%",
                groups: {
                    property: "timestamp",
                    formatter: (value: any, data: any) => {
                        return Helpers.toDateString(value);
                    }
                },
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "message",
                        type: "String",
                        property: "message",
                        label: "tables.columns.message",
                        width: 350,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "ip",
                        type: "String",
                        property: "message",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell">${data.data?.ip}</div>`;
                        },
                        label: "tables.columns.ip",
                        width: 150,
                        sortable: true
                    },
                    {
                        name: "ip",
                        type: "String",
                        property: "message",
                        formatter: (value: any, data: any) => {
                            return `<div class="cell cell-ellipsis">${data.data?.userAgent}</div>`;
                        },
                        label: "tables.columns.userAgent",
                        sortable: true
                    }
                ]
            }
        });

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            //this.openDetail(data);
        }
    }
}
