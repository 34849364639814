import "./radio-button.scss";
import * as template from "./radio-button.hbs";
import { Context } from "hiyo/context";
import { RadioButtonItem, RadioButtonOptions } from "./types";
import { Input } from "../input/input";

export class RadioButton extends Input<Context, RadioButtonOptions> {

    // Event handling methods
    public onCheck(item: RadioButtonItem): void {};

    constructor(context: Context, options: RadioButtonOptions) {
        super(context, template, options);

        // Set selected value
        this.options.value = this.options.items.find(x => x.checked)?.name;
    }

    public check(i: number): void {
        // Already selected?
        if (this.options.items[i].checked) {
            return;
        }

        // Unselect all
        this.options.items.forEach(x => x.checked = false);

        // Selected item and set value
        this.options.items[i].checked = true;
        this.options.value = this.options.items[i].name;

        // Update
        this.update();

        // Handlers
        this.onCheck(this.options.items[i]);
        this.onSubmit();
    }

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}