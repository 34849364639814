import "./search.scss";
import * as template from "./search.hbs";
import { Context } from "hiyo/context";
import { Input } from "../input/input";
import { SearchOptions } from "./types";

const CLASS_ACTIVE = "muklit-search-active";
const CLASS_EMPTY = "muklit-search-empty";

export class Search extends Input<Context, SearchOptions> {

    // Event handling methods
    public onKey(key: number): void {};

    constructor(context: Context, options: SearchOptions) {
        super(context, template, options);
    }

    public focus(selectAll?: boolean): void {
        this.element.classList.add(CLASS_ACTIVE);

        // Focus also an input element
        this.query("input").focus();

        // Select all text inside input?
        if (selectAll && this.options.value) {
            this.query<HTMLInputElement>("input").setSelectionRange(0, this.options.value.length);
        }
    }

    public blur(): void {
        this.element.classList.remove(CLASS_ACTIVE);
    }

    public key(key: number) {
        // Read value
        this.options.value = this.element.querySelector<HTMLInputElement>("input").value;

        // Enter
        if (key == 13) {
            // OnSubmit handler
            this.onSubmit();
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear text
            this.clear(true)
            return;
        }

        // Is empty?
        if (!this.options.value) {
            this.element.classList.add(CLASS_EMPTY);
        }
        else {
            this.element.classList.remove(CLASS_EMPTY);
        }

        // OnKey handler
        this.onKey(key);
    }

    public clear(submit?: boolean): void {
        this.setValue(null);

        // Call onSubmit handler?
        if (submit) {
            this.onSubmit();
        }
    }

    public setValue(value: string): void {
        // Sets value to options
        this.options.value = value;

        // Is empty?
        if (!value) {
            this.element.classList.add(CLASS_EMPTY);
        }
        else {
            this.element.classList.remove(CLASS_EMPTY);
        }

        // Reattach select to render it properly
        if (this.isAttached()) {
            this.update();
        }
    }

}
