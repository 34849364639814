import "./traffic-event-form.scss";
import * as template from "./traffic-event-form.hbs";
import "./traffic-event-form-marker.scss";
import * as markerTemplate from "./traffic-event-form-marker.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { eventFormMenuGroups, TrafficEventFormOptions } from "./types";
import { Select } from "muklit/components/select/select";
import * as pickerMenu from "../../common/picker-menu/picker-menu";
import { Helpers } from "hiyo/helpers";
import { PickerItem } from "../../common/picker-menu/types";
import { FormFieldset } from "muklit/components/form/types";
import { DateInput } from "muklit/components/date-input/date-input";
import { HttpMethod } from "hiyo/http";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { LngLatBounds } from "mapbox-gl";
import { TrafficEvent, TrafficEventType } from "../traffic-event-manager/types";
import { TextArea } from "muklit/components/text-area/text-area";
import { PickerMenu } from "../../common/picker-menu/picker-menu";

export class TrafficEventForm extends Detail<InvipoContext, TrafficEventFormOptions> {

    // Components
    public menu: PickerMenu;
    public form: Form;

    // Basic Event Info Components
    public from: DateInput;
    public to: DateInput;
    public title: TextInput;
    public description: TextArea;

    // Event Position Componets
    public positionType: Select;
    public fromLatitude: TextInput;
    public fromLongitude: TextInput;
    public toLatitude: TextInput;
    public toLongitude: TextInput;

    public basicMap: BasicMap;
    public startMarker: MapMarker;
    public endMarker: MapMarker;

    // Event Info Inputs
    public eventId: TextInput;
    public timestamp: DateInput;

    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: TrafficEventFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.menu, "menu");
        this.registerComponent(this.form, "form");
    }

    public onAttach() {
        // Show proper content
        this.toggle(this.options.event?.type ? "form" : "menu");
    }

    private createMenu(): void {
        // Create component
        this.menu = new pickerMenu.PickerMenu(this.context, {
            style: "Light",
            title: "components.TrafficEventForm.createEvent",
            closable: true,
            overlay: true,
            groups: eventFormMenuGroups
        });

        this.menu.onSelect = (item: PickerItem) => {
            // Set selected type and subtype
            if (!this.options.event) this.options.event = <TrafficEvent>{}
            this.options.event.type = <TrafficEventType>item.name

            // Detachg and recreate the form due to custom validation fields
            this.form.detach();
            this.createForm();

            // Rerender form because field options have changed
            this.form.attach(this.query("div.content-form div.form"));

            // Finally switch to form content
            this.toggle("form");
        }
    }

    private createForm(): void {
        // Create all inputs necessary for the form
        this.createDefaultInputs();
        this.createPositionInputs();
        this.createInfoInputs();

        // Create form fieldsets
        let fieldsets: FormFieldset[] = [
            {
                name: "TimeInfo",
                label: this.context.locale.getMessage(`enums.TrafficEventType.${this.options.event?.type}`),
                fields: [this.title, this.description, this.from, this.to]
            },
            {
                name: "Location",
                label: "components.TrafficEventForm.location",
                fields: [this.positionType, this.fromLatitude, this.fromLongitude, this.toLatitude, this.toLongitude]
            },
            this.options.event?.id ? {
                name: "Info",
                label: "components.TrafficEventForm.info",
                fields: [this.eventId, this.timestamp]
            }: undefined,
        ].filter(x => Boolean(x));

        // Create form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: fieldsets
        });

        this.form.onAttach = () => {
            // Map is attech into "Location" fieldset
            // therefore it has to be attached created and attached after from
            this.createMap();
        };

        // Project position changes to map
        const updateMapFeature = () => {
            const bounds = new LngLatBounds();

            const isPoint = !this.positionType.options.value["LineString"]

            // Update starting marker
            const fromLat = parseFloat(this.fromLatitude.options.value);
            const fromLon = parseFloat(this.fromLongitude.options.value);
            if (!isNaN(fromLat) && !isNaN(fromLon)) {
                this.basicMap.addMarker(this.startMarker);
                this.basicMap.removeMarker(this.endMarker.id);

                this.startMarker.setPosition([fromLon, fromLat]);
                bounds.extend([fromLon, fromLat]);

            }

            // Update ending marker
            const toLat = parseFloat(this.toLatitude.options.value);
            const toLon = parseFloat(this.toLongitude.options.value);
            if (!isPoint && !isNaN(toLat) && !isNaN(toLon)) {
                this.basicMap.addMarker(this.endMarker);
                this.endMarker.setPosition([toLon, toLat]);

                bounds.extend([toLon, toLat]);
            }

            // Fit markers on map
            if (bounds.isEmpty()) {
                this.basicMap.removeMarker(this.startMarker.id);
                this.basicMap.removeMarker(this.endMarker.id);
            } else {
                this.basicMap.fitBounds(bounds, 0);
                if (isPoint) this.basicMap.setZoom(18);
            }
        }

        // Position changed - update map
        this.positionType.onSubmit = () => {
            const value = <any>(this.positionType.options.value);

            this.toLatitude.setDisabled(!value["LineString"]);
            this.toLongitude.setDisabled(!value["LineString"]);

            updateMapFeature();
        }
        this.fromLatitude.onKey = () => updateMapFeature();
        this.fromLongitude.onKey = () => updateMapFeature();
        this.toLatitude.onKey = () => updateMapFeature();
        this.toLongitude.onKey = () => updateMapFeature();
    }

    private createDefaultInputs(): void {
        // Default time values
        const from = new Date();
        from.setMinutes(from.getMinutes() + 5);
        from.setSeconds(0, 0);

        const to = new Date();
        to.setMinutes(to.getMinutes() + 65);
        to.setSeconds(0, 0);

        const transmission = new Date();
        from.setMinutes(from.getMinutes() + 5);
        transmission.setSeconds(0, 0);

        this.from = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "from",
            label: "forms.fields.from",
            required: true,
            value: this.options?.event?.duration?.from ?? from.toISOString(),
            width: 320,
        });

        this.to = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "to",
            label: "forms.fields.to",
            required: true,
            value: this.options?.event?.duration?.to ?? to.toISOString(),
            width: 320,
        });

        this.title = new TextInput(this.context, {
            style: "Light",
            name: "title",
            label: "forms.fields.title",
            value: this.options.event?.title,
            width: 672,
            bright: true
        });

        this.description = new TextArea(this.context, {
            style: "Light",
            name: "description",
            label: "forms.fields.description",
            value: this.options.event?.description,
            rows: 8,
            width: 672,
            bright: true
        });
    }

    private createPositionInputs(): void {
        this.positionType = new Select(this.context, {
            style: "Light",
            bright: true,
            name: "positionType",
            label: "components.TrafficEventForm.positionType",
            multiselect: false,
            width: 320,
            value: this.options.event?.position?.type ?? 'Point',
            items: [
                { name: "Point", label: "components.TrafficEventForm.point" },
                { name: "LineString", label: "components.TrafficEventForm.line" }
            ]
        });

        // Parse GeoJSON Geometry to individual from-to values
        let fromPosition = this.options.event?.position?.coordinates ?? [];
        let toPosition: number[] = [];
        if (this.options.event?.position?.type === "LineString") {
            fromPosition = this.options.event.position.coordinates[0]
            toPosition = this.options.event.position.coordinates[1]
        }

        this.fromLatitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 320,
            name: "fromLatitude",
            label: "components.TrafficEventForm.fromLatitude",
            value: fromPosition[1],
            required: true,
        });
        this.fromLongitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 320,
            name: "fromLongitude",
            label: "components.TrafficEventForm.fromLongitude",
            value: fromPosition[0],
            required: true,
        });
        this.toLatitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 320,
            name: "toLatitude",
            label: "components.TrafficEventForm.toLatitude",
            value: toPosition[1],
            disabled: this.options.event?.position?.type !== 'LineString'
        });
        this.toLongitude = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 320,
            name: "toLongitude",
            label: "components.TrafficEventForm.toLongitude",
            value: toPosition[0],
            disabled: this.options.event?.position?.type !== 'LineString'
        });
    }

    private createInfoInputs(): void {
        this.eventId = new TextInput(this.context, {
            style: "Light",
            bright: true,
            width: 672,
            name: "guid",
            label: "components.TrafficEventManager.eventId",
            value: this.options.event?.eventId,
            disabled: true,
        });

        this.timestamp = new DateInput(this.context, {
            style: "Light",
            bright: true,
            name: "timestamp",
            label: "components.TrafficEventManager.timestamp",
            disabled: true,
            width: 320,
            value: this.options?.event?.timestamp
        });
    }

    public createMap () {
        this.basicMap = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.home.center,
            zoom: 10,
            // minZoom: 5,
            maxZoom: 20
        });
        this.basicMap.attach(this.query('.fieldset-location'));

        this.basicMap.onMapLoad = () => {
            this.basicMap.resize();
        };

        this.basicMap.mapboxMap.on('click', (e) => {
            const isPoint = !!this.positionType.options.value["Point"];
            const position = e.lngLat.toArray();

            if (!this.basicMap.hasMarker(this.startMarker.id)) {
                this.startMarker.options.position = position;
                this.basicMap.addMarker(this.startMarker);

                this.fromLatitude.setValue(position[1]);
                this.fromLongitude.setValue(position[0]);
            } else if (!isPoint && !this.basicMap.hasMarker(this.endMarker.id)) {
                this.endMarker.options.position = position;
                this.basicMap.addMarker(this.endMarker);

                this.toLatitude.setValue(position[1]);
                this.toLongitude.setValue(position[0]);
            }
        })

        // Create start and end Markers
        this.startMarker = new MapMarker(this.context, markerTemplate, {
            position: this.context.options.home.center,
            draggable: true,
            selected: false
        });

        this.startMarker.onMove = () => {
            this.fromLatitude.setValue(this.startMarker.options.position[1]);
            this.fromLongitude.setValue(this.startMarker.options.position[0]);
        }

        this.endMarker = new MapMarker(this.context, markerTemplate, {
            position: this.context.options.home.center,
            draggable: true,
            selected: true
        });

        this.endMarker.onMove = () => {
            this.toLatitude.setValue(this.endMarker.options.position[1]);
            this.toLongitude.setValue(this.endMarker.options.position[0]);
        }

        const bounds = new LngLatBounds();

        if (this.options.event?.position?.type === "Point") {
            this.startMarker.options.position = this.options.event.position.coordinates;
            this.basicMap.addMarker(this.startMarker);

            bounds.extend(this.startMarker.options.position as [number, number]);
        }
        if (this.options.event?.position?.type === 'LineString') {
            this.startMarker.options.position = this.options.event.position.coordinates[0];
            this.endMarker.options.position = this.options.event.position.coordinates[1];

            this.basicMap.addMarker(this.startMarker);
            this.basicMap.addMarker(this.endMarker);

            bounds.extend(this.startMarker.options.position as [number, number]);
            bounds.extend(this.endMarker.options.position as [number, number]);
        }

        if (!bounds.isEmpty()) {
            this.basicMap.fitBounds(bounds, 0);
            if (this.options.event?.position?.type === "Point") {
                this.basicMap.setZoom(18);
            }
        }
    }

    public toggle(content: string): void {
        // Hide all contents
        this.queryAll("div.content").forEach((element: HTMLElement) => {
            element.style.display = "none";
        });

        // Show only selected content
        this.query(`div.content-${Helpers.toKebabCase(content)}`).style.display = "block";

        // Show control panel on form only
        this.query("div.control").style.display = (content == "form") ? "flex" : "none";
    }

    public async submit(): Promise<void> {

        // Basic form validation?
        if (!this.form.validate()) {
            return;
        }

        // Get form data
        let data = this.form.getData(true);

        // Resulting TrafficEvent data
        const event: TrafficEvent = {
            id: this.options.event.id,
            type: this.options.event.type,
            position: this.positionType.options.value["LineString"] ? {
                type: "LineString",
                coordinates: [this.startMarker.mapboxMarker.getLngLat().toArray(), this.endMarker.mapboxMarker.getLngLat().toArray()]
            } : {
                type: "Point",
                coordinates: this.startMarker.mapboxMarker.getLngLat().toArray()
            },
            title: data.title,
            description: data.description,
            duration: {
                from: data.from,
                to: data.to
            },
            eventId: this.options.event?.eventId ?? undefined,
            timestamp: this.options.event?.timestamp ?? undefined
        };

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            const http = this.context.invipo.http;
            const host = this.context.invipo.options.host;
            let method = HttpMethod.POST;
            let url = `${host}/api/management/traffic/events`;

            if (this.options.event.id) {
                method = HttpMethod.PUT;
                url += `/${this.options.event.id}`;
            }

            await http.request(method, url, {
                data: event
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.form.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnNotificationSubmit handler
        this.onSubmit();
    }

    // split lanes (space, comma, semicolon)
    private splitLanes (lanes?: string): number[] {
        if (!lanes) return undefined;

        const strings = lanes
            .split(" ").join(",")
            .split(";").join(",")
            .split(",")
        const result = strings.map(x => parseInt(x)).filter(x => !isNaN(x));

        return result.length > 0 ? result : undefined;
    }
}
