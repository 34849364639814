import "./vehicle-location-card.scss";
import * as template from "./vehicle-location-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { VehicleLocationCardOptions } from "./types";

export class VehicleLocationCard extends CityCard<VehicleLocationCardOptions> {

    constructor(context: InvipoContext, options: VehicleLocationCardOptions) {
        super(context, template, options);
    }
}
