import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { METRICS } from "../../components/city/city-subdomain/types";

export class CameraSurveillanceSymbolLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "camera-surveillance-symbol",
                type: "symbol",
                source: {
                    type: "geojson",
                    data: null
                },
                filter: [
                    "all",
                    ["==", ["typeof", ["get", "direction"]], "number"],
                ],
                layout: {
                    "icon-image": "16-tools-caret_up",
                    "icon-offset": [0, -12],
                    "icon-rotate": ["get", "direction"],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                }
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "Camera"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            json.features.push({
                type: "Feature",
                properties: {
                    direction: item.meta?.direction
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }

}
