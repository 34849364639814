import './data-storage-tile.scss';
import * as template from "./data-storage-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { DataStorageTileOptions } from "./types";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend } from "../../common/tile/types";
import { CLASS_COLORS } from "../../../layers/city/item-circle-layer";
import { LegendSymbol } from "../../city/city-subdomain/types";
import { Helpers } from "hiyo/helpers";
import { InvipoHelpers } from "../../../invipo-helpers";

export class DataStorageTile extends Tile<DataStorageTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;

    constructor(context: InvipoContext, options?: DataStorageTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load data
        let data = await this.context.invipo.getQuery("data-storage");

        // Filter relevant data
        data = data.filter(x => x.size > 0 && (x.collection.includes("Data") || x.collection.includes("History")));

        // Sum up total size and count
        let size = data.map(x => x.size).reduce((a, b: number) => { return a + b }, 0);
        let count = data.map(x => x.count).reduce((a, b: number) => { return a + b }, 0);

        // Build storage kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.DataStorageTile.size",
                    value: Helpers.toGb(size * 1024 * 1024)
                },
                {
                    label: "components.DataStorageTile.count",
                    value: Helpers.toNumber(count)
                }
            ]
        }

        // Builld storage legend
        this.legend = {
            flat: true,
            range: null,
            symbols: []
        };

        for (let i = 0; i < data.length; i++) {
            let d = data[i];

            // Domain color
            let color = InvipoHelpers.toChartColor(i + 1);

            // Add row to legend
            this.legend.symbols.push({
                symbol: d.collection,
                count: d.size,
                label: `components.DataStorageTile.collections.${d.collection}`,
                unit: "MB",
                percent: d.size / size * 100,
                color: color
            });
        }

        // Sort legend by color
        this.legend.symbols.sort((a: LegendSymbol, b: LegendSymbol): number => {
            return b.percent - a.percent;
        });

        // Create tile status
        this.status = {
            icon: "Success",
            label: "components.DataStorageTile.statusOk"
        }
    }
}
