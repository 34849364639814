import "./license-detail.scss";
import * as template from "./license-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { LicenseDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";

export class LicenseDetail extends Detail<InvipoContext, LicenseDetailOptions> {

    constructor(context: InvipoContext, options: LicenseDetailOptions) {
        super(context, template, options);
    }

}
