import "./parking-area-card.scss";
import * as template from "./parking-area-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { ParkingAreaCardOptions } from "./types";

export class ParkingAreaCard extends CityCard<ParkingAreaCardOptions> {

    constructor(context: InvipoContext, options: ParkingAreaCardOptions) {
        super(context, template, options);
    }

}
