import "./preveza-dashboard.scss";
import * as template from "./preveza-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { PrevezaDashboardOptions } from "./types";
import { Dashboard } from "../dashboard/dashboard";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";

export class PrevezaDashboard extends Dashboard<PrevezaDashboardOptions> {

    constructor(context: InvipoContext, options?: PrevezaDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficVolumeTile(this.context, {
                        itemId: "6273aa14a9b8e0fed571fbd5"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "6239c4761abbd52d010213be"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "6239c4461abbd52d0101e381"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "6239c4a71abbd52d010243f4"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "6239c4cf1abbd52d0102512d"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "624adebb9125a7978bfc66d3"
                    })
                ]
            }
        ];
    }

}
