import "./image-detail.scss";
import * as template from "./image-detail.hbs";
import { Detail } from "muklit/components/detail/detail";
import { InvipoContext } from "../../../context/invipo-context";
import { ImageDetailOptions } from "./types";

export class ImageDetail extends Detail<InvipoContext, ImageDetailOptions> {

    // Properties
    public dragging: boolean;
    public moving: boolean;
    public previousX: number;
    public previousY: number;
    public index: number = 0;

    constructor(context: InvipoContext, options: ImageDetailOptions) {
        super(context, template, options);

        // Set selected index
        this.index = this.options.urls?.indexOf(this.options.url);
    }

    public onKeyUp(e: KeyboardEvent) {
        // If no multiple images, we will quit
        if (!this.options.urls?.length || this.options.urls.length <= 1) {
            return;
        }

        // Previous image?
        if (e.key == "ArrowLeft" && this.index > 0) {
            this.setUrl(this.index - 1);
        }

        // Next image?
        if (e.key == "ArrowRight" && this.index < this.options.urls.length - 1) {
            this.setUrl(this.index + 1);
        }
    }

    public setDragging(dragging: boolean): void {
        this.dragging = dragging;

        // Cursor switch
        this.query("div.image").style.cursor = this.dragging ? "grabbing" : "grab";
    }

    public mouseDown(e: MouseEvent): void {
        // Stop propagation
        e.preventDefault();

        // Keep position
        this.previousX = e.clientX;
        this.previousY = e.clientY;

        // Reset mooving
        this.moving = false;

        this.setDragging(true);
    }

    public mouseUp(e: MouseEvent): void {
        // If not dragged, we will zoom in/out
        if (!this.moving) {
            // Toggle zoom class
            this.element.classList.toggle("invipo-image-detail-zoomed", !this.element.classList.contains("invipo-image-detail-zoomed"));

            // Center zoomed image to container
            let container = this.query("div.image");
            let image = this.query("div.image img");

            // Center via scroll
            container.scrollTo((image.offsetWidth - container.offsetWidth) / 2, (image.offsetHeight - container.offsetHeight) / 2);
        }

        this.setDragging(false);
    }

    public mouseLeave(e: MouseEvent): void {
        this.setDragging(false);
    }

    public mouseMove(e: MouseEvent): void {
        // Mouse not down?
        if (!this.dragging) {
            return;
        }

        // Stop propagation
        e.preventDefault();

        // Set moving
        this.moving = true;

        // Image container
        let image = this.query("div.image");

        // Scroll by move
        image.scrollTo({
            left: image.scrollLeft + (this.previousX - e.clientX),
            top: image.scrollTop + (this.previousY - e.clientY)
        })

        // Store last positions
        this.previousX = e.clientX;
        this.previousY = e.clientY;
    }

    public setUrl(i: number): void {
        // Assign to options
        this.options.url = this.options.urls[i];

        // Remove selected classes
        this.queryAll("div.preview div.image").forEach((e: HTMLElement) => {
            e.classList.remove("image-selected");
        });

        // Select current
        this.queryAll("div.preview div.image")[i].classList.add("image-selected");

        // Change image source
        this.query<HTMLImageElement>("div.image img").src = this.options.urls[i];

        // Update selected index
        this.index = i;
    }

    public close = (back?: boolean) => {
        // It is important to close all running HTTP streams
        // by repalcing image source before close
        let image = this.query<HTMLImageElement>("div.image img");
        image.src = "";

        // Call super method
        super.close(back);
    }

    public async load(): Promise<void> {
        // Image element
        let image = this.query<HTMLImageElement>("div.image img");

        // Image not loaded?
        if (!image.complete || image.naturalHeight == 0) {
            // Show loader
            this.showLoader();

            // Hide loader when image is loaded
            image.onload = () => {
                this.hideLoader();
            }
        }
    }
}
