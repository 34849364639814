import "./human-detail.scss";
import * as template from "./human-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { HumanDetailOptions } from "./types";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class HumanDetail extends Detail<InvipoContext, HumanDetailOptions> {

    // Components
    public tabs: Tabs;

    constructor(context: InvipoContext, options: HumanDetailOptions) {
        super(context, template, options);
    }

    public openImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.HumanDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Shoe
        detail.attach();
    }

}
