import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { METRICS } from "../../components/city/city-subdomain/types";
import { TrafficIncidentLayer } from "./traffic-incident-layer";

export class TrafficIncidentCircleLayer extends TrafficIncidentLayer {

    // Properties
    public incident: any;

    public constructor(context: InvipoContext, event?: any) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "TrafficIncidentCard",
            detail: "CityDetail",
            layer: {
                id: "traffic-incident-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 6, // 6, 3
                        14.95, 6,
                        15.0, 14,
                        22, 14
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                    "circle-stroke-opacity": 0, // 0 -> 0.75
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
                "circle-stroke-opacity": 0.75
            }
        });

        // Set message
        this.incident = event;
    }

    public update(): void {
        // Tell map source has updated
        this.map?.setLayerData(this, this.data);
    }

    public async load(): Promise<FeatureCollection> {
        // Load incidents
        let incidents = [];

        // Load detail?
        if (this.incident) {
            incidents.push(this.incident);
        }
        // Load all currently visible
        else {
            incidents = await this.context.invipo.getQuery("traffic-incidents-current");
        }

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let incident of incidents) {
            // Get symbol color from definition
            let color = METRICS.traffic.incidents.incidents.colors[METRICS.traffic.incidents.incidents.range.indexOf(incident.type)];

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: this.context.locale.getMessage(`enums.TrafficIncidentType.${incident.type}`),
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: incident.id,
                    incidentId: incident.id,
                    incidentType: incident.type,
                    color: color
                },
                geometry: {
                    type: "Point",
                    coordinates: (incident.position.type == "LineString") ? incident.position.coordinates[0] : incident.position.coordinates
                }
            })
        }

        return json;
    }
}
