import { DataStoreOptions, InvipoAreaQuery, InvipoItemQuery } from "./types";
import { InvipoArea, InvipoItem } from "../clients/invipo-client/types";
import { Log } from "../../hiyo/log";

const RELOAD_INTERVAL = 30; // How offten synchronize data from server (in seconds)

export class DataStore {

    // Properties
    public options: DataStoreOptions;
    public interval: any;
    private items: InvipoItem[];
    private areas: InvipoArea[];
    private distinct: { [data: string]: any };

    constructor(options: DataStoreOptions) {
        this.options = options;

        // Reload periodically
        setInterval(async () => {
            await this.sync();
        }, RELOAD_INTERVAL * 1000)
    }

    public getItem(id: string): InvipoItem {
        // No data loaded yet?
        if (!this.items) {
            Log.w(`Data: Items not synced up (forgot to initalize the store?)`);
            return;
        }

        // We need to do a card copy of items to prevent dta manipulation
        // If this is too slow, should be replaced by shallow copy
        let items: InvipoItem[] = JSON.parse(JSON.stringify(this.items));

        // Find result
        return items.find(x => x.id == id);
    }

    public getItems(query?: InvipoItemQuery): InvipoItem[] {
        // No data loaded yet?
        if (!this.items) {
            Log.w(`Data: Items not synced up (forgot to initalize the store?)`);
            return;
        }

        // We need to do a card copy of items to prevent dta manipulation
        // If this is too slow, should be replaced by shallow copy
        let items: InvipoItem[] = JSON.parse(JSON.stringify(this.items));

        // Filter by query
        if (query?.id != null) {
            items = items.filter(x => x.id == query.id);
        }
        if (query?.class != null) {
            items = items.filter(x => query.class.split(",").includes(x.class));
        }
        if (query?.model != null) {
            items = items.filter(x => query.model.split(",").includes(x.model));
        }
        if (query?.features != null) {
            items = items.filter(x => query.features.split(",").some(y => x.features?.includes(y)));
        }
        if (query?.monitoringStatus?.status) {
            items = items.filter(x => x.monitoringStatus?.status == query.monitoringStatus.status);
        }
        if (query?.parent?.id) {
            items = items.filter(x => x.parent?.id == query.parent.id);
        }

        // Sort enabled?
        if (query?.sort != null) {
            // Only first pair supported
            let pairs = query.sort.split(",");
            let tokens = pairs[0].split(":");

            // Sort function
            items.sort((a, b) => {
                // By name?
                if (tokens[0] == "name") {
                    return a.name?.localeCompare(b.name);
                }
                // Uknown proerty to sort
                else {
                    return 1;
                }
            });

            // Descendand sort?
            if (tokens[1] == "desc") {
                items.reverse();
            }
        }

        return items;
    }

    public getArea(id: string): InvipoArea {
        // No data loaded yet?
        if (!this.areas) {
            Log.w(`Data: Areas not synced up (forgot to initalize the store?)`);
            return;
        }

        // We need to do a card copy of areas to prevent dta manipulation
        // If this is too slow, should be replaced by shallow copy
        let areas: InvipoArea[] = JSON.parse(JSON.stringify(this.areas));

        // Find result
        return areas.find(x => x.id == id);
    }

    public getAreas(query?: InvipoAreaQuery): InvipoArea[] {
        // No data loaded yet?
        if (!this.areas) {
            Log.w(`Data: Areas not synced up (forgot to initalize the store?)`);
            return;
        }

        // We need to do a card copy of areas to prevent dta manipulation
        // If this is too slow, should be replaced by shallow copy
        let areas: InvipoArea[] = JSON.parse(JSON.stringify(this.areas));

        // Filter by query
        if (query?.type != null) {
            areas = areas.filter(x => query.type.split(",").includes(x.type));
        }

        // Sort enabled?
        if (query?.sort != null) {
            // Only first pair supported
            let pairs = query.sort.split(",");
            let tokens = pairs[0].split(":");

            // Sort function
            areas.sort((a, b) => {
                // By name?
                if (tokens[0] == "name") {
                    return a.name?.localeCompare(b.name);
                }
                // Uknown proerty to sort
                else {
                    return 1;
                }
            });

            // Descendand sort?
            if (tokens[1] == "desc") {
                areas.reverse();
            }
        }

        return areas;
    }

    public getDistinct(data: string, object: string): any {
        // We need to do a card copy of areas to prevent dta manipulation
        // If this is too slow, should be replaced by shallow copy
        let distinct: { [data: string]: any }  = JSON.parse(JSON.stringify(this.distinct));

        // No distinct at all?
        if (distinct == null || !distinct[data]) {
            return null;
        }

        return distinct[data][object];
    }

    public async sync(): Promise<any> {
        // Reload data
        this.items = await this.options.invipo.getItems();
        this.areas = await this.options.invipo.getAreas();
        this.distinct = await this.options.invipo.getDistinct();
        Log.d(`Data: Data store synced up (${this.items.length} items, ${this.areas.length} areas)`);
    }
}
