import "./element-tooltip.scss";
import * as template from "./element-tooltip.hbs";
import { Tooltip } from "muklit/components/tooltip/tooltip";
import { TooltipOptions } from "muklit/components/tooltip/types";
import { Log } from "hiyo/log";
import { InvipoContext } from "../../../context/invipo-context";

export class ElementTooltip extends Tooltip<InvipoContext> {

    public constructor(context: InvipoContext, options: TooltipOptions) {
        super(context, template, options);
    }

    public static create(context: InvipoContext, element: HTMLElement): boolean {
        let text = element.getAttribute("data-tooltip");
        let delay = element.getAttribute("data-tooltip-delay");

        // Not text?
        if (!text) {
            Log.w("Could not create tooltip, missing element data-tooltip attribute");
            return false;
        }

        // Tooltip already created for element?
        if ((<any>element).tooltip) {
            return false;
        }

        // Create new instance of tooltip for each DOM element that has "tooltip: attribute
        let tooltip = new ElementTooltip(context, {
            text: text
        });

        // Show tooltip on enter
        element.onmouseenter = (e) => {
            tooltip.show(e.pageX, e.pageY, delay ? Number(delay) : null);
        }

        // Reposition tooltip
        element.onmousemove = (e) => {
            tooltip.setPosition(e.pageX, e.pageY);
        }

        // Hide tooltip on out
        element.onmouseleave = (e) => {
            tooltip.hide();
        }

        // Tooltip must disappear after mouse is clicked
        // This is quite tricky, because we use mousedown instead of click that is usually bound for other actions
        element.onmousedown = (e) => {
            tooltip.hide();
        }

        // Assign tooltip to element
        (<any>element).tooltip = tooltip;

        return true;
    }

}
