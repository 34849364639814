import "./tooltip.scss";
import * as header from "./tooltip.header.hbs";
import { TooltipOptions } from "./types";
import { Context } from "hiyo/context";
import { MuklitComponent } from "../muklit-component/muklit-component";
import { Templates } from "../../../hiyo/templates";

const SPACE_HORIZONTAL = 10;
const SPACE_VERTICAL = 8;
const DELAY_SHOW = 600; // In ms

export abstract class Tooltip<T extends Context = Context, U extends TooltipOptions = TooltipOptions> extends MuklitComponent<T, U> {

    // Properties
    public timer: any;
    public left: number;
    public top: number;

    protected constructor(context: T, template: any, options: U) {
        super(context, template, options);

        // Partials
        Templates.registerPartial("tooltip-header", header);
    }

    public onAttach() {
        // Restore position if we are reattaching tooltip
        this.setPosition(this.left, this.top);
    }

    public onUpdate() {
        // On update tooltip size could be changed.
        // We need set same position to check clipping
        this.setPosition(this.left, this.top);
    }

    public show(left?: number, top?: number, delay?: number): void {
        this.left = left;
        this.top = top;

        // Tooltip is displayed with timout
        if (!this.timer) {
            // Set show timeout
            this.timer = setTimeout(() => {
                // Clear timer
                clearTimeout(this.timer);
                this.timer = null;

                // Attach if not visible
                if (!this.isAttached()) {
                    this.attach();
                }

                // Defaults
                left = this.left || this.element.offsetLeft;
                top = this.top || this.element.offsetTop;

                // Set position if required
                this.setPosition(left, top);
            }, delay ?? DELAY_SHOW);
        }
    }

    public hide(): void {
        // Not shown yet?
        if (this.timer) {
            // Clear timer
            clearTimeout(this.timer);
            this.timer = null;
        }

        // Detach if visible
        if (this.isAttached()) {
            this.detach();
        }
    }

    public setPosition(left: number, top: number): void {
        // Remember last position (use can move mouse before the tooltip is visible)
        this.left = left;
        this.top = top;

        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Viewport
        let width = this.element.offsetWidth;
        let height = this.element.offsetHeight;

        // Right clipping
        if (left + width + SPACE_HORIZONTAL > window.innerWidth) {
            left = left - width - SPACE_HORIZONTAL;
        }
        else {
            left = left + SPACE_HORIZONTAL;
        }

        // Bottom clipping
        if (top + height + SPACE_VERTICAL > window.innerHeight) {
            top = top - height - SPACE_VERTICAL;
        }
        else {
            top = top + SPACE_VERTICAL;
        }

        // Set position
        this.element.style.left = left + "px";
        this.element.style.top = top + "px";
    }

    public bringToFront(): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Increase zIndex and assign to style
        let zIndex = parseInt(this.element.style.zIndex);
        this.element.style.zIndex = (++zIndex).toString();
    }

    public close(): void {
        // Not attached?
        if (!this.isAttached()) {
            return;
        }

        // Detach from DOM
        this.detach();

        // OnClose handler
        this.onClose();
    }
}
