import './map-control.scss';
import * as template from "./map-control.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { MapControlOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { OverflowMenu } from 'muklit/components/overflow-menu/overflow-menu';
import { MenuItem } from 'muklit/components/overflow-menu/types';


export class MapControl extends MuklitComponent<InvipoContext, MapControlOptions> {

    // Event handling methods
    public onZoomInSelect(): void {};
    public onZoomOutSelect(): void {};
    public onZoomAllSelect(): void {};
    public onZoomHomeSelect(): void {};
    public onStyleSelect(style: string): void {};

    constructor(context: InvipoContext, options: MapControlOptions) {
        super(context, template, options);
    }

    public setZoomInDisabled(disabled: boolean) {
        if (this.options.zoomInDisabled != disabled) {
            // Update only when changed
            this.options.zoomInDisabled = disabled;
            this.update();
        }
    }

    public setZoomOutDisabled(disabled: boolean) {
        if (this.options.zoomOutDisabled != disabled) {
            // Update only when changed
            this.options.zoomOutDisabled = disabled;
            this.update();
        }
    }

    public selectMapStyle () {
        // Create menu
        let menu = new OverflowMenu(this.context, {
            style: this.options.style,
            start: "TopLeft",
            anchor: "BottomLeft",
            items: this.options.styleItems
        });

        // Change map style
        menu.onSelect = (item: MenuItem): void => {
            // Disable currently selected style
            for (let i of this.options.styleItems) {
                i.disabled = (i.name == item.name);
            }

            // onStyleSelect handler
            this.onStyleSelect(item.name);
        }

        // Show overflow menu
        menu.show(this.query("div.icon-map-style"));
    }
}
