import './wall-2droad.scss';
import * as template from "./wall-2droad.hbs";
import { Wall2droadOptions } from "./types";
import { InvipoContext } from "../../../context/invipo-context";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { ImageDetail } from "../../common/image-detail/image-detail";

const RELOAD_TIMEOUT = 60;

export class Wall2droad extends MuklitComponent<InvipoContext, Wall2droadOptions> {

    // Properties
    public items: InvipoItem[] = null;
    public timer: any;

    constructor(context: InvipoContext, options?: Wall2droadOptions) {
        super(context, template, options);
    }

    public open(url: string): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: null,
            url: `${this.context.options.host}/download${url}`,
            closable: true,
            overlay: true
        });

        // Show and center in element viewport
        detail.attach(this.element);
    }

    public async load(): Promise<void> {
        // Clear timer
        clearTimeout(this.timer);

        // Show loader
        this.showLoader();

        // 2DRoad Items
        this.items = this.context.data.getItems({
            class: "RoadWeatherStation",
            model: "2DRoad"
        });

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Reset after one minute
        this.timer = setTimeout(() => {
            this.load()
        }, RELOAD_TIMEOUT * 1000);

        // Update
        this.update();
    }

}
