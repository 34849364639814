import "./human-browser.scss";
import * as template from "./human-browser.hbs";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { ItemSelect } from "../../common/item-select/item-select";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { RangeInput } from "muklit/components/range-input/range-input";
import { HumanBrowserOptions } from "./types";
import { HumanDetailOptions } from "../human-detail/types";
import { HumanDetail } from "../human-detail/human-detail";
import { Select } from "muklit/components/select/select";
import { InvipoHelpers } from "../../../invipo-helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class HumanBrowser extends MuklitComponent<InvipoContext, HumanBrowserOptions> {

    // Components
    public table: FilterTable;
    public detail: HumanDetail;

    constructor(context: InvipoContext, options?: HumanBrowserOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTable();
    }

    public onDetach(): void {
        // Detach detail if attached
        if (this.detail?.isAttached()) {
            this.detail.detach();
        }
    }

    private createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/humans-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.HumanBrowser.title",
                items: [
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "HumanData",
                                items: [],
                                multiselect: true
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "gender",
                                label: "forms.fields.gender",
                                placeholderText: "forms.placeholders.all",
                                items: InvipoHelpers.toMenuItems(this.context.locale.getMessages("enums.HumanGender")),
                                multiselect: true
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "gender",
                        type: "String",
                        property: (data: any) => {
                            return data.gender ? this.context.locale.getMessage(`enums.HumanGender.${data.gender}`) : "";
                        },
                        label: "tables.columns.gender",
                        width: 100,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "age",
                        type: "Number",
                        property: "age",
                        label: "tables.columns.age",
                        align: "Right",
                        width: 60,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    protected openDetail(data: any, title?: string): void {
        // Detail options
        let options: HumanDetailOptions = {
            style: "Light",
            human: data,
            title: title || "components.HumanDetail.title",
            subtitle: data.item.name,
            printable: false,
            closable: true
        }

        // Detail already visible
        if (this.detail?.isAttached()) {
            // Assign new options
            this.detail.options = options;

            // Redraw completely
            this.detail.invalidate();

            // Not continue
            return;
        }

        // New detail
        this.detail = new HumanDetail(this.context, options);

        // Unselect table row and null detail
        this.detail.onClose = () => {
            this.table.unselectRow(this.detail.options.human.id);
            this.detail = null;
        }

        // Show detail
        this.detail.attach();
    }
}
