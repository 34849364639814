import './access-subdomain.scss';
import * as template from "./access-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { AreaList } from "../../city/area-list/area-list";
import { AccessSubdomainOptions } from "./types";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";
import { AccessAuthorizationAreaCircleLayer } from "../../../layers/safety/access-authorization-area-circle-layer";
import { AccessAuthorizationAreaLabelLayer } from "../../../layers/safety/access-authorization-area-label-layer";

export class AccessSubdomain extends CitySubdomain<AccessSubdomainOptions> {

    constructor(context: InvipoContext, options?: AccessSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Authorization",
                    itemClass: "TrafficCamera,BluetoothDetector,SecurityCamera",
                    areaType: "Access",
                    layers: [
                        new AccessAuthorizationAreaCircleLayer(context),
                        new AccessAuthorizationAreaLabelLayer(context)
                    ]
                }
            ],
            ...options
        });
    }

    public selectAreas(): void {
        // Crate item list
        let list = new AreaList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.areas",
            subtitle: "components.TrafficCountingSubdomain.areas",
            layers: []
        });

        // Areas already loaded, will use them
        list.areas = this.areas;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.TrafficCountingSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list);
    }

    public async extraLoad(): Promise<void> {
        // Last 24 hours
        let from = new Date(Date.now() - 86400000);

        // Access counts
        let access = await this.context.invipo.getQuery<any>("access-count", `from=${from.toISOString()}`);


        // Create authorization legend
        this.legend = {
            description: "components.AccessSubdomain.authorizationDescription",
            range: {
                range: METRICS.safety.access.authorization.range,
                colors: METRICS.safety.access.authorization.colors,
                count: access.count,
                percent: access.authorization.denied / (access.count || 1) * 100,
                unit: "%"
            },
            symbols: []
        }
    }
}
