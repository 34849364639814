import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { StatsDetail } from "../../common/stats-detail/stats-detail";
import { NetworkConnectionStatsOptions } from "./types";
import { RangeInput } from "../../../../muklit/components/range-input/range-input";
import { NetworkConnectionChart } from "../network-connection-chart/network-connection-chart";

export class NetworkConnectionStats extends StatsDetail<NetworkConnectionStatsOptions> {

    public createForm(): void {
        // Create component
        this.form = new Form(this.context, {
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 604800000).toISOString(),
                                to: new Date(new Date().setHours(-24 * ((new Date().getDay() + 6) % 7), 0, 0, 0) - 1).toISOString(),
                                range: "LastWeek"
                            },
                            placeholderText: "forms.placeholders.anytime"
                        }),
                        new ItemSelect(this.context, {
                            style: "Light",
                            name: "item.id",
                            label: "forms.fields.item",
                            placeholderText: "forms.placeholders.all",
                            distinct: "NetworkData",
                            items: [],
                            multiselect: true
                        })
                    ]
                }
            ]
        });

        // Set new search parameters
        this.form.onSubmit = async (data: any) => {
            await this.chart.filter(data);
        }
    }

    public createChart(): void {
        // Create component
        this.chart = new NetworkConnectionChart(this.context, {
            style: "Light",
            queryName: "NetworkConnectionsByGroup",
            view: "Chart",
            groups: [
                {
                    name: "Hour",
                    label: "components.StatsChart.groups.Hour",
                    selected: true
                },
                {
                    name: "Date",
                    label: "components.StatsChart.groups.DayOfYear",
                },
                {
                    name: "Day",
                    label: "components.StatsChart.groups.DayOfWeek"
                },
                {
                    name: "Week",
                    label: "components.StatsChart.groups.Week"
                },
                {
                    name: "Month",
                    label: "components.StatsChart.groups.Month"
                }
            ],
            title: "components.NetworkConnectionStats.title",
            search: this.form.getData(),
            closable: this.options.closable
        });

        // Form togle
        this.chart.onFormToggle = () => {
            this.toggleForm();
        }

        // Clear search parameter
        this.chart.onSearchRemove = (key: string) => {
            this.form.setValue(key, null);
        }
    }

}
