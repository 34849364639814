import { Context } from "../../hiyo/context";
import { Log } from "../../hiyo/log";
import { InvipoClient } from "../clients/invipo-client/invipo-client";
import { WsClient } from "../clients/ws-client/ws-client";
import { HiyoEventSource } from "../../hiyo/event-broker";
import { Info, InvipoEvent, License } from "../clients/invipo-client/types";
import { InvipoConfig, InvipoContextOptions } from "./types";
import { DataStore } from "../data/data-store";

export class InvipoContext extends Context<InvipoContextOptions> {

    // Properties
    public config: InvipoConfig;
    public license: License;
    public info: Info;
    public data: DataStore;
    public invipo: InvipoClient;
    public ws: WsClient;

    constructor(options: InvipoContextOptions) {
        Log.i("Creating InvipoContext instance");

        // Local host switch?
        if (options.host == null) {
            options.host = document.location.origin;
        }

        // Validation
        if (!options.langs) {
            throw new Error(`Missing options.langs in InvipoContextOptions`);
        }
        if (!options.project) {
            throw new Error(`Missing options.project in InvipoContextOptions`);
        }
        if (!options.name) {
            throw new Error(`Missing options.name in InvipoContextOptions`);
        }
        if (!options.classes) {
            throw new Error(`Missing options.classes in InvipoContextOptions`);
        }
        if (!options.permissions) {
            throw new Error(`Missing options.permissions in InvipoContextOptions`);
        }
        if (!options.notifications) {
            throw new Error(`Missing options.notifications in InvipoContextOptions`);
        }
        if (!options.home) {
            throw new Error(`Missing options.home in InvipoContextOptions`);
        }

        // Base init
        super(options);

        this.options = options;

        // Invipo client instance
        this.invipo = new InvipoClient({
            host: this.options.host
        });

        // Invipo data instance
        this.data = new DataStore({
            invipo: this.invipo
        });

        // Websocket URL uses same host
        let wsUrl = `${new URL(this.options.host).protocol.replace("http", "ws")}//${new URL(this.options.host).host}/ws`;

        // WebSocket client
        this.ws = new WsClient({
            wsUrl: wsUrl
        });

        // Ws onEvent handler
        this.ws.onEvent = (event: InvipoEvent) => {
            // InvipoEvent -> HiyoEvent
            this.broker.fire({
                type: event.type,
                source: HiyoEventSource.Websocket,
                timestamp: event.timestamp,
                payload: event
            });
        };
    }
}

