import * as alerts from "./detail-panel.alerts.hbs";
import * as map from "./detail-panel.map.hbs";
import * as item from "./detail-panel.item.hbs";
import * as malfunctions from "./detail-panel.malfunctions.hbs";
import "./detail-panel.scss";
import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoArea, InvipoItem } from "../../../clients/invipo-client/types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { DetailPanelOptions } from "./types";
import { Templates } from "hiyo/templates";
import { ItemSingleCircleLayer } from "../../../layers/infrastructure/item-single-circle-layer";
import { Feature, Point } from "geojson";

export abstract class DetailPanel<T extends DetailPanelOptions = DetailPanelOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public item: InvipoItem;
    public area: InvipoArea;

    // Components
    public map: BasicMap;

    protected constructor(context: InvipoContext, template: any, options?: T) {
        super(context, template, options);

        // Register partials
        Templates.registerPartial("detail-panel-alerts", alerts);
        Templates.registerPartial("detail-panel-map", map);
        Templates.registerPartial("detail-panel-item", item);
        Templates.registerPartial("detail-panel-malfunctions", malfunctions);
    }

    public onCreate(): void {
        // Create components
        this.createMap();

        // Register components that will be automatically attached
        this.registerComponent(this.map, "map");
    }

    protected createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 0,
            maxZoom: 20
        });

        // Zoom on click
        this.map.onFeatureClick = async (layer: string, feature: Feature, event?: MouseEvent) => {
            this.map.flyTo({
                center: (<Point>feature.geometry).coordinates,
                zoom: 15
            });
        }

        // Register map layers
        this.map.addLayer(new ItemSingleCircleLayer(this.context, this.options.itemId));
    }

    public async extraLoad(): Promise<void> {
        // To overload in subclass to manage extra loading
    };

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load item data
        if (this.options.itemId) {
            this.item = this.context.data.getItem(this.options.itemId);
        }

        // Load area data
        if (this.options.areaId) {
            this.area = this.context.data.getArea(this.options.areaId);
        }

        // Extra loading
        await this.extraLoad();

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Update
        this.invalidate(true);
    }
}
