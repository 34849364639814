import "./camera-overview.scss";
import * as template from "./camera-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { CameraOverviewOptions } from "./types";
import { ImageDetail } from "../../common/image-detail/image-detail";

export class CameraOverview extends DetailPanel<CameraOverviewOptions> {

    // Properties
    public history: { [data: string]: DetailHistoryValue[] };
    public snapshots: any[];

    constructor(context: InvipoContext, options?: CameraOverviewOptions) {
        super(context, template, options);
    }

    public openImage(url: string): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: null,
            url: url,
            closable: true,
            overlay: true
        });

        // Show and center in element viewport
        detail.attach();
    }

    public async extraLoad(): Promise<void> {
        this.snapshots = [];

        // Load camera snapshots
        for (let i = 3; i > 0; i--) {
            let data = await this.context.invipo.getDataset("cameras-data", `item.id=${this.options.itemId}&from=${new Date(new Date().setMinutes(-(i * 60), 0, 0)).toISOString()}&pageSize=1&sort=interval.from:asc`);

            // Push first snapshot only
            if (data.data?.length) {
                this.snapshots.push(data.data[0].snapshots[0]);
            }
        }
    }

}
