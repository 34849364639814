import "./pulsing-marker.scss";
import * as template from "./pulsing-marker.hbs";
import { MapMarker } from "muklit/components/map-marker/map-marker";
import { PulsingMarkerOptions } from "./types";
import { Context } from "../../../hiyo/context";

export class PulsingMarker extends MapMarker<Context, PulsingMarkerOptions> {

    // Event handling methods
    public onFinish(): void {}

    constructor(context: Context, options: PulsingMarkerOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        // OnFinish handler
        this.element.addEventListener("animationend", () => {
            this.onFinish();
        });
    }
}
