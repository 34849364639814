import "./schedule-form.scss";
import * as template from "./schedule-form.hbs";
import { InvipoContext } from "../../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { ScheduleFormOptions } from "./types";
import { InvipoItemStream } from "../../../../clients/invipo-client/types";
import { Form } from "muklit/components/form/form";
import { DateInput } from "muklit/components/date-input/date-input";
import { TextArea } from "../../../../../muklit/components/text-area/text-area";

export class ScheduleForm extends Detail<InvipoContext, ScheduleFormOptions> {

    // Properties
    public stream: InvipoItemStream;

    // Components
    public form: Form;


    // Event handling methods
    public onSubmit(): void {}

    public constructor(context: InvipoContext, options: ScheduleFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();

        // Register components that will be always attached
        this.registerComponent(this.form, "form");
    }

    public createForm () {
        // Create component
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new DateInput(this.context, {
                            style: "Light",
                            name: "time",
                            label: "components.ItemPassportForm.time",
                            type: "DateTime",
                            required: true,
                            bright: true
                        })
                    ]
                },
                {
                    name: "Left",
                    fields: [
                        new TextArea(this.context, {
                            style: "Light",
                            name: "task",
                            label: "components.ItemPassportForm.task",
                            rows: 4,
                            required: true,
                            bright: true
                        })
                    ]
                },
            ]
        });
    }

    public async submit(): Promise<void> {
        // Invalid form data?
        if (!this.form.validate()) {
            return;
        }

        // Form data
        let data = this.form.getData(true);

        // Show loader
        this.showLoader();

        // add comment to stream
        try {
            const result = await this.context.invipo.createItemSchedule(this.options.itemId, data.time, data.task);
            console.log(result);
        }
        finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }
}
