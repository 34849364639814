// Imports
import { Log } from "./log";
import { Dom } from "./dom";
import { HiyoObject } from "./hiyo-object";
import { Context } from "./context";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class Component<T extends Context = Context, U extends ComponentOptions = ComponentOptions> extends HiyoObject<T> {

    // Properties
    public template: any;
    public element: HTMLElement;
    public options: U;
    public parent: Component;

    // Event handling methods
    public onCreate(): void {}
    public onAttach(): void {}
    public onUpdate(): void {}
    public onDetach(): void {}
    public onLoad(): void {}
    public onClose(back?: boolean): void {}

    constructor(context: T, template: any, options?: U) {
        super(context);

        this.template = template;
        this.options = options;

        // OnCreate handler
        this.onCreate();
    }

    public isAttached(): boolean {
        return this.element != null;
    }

    public attach(target?: string | HTMLElement, before?: boolean, skipLoad?: boolean): void {
        // Already attached to DOM?
        if (this.isAttached()) {
            Log.w(`Trying to attach already attached component ${this.id}`);
            return;
        }

        let html = this.render();

        // Create a DOM element
        this.element = Dom.createElement(target || "body", html, before);

        // Add default class
        //this.element.className = DEFAULT_CLASS_NAME + " " + this.element.className;

        // Set component and id attributes
        this.element.setAttribute("component", this.constructor.name);
        this.element.setAttribute("id", this.id);

        // Custom class
        if (this.options?.class) {
            this.element.classList.add(this.options.class);
        }

        // Ensure that calling component.function() from DOM will be possible
        Dom.propagateToContext(this.element, this);

        Log.d(`+Component ${this.id}`);

        // Attach all registered components
        for (let key in this.components) {
            const component = this.components[key];
            const elements = this.element.querySelectorAll<HTMLElement>(`component[key="${key}"]`);

            // No element for register component
            if (elements.length == 0) {
                // This is not an error or warning furthermore!
                continue;
            }

            // Only one instance can exist in DOM
            if (elements.length > 1) {
                Log.w(`Multiple elements <component key="${key}"></component> found in ${this.constructor.name} template`);
                continue;
            }

            // Assign parent
            component.parent = this;

            // We must attach the component into same level as custom tag was
            // Loading will be NOT skipped if parent loading is skipped too
            component.attach(elements[0]);

            // And remove the custom tag by replacing it by the component itself
            elements[0].replaceWith(component.element);
        }

        // If custom tags remain it looks like something was not finished properly
        const elements = this.element.querySelectorAll<HTMLElement>(`component`);
        if (elements.length > 0) {
            Log.w(`One or more <component> elements found in ${this.constructor.name} template that was not replaced`);
        }

        // Subscribe to EventBroker automatically
        //this.context.broker.subscribe(this);

        // OnAttach handler
        this.onAttach();

        // Skip loading?
        if (skipLoad) {
            Log.d(`Loading skipped in component ${this.id}`);
            return;
        }

        // Load handler (async)
        (async () => {
            // Wait for load
            await this.load();

            // OnLoad handler
            this.onLoad();
        })();
    }

    public detach(): void {
        // Not created and attached to DOM?
        if (!this.isAttached()) {
            Log.w(`Trying to detach not attached component ${this.id}`);
            return;
        }

        // Detach all registered components
        for (let key in this.components) {
            const component = this.components[key];
            if (component.isAttached()) {
                component.detach()
            }
        }

        // Remove element and SET instance to null
        this.element.remove();
        this.element = null;

        Log.d(`-Component ${this.id}`);

        // Unsubscribe to EventBroker automatically
        //this.context.broker.unsubscribe(this);

        // OnDetach handler
        this.onDetach();
    }

    public update(): void {
        if (!this.isAttached()) {
            Log.w(`Trying to update on unattached/detached component ${this.id}`);
            return;
        }

        let html = this.render();

        // Will replace the body
        // Warning: no full render of all children!
        Dom.updateElement(this.element, html);
        Dom.propagateToContext(this.element, this);

        // If custom tags remain it looks that invalidate() should be called instead of update()
        const elements = this.element.querySelectorAll<HTMLElement>(`component`);
        if (elements.length > 0) {
            Log.w(`One or more <component> elements found in ${this.constructor.name} template, call invalidate() instead of update() to replace them`);
        }

        // Add default class
        //this.element.className = DEFAULT_CLASS_NAME + " " + this.element.className;

        Log.i(`*Component ${this.id} updated`);

        // OnUpdate handler
        this.onUpdate();
    }

    public render(): string {
        let template = Handlebars.compile(this.template);

        return template(this);
    }

    public async load(): Promise<void> {
    }

    public invalidate(skipLoad?: boolean): void {
        if (!this.isAttached()) {
            Log.w(`Trying to invalidate on unattached/detached component ${this.id}`);
            return;
        }

        let parent = this.element ? this.element.parentElement : null;

        // Update means reattaching the component ege
        this.detach();
        this.attach(parent, null,  skipLoad);

        Log.i(`:Component ${this.id} invalidated${skipLoad ? " (skipped loading)" : ""}`);
    }

    public animate(features: {[selector : string]: string}): void {
        if (!this.isAttached()) {
            Log.w(`Trying to animate on unattached/detached component ${this.id}`);
            return;
        }

        for (let selector in features) {
            let element = (selector == "self") ? this.element : this.element.querySelector<HTMLElement>(selector);

            // Found
            if (element) {
                let animation = features[selector];

                // Class name
                if (animation.startsWith(".")) {
                    // Animation restart hack, see https://css-tricks.com/restart-css-animation/
                    element.classList.remove(animation.substring(1));
                    void element.offsetWidth;
                    element.classList.add(animation.substring(1));
                }
                // Animation style
                else {
                    // Animation restart hack, see https://css-tricks.com/restart-css-animation/
                    element.style.animation = "none";
                    void element.offsetWidth;
                    element.style.animation = features[selector];
                }
            }
        }
    }

    public query<T extends HTMLElement>(selector: string): T {
        // Element not ready or gone
        if (!this.isAttached()) {
            Log.w(`Trying to query on unattached/detached component ${this.id}`);
            return;
        }

        return this.element.querySelector<T>(selector);
    }

    public queryAll<T extends HTMLElement>(selector: string): NodeListOf<T> {
        // Element not ready or gone
        if (!this.isAttached()) {
            Log.w(`Trying to query all on unattached/detached component ${this.id}`);
            return;
        }

        return this.element.querySelectorAll<T>(selector);
    }
}

export interface ComponentOptions {
    class?: string;
}
