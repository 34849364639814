import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { PublicTransportVehicleLayer } from "./public-transport-vehicle-layer";

export class PublicTransportVehicleHistoryCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60, // Each minute hard init
            layer: {
                id: "public-transport-vehicle-history-circle",
                type: "circle",
                minzoom: ZOOM_AREA + 2,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        ZOOM_AREA + 2, 3,
                        22, 8
                    ],
                    "circle-color": "#298cff",
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 0.4
            }
        });
    }

    public async load(): Promise<FeatureCollection> {
        // Load history
        let history = await this.context.invipo.getDataset("public-vehicles-history", `from=${new Date(new Date().getTime() - 8 * 3600000).toISOString()}&sort=timestamp:desc`);

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of history.data) {
            json.features.push({
                type: "Feature",
                properties: {
                },
                geometry: vehicle.position
            })
        }

        return json;
    }
}
