import "./traffic-detail.scss";
import * as template from "./traffic-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficDetailOptions } from "./types";
import { Detail } from "muklit/components/detail/detail";

export class TrafficDetail extends Detail<InvipoContext, TrafficDetailOptions> {

    // Event handling methods
    public onImageSelect(image: any): void {}

    constructor(context: InvipoContext, options: TrafficDetailOptions) {
        super(context, template, options);
    }

    public selectImage(i: number): void {
        // OnImageSelect handler
        this.onImageSelect(this.options.traffic.snapshot);
    }

    public openVehicles(): void {
        this.context.application.route({
            viewId: "Data",
            componentName: this.context.options.data["Traffic"].find(x => x.endsWith("VehicleBrowser")),
            from: this.options.traffic.interval.from,
            to: this.options.traffic.interval.to,
            itemId: this.options.traffic.item.id,
            lane: this.options.traffic.segment
        });
    }

}
