import "./user-menu.scss";
import * as template from "./user-menu.hbs";
import { Context } from "hiyo/context";
import { UserMenuOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";

export const TIMEOUT_DOCUMENT_LISTENER = 150; // ms

export class UserMenu extends MuklitComponent<Context, UserMenuOptions> {

    // Properties
    private hideListener = () => this.hide();

    // Event handling methods
    public onSelect(name: string): void {};

    constructor(context: Context, options?: UserMenuOptions) {
        super(context, template, options);
    }

    public select(name: string): void {
        // OnSelect handler
        this.onSelect(name);
    }

    public show(trigger: HTMLElement): void {
        // Already visible?
        if (this.isAttached()) {
            return;
        }

        // Attach to document
        this.attach();

        // Trigger element positions
        let rect = trigger.getBoundingClientRect();

        // Set position to style
        this.element.style.left = `${rect.left + rect.width}px`;
        this.element.style.top = `${rect.bottom - this.element.offsetHeight}px`;

        // We add document listener after some short delay because of event bubbling
        setTimeout(() => {
            document.addEventListener("click", this.hideListener);
        }, TIMEOUT_DOCUMENT_LISTENER)
    }

    public hide(): void {
        // Detach from document
        this.detach();

        // Remove
        document.removeEventListener("click", this.hideListener);
    }

}
