import "./traffic-camera-card.scss";
import * as template from "./traffic-camera-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { Helpers } from "hiyo/helpers";
import { CardHistoryValue } from "../../city/city-card/types";
import { TrafficCameraCardOptions } from "./types";

export class TrafficCameraCard extends CityCard<TrafficCameraCardOptions> {

    // Properties
    public access: any[];
    public history: CardHistoryValue[];

    constructor(context: InvipoContext, options: TrafficCameraCardOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Load history
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        this.access = await this.context.invipo.getQuery("access-count-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}&sort=timestamp`);

        // Calculate history data (last 2 days)
        this.history = [];

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = this.access.find(x => x.timestamp == from.toISOString());

            // Add hour data
            if (d) {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: d.count,
                    percent: Helpers.range(0, 100, 0, 50, d.count),
                    color: "#008efa"
                });
            }
            // No data for hour, we are in the future
            else {
                this.history.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

    }
}
