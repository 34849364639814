import { Feature, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer } from "muklit/components/basic-map/map-layer";
import { HiyoEvent } from "hiyo/event-broker";

export abstract class CitsVehicleLayer extends MapLayer<InvipoContext> {

    // Properties
    public intercity: boolean;

    public onAdd(): void {
        this.context.broker.subscribe(this, ["CitsVehicleUpdated", "CitsVehicleTerminated"]);
    }

    public onRemove(): void {
        this.context.broker.unsubscribe(this);
    }

    public onHandle(event: HiyoEvent): void {
        // Data was not loaded yet?
        if (!this.data) {
            return;
        }

        // Get vehicle index
        let i = this.getFeatureIndex(event.payload.extras.stationId);

        // Vehicle terminated?
        if (event.type == "CitsVehicleTerminated") {
            if (i >= 0) {
                this.data.features.splice(i, 1);
            }
        }

        // Vehicle updated?
        if (event.type == "CitsVehicleUpdated") {
            if (i >= 0) {
                this.data.features[i] = this.toFeature(event.payload.extras);
            }
            // New vehicle must fetch intercity flag
            else if (this.intercity == null || event.payload.extras.inrercity == this.intercity) {
                this.data.features.push(this.toFeature(event.payload.extras));
            }
        }

        // Force to update source
        this.update();
    }

    public getFeatureIndex(vehicleId: string): number {
        if (!this.data) {
            return -1;
        }

        for (let i = 0; i < this.data.features.length; i++) {
            if (this.data.features[i].properties.vehicleId == vehicleId) {
                return i;
            }
        }

        return -1;
    }

    public abstract toFeature(vehicle: any): Feature<Point>;

}
