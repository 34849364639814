import "./garbage-overview.scss";
import * as template from "./garbage-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailPanel } from "../../common/detail-panel/detail-panel";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { GarbageOverviewOptions } from "./types";
import { DatasetResult } from "muklit/components/query-table/types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class GarbageOverview extends DetailPanel<GarbageOverviewOptions> {

    // Properties
    public history: { [data: string]: DetailHistoryValue[] };
    public collections: DatasetResult;

    constructor(context: InvipoContext, options?: GarbageOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Empty history
        this.history = {};

        for (let m of ["level", "temperature"]) {
            // Select proper metrics
            let metrics = METRICS.waste.garbage[m];

            // Load history
            let from = new Date(new Date().setHours(-120, 0, 0, 0));
            let to = new Date(new Date().setHours(24, 0, 0, 0));
            let data = await this.context.invipo.getQuery(`garbage-${m}-by-hour`, `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

            // Reset history data
            this.history[m] = [];

            // Get history from yesterday's midnight to today's midnight
            for (let h = 0; h < 120; h++) {
                // Find hour in data
                let d = data.find(x => x.timestamp == from.toISOString());

                // Add history line
                if (d) {
                    this.history[m].push({
                        timestamp: from.toISOString(),
                        value: d[m],
                        percent: Helpers.range(0, 100, 0, metrics.range[1], d[m]),
                        color: metrics.colors[Math.round(Helpers.range(0, metrics.colors.length - 1, metrics.range[0], metrics.range[1], d[m]))]
                    });
                }
                // No data for hour, we are in the future
                else {
                    this.history[m].push({
                        timestamp: from.toISOString(),
                        value: 0
                    });
                }

                // Move to next hour
                from.setTime(from.getTime() + 3600000);
            }
        }
    }

}
