import "./public-transport-stop-card.scss";
import * as template from "./public-transport-stop-card.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { CityCard } from "../../city/city-card/city-card";
import { PublicTransportStopCardOptions } from "./types";

export class PublicTransportStopCard extends CityCard<PublicTransportStopCardOptions> {

    constructor(context: InvipoContext, options: PublicTransportStopCardOptions) {
        super(context, template, options);
    }

}
