import "./template-detail.scss";
import * as template from "./template-detail.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { TemplateDetailOptions } from "./types";
import { Dialog } from "muklit/components/dialog/dialog";
import { TemplateForm } from "../template-form/template-form";

export class TemplateDetail extends Detail<InvipoContext, TemplateDetailOptions> {

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: TemplateDetailOptions) {
        super(context, template, options);
    }

    public openForm(): void {
        // Details form
        let form = new TemplateForm(this.context, {
            style: "Light",
            title: this.options.template.key,
            template: this.options.template,
            overlay: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Get new template
            this.options.template = form.options.template;

            // Update
            this.update();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.TemplateDetail.templateDelete",
            text: this.context.locale.getMessage("components.TemplateDetail.reallyDelete", this.template.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        // OnTemplateLogout handler
        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteTemplate(this.options.template.key);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

}
