import "./traffic-light-marker.scss";
import * as template from "./traffic-light-marker.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TrafficLightMarkerOptions } from "./types";
import { HiyoEvent } from "hiyo/event-broker";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { Helpers } from "hiyo/helpers";
import { OrtoMarker } from "../orto-marker/orto-marker";
import { TrafficControlElements } from "../../devices/traffic-detail/traffic-detail-element/elements";

export class TrafficLightMarker extends OrtoMarker<TrafficLightMarkerOptions> {

    // Properties
    public item: InvipoItem;

    // Event handling methods
    public onFinish(): void {}

    constructor(context: InvipoContext, options: TrafficLightMarkerOptions) {
        TrafficControlElements.registerAll();

        super(context, template, options);
    }

    public onEvent(event: HiyoEvent) {
        // Traffic second frame
        let group = (<any[]>event.payload.extras.groups).find(x => x.no == this.options.no);

        // For me?
        if (group) {
            this.query("div.light").setAttribute("data-tooltip", group.name);
            this.query("svg").setAttribute("class", Helpers.toKebabCase(group.state));
        }
    }
}
