import "./esbjerg-dashboard.scss";
import * as template from "./esbjerg-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Dashboard } from "../dashboard/dashboard";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { TrafficVolumeTile } from "../traffic-volume-tile/traffic-volume-tile";
import { EsbjergDashboardOptions } from "./types";
import { ParkingAreaTile } from "../parking-area-tile/parking-area-tile";

export class EsbjergDashboard extends Dashboard<EsbjergDashboardOptions> {

    constructor(context: InvipoContext, options?: EsbjergDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Traffic",
                width: "2",
                tiles: [
                    new TrafficVolumeTile(this.context, {
                        itemId: "63da756f3f5b016fa1da30a9"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "63da74ed3f5b016fa1da30a8"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "63b6e543d9f50e082b674560"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "63da74ae3f5b016fa1da30a7"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "63da74393f5b016fa1da30a5"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "63da73e23f5b016fa1da30a4"
                    }),
                    new TrafficVolumeTile(this.context, {
                        itemId: "644a7c7b1cd29e17d7c60df6"
                    })
                ]
            },
            {
                name: "Parking",
                width: "2",
                tiles: [
                    new ParkingAreaTile(this.context, {
                        areaId: "60b09d396f6f1f04d032711d"
                    }),
                    new ParkingAreaTile(this.context, {
                        areaId: "60b09d466f6f1f04d0327120"
                    })
                ]
            }
        ];
    }

}
