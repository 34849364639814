import { Component } from "hiyo/component";
import { Context } from "hiyo/context";
import { MuklitComponentOptions } from "./types";
import { Dom } from "hiyo/dom";

const CLASS_OVERLAY = "muklit-overlay";
const CLASS_LOADER = "muklit-loader";

export class MuklitComponent<T extends Context = Context, U extends MuklitComponentOptions = MuklitComponentOptions> extends Component<T, U> {

    // Static
    public static Z_INDEX: number = 100;

    // Properties
    public overlay: HTMLElement;
    public loader: HTMLElement;

    // Event handling methods
    public onOverlayClick(): void {}

    public showOverlay(): void {
        // Already created?
        if (this.overlay) {
            return;
        }

        // Add overlay element to DOM
        this.overlay = Dom.createElement(document.body, `<div class="${CLASS_OVERLAY}"></div>`);

        // Add class to component
        this.element.classList.add(`${CLASS_OVERLAY}-visible`);

        // Calculate z-indexes of overlay and detail to make layering possible
        this.overlay.style.zIndex = `${MuklitComponent.Z_INDEX - 1}`

        // Increase z index
        MuklitComponent.Z_INDEX += 2;

        // OnOverlayClick handler
        this.overlay.addEventListener("click", () => {
            this.onOverlayClick();
        })
    }

    public hideOverlay(): void {
        // Not created?
        if (!this.overlay) {
            return;
        }

        // Remove overlay from DOM
        this.overlay.remove();
        this.overlay = null;

        // Remove class from component
        this.element?.classList.remove(`${CLASS_OVERLAY}-visible`);

        // Decrease z index
        MuklitComponent.Z_INDEX -= 2;
    }

    public showLoader(): void {
        // Component not attached?
        if (!this.isAttached()) {
            return;
        }

        // Already shown?
        if (this.loader) {
            return;
        }

        // Add loader element to DOM
        this.loader = Dom.createElement(this.element, `<div class="muklit-loader"><div class="loader-bar"></div></div>`);

        // Add class to component
        this.element?.classList.add(`${CLASS_LOADER}-visible`);
    }

    public hideLoader(): void {
        // Not yet shown?
        if (!this.loader) {
            return;
        }

        // Remove loader from DOM
        this.loader.remove();
        this.loader = null;

        // Remove class from component
        this.element?.classList.remove(`${CLASS_LOADER}-visible`);
    }
}
