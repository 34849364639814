import "./stats-chart.scss";
import * as template from "./stats-chart.hbs";
import { ChartHighlight, ChartSymbol, StatsChartOptions } from "./types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { OverflowMenu } from "muklit/components/overflow-menu/overflow-menu";
import { InvipoContext } from "../../../context/invipo-context";
import { Helpers } from "hiyo/helpers";
import { Log } from "hiyo/log";
import { ElementTooltip } from "../element-tooltip/element-tooltip";
import { ClientExportForm } from "../client-export-form/client-export-form";
import { InvipoHelpers } from "../../../invipo-helpers";
import { DataTable } from "muklit/components/data-table/data-table";

export const DEFAULT_FONT = {
    fontFamily: "Invipo Regular",
    fontSize: 12,
    fontColor: "#a8a8a8"
}

export abstract class StatsChart<T extends StatsChartOptions = StatsChartOptions> extends MuklitComponent<InvipoContext, T> {

    // Properties
    public data: any[];
    public legend: ChartSymbol[];
    public highlights: { [name: string]: ChartHighlight };
    public table: DataTable;
    public chart: Chart;
    public tooltip: ElementTooltip;

    // Components
    public menu: OverflowMenu;

    // Event handler
    public onFormToggle(): void {};

    // Event handling methods
    public onDataLoad(data: any): void {};
    public onItemSelect(item: MenuItem): void {};
    public onSearchRemove(key: string): void {};

    public constructor(context: InvipoContext, options: T) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMenu();
    }

    private createMenu(): void {
        // Items are empty, menu will be never activated
        if (!this.options.items) {
            return;
        }

        // Create component
        this.menu = new OverflowMenu(this.context, {
            style: this.options.style,
            items: this.options.items,
            anchor: "BottomLeft"
        })

        // Reset value when selected from menu
        this.menu.onSelect = (item: MenuItem) => {
            // OnItemSelect handler
            this.onItemSelect(item);
        }
    }

    public selectMenu(): void {
        // Show menu
        this.menu.show(this.query("div.header div.icon-menu"));
    }

    public async removeSearch(key: string): Promise<void> {
        // Remove from query
        delete this.options.search[key];

        // Reload data
        await this.load();

        // OnSearchRemove handler
        this.onSearchRemove(key);
    }

    public async selectGroup(i: number): Promise<void> {
        // Already selected?
        if (this.options.groups[i].selected) {
            return;
        }

        // Unselect all
        this.options.groups.forEach(x => x.selected = false);

        // Selected tab
        this.options.groups[i].selected = true;

        // Reload data
        await this.load();
    }

    public changeView(view: any): void {
        // New view
        this.options.view = view;

        // Update with data
        this.update();

        // Which data view?
        if (this.options.view == "Chart") {
            // Render chart
            this.drawChart();
        }
        else {
            // Render table
            this.drawTable();
        }
    }

    public createLegend(): void {
        this.legend = [];
    }

    public createHighlights(): void {
        this.highlights = {};
    }

    public createTable(): void {
    }

    public abstract drawChart(): void;

    public drawTable(): void {
        // Already attached
        if (this.table.isAttached()) {
            this.table.detach();
        }

        this.table.attach(this.element.querySelector<HTMLElement>("div.table"));
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ClientExportForm(this.context, {
            style: "Light",
            title: "components.ClientExportForm.title",
            exportPdf: true,
            exportCsv: true,
            overlay: true,
            closable: true
        });

        // Handle PDF export
        form.onPdfExport = (orientation: string, title?: string) => {
            title = title ?? this.context.locale.getMessage(`components.${this.name}.title`);
            InvipoHelpers.toPdf(this.chart, orientation, title);
        }

        // Handle CSV export
        form.onCsvExport = (name: string) => {
            //this.exportPdf(orientation, title);
            this.table.exportCsv(name);
        }

        // Show form
        form.attach();
    }

    public async filter(search: any): Promise<any> {
        this.options.search = search;

        // Load new data
        await this.load();
    }

    public toggleForm(): void {
        // OnFormToggle handler
        this.onFormToggle();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // URL query
        let query = this.options.query ?? "";

        // Grouping active?
        if (this.options.groups) {
            // Selected group
            let group = this.options.groups.find(x => x.selected);

            if (!group) {
                Log.w(`At last one group in ${this.id} must be selected`);
                return;
            }

            // Group parameter is mandatory
            query += `&group=${Helpers.toCamelCase(group.name)}`;
        }

        if (this.options.search) {
            for (let key of Object.keys(this.options.search)) {
                let value = this.options.search[key];

                // Null value?
                if (value == null) {
                    continue;
                }

                // Interval (from - to)
                if (value.from || value.to) {
                    query += `&from=${value.from}`;
                    query += `&to=${value.to}`;
                }
                // Coma separated values
                else if (typeof value == "object") {
                    query += `&${key}=${Helpers.toCommaKeys(value)}`;
                }
                // String value
                else {
                    query += `&${key}=${value}`;
                }
            }
        }

        // Query API
        if (this.options.queryName) {
            this.data = await this.context.invipo.getQuery(Helpers.toKebabCase(this.options.queryName), query);
        }
        // Dataset API
        else if (this.options.dataset) {
            let dataset = await this.context.invipo.getDataset(Helpers.toKebabCase(this.options.dataset), query);
            this.data = dataset?.data;
        }
        // Model API
        else {
            Log.w(`No query or dataset defined to fetch data from`);
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // OnDataLoad handler
        this.onDataLoad(this.data);

        // Calculate data highlights and create legend
        if (this.data?.length > 0) {
            this.createHighlights();
            this.createLegend();
            this.createTable();
        }

        // Hide loader
        this.hideLoader();

        // Update with data
        this.update();

        // Which data view?
        if (this.options.view == "Chart") {
            // Chart has data?
            if (this.data?.length > 0) {
                this.drawChart();
            }
        }
        else {
            // Render table
            this.drawTable();
        }
    }
}
