import { AreaLayer } from "../area-layer";
import { InvipoContext } from "../../context/invipo-context";
import { TRANSITION_DURATION } from "../../../muklit/components/basic-map/map-layer";

const MIN_ZOOM = 8;
const MAX_ZOOM = 14;

export class PublicTransportAreaBoundaryLayer extends AreaLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            areaType: "PublicTransport",
            dynamic: true,
            before: "road-label",
            layer: {
                id: "public-transport-area-boundary",
                type: "line",
                minzoom: MIN_ZOOM,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "line-color": "#000000",
                    "line-width": 4,
                    "line-opacity": 0.15,
                    "line-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            }
        });
    }
}
