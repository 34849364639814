import { FeatureCollection, Geometry } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION, ZOOM_AREA } from "muklit/components/basic-map/map-layer";
import { InvipoHelpers } from "../../invipo-helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { Helpers } from "../../../hiyo/helpers";

export class ParkingLotCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            tooltip: true,
            card: "ParkingLotCard",
            detail: "CityDetail",
            layer: {
                id: "parking-lot-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                maxzoom: ZOOM_AREA,
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "ParkingLot"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // Gemoetry and occupancy color
            let geometry: Geometry = null;
            let occupancy = item.data?.occupancy?.overall?.occupancy;
            let symbol = "Unknown";
            let color = "#aaaaaa";

            // Has data?
            if (occupancy != undefined) {
                for (let i = 0; i < METRICS.parking.parking.occupancy.interval.length; i++) {
                    if (Math.max(occupancy, 0) >= METRICS.parking.parking.occupancy.interval[i][0] && Math.min(occupancy, 100) < METRICS.parking.parking.occupancy.interval[i][1]) {
                        symbol = METRICS.parking.parking.occupancy.range[i];
                        break;
                    }
                }

                // Get color from scale
                color = METRICS.parking.parking.occupancy.colors[METRICS.parking.parking.occupancy.range.indexOf(symbol)];
            }

            // Center from area if available
            if (item.geometry.area) {
                geometry = {
                    type: "Point",
                    coordinates: InvipoHelpers.toBounds(item.geometry.area).getCenter().toArray()
                }
            }

            // Capacity correspons to number of children
            let children = this.context.data.getItems({
                class: "ParkingSensor",
                parent: {
                    id: item.id
                }
            });

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: item.name,
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color,
                    scale: Helpers.range(5, 10, 0, 500, item.meta?.capacity?.total || children.length)
                },
                geometry: geometry || item.geometry["location"]
            })
        }

        return json;
    }


}
