import { Context } from "../../../hiyo/context";
import { InputOptions, InputValue } from "./types";
import { MuklitComponent } from "../muklit-component/muklit-component";

const REGEXP_NUMBER = /^-?[0-9,\.]+$/;
const REGEXP_EMAIL =  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
const REGEXP_PHONE = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

export abstract class Input<T extends Context = Context, U extends InputOptions = InputOptions> extends MuklitComponent<T, U> {

    // Event handling methods
    public onSubmit(): void {};
    public onClear(): void {};

    public abstract setValue(value: any | InputValue): void;

    public setDisabled(disabled: boolean) {
        // No change?
        if (this.options.disabled == disabled) {
            return;
        }

        this.options.disabled = disabled;

        // Update
        this.update();
    }

    public setInvalid(invalid: boolean, messageText?: string) {
        // No change?
        if (this.options.invalid == invalid && this.options.messageText == messageText) {
            return;
        }

        this.options.invalid = invalid;
        this.options.messageText = messageText;

        // Update
        this.update();
    }

    public validate(): string {
        // Required and null?
        if (this.options.required && (this.options.value == null || this.options.value.length == 0)) {
            return "forms.validation.Required";
        }

        // Invalid number?
        if (this.options.value?.length > 0 && this.options.format == "Number" && !REGEXP_NUMBER.exec(this.options.value)) {
            return "forms.validation.Invalid";
        }

        // Invalid email?
        if (this.options.value?.length > 0 && this.options.format == "Email" && !REGEXP_EMAIL.exec(this.options.value)) {
            return "forms.validation.Invalid";
        }

        // Invalid phone?
        if (this.options.value?.length > 0 && this.options.format == "Phone" && !REGEXP_PHONE.exec(this.options.value)) {
            return "forms.validation.Invalid";
        }

        // Invalid speed?
        if (this.options.value?.length > 0 && this.options.format == "Speed" && (!REGEXP_NUMBER.exec(this.options.value) || this.options.value <= 0 || this.options.value >= 300)) {
            return "forms.validation.InvalidSpeed";
        }

        // Invalid distance?
        if (this.options.value?.length > 0 && this.options.format == "Distance" && (!REGEXP_NUMBER.exec(this.options.value) || this.options.value <= 0 || this.options.value >= 10000)) {
            return "forms.validation.InvalidDistance";
        }

        // Invalid search?
        if (this.options.value?.length > 0 && this.options.format == "Search" && this.options.value.length < 2) {
            return "forms.validation.InvalidSearch";
        }

        // Fix correct type to prevent sending number as string
        if (this.options.value?.length > 0 && this.options.format == "Number") {
            this.options.value = Number(this.options.value);
        }

        // Validated
        return null;
    }
}
