import "./document-form.scss";
import * as template from "./document-form.hbs";
import { InvipoContext } from "../../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { DocumentFormOptions } from "./types";
import { InvipoItem, InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../../clients/invipo-client/types";
import { TextInput } from "muklit/components/text-input/text-input";

export class DocumentForm extends Detail<InvipoContext, DocumentFormOptions> {

    // Properties
    public document: InvipoItemDocument;

    // Components
    public filename: TextInput;

    public constructor(context: InvipoContext, options: DocumentFormOptions) {
        super(context, template, options);
    }

    // Event handling methods
    public onSubmit(): void {
    }

    public onCreate(): void {
        // Create components
        this.createFilename();

        // Register components that will be always attached
        this.registerComponent(this.filename, "filename");
    }

    public createFilename() {
        // Create component
        this.filename = new TextInput(this.context, {
            name: 'filename',
            label: 'components.ItemPassportForm.name',
            required: true
        });
    }

    public async submit(): Promise<void> {
        let fileInput = <HTMLInputElement>this.element.querySelector('input[type=file]');

        // Invalid form data?
        const invalidFilename = this.filename.validate()
        if (invalidFilename) {
            this.filename.setInvalid(true, invalidFilename);
            return;
        }

        const invalidFile = !fileInput.files || !fileInput.files[0]
        if (invalidFile) {
            return;
        }

        // Form data
        let filename = this.filename.options.value;
        let file = fileInput.files[0];

        console.log(filename, file);

        // Show loader
        this.showLoader();

        // Update existing user
        try {
            const result = await this.context.invipo.createItemDocument(this.options.itemId, filename, file);
            console.log(result);
        } finally {
            this.hideLoader();
        }

        // Close self
        this.close();

        // OnSubmit handler
        this.onSubmit();
    }

    public async load(): Promise<void> {
        // // Show loader
        // this.showLoader();

        // // Load all data
        // this.item = await this.context.invipo.getItem(this.options.itemId);
        // this.documents = await this.context.invipo.getItemDocuments(this.item.id);
        // this.properties = await this.context.invipo.getItemPassports(this.item.id);
        // this.stream = await this.context.invipo.getItemStream(this.item.id);
        // this.calendar = await this.context.invipo.getItemScheduler(this.item.id);

        // // Component might be gone while loading
        // if (!this.isAttached()) {
        //     return;
        // }

        // // Hide loader
        // this.hideLoader();

        // // Set data from options to form
        // this.documentsTable?.setData(this.documents);
        // this.propertiesTable?.setData(this.properties);
        // this.streamTable?.setData(this.stream);
        // this.calendarTable?.setData(this.calendar);
    }

}
