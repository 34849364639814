import { Feature, FeatureCollection, Point } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { PublicTransportVehicleLayer } from "./public-transport-vehicle-layer";

export class PublicTransportVehicleHeadingLayer extends PublicTransportVehicleLayer {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            layer: {
                id: "public-transport-vehicle-heading",
                type: "symbol",
                minzoom: 15,
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "icon-opacity": [
                        "case",
                        ["has", "vehicleHeading"], 1,
                        0
                    ]
                },
                layout: {
                    "icon-image": "16-tools-caret_up",
                    "icon-offset": [0, -20],
                    "icon-rotate": [
                        "case",
                        ["has", "vehicleHeading"], ["get", "vehicleHeading"],
                        0
                    ],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                }
            }
        });
    }

    public toFeature(vehicle: any): Feature<Point> {
        return {
            type: "Feature",
            properties: {
                type: "Location",
                vehicleId: vehicle.vehicleId,
                vehicleType: vehicle.type,
                vehicleLine: vehicle.line,
                vehicleDeviation: vehicle.deviation,
                vehicleSpeed: vehicle.speed,
                vehicleHeading: vehicle.heading
            },
            geometry: vehicle.position
        }
    }

    public async load(): Promise<any> {
        // Load vehicles
        let vehicles = await this.context.invipo.getDataset("public-vehicles");

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let vehicle of vehicles.data) {
            json.features.push(this.toFeature(vehicle))
        }

        return json;
    }
}
