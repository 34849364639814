import "./traffic-detail-repository.scss";
import * as template from "./traffic-detail-repository.hbs";

import { InvipoContext } from "invipo/context/invipo-context";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { TrafficDetailRepositoryOptions } from "./types";
import { Select } from "muklit/components/select/select";
import { MenuItem } from "muklit/components/overflow-menu/types";

export class TrafficDetailRepository extends MuklitComponent<InvipoContext, TrafficDetailRepositoryOptions> {

    // Properties
    public data: any;
    public groups: any[];
    public detectors: any[];
    public scrollTop: number;

    // Components
    public source: Select;
    public type: Select;

    // Event handling methods
    public onGroupSelected(no: number): void {}
    public onDetectorSelected(no: number): void {}

    constructor(context: InvipoContext, options?: TrafficDetailRepositoryOptions) {
        super(context, template, options);
    }

    public onAttach(): void {
        // Preserve current scroll
        if (this.scrollTop) this.element.scrollTop = this.scrollTop;
        this.element.onscroll = () => this.scrollTop = this.element.scrollTop;
    }

    public onLoad(): void {
        let markers = this.options.item?.schema?.orto?.markers ?? [];

        // Is a group already selected
        const selectedGroup = this.groups?.find(x => x.selected)?.no;
        this.groups = this.options.item?.meta?.groups?.map((x: any) => ({
            no: x.no,
            name: x.name,
            count: markers.filter((m: any) => m.name === "TrafficLightMarker" && m.options.no === x.no).length,
            selected: selectedGroup === x.no
        })) ?? [];

        const selectedDetector = this.detectors?.find(x => x.selected)?.no;
        this.detectors = this.options.item?.meta?.detectors?.map((x: any) => ({
            no: x.no,
            name: x.name,
            count: markers.filter((m: any) => m.name === "TrafficDetectorMarker" && m.options.no === x.no).length,
            selected: selectedDetector === x.no
        })) ?? [];

        this.update();
    }

    public selectGroup (no: number, selected: boolean = true) {
        for (const group of this.groups) {
            group.selected = group.no === no && selected;
        }

        for (const detector of this.detectors) {
            detector.selected = false;
        }

        this.update();
    }

    public selectDetector (no: number, selected: boolean = true) {
        for (const group of this.groups) {
            group.selected = false;
        }

        for (const detector of this.detectors) {
            detector.selected = detector.no === no && selected;
        }

        this.update();
    }
}
