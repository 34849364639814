import './item-inspector-tile.scss';
import * as template from "./item-inspector-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileKpis, TileLegend, TileList } from "../../common/tile/types";
import { ItemInspectorTileOptions } from "./types";
import { Helpers } from "hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";
import { Detail } from "muklit/components/detail/detail";
import { COMPONENT_STORE } from "../../../store";
import { CityDetail } from "../../city/city-detail/city-detail";

export class ItemInspectorTile extends Tile<ItemInspectorTileOptions> {

    // Properties
    public kpis: TileKpis;
    public legend: TileLegend;
    public list: TileList;

    constructor(context: InvipoContext, options?: ItemInspectorTileOptions) {
        super(context, template, options);
    }

    public selectList(i: number): void {
        // Get item
        let item = this.items.find(x => x.id == this.list.items[i].id);

        // Open city detail
        let detail: Detail = new CityDetail(this.context, {
            title: null,
            itemId: item.id,
            itemClass: item.class,
            itemName: item.name
        });

        // Show detail
        detail.attach();
    }

    public async extraLoad(): Promise<void> {
        // Load data
        this.items = this.context.data.getItems({
            sort: "name:asc"
        });

        // Filter out items with problems
        let problematicItems = this.items.filter(x => ["Error", "Disconnected", "Unreachable"].includes(x.monitoringStatus?.status));

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.ItemInspectorTile.total",
                    value: Helpers.toNumber(this.items.length)
                },
                {
                    label: "components.ItemInspectorTile.problematic",
                    value: Helpers.toNumber(problematicItems.length)
                }
            ]
        }

        // Builld items legend
        this.legend = {
            flat: true,
            symbols: []
        };

        for (let i in METRICS.infrastructure.technology.status.range) {
            let symbol = METRICS.infrastructure.technology.status.range[i]
            let count = this.items.filter(x => x.monitoringStatus?.status == symbol).length;

            // Add row to legend
            this.legend.symbols.push({
                metrics: "State",
                symbol: symbol,
                count: count,
                label: `monitoringStatus.${symbol}`,
                percent: count / (this.items.length || 1) * 100,
                color: METRICS.infrastructure.technology.status.colors[i],
                selectable: true
            });
        }

        // Builld items list
        this.list = {
            items: []
        };

        for (let item of problematicItems) {
            // Get symbol index
            let i = METRICS.infrastructure.technology.status.range.indexOf(item.monitoringStatus.status);

            this.list.items.push({
                name: item.name,
                label: this.context.locale.getMessage(`monitoringStatus.${item.monitoringStatus.status}`),
                tooltip: `classes.${item.class}`,
                id: item.id,
                color: METRICS.infrastructure.technology.status.colors[i],
                link: true
            });
        }

        // Create tile status
        if (problematicItems.length) {
            this.status = {
                icon: "Error",
                label: "components.ItemInspectorTile.statusError"
            }
        }
        else {
            this.status = {
                icon: "Success",
                label: "components.ItemInspectorTile.statusOk"
            }
        }
    }
}
