import "./user-profile.scss";
import * as template from "./user-profile.hbs";

import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { InvipoContext } from "../../../context/invipo-context";
import { UserProfileOptions } from "./types";
import { TextInput } from "muklit/components/text-input/text-input";
import { Toggle } from "muklit/components/toggle/toggle";
import { User } from "invipo/clients/invipo-client/types";
import { Form } from "muklit/components/form/form";
import { InvipoApplication } from "invipo/invipo-application";
import { Checkbox } from "muklit/components/checkbox/checkbox";
import { Input } from "muklit/components/input/input";
import { Dialog } from "muklit/components/dialog/dialog";
import { Helpers } from "hiyo/helpers";

export class UserProfile extends MuklitComponent<InvipoContext, UserProfileOptions> {

    // Components
    public accountForm: Form;
    public securityForm: Form;
    public permissionsForm: Form;
    public otpToggle: Toggle;

    // Properties
    private user: User;
    private permissions: Record<string, boolean> = {};

    constructor(context: InvipoContext, options?: UserProfileOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createAccountForm();
        this.createSecurityForm();
        this.createOtpToggle();
        this.createPermissionsForm();

        // Register components that will be automatically attached
        this.registerComponent(this.accountForm, "account-form");
        this.registerComponent(this.securityForm, "security-form");
        this.registerComponent(this.otpToggle, "otp-toggle");
        this.registerComponent(this.permissionsForm, "permissions-form");
    }

    public async load(): Promise<void> {
        // Load user and their image
        this.user = await this.context.invipo.getUser(this.context.user.id);

        this.permissions = this.context.options.permissions.reduce((s: Record<string, boolean>, x) => {
            s[x] = this.user.permissions?.includes(x) ?? false;
            return s;
        }, {});

        // Set data to forms
        this.accountForm.setData(this.user);
        this.securityForm.setData(undefined);
        this.otpToggle.setValue(this.user.otp);
        this.otpToggle.setDisabled(!this.user?.phone)
        this.permissionsForm.setData(this.permissions);

        // Reset validation messages
        this.accountForm.options.fieldsets.forEach(x => x.fields.forEach(y => y.setInvalid(false, y.options.messageText)));

        // Reset validation messages and empty inputs
        this.securityForm.options.fieldsets.forEach(x => x.fields.forEach(y => y.setInvalid(false, y.options.messageText)));

        // Update view
        this.invalidate(true);
    }

    public createAccountForm () {
        const name = new TextInput(this.context, {
            style: "Light",
            name: "name",
            label: "forms.fields.name",
            width: 280,
            bright: false,
            value: this.user?.name,
            required: true
        });

        name.onKey = () => {
            // Show buttons
            this.query("div.account div.buttons").classList.remove("hidden");
        }

        const userName = new TextInput(this.context, {
            style: "Light",
            name: "username",
            label: "forms.fields.username",
            width: 280,
            bright: false,
            value: this.user?.name,
            required: true,
            disabled: true,
            messageText: "components.UserProfile.usernameCannotChange"
        });

        userName.onKey = () => {
            // Show buttons
            this.query("div.account div.buttons").classList.remove("hidden");
        }

        const email = new TextInput(this.context, {
            style: "Light",
            name: "email",
            label: "forms.fields.email",
            width: 280,
            bright: false,
            value: this.user?.email,
            required: true
        });

        email.onKey = () => {
            // Show buttons
            this.query("div.account div.buttons").classList.remove("hidden");
        }

        const phone = new TextInput(this.context, {
            style: "Light",
            name: "phone",
            label: "forms.fields.phone",
            width: 280,
            bright: false,
            value: this.user?.phone,
            required: this.user?.otp
        });

        phone.onKey = () => {
            // Show buttons
            this.query("div.account div.buttons").classList.remove("hidden");
        }

        this.accountForm = new Form(this.context, {
            style: "Light",
            fieldsets: [{
                name: "name",
                fields: [
                    name,
                    userName
                ]
            }, {
                name: "contacts",
                fields: [
                    email,
                    phone
                ]
            }]
        });
        this.accountForm.onSubmit = () => this.saveAccount();
    }

    public createSecurityForm() {
        const oldPassword = new TextInput(this.context, {
            style: "Light",
            name: "oldPassword",
            label: "forms.fields.currentPassword",
            width: 280,
            bright: false,
            password: true,
            required: true
        });
        oldPassword.onKey = () => {
            // Show buttons
            this.query("div.security div.buttons").classList.remove("hidden");
        }

        const newPassword = new TextInput(this.context, {
            style: "Light",
            name: "newPassword",
            label: "forms.fields.newPassword",
            width: 280,
            bright: false,
            password: true,
            required: true
        });

        newPassword.onKey = () => {
            // Show buttons
            this.query("div.security div.buttons").classList.remove("hidden");
        }

        const retypePassword = new TextInput(this.context, {
            style: "Light",
            name: "retypePassword",
            label: "forms.fields.checkPassword",
            width: 280,
            bright: false,
            password: true,
            required: true
        });

        retypePassword.onKey = () => {
            // Show buttons
            this.query("div.security div.buttons").classList.remove("hidden");
        }

        this.securityForm = new Form(this.context, {
            style: "Light",
            fieldsets: [{
                name: "old-password",
                fields: [
                    oldPassword
                ]
            }, {
                name: "new-password",
                fields: [
                    newPassword,
                    retypePassword
                ]
            }]
        });

        this.securityForm.onSubmit = () => this.saveSecurity();
    }

    public createOtpToggle () {
        this.otpToggle = new Toggle(this.context, {
            style: "Light",
            name: "2f-auth",
            size: "Tall",
            value: this.user?.otp,
            disabled: !this.user?.phone
        });

        this.otpToggle.onToggle = () => {
            this.query("div.security div.buttons").classList.remove("hidden");
        }
    }

    public createPermissionsForm () {
        // User profile form
        let fields: Input[] = [];

        // Generate permission fields from project config
        for (let permission of this.context.options.permissions) {
            fields.push(
                new Checkbox(this.context, {
                    style: "Light",
                    name: permission,
                    label: `permissions.${permission}`,
                    disabled: true
                })
            );
        }

        this.permissionsForm = new Form(this.context, {
            fieldsets: [
                {
                    name: "Right",
                    fields: fields
                }
            ]
        });
    }

    public logout(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.InvipoMenu.logoutUser",
            text: "components.InvipoMenu.reallyLogoutUser",
            labelCancel: "labels.cancel",
            labelConfirm: "labels.logout"
        });

        // OnUserLogout handler
        dialog.onConfirm = async () => {
            // Fadeout animation
            document.body.style.opacity = "0";

            // Wait for animation
            await Helpers.sleep(300);

            // Delete user token
            await this.context.invipo.logoutUser();

            // We need to remove user principal from context
            this.context.setUser(null);

            // After refresh with no user in context login page will be displayed again
            document.location.reload();
        }

        // Show dialog
        dialog.attach();
    }

    public async saveAccount () {
        // Validate form
        if (!this.accountForm.validate()) return;

        // Get data from form
        this.user = { ...this.user, ...this.accountForm.getData() };

        // Disable otp if there is no phone number
        if (!this.user.phone) this.user.otp = false;

        // Update user data
        try {
            await this.context.invipo.updateUserProfile(this.user.id, this.user)
        }
        catch (e) {
            if (e.status == 422) {
                this.accountForm.setValidationErrors(e.response);
                return;
            }
        }

        // Update avatar
        await this.context.invipo.updateUserAvatar(this.user.id, this.user.avatar)

        // Redraw
        this.invalidate();
    }

    public async saveSecurity () {
        // Save otp
        this.user.otp = this.otpToggle.options.value;
        await this.context.invipo.updateUserProfile(this.user.id, this.user);

        // Update password
        const data = this.securityForm.getData();

        if (data.oldPassword || data.newPassword || data.retypePassword) {
            if (!this.securityForm.validate()) return;

            // New password has to match with the control input
            if (data.newPassword !== data.retypePassword) {
                this.securityForm.setValidationErrors([{ field: "retypePassword", code: "PasswordsDontMatch" }])
                return;
            }

            // Save new password
            try {
                await this.context.invipo.updateUserPassword(this.user.id, data);

                // notify password change
                let app = <InvipoApplication>this.context.application;
                app.toasts.showInfoToast("components.UserProfile.title", "components.UserProfile.passwordChanged")
            }
            catch (e) {
                if (e.status == 422) {
                    this.securityForm.setValidationErrors(e.response);
                    return;
                }
            }
            this.securityForm.setData(undefined)
        }

        // Redraw
        this.invalidate();
    }

    public removePicture(): void {
        // Hide avatar image
        const img: HTMLImageElement = this.query("div.picture div.current img");
        img.parentElement.classList.add("hidden");

        // Clear avatar value
        this.user.avatar = null;

        // Show buttons
        this.query("div.account div.buttons").classList.remove("hidden");
    }

    public selectImage(): void {
        // Trigger click on hidden file input
        const input: HTMLInputElement = this.query("#imgupload");
        input.click();
        input.onchange = () => this.uploadImage(input.files[0]);
    }

    public dropImage(e: DragEvent): void {
        // prevent default action (dont open new tab)
        e.stopPropagation();
        e.preventDefault();

        // First file
        const file = e.dataTransfer.files[0];

        // Only png and jpeg is accepted
        if (["image/png", "image/jpeg"].includes(file.type)) {
            this.uploadImage(file);
        }
    }

    private uploadImage(file: File): void {
        // Load image file
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            // Load image data
            const loadImg = new Image();
            loadImg.onload = () => {
                // Crop and update image
                this.user.avatar = this.cropImage(loadImg);

                // Display new image
                const img: HTMLImageElement = this.query("div.picture div.current img");
                img.parentElement.classList.remove("hidden");
                img.src = this.user.avatar;

                // Image updated - show buttons
                this.query("div.account div.buttons").classList.remove("hidden");

            }
            loadImg.src = event.target.result.toString();
        });

        reader.readAsDataURL(file);
    }

    private cropImage (image: HTMLImageElement): string {
        // Scale down and crop image

        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = 160;
        canvas.height = 160;
        const ctx = canvas.getContext("2d");

        let offsetX = 0;
        let offsetY = 0;
        let sizeX = canvas.width;
        let sizeY = canvas.height;

        // Image is wider => stretch to max height and offset -x
        if (image.naturalWidth > image.naturalHeight) {
            const r = image.naturalWidth / image.naturalHeight;
            sizeX = sizeY * r;
            offsetX = -1 * (sizeX - canvas.width) / 2
        }

        // Image is higher => stretch to max width and offset -y
        if (image.naturalHeight > image.naturalWidth) {
            const r = image.naturalHeight / image.naturalWidth;
            sizeY = sizeX * r;
            offsetY = -1 * (sizeY - canvas.height) / 2
        }

        // Render
        ctx.drawImage(image, offsetX, offsetY, sizeX, sizeY);

        // Return base64
        return canvas.toDataURL();
    }
}
