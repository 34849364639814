import "./item-detail.scss";
import "./item-detail.scss";
import * as template from "./item-detail.hbs";
import { InvipoItem, InvipoItemDocument, InvipoItemPassport, InvipoItemSchedule, InvipoItemStream } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Tabs } from "muklit/components/tabs/tabs";
import { Detail } from "muklit/components/detail/detail";
import { ItemDetailOptions } from "./types";
import { BasicMap } from "muklit/components/basic-map/basic-map";
import { InvipoApplication } from "../../../invipo-application";
import { ImageDetail } from "../../common/image-detail/image-detail";
import { Feature, Point } from "geojson";
import { ItemGeometryForm } from "../item-geometry-form/item-geometry-form";
import { ItemPassportForm } from "../../devices/item-passport-form/item-passport-form";
import { ItemSingleCircleLayer } from "../../../layers/infrastructure/item-single-circle-layer";
import { ParkingCapacityForm } from "../../parking/parking-capacity-form/parking-capacity-form";
import { TrafficDetail } from "invipo/components/devices/traffic-detail/traffic-detail";

export class ItemDetail extends Detail<InvipoContext, ItemDetailOptions> {

    // Properties
    public item: InvipoItem;
    public documents: InvipoItemDocument[];
    public stream: InvipoItemStream[];
    public calendar: InvipoItemSchedule[];
    public properties: InvipoItemPassport[];

    // Components
    public tabs: Tabs;
    public map: BasicMap;

    // Event handling methods
    public onSelectDetail(item: InvipoItem): void {}
    public onSelectDelete(item: InvipoItem): void {}

    constructor(context: InvipoContext, options: ItemDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createMap();
    }

    public onAttach() {
        // Remove layers we have added previously
        this.map.removeLayers();

        // Add map layer
        this.map.addLayer(new ItemSingleCircleLayer(this.context, this.options.itemId));
    }

    private createMap(): void {
        // Create component
        this.map = new BasicMap(this.context, {
            style: "Light",
            center: this.context.options.overview?.center || this.context.options.home.center,
            zoom: this.context.options.overview?.zoom || this.context.options.home.zoom,
            minZoom: 2,
            maxZoom: 20
        });

        // Zoom on click
        this.map.onFeatureClick = (layer: string, feature: Feature, event?: MouseEvent) => {
            // Zoom to area center
            this.map.flyTo({
                center: (<Point>feature.geometry).coordinates,
                zoom: 17
            });
        }

        // Register component
        this.registerComponent(this.map, "map");
    }

    public async disable(disabled: boolean): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        //await this.context.invipo.disableItem(this.options.itemId, disabled);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        //this.onDetailUpdate();
    }

    public selectDetail(): void {
        // OnSelectDetail handler
        this.onSelectDetail(this.item);
    }

    public selectDelete(): void {
        // OnSelectDelete handler
        this.onSelectDelete(this.item);
    }

    public selectGeometry(): void {
        // New image detail
        let form = new ItemGeometryForm(this.context, {
            style: "Light",
            title: "components.ItemGeometryForm.title",
            item: this.item,
            overlay: true,
            closable: true
        });

        // Reload self after close
        form.onSubmit = async () => {
            await this.load();
        }

        // Show
        form.attach();
    }

    public selectImage(urls: string[]): void {
        // New image detail
        let detail = new ImageDetail(this.context, {
            style: "Dark",
            title: "components.ImageDetail.title",
            url: urls[0],
            urls: urls,
            overlay: true,
            closable: true
        });

        // Show
        detail.attach();
    }

    public async copyData(): Promise<void> {
        // Copy to clipboard and show toast
        await navigator.clipboard.writeText(JSON.stringify(this.item.data, null, 2));
        (<InvipoApplication>this.context.application).toasts.showInfoToast(null, "components.ItemDetail.dataCopied");
    }

    public selectPassports() {
        // Detail form
        let form = new ItemPassportForm(this.context, {
            style: "Light",
            title: null,
            itemId: this.item.id,
            overlay: true,
            closable: true,
            preventEsc: true
        });

        // Refresh on submit
        form.onClose = async () => {
            // Reload table
            await this.load();
        }

        // Show form
        form.attach();
    }

    public selectCapacity() {
        // Edit form
        let form = new ParkingCapacityForm(this.context, {
            style: "Light",
            title: "components.ParkingCapacityForm.title",
            item: this.item,
            overlay: true
        })

        // OnSubmit handler
        form.onSubmit = async () => {
            // Refresh item
            await this.load();
        }

        // Show dialog
        form.attach();
    }

    public selectSchema() {
        let detail = new TrafficDetail(this.context, {
            style: "Light",
            title: "components.TrafficDetail.title",
            itemId: this.item.id,
            overlay: true,
            closable: true
        });

        detail.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.item = this.context.data.getItem(this.options.itemId);

        // Item passports enabled?
        if (this.context.options.features?.includes("ItemPassports")) {
            this.documents = await this.context.invipo.getItemDocuments(this.options.itemId);
            this.properties = await this.context.invipo.getItemPassports(this.options.itemId);
            this.stream = await this.context.invipo.getItemStream(this.options.itemId);
            this.calendar = await this.context.invipo.getItemScheduler(this.options.itemId);
        }

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
