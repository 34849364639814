import './public-transport-stop-list.scss';
import * as template from "./public-transport-stop-list.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { PublicTransportStopListOptions } from "./types";
import { CityList } from "../../city/city-list/city-list";

export class PublicTransportStopList extends CityList<PublicTransportStopListOptions> {

    // Properties
    public stops: any[];

    constructor(context: InvipoContext, options?: PublicTransportStopListOptions) {
        super(context, template, options);
    }

    public selectStop(i: number): void {
        // Get item
        let stop = this.stops[i];

        // Call handler
        this.onPositionNavigate((stop.position.type == "LineString") ? stop.position.coordinates[0] : stop.position.coordinates);
    }

    public async extraLoad(): Promise<void> {
        // Load items
        this.stops = await this.context.invipo.getManagement("transport/public/stops");
    }

    public async load(): Promise<void> {
        // No loading when items already assigned
        if (this.stops) {
            return ;
        }

        // Load handilig by CityList
        await super.load();
    }
}
