import { Chart, ChartTooltipModel } from "chart.js";
import { Helpers } from "hiyo/helpers";
import { DataTable } from "muklit/components/data-table/data-table";
import { ElementTooltip } from "../../common/element-tooltip/element-tooltip";
import { InvipoHelpers } from "../../../invipo-helpers";
import { DEFAULT_FONT, StatsChart } from "../../common/stats-chart/stats-chart";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { BatteryStatusChartOptions } from "./types";

export class BatteryStatusChart extends StatsChart<BatteryStatusChartOptions> {

    // Properties
    public item: InvipoItem[];

    public createLegend() {
        this.legend = [
            {
                type: "Line",
                label: "tables.columns.temperature",
                color: InvipoHelpers.toChartColor(1)
            },
            {
                type: "Line",
                label: "tables.columns.voltage",
                color: InvipoHelpers.toChartColor(2)
            }
        ];
    }

    public createHighlights(): void {
        // Reset
        this.highlights = {};

        // Sum speed
        let temperature = 0;

        // First and last
        let first = this.data[0];
        let last = this.data[this.data.length - 1];

        // Minimum and maximum
        let min = { ...first };
        let max = { ...first };

        for (let data of this.data) {
            if (data.temperature < min.temperature) {
                min = data;
            }
            if (data.temperature > max.temperature) {
                max = data;
            }
        }

        // Maximum
        this.highlights.highest = {
            label: "components.StatsChart.highlights.highest",
            value: Helpers.toNumber(max.temperature, 1),
            units: "units.celsius",
            description: Helpers.toDateTimeString(max.timestamp)
        }

        // Minimum
        this.highlights.lowest = {
            label: "components.StatsChart.highlights.lowest",
            value: Helpers.toNumber(min.temperature, 1),
            units: "units.celsius",
            description: Helpers.toDateTimeString(min.timestamp)
        }
    }

    public createTable() {
        this.table = new DataTable(this.context, {
            style: "Light",
            type: "Unselectable",
            size: "Short",
            height: "100%",
            autosort: true,
            data: this.data,
            rows: {
                id: "timestamp"
            },
            columns: [
                {
                    name: "timestamp",
                    type: "DateTime",
                    property: "timestamp",
                    label: "tables.columns.timestamp",
                    width: 160,
                    sortable: true,
                    selected: true
                },
                {
                    name: "temperature",
                    type: "Number",
                    property: "temperature",
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return null;
                        }
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} &deg;C</div>`
                    },
                    label: "tables.columns.temperature",
                    width: 120,
                    align: "Right",
                    sortable: true
                },
                {
                    name: "voltage",
                    type: "Number",
                    property: "voltage",
                    formatter: (value: any, data: any) => {
                        if (value == null) {
                            return null;
                        }
                        return `<div class="cell cell-right">${Helpers.toNumber(value, 3, 3)} &deg;V</div>`
                    },
                    label: "tables.columns.voltage",
                    width: 120,
                    align: "Right",
                    sortable: true
                },
                {
                    name: "last",
                    type: "String",
                    property: "last",
                    label: null,
                    align: "Center"
                }
            ]
        });
    }

    public drawChart(): void {
        // Canvas to render to
        let canvas = (this.query<HTMLCanvasElement>("div.chart canvas"));

        // Labels and data
        let labels: any[] = [];
        let temperatureData: any[] = [];
        let voltageData: any[] = [];

        for (let d of this.data) {
            labels.push(d.timestamp);
            temperatureData.push(d.temperature);
            voltageData.push(d.voltage);
        }

        // Vehicles count graph
        this.chart = new Chart(canvas,
            {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "tables.columns.temperature",
                            data: temperatureData,
                            yAxisID: "left",
                            borderWidth: 2,
                            pointRadius: 0,
                            hoverRadius: 0,
                            borderColor: InvipoHelpers.toChartColor(1),
                            lineTension: 0.2,
                            fill: false
                        },
                        {
                            label: "tables.columns.voltage",
                            data: voltageData,
                            yAxisID: "right",
                            borderWidth: 2,
                            pointRadius: 0,
                            hoverRadius: 0,
                            borderColor: InvipoHelpers.toChartColor(2),
                            lineTension: 0.2,
                            fill: false
                        }
                    ]
                },
                options: {
                    //...DEFAULT_ANIMATION,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    animation: {
                        duration: 0
                    },
                    tooltips: {
                        mode: "index",
                        enabled: false,
                        intersect: false,
                        custom: (model: ChartTooltipModel) => {
                            // Hide tooltip if visible
                            this.tooltip?.hide();

                            // Tooltip value exists?
                            if (model.dataPoints) {
                                let text = "";

                                text += `<div style="margin-bottom: 4px">${Helpers.toDateTimeString(model.dataPoints[0].label)}</div>`;
                                text += `${this.context.locale.getMessage("tables.columns.temperature")} ${Helpers.toNumber(model.dataPoints[0].value, 1)} °C<br />`;
                                text += `${this.context.locale.getMessage("tables.columns.voltage")} ${Helpers.toNumber(model.dataPoints[1].value, 1)} V<br />`;

                                // New tooltip
                                this.tooltip = new ElementTooltip(this.context, {
                                    style: "Dark",
                                    text: text
                                });

                                // Position tooltip;
                                let element = this.query("div.chart canvas");
                                let box = element.getBoundingClientRect();
                                this.tooltip.show(model.caretX + box.x, model.caretY + box.y, 100);
                            }
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                type: "time",
                                time: {
                                    unit: "day"
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    padding: 10,
                                    min: this.options.search?.from ? Object.keys(this.options.search.from)[0] : null,
                                    max: this.options.search?.to ? Object.keys(this.options.search.to)[0] : null,
                                    callback: (value: any): string | number => {
                                        return Helpers.toShortDateString(value);
                                    }
                                },
                                gridLines: {
                                    display: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                id: "left",
                                display: true,
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("tables.columns.temperature")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    min: 0,
                                    max: 100,
                                    stepSize: 10,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value)} °C`;
                                    }
                                },
                                gridLines: {
                                    display: true,
                                    drawBorder: false,
                                    color: "rgba(141, 141, 141, 0.1)",
                                    zeroLineColor: "rgba(141, 141, 141, 0.1)",
                                    drawOnChartArea: true
                                }
                            },
                            {
                                id: "right",
                                display: true,
                                position: "right",
                                scaleLabel: {
                                    ...DEFAULT_FONT,
                                    display: true,
                                    labelString: this.context.locale.getMessages("tables.columns.voltage")
                                },
                                ticks: {
                                    ...DEFAULT_FONT,
                                    min: 10,
                                    max: 12,
                                    stepSize: 0.2,
                                    padding: 30,
                                    beginAtZero: true,
                                    callback: (value: any): string | number => {
                                        return `${Helpers.toNumber(value, 1, 1)} V`;
                                    }
                                },
                                gridLines: {
                                    display: false,
                                }
                            }

                        ]
                    }
                },
                plugins: [{
                    afterDraw: (chart: any) => {
                        if (chart.tooltip?._active?.length) {
                            let ctx = chart.ctx;
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(chart.tooltip._active[0]?._view.x, chart.scales.left.top);
                            ctx.lineTo(chart.tooltip._active[0]?._view.x, chart.scales.left.bottom);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = "rgba(141, 141, 141, 0.35)";
                            ctx.stroke();
                            ctx.restore();
                        }
                    }
                }]
            });
    }

    public async load(): Promise<void> {
        // Item not selected?
        if (!this.options.search["item.id"]) {
            // Reset data
            this.data = null;

            // Update and return
            return this.update();
        }

        // Call load to complete data loading
        await super.load();
    }

}
